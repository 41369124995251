import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Collapsible from 'react-collapsible';
import ReactPlayer from 'react-player'
import Bforbank_logo from '../../../../../assets/img/providers/bforbank.png';
import Button from '@material-ui/core/Button';
import Issoyo_logo from '../../../../../assets/img/fav_icon32@2x.png';
import bforbank_brochure from '../../../../../assets/document/ISSOYO_Bforbank_brochure.pdf';

require('./Bforbank.scss');

class Bforbank extends Component {
    constructor(props) {
        super(props);
        this.state = {
            position: '0',
            littlepos: '',
            style: {
                modal: {
                    'maxWidth': '700px',
                    'width': '100%',
                    'color': '#3f2751',
                    'textAlign': 'justify'
                },
            },
            youtubeHW: 'https://www.youtube.com/watch?v=ba4bthVohiY&feature=youtu.be',
						
        }
        this.handleClick = this.handleClick.bind(this);
        this._onReady = this._onReady.bind(this);
        this.handleClickAccount = this.handleClickAccount.bind(this);
    }

    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }

    handleClick(position) {
        if (this.state.position === position) {
            this.setState({ position: "0" })
        } else {
            this.setState({ position: position });
        }
    };

    handleClickAccount() {
        this.props.handleClickAccount("https://www.awin1.com/awclick.php?gid=343321&mid=13267&awinaffid=525947&linkid=2215025&clickref=");
    };

    render() {
        return (
            <div className="service-bank-transfertWise">
                <div className="header">
                    <div className="header_logo"><img className="header-pic" src={Bforbank_logo} alt=''></img></div>
                    <div className="header-title"><b><FormattedMessage id="service.bank.Bforbank.title" defaultMessage="BforBank, la banque selon moi modifier1..." /></b></div>
                </div>
                <div className="cont-mod">
                    <FormattedMessage id="bank.Bforbank.benefits" defaultMessage="Benefits / Features">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "1")} handleTriggerClick={this.handleClick} accordionPosition="1">
                                <p className='text'><FormattedMessage id="bank.Bforbank.reason" defaultMessage="BforBank propose une gamme de produits complète :" /></p>
                                <ul className='text'>
                                    <li><FormattedMessage id="bank.Bforbank.reason1" defaultMessage="Un Crédit Immobilier à taux compétitif avec 0€ de frais de dossier, pas d'indemnité de remboursement anticipé, et sans obligation d'ouvrir un compte bancaire ou de domicilier les revenus." /></li>
                                    <li><FormattedMessage id="bank.Bforbank.reason2" defaultMessage="Un Compte Bancaire Premium, incluant une carte Visa Premier offerte et une gamme d’outils et de services haut de gamme sans frais," /></li>
                                    <li><FormattedMessage id="bank.Bforbank.reason3" defaultMessage="Un livret d'épargne avec un taux attractif," /></li>
                                    <li><FormattedMessage id="bank.Bforbank.reason4" defaultMessage="Une assurance-vie sans droits d'entrée," /></li>
                                    <li><FormattedMessage id="bank.Bforbank.reason5" defaultMessage="Une offre Bourse et Fonds complète, dont 50 fonds spécialement choisis en partenariat avec AMUNDI, et l'accès à plus de 2000 fonds sans droits d'entrée." /></li>
                                    <li><FormattedMessage id="bank.Bforbank.reason6" defaultMessage="Service de mobilité avec clôture du compte d’origine en option" /></li>
                                </ul>
                            </Collapsible>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="bank.Bforbank.pricing" defaultMessage="Pricing">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "2")} handleTriggerClick={this.handleClick} accordionPosition="2">
                                <div className="tarif_bforbank" onClick={() => window.open("https://www.bforbank.com/files/live/sites/Bforbank/files/contributed/corporate/documentation/tarification-compte-bancaire.pdf", "_blank")}>
                                    <u><FormattedMessage id="bank.Bforbank.tarif" defaultMessage="Click here to see the pricing"></FormattedMessage></u>
                                </div>
                            </Collapsible>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="bank.Bforbank.howtodo" defaultMessage="How to do?">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "3")} handleTriggerClick={this.handleClick} accordionPosition="3">
                                <ul className='text'>
                                    <li><FormattedMessage id="bank.Bforbank.condition1" defaultMessage="Remplir et signer la demande d’ouverture de compte (5 minutes)" /></li>
                                    <li><FormattedMessage id="bank.Bforbank.condition2" defaultMessage="Deposer les pieces justificatives (1 justificatif d’identité ; 2 justificatifs de revenu ; 1 justificatif de domicile)" /></li>
                                    <li><FormattedMessage id="bank.Bforbank.condition3" defaultMessage="Effectuez un 1er versement de 300 euro " /></li>
                                </ul>
                            </Collapsible>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="bank.Bforbank.video" defaultMessage="Video">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "4")} handleTriggerClick={this.handleClick} accordionPosition="4">
                                <ReactPlayer
                                    width='100%'
                                    controls
                                    config={{
                                        youtube: {
                                            playerVars: { showinfo: 1 }
                                        }
                                    }}
                                    url={this.state.youtubeHW}
                                />
                            </Collapsible>
                        )}
                    </FormattedMessage>
                    <div className="habitation_document" style={{ marginTop: "24px", marginBottom: "48px" }}>
                        <div className="">
                            <div className="health_document-container" onClick={() => window.open(bforbank_brochure, "_blank")}>
                                <div style={{ textAlign: 'center' }}>
                                    <i className="fas fa-file-alt"></i> <FormattedMessage id="insurance.health.brochure" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer" style={{ textAlign: 'center' }}>
                    <Button variant="contained" color="primary" className="button" onClick={this.handleClickAccount}>
                        <img src={Issoyo_logo} alt='' style={{ width: '11%', marginRight: '5%' }}></img>
                        <FormattedMessage id="bank.open.account"></FormattedMessage>
                    </Button>
                </div>
            </div>
        )
    }
}

Bforbank.propTypes = {
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    service: state.services,
});

export default connect(
    mapStateToProps, {}
)(Bforbank);
