import React from 'react';
import Sectionf from './Sectionf.jsx';
import { Sections } from './Sections.jsx';
import { Sectiont } from './Sectiont.jsx';
import { Sectionfo } from './Sectionfo.jsx';
import { Sectionfive } from './Sectionfive.jsx';
import SmSectionf from './SmSectionf.jsx';
import { SmSections } from './SmSections.jsx';
import { SmSectiont } from './SmSectiont.jsx';
import { SmSectionfo } from './SmSectionfo.jsx';
import { SmSectionfive } from './SmSectionfive.jsx';
import { Footer } from './Footer.jsx';

require('./Home.scss')

function home_large(language, history) {
    return (
        <div className="hide-large" style={{ maxWidth: '1440px' }}>
            <Sectionf history={history} language={language} />
            <Sections language={language} />
            <Sectiont />
            <Sectionfo />
            <Sectionfive history={history} />
        </div>
    );
};

function home_small(language, history) {
    return (
        <div className="hide-small">
            <SmSectionf history={history} language={language} />
            <SmSections language={language} />
            <SmSectiont />
            <SmSectionfo />
            <SmSectionfive history={history} />
        </div>
    )
}
export class Home extends React.Component {
    constructor() {
        super();
        this.state = {
            home: null,
        }
        this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    }

    componentWillMount() {
        if (window.matchMedia("(min-width: 800px)").matches) {
            /* The viewport is at least 400 pixels wide */
            this.setState({ home: home_large(this.props.language, this.props.history) });
        } else {
            /* The viewport is less than 400 pixels wide */
            this.setState({ home: home_small(this.props.language, this.props.history) });
        }
        window.matchMedia(`(min-width: 800px)`).addListener(this.mediaQueryChanged);
    }

    mediaQueryChanged() {
        if (window.matchMedia("(min-width: 800px)").matches) {
            /* The viewport is at least 400 pixels wide */
            this.setState({ home: home_large(this.props.language, this.props.history) });
        } else {
            /* The viewport is less than 400 pixels wide */
            this.setState({ home: home_small(this.props.language, this.props.history) });
        }
    }

    render() {
        return (
            <div className="home">
                {this.state.home}
                <Footer />
            </div>

        )
    }
}

export default (Home);