import React from 'react';
import { FormattedMessage } from 'react-intl';
import image_section4 from "../../../assets/img/image_section4@2x.png";
/*import logo_orange_lg from "../../../assets/img/logo_orange_lg.png";
import logo_myfinimo_lg from "../../../assets/img/logo_myfinimo_lg.png";
import logo_sosh_lg from "../../../assets/img/logo_sosh_lg.png";
import logo_april_lg from "../../../assets/img/logo_april_lg.png";
import logo_transferwise_lg from "../../../assets/img/logo_transferwise_lg.png";*/

require('./Home.scss');

export class SmSectionfo extends React.Component {
    render() {
        return (
            <section className="section section-4">
        <div className="content-image">
          <div className="title">
          <div className="our-partners">
          <FormattedMessage id="home.sm.titlePartner" defaultMessage="Our partners" />          
          </div>
          </div>
          <div className="text">
          <div className="partnerships-allows">
          <FormattedMessage id="home.sm.textPartnerL2" defaultMessage="So far, we are working with a few but there is more to come." />                        
          </div>
          </div>
        </div>
        <img className="image-center" src={image_section4} alt=''/>
        { /* <!--
        <div className="img-shape-4">
            <div className="col-12 row align-items-center">
                <div className="col-6">
                    <img src="../../../../assets/img/logo_orange.png"/>
                </div>
                <div className="col-6">
                    <img src="../../../../assets/img/logo_myfinimo.png"/>
                </div>
            </div>
            <div className="col-12">
                <img src="../../../../assets/img/logo_sosh.png"/>
            </div>
            <div className="col-12 row align-items-center">
                <div className="col-6">
                    <img src="../../../../assets/img/logo_april.png"/>
                </div>
                <div className="col-6">
                    <img src="../../../../assets/img/logo_transferwise.png"/>
                </div>
            </div>
        </div>
        --> */}
        <div className="partnerButton">
        </div>
      </section>
        )
    }
}
