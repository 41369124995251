import React from 'react';
import ReactCountryFlag from "react-country-flag";
import { FormattedMessage } from 'react-intl';
import { countryList } from './countryList.js';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles';
import { countryAvailable } from './../Services/listCountry.js';
import PropTypes from "prop-types";
import { connect } from "react-redux";

require("./Countries.scss");

const styles = {
    root: {
        color: '#60239a',
        fontSize: '2rem',
        fontFamily: "'Nunito', sans-serif !important"
    },
};

class Countries extends React.Component {
    constructor(props) {
        super(props);
        this.countries = countryList;
        this.countryAvailable = countryAvailable[0];
        this.state = {
            filtered: this.countries,
            country: { label: '', value: '' },
            index: 0,
            popular: true,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
    }

    handleClick(country) {
        this.props.changeCountry(country.value, country.label);
    }

    _handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.props.changeCountry(this.state.filtered[0].value, this.state.filtered[0].label)
        }
    }

    handleChange(e) {
        let currentList = [];
        let newList = [];
        if (e.target.value !== "") {
            currentList = this.countries;
            newList = currentList.filter(item => {
                const lc = item.label.toLowerCase();
                const iso = item.value.toLowerCase();
                const filter = e.target.value.toLowerCase();
                if (lc.includes(filter)) {
                    return true;
                } else if (iso.includes(filter)) {
                    return true;
                } else if (lc === "united kingdom" && (filter === "uk" || filter === "UK")) {
                    return true;
                } else {
                    return false;
                }
            });
            this.setState({ popular: false });
        } else {
            // If the search bar is empty, set newList to original task list
            newList = this.countries;
            this.setState({ popular: true });
        }
        // Set the filtered state based on what our rules added to newList
        this.setState({
            filtered: newList
        });
    }

    changeHandler = value => {
        this.setState({ country: value })
    }

    render() {
        const { classes } = this.props;
        var country = this.state.filtered.map((item, id) => (
            <div className='button-country' key={id} onClick={() => this.handleClick(item)}>
                <b>{item.label}</b>
                <ReactCountryFlag
                    styleProps={{
                        width: '20px',
                        height: '20px',
                        marginLeft: '1%'
                    }}
                    code={item.value}
                />
            </div>
        ))
        return (
            <React.Fragment>
                <div className="country">
                    <div className="input-country">
                        <FormattedMessage id="country.where" defaultMessage="Where to go?">
                            {msg => (
                                <TextField
                                    id="standard-with-placeholder"
                                    placeholder={msg}
                                    className={classes.test}
                                    onKeyDown={this._handleKeyDown}
                                    margin="normal"
                                    onChange={this.handleChange}
                                    style={{ width: '100%' }}
                                    autoFocus
                                    InputProps={{ classes: { root: classes.root } }}
                                />
                            )}
                        </FormattedMessage>
                    </div>
                    <div className="list-country">
                        <div className='button-country' onClick={() => this.handleClick(this.countries[24])} style={this.state.popular ? {} : { display: 'none' }}>
                            <b>{this.countries[24].label}</b>
                            <ReactCountryFlag
                                styleProps={{
                                    width: '20px',
                                    height: '20px',
                                    marginLeft: '1%'
                                }}
                                code={this.countries[24].value}
                            />
                        </div>
                        {country}
                    </div>
                    <div className="text" style={this.state.popular ? {} : { display: 'none' }}>
                        <Divider variant="middle" style={{ width: "50%", marginLeft: "25%", backgroundColor: 'rgb(116, 35, 202, 0.49)' }} />
                        <div className="text" onClick={() => this.props.wishList()} style={{ textAlign: 'center', cursor: 'pointer' }}><u><FormattedMessage id="country.destination" defaultMessage="You wish to add a country? --> Let us know on the “wishlit”!" /> <i style={{ fontSize: '1.2rem', fontWeight: '600' }} className="fal fa-vote-yea"></i></u></div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

Countries.propTypes = {
    auth: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    service: state.services
});

export default connect(
    mapStateToProps,
    {}
)(withStyles(styles)(Countries));
