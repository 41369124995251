import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';

export default class TableRow extends Component {
    constructor(props) {
        super(props);
        this.delete = this.delete.bind(this);
        this.publish = this.publish.bind(this);
        this.unpublish = this.unpublish.bind(this);
    }

    publish(){
        const obj = {
            publish: true,
            request: 'publish'
        };
        axios.post('/api/service/updateblog/' + this.props.obj._id, obj).then(res => console.log(res.data));
        document.location.reload(true)
    }

    unpublish(){
        const obj = {
            publish: false,
            request: 'publish'
        };
        axios.post('/api/service/updateblog/' + this.props.obj._id, obj).then(res => console.log(res.data));
        document.location.reload(true)
    }

    delete() {
        axios.get('/api/service/deleteblog/' + this.props.obj._id)
            .then(console.log('Deleted'))
            .catch(err => console.log(err))
            document.location.reload(true)
    }

    render() {
        console.log(this.props.obj)
        return (
            <tr>
                <td>
                    <Link to={`/blog/show/${this.props.obj._id}`} >{this.props.obj.title.substring(0,40)}</Link>
                </td>
                <td>
                    {this.props.obj.description.replace(/<\/?[^>]+(>|$)/g, "").substring(0,20)}
                </td>
                <td>
                    {this.props.obj.create_date.substring(0,10)}
                </td>
                <td>
                    {this.props.obj.country}
                </td>
                <td>
                    {this.props.obj.typeService}
                </td>
                <td>
                    {this.props.obj.langueService}
                </td>
                <td>
                <Link to={"/admin-issoyo/edit/"+ this.props.obj._id} className="btn btn-primary">Edit</Link>
                </td>
                <td>
                    {this.props.obj.publish ? <button onClick={this.unpublish} className="btn btn-warning">Unpublish</button> : <button onClick={this.publish} className="btn btn-success">Publish</button>}
                </td>
                <td>
                    <button onClick={this.delete} className="btn btn-danger">Delete</button>
                </td>
            </tr>
        );
    }
}
