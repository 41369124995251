import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TicketingFrance from './TicketingFrance.jsx';
import TicketingUnitedKingdom from './TicketingUnitedKingdom.jsx';
import ScrollUpButton from "react-scroll-up-button";
import { FormattedMessage } from 'react-intl';
import Issoyoservice from '../Issoyoservice/Issoyoservice.jsx';
import { countryAvailable } from '../listCountry.js';

require('./Ticketing.scss');


class Ticketing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticket: null,
      user_location: '',
    }
  }

  componentDidMount(){
    if (!this.props.auth.user.location){
      this.props.history.push('/services');
    }  else {
      this.setState({ user_location: this.props.auth.user.location})
  }
    if (this.props.auth.user.location === 'FR'){
      this.setState({ ticket: <TicketingFrance history={this.props.history}/> })
    } else{
      this.setState({ ticket: <TicketingUnitedKingdom history={this.props.history} language={this.props.language}/> })
    }
      window.scrollTo(0, 0)
  }

  render() {
    if (countryAvailable[0].ticketing.indexOf(this.state.user_location.toLowerCase()) < 0) {
      return (
          <div className="Ticketing">
              <div className="ticketing_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
              <div className="text" style={{ textAlign: "center" }}> <Issoyoservice serviceName="ticket" handleClickAccount={() => this.handleClickAccount()} language={this.state.language} /></div></div>
      
      )
  } else {
    return (
      <div>
        <div className="Ticketing">
          <div className="ticketing_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
          <div className="title_ticket">
            <div>
              <FormattedMessage id="service.ticketing.title" defaultMessage="What do you want to do ?" />
            </div>
          </div>
          {this.state.ticket}
        </div>
        <ScrollUpButton ShowAtPosition={70}/>
      </div>
    )
  }
}
}

Ticketing.propTypes = {
  auth: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  service: state.services,
});

export default connect(
  mapStateToProps,
  {}
)(Ticketing);