import React from 'react';
import { FormattedMessage } from 'react-intl';
import imagedesktop_section4 from "../../../assets/img/imagedesktop_section4@2x.png";
/*import logo_orange_lg from "../../../assets/img/logo_orange_lg.png";
import logo_myfinimo_lg from "../../../assets/img/logo_myfinimo_lg.png";
import logo_sosh_lg from "../../../assets/img/logo_sosh_lg.png";
import logo_april_lg from "../../../assets/img/logo_april_lg.png";
import logo_transferwise_lg from "../../../assets/img/logo_transferwise_lg.png";*/

require('./Home.scss');

export class Sectionfo extends React.Component {
    render() {
        return (
            <section className="section section-4">
                <div className="col-md-12 content-image">
                    <div className="title">
                        <div className="our-partners">
                            <FormattedMessage id="home.titlePartner" defaultMessage="Our partners" />
                        </div>
                    </div>
                    <div className="text">
                        <div className="partnerships-allows">
                            <FormattedMessage id="home.textPartnerL1" defaultMessage="We built partnerships to select the best suppliers and to provide a free service." />                        
                        </div>
                    </div>
                    <div className="text">
                        <div className="partnerships-allows">
                            <FormattedMessage id="home.textPartnerL2" defaultMessage="So far, we are working with a few but there is more to come." />                        
                        </div>
                    </div>
                </div>
                 <div className="col-md-8 no-border-padding">
                    <img className="image-center" src={imagedesktop_section4} alt=""/>
                    { /*   <div className="bg-shape-4">
                        <div className="img-shape-4">
                            <div className="col-md-12 row align-items-center">
                                <div className="col-md-6">
                                    <img src={logo_orange_lg} alt=""/>
                                </div>
                                <div className="col-md-6">
                                    <img src={logo_myfinimo_lg} alt=""/>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <img src={logo_sosh_lg} alt=""/>
                            </div>
                            <div className="col-md-12 row align-items-center">
                                <div className="col-md-6">
                                    <img src={logo_april_lg} alt=""/>
                                </div>
                                <div className="col-md-6">
                                    <img src={logo_transferwise_lg} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>  */ }
                </div>
                <div className="col-md-12"/>
            </section>
        )
    }
}
