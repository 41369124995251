import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import PropTypes from "prop-types";
import Modal from 'react-responsive-modal';
import { connect } from "react-redux";
import { loginUser, checkEmail, loginUserGoogle } from "../../../actions/authActions";
import { FormattedMessage } from 'react-intl';
import { withAlert } from 'react-alert'
import { withStyles } from '@material-ui/core/styles';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import facebook_logo from '../../../assets/img/facebook_button.svg';

require("./Login.scss");


const style = {
    root: {
        color: "rgba(68, 76, 165, 0.85)",
        fontFamily: "'Nunito', sans-serif !important",
    },
    input: {
        borderColor: '#3e45948a !important',
    },
};

const modalstyles = {
    modal: {
        'max-width': '500px',
        'width': '100%',
        'color': '#3f2751',
        error: {
            'color': '#cc0033',
            'display': 'flex',
            'font-size': '12px'
        }
    },
};

class Login extends React.Component {
    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            showPassword: false,
            errorRequired: false,
            errorPassword: false,
            errorEmail: false,
            errorLogin: false,
            errorMessage: [],
            open: false,
            forgotten_email: '',
            forgot: false,
            errorModalEmail: false,
            messageMail: <FormattedMessage id="login.error.noemail" defaultMessage="This user doesn't exist" />,
        };
        this.loginError = '';
        this.handleChange = this.handleChange.bind(this);
        this.onClickLogin = this.onClickLogin.bind(this);
        this.setErrorRed = this.setErrorRed.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.sendRecoverMail = this.sendRecoverMail.bind(this);
        this.responseGoogle = this.responseGoogle.bind(this);
        this.responseFacebook = this.responseFacebook.bind(this);
    }

    onOpenModal() {
        this.setState({ open: true });
    };

    onCloseModal() {
        this.setState({ open: false });
    };

    componentDidMount() {
        // If logged in and user navigates to Register page, should redirect them to dashboard
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/profile");
        }
    }

		signup() {
        this.props.history.push('/signup')
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.forgot === true) {
            if (nextProps.errors.email) {
                // axios.put('/api/users/recovermail', { email: this.state.forgotten_email });
                this.props.alert.show('Email sent', { type: 'success' });
                this.onCloseModal();
                this.setState({ forgot: false });
            } else {
                this.setState({
                    errorModalEmail: true,
                    forgot: false,
                })
            }
        }
        if (nextProps.auth.isAuthenticated) {
            if (this.props.history.length > 1) {
                this.props.history.goBack()
            } else {
                this.props.history.push('/')
            }
        }
        if (nextProps.errors) {
            if (nextProps.errors.confirmemail) {
                this.setState({
                    errors: nextProps.errors, errorLogin: true, errorMessage: <FormattedMessage id="login.error.login.confirm" defaultMessage="Please confirm your account" />
                });
            } else if (nextProps.errors.emailnotfound || nextProps.errors.passwordincorrect || nextProps.errors.email) {
                this.setState({
                    errors: nextProps.errors, errorLogin: true, errorMessage: <FormattedMessage id="login.error.login" defaultMessage="Email or password is not correct" />
                });
            }
        }
    }

    setErrorRed() {
        if (this.state.email === '')
            this.setState({ errorEmail: true });
        if (this.state.password === '')
            this.setState({ errorPassword: true });
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.name === 'email')
            this.setState({ errorEmail: false });
        if (event.target.name === 'password')
            this.setState({ errorPassword: false });
    }

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    responseFacebook(response) {
        if (response.status !== undefined) {
            var user = {};
            user.nickname = response.first_name + '#' + Math.round(Math.random() * (9999 - 1) + 1);
            user.firstname = response.first_name;
            user.name = response.last_name;
            user.email = response.email;
            user.token = response.id;
            this.props.loginUserGoogle(user);
        }
    }

    responseGoogle(response) {
			console.log('ici');
        if (response.email !== null) {
            var user = {};
            user.nickname = response.profileObj.givenName + '#' + Math.round(Math.random() * (9999 - 1) + 1);
            user.firstname = response.profileObj.givenName;
            user.name = response.profileObj.familyName;
            user.email = response.profileObj.email;
            user.token = response.profileObj.googleId;
            this.props.loginUserGoogle(user);
        }
    }

    onClickLogin() {
        if (this.state.email === '' || this.state.password === '') {
            this.setState({ errorRequired: true });
            this.setErrorRed();

        } else if ((this.state.errorEmail) || (this.state.errorPassword)) {
            this.setState({ errorRequired: true });
            this.setErrorRed();
        } else {
            var country, location = ''
            if (this.props.auth.user.country){
                country = this.props.auth.user.country;
                location = this.props.auth.user.location;
            }
            const userData = {
                email: this.state.email,
                password: this.state.password,
                location: location,
                country: country,
            };
            this.setState({ errorRequired: false });
            this.props.loginUser(userData);
        }
    }

    sendRecoverMail() {
				console.log("send recover mail "+"ici1i")
        const reqData = { email: this.state.forgotten_email }
				console.log("forgotten email : "+reqData );
        this.setState({ forgot: true });
        this.props.checkEmail(reqData);
    }

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <div className="Login">
                    <div className="signup_back"><i style={{ color: '#593e96' }} className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                    <div className="col-12 title"><div>
                        <FormattedMessage id="login.title" defaultMessage="Login to your account" />
                    </div></div>
                    <div className="error required" style={this.state.errorRequired ? {} : { display: 'none' }}>
                        <FormattedMessage id="login.error.required" defaultMessage="You must fill out all required fields" />
                    </div>
                    <div className="error required" style={this.state.errorLogin ? {} : { display: 'none' }}>
                        {this.state.errorMessage}
                    </div>
                    <div className="div-input-mail">
                        <TextField
                            id="outlined-email-input"
                            label="Email"
                            error={this.state.errorEmail}
                            className="input-mail"
                            type="email"
                            name="email"
                            value={this.state.email}
                            autoComplete="email"
                            margin="normal"
                            variant="outlined"
                            onChange={this.handleChange}
                            InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                        />
                    </div>
                    <div className="div-input-password">
                        <FormattedMessage id="login.password" defaultMessage="Password" >
                            {msg => (<TextField
                                id="outlined-adornment-password"
                                className="input-password"
                                variant="outlined"
                                error={this.state.errorPassword}
                                type={this.state.showPassword ? 'text' : 'password'}
                                label={msg}
                                name="password"
                                value={this.state.password}
                                onChange={this.handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                            >
                                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    classes: { root: classes.root, notchedOutline: classes.input },
                                }}
                            />)}
                        </FormattedMessage>
                    </div>
                    <div className="button-connect">
                        <Button variant="contained" color="primary" className="button" onClick={this.onClickLogin}>
                            <FormattedMessage id="login.login" defaultMessage="Login" />
                        </Button>
                    </div>
                    <div className="text">
                        <div onClick={this.onOpenModal} style={{ cursor: 'pointer', color: 'rgba(68, 76, 165, 0.85)9c', marginTop: '13px' }}><u><FormattedMessage id="login.forget_password" defaultMessage="Forget password ?" /></u></div>
                    </div>
                    <div className="divider-signup">
                        <Divider variant="middle" />
                    </div>
										{/* change page sign up */}
                    <div className="text">
                        <div  color="primary" className="text" onClick={() => this.signup()} style={{ cursor: 'pointer', color: 'rgba(68, 76, 165, 0.85)9c', marginTop: '12px' }}><FormattedMessage id="login.register" defaultMessage="create accounte" /></div>
                    </div>

                    <div className="divider-signup">
                        <Divider variant="middle" />
                    </div>
                    <div className="login_wrap">
                    <div style={{ marginBottom: '12px', marginTop: '12px' }}>
                        <FormattedMessage id="login.google" defaultMessage="Login with Google">
                            {msg => (
                                <GoogleLogin
                                    //clientId="579721595351-htqc75h727bea53rc3lnpuugb79ue0jd.apps.googleusercontent.com"
                                    clientId="922307892462-06cn4i30jh5js23vtvapd4e18fqo534b.apps.googleusercontent.com"//id issoyo.com et https://issoyoweb.nogaetechnologies.com/ et 	http://localhost:3000
                                    buttonText={msg}
                                    onSuccess={this.responseGoogle}
                                    onFailure={this.responseGoogle}
                                    cookiePolicy={'single_host_origin'}
                                    className='login-google-btn'
                                />)}
                        </FormattedMessage>
                    </div>
                    <div style={{ marginBottom: '12px', marginTop: '12px', display: "block" }}>
                        <FormattedMessage id="login.facebook" defaultMessage="Login with Facebook..">
                            {msg => (
                                <FacebookLogin
                                    //appId="261704477828286"

                                    appId="794527530972146"
                                    autoLoad={false}
                                    fields="first_name,last_name,email,picture"
                                    onClick={() => this.responseFacebook}
                                    render={renderProps => (
                                        <button className="button_facebook" onClick={renderProps.onClick}>
                                            <div className="button_facebook-icon">
                                                <img style={{width: '18px', height: '18px'}} src={facebook_logo} alt=''></img>
                                            </div>
                                            <span style={{ padding: '10px 10px 10px 0px', fontWeight: '500'}}>{msg}</span>
                                        </button>
                                    )}
                                    isMobile={false}
                                />
                            )}
                        </FormattedMessage>
                    </div>
                    </div>
                </div>
                <Modal open={this.state.open} onClose={this.onCloseModal} center blockScroll styles={modalstyles}>
                    <p className='message-forgotpassword'><FormattedMessage id="login.forgot.password" defaultMessage="What's your email address?" /></p>
                    <div className="div-input-email">
                        <TextField
                            id="outlined-with-placeholder"
                            label="Email"
                            placeholder="Email"
                            name="forgotten_email"
                            onChange={this.handleChange}
                            value={this.state.forgotten_email}
                            className="forgot-input-email"
                            margin="normal"
                            variant="outlined"
                        />
                        <div className="error-modal" style={this.state.errorModalEmail ? {} : { display: 'none' }}>{this.state.messageMail}</div>
                    </div>
                    <Button color="primary" className="button" onClick={this.sendRecoverMail} style={{ float: 'right', fontFamily: 'Nunito', backgroundColor: 'transparent', color: 'rgba(68, 76, 165, 0.85)' }} >
                        <FormattedMessage id="login.modal.send" defaultMessage="Send" />
                    </Button>

                </Modal>

            </React.Fragment>
        );
    }
}

Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.auth.error
});
export default connect(
    mapStateToProps,
    { loginUser, checkEmail, loginUserGoogle }
)(withAlert(withStyles(style)(Login)));
