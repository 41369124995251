import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
import Moto from './Moto/Moto.jsx';
import Scooter from './Scooter/Scooter.jsx';
import Travel from './Travel/Travel.jsx';
import Emprunt from './Emprunt/Emprunt.jsx';
import House from './House/House.jsx';
import Car from './Car/Car.jsx';
import CarUnitedKingdom from './Car/CarUnitedKingdom.jsx';
import Landlord from './Landlord/Landlord.jsx';
import Health from './Health/Health.jsx';
import Business from './Business/Business.jsx';
import ScrollUpButton from "react-scroll-up-button";
import Issoyo_logo from '../../../../assets/img/fav_icon32@2x.png';
import Issoyo_logo_blanc from '../../../../assets/img/issoyo_blanc.png';
import Issoyoservice from '../Issoyoservice/Issoyoservice.jsx';
import ButtonToService from '../../ButtonToService/ButtonToService';

require("./Insurances.scss");

class Insurances extends Component {
    constructor() {
        super();
        this.state = {
            carName: 'car_hide',
            colorHouse: { color: '#7f3fce', backgroundColor: 'white' },
            colorCar: { color: '#7f3fce', backgroundColor: 'white' },
            colorMoto: { color: '#7f3fce', backgroundColor: 'white' },
            colorHealth: { color: '#7f3fce', backgroundColor: 'white' },
            colorEmprunt: { color: '#7f3fce', backgroundColor: 'white' },
            colorScooter: { color: '#7f3fce', backgroundColor: 'white' },
            colorTravel: { color: '#7f3fce', backgroundColor: 'white' },
            colorBusiness: { color: '#7f3fce', backgroundColor: 'white' },
            colorLandlord: { color: '#7f3fce', backgroundColor: 'white' },
            colorIssoyoenergy: { color: '#7f3fce', backgroundColor: 'white' },
            logo: Issoyo_logo,
            insuranceComp: null,
            afiliate_link: "",
            children_button: null,
            issoyo: false,
            user_location: '',
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleClickAccount = this.handleClickAccount.bind(this);
        this.linkChange = this.linkChange.bind(this);
    }

    linkChange(link){
        this.setState({afiliate_link: link})
    }

    componentWillReceiveProps() {
        this.setState({
            carName: 'car_hide',
            colorHouse: { color: '#7f3fce', backgroundColor: 'white' },
            colorCar: { color: '#7f3fce', backgroundColor: 'white' },
            colorMoto: { color: '#7f3fce', backgroundColor: 'white' },
            colorHealth: { color: '#7f3fce', backgroundColor: 'white' },
            colorEmprunt: { color: '#7f3fce', backgroundColor: 'white' },
            colorScooter: { color: '#7f3fce', backgroundColor: 'white' },
            colorTravel: { color: '#7f3fce', backgroundColor: 'white' },
            colorBusiness: { color: '#7f3fce', backgroundColor: 'white' },
            colorLandlord: { color: '#7f3fce', backgroundColor: 'white' },
            colorIssoyoenergy: { color: '#7f3fce', backgroundColor: 'white' },
            insuranceComp: null,
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        if (!this.props.auth.user.location) {
            this.props.history.push('/services');
        } else {
            this.setState({ user_location: this.props.auth.user.location})
        }
    }

    handleClickAccount() {
        this.props.history.push('/login');
    }

    handleClick(name) {
        if (name === 'house') {
            this.setState({
                insuranceComp: <House linkChange={(link) => this.linkChange(link)}  handleClickAccount={() => this.handleClickAccount()} language={this.props.language} />,
                colorHouse: { color: "white", backgroundColor: '#7f3fce' },
                colorCar: { color: '#7f3fce', backgroundColor: 'white' },
                colorMoto: { color: '#7f3fce', backgroundColor: 'white' },
                colorScooter: { color: '#7f3fce', backgroundColor: 'white' },
                colorHealth: { color: '#7f3fce', backgroundColor: 'white' },
                colorEmprunt: { color: '#7f3fce', backgroundColor: 'white' },
                colorTravel: { color: '#7f3fce', backgroundColor: 'white' },
                colorLandlord: { color: '#7f3fce', backgroundColor: 'white' },
                colorIssoyoenergy: { color: '#7f3fce', backgroundColor: 'white' },
                logo: Issoyo_logo,
                afiliate_link: "",
                children_button:  null,
                issoyo: false,
            });
        } else if (name === 'car') {
            this.setState({
                insuranceComp: <Car handleClickAccount={() => this.handleClickAccount()} />,
                colorHouse: { color: '#7f3fce', backgroundColor: 'white' },
                colorCar: { color: "white", backgroundColor: '#7f3fce' },
                colorMoto: { color: '#7f3fce', backgroundColor: 'white' },
                colorHealth: { color: '#7f3fce', backgroundColor: 'white' },
                colorEmprunt: { color: '#7f3fce', backgroundColor: 'white' },
                colorScooter: { color: '#7f3fce', backgroundColor: 'white' },
                colorTravel: { color: '#7f3fce', backgroundColor: 'white' },
                colorLandlord: { color: '#7f3fce', backgroundColor: 'white' },
                colorIssoyoenergy: { color: '#7f3fce', backgroundColor: 'white' },
                logo: Issoyo_logo,
                afiliate_link: "https://www.gestion-assurances.com/site_partenaire.aspx?code=OPE217279&dst=2",
                children_button:  null,
                issoyo: false,
            });
        } else if (name === 'moto') {
            this.setState({
                insuranceComp: <Moto handleClickAccount={() => this.handleClickAccount()} />,
                colorHouse: { color: '#7f3fce', backgroundColor: 'white' },
                colorCar: { color: '#7f3fce', backgroundColor: 'white' },
                colorMoto: { color: "white", backgroundColor: '#7f3fce' },
                colorHealth: { color: '#7f3fce', backgroundColor: 'white' },
                colorEmprunt: { color: '#7f3fce', backgroundColor: 'white' },
                colorScooter: { color: '#7f3fce', backgroundColor: 'white' },
                colorTravel: { color: '#7f3fce', backgroundColor: 'white' },
                colorLandlord: { color: '#7f3fce', backgroundColor: 'white' },
                colorIssoyoenergy: { color: '#7f3fce', backgroundColor: 'white' },
                logo: Issoyo_logo,
                afiliate_link: "https://www.gestion-assurances.com/site_partenaire.aspx?code=OPE217279&dst=22",
                children_button:  null,
                issoyo: false,
            });
        } else if (name === 'health') {
            this.setState({
                insuranceComp: <Health linkChange={(link) => this.linkChange(link)}  handleClickAccount={() => this.handleClickAccount()} language={this.props.language} />,
                colorHouse: { color: '#7f3fce', backgroundColor: 'white' },
                colorCar: { color: '#7f3fce', backgroundColor: 'white' },
                colorMoto: { color: '#7f3fce', backgroundColor: 'white' },
                colorHealth: { color: "white", backgroundColor: '#7f3fce' },
                colorEmprunt: { color: '#7f3fce', backgroundColor: 'white' },
                colorScooter: { color: '#7f3fce', backgroundColor: 'white' },
                colorTravel: { color: '#7f3fce', backgroundColor: 'white' },
                colorLandlord: { color: '#7f3fce', backgroundColor: 'white' },
                colorIssoyoenergy: { color: '#7f3fce', backgroundColor: 'white' },
                logo: Issoyo_logo,
                afiliate_link: "",
                children_button:  null,
                issoyo: false,
            });
        } else if (name === 'emprunt') {
            this.setState({
                insuranceComp: <Emprunt handleClickAccount={() => this.handleClickAccount()} />,
                colorHouse: { color: '#7f3fce', backgroundColor: 'white' },
                colorCar: { color: '#7f3fce', backgroundColor: 'white' },
                colorMoto: { color: '#7f3fce', backgroundColor: 'white' },
                colorHealth: { color: '#7f3fce', backgroundColor: 'white' },
                colorEmprunt: { color: "white", backgroundColor: '#7f3fce' },
                colorTravel: { color: '#7f3fce', backgroundColor: 'white' },
                colorScooter: { color: '#7f3fce', backgroundColor: 'white' },
                colorLandlord: { color: '#7f3fce', backgroundColor: 'white' },
                colorIssoyoenergy: { color: '#7f3fce', backgroundColor: 'white' },
                logo: Issoyo_logo,
                afiliate_link: "https://tracking.publicidees.com/clic.php?promoid=203228&progid=7017&partid=57757",
                children_button:  null,
                issoyo: false,
            });
        } else if (name === 'scooter') {
            this.setState({
                insuranceComp: <Scooter handleClickAccount={() => this.handleClickAccount()} language={this.props.language} />,
                colorHouse: { color: '#7f3fce', backgroundColor: 'white' },
                colorCar: { color: '#7f3fce', backgroundColor: 'white' },
                colorMoto: { color: '#7f3fce', backgroundColor: 'white' },
                colorHealth: { color: '#7f3fce', backgroundColor: 'white' },
                colorEmprunt: { color: "#7f3fce", backgroundColor: 'white' },
                colorScooter: { color: 'white', backgroundColor: '#7f3fce' },
                colorTravel: { color: '#7f3fce', backgroundColor: 'white' },
                colorLandlord: { color: '#7f3fce', backgroundColor: 'white' },
                colorIssoyoenergy: { color: '#7f3fce', backgroundColor: 'white' },
                logo: Issoyo_logo,
                afiliate_link: "https://www.awin1.com/cread.php?awinmid=16157&awinaffid=525947&clickref=&p=https%3A%2F%2Fwww.getluko.com%2Ffr%2Fassurance-trottinette-electrique-nvei%3Futm_source%3Dpartner%26utm_medium%3DAffiliation%26utm_campaign%3DAwin%26utm_content%3DHomeFR",
                children_button:  null,
                issoyo: false,
            });
        } else if (name === 'travel') {
            this.setState({
                insuranceComp: <Travel handleClickAccount={() => this.handleClickAccount()} language={this.props.language} />,
                colorHouse: { color: '#7f3fce', backgroundColor: 'white' },
                colorCar: { color: '#7f3fce', backgroundColor: 'white' },
                colorMoto: { color: '#7f3fce', backgroundColor: 'white' },
                colorHealth: { color: '#7f3fce', backgroundColor: 'white' },
                colorEmprunt: { color: "#7f3fce", backgroundColor: 'white' },
                colorScooter: { color: '#7f3fce', backgroundColor: 'white' },
                colorTravel: { color: 'white', backgroundColor: '#7f3fce' },
                colorLandlord: { color: '#7f3fce', backgroundColor: 'white' },
                colorIssoyoenergy: { color: '#7f3fce', backgroundColor: 'white' },
                logo: Issoyo_logo,
                afiliate_link: "https://clk.tradedoubler.com/click?p=267636&a=3083026&g=22970204",
                children_button:  null,
                issoyo: false,
            });
        } else if (name === 'business') {
            this.setState({
                insuranceComp: <Business handleClickAccount={() => this.handleClickAccount()} language={this.props.language} />,
                colorBusiness: { color: 'white', backgroundColor: '#7f3fce' },
                colorCar: { color: '#7f3fce', backgroundColor: 'white' },
                colorLandlord: { color: '#7f3fce', backgroundColor: 'white' },
                colorIssoyoenergy: { color: '#7f3fce', backgroundColor: 'white' },
                logo: Issoyo_logo,
                afiliate_link: "https://www.awin1.com/cread.php?awinmid=12435&awinaffid=525947&clickref=&p=https%3A%2F%2Fwww.axa.co.uk%2Fbusiness-insurance%2F",
                children_button:  null,
                issoyo: false,
            });
        } else if (name === 'carGB') {
            this.setState({
                insuranceComp: <CarUnitedKingdom handleClickAccount={() => this.handleClickAccount()} />,
                colorBusiness: { color: '#7f3fce', backgroundColor: 'white' },
                colorCar: { color: "white", backgroundColor: '#7f3fce' },
                colorLandlord: { color: '#7f3fce', backgroundColor: 'white' },
                colorIssoyoenergy: { color: '#7f3fce', backgroundColor: 'white' },
                logo: Issoyo_logo,
                afiliate_link: "https://www.awin1.com/cread.php?awinmid=13529&awinaffid=525947&clickref=&p=https%3A%2F%2Fwww.axa.co.uk%2Fget-axa-car-insurance%2F",
                children_button:  null,
                issoyo: false,
            });
        } else if (name === 'landlord') {
            this.setState({
                insuranceComp: <Landlord handleClickAccount={() => this.handleClickAccount()} />,
                colorBusiness: { color: '#7f3fce', backgroundColor: 'white' },
                colorCar: { color: '#7f3fce', backgroundColor: "white" },
                colorLandlord: { color: "white", backgroundColor: '#7f3fce' },
                colorIssoyoenergy: { color: '#7f3fce', backgroundColor: 'white' },
                logo: Issoyo_logo,
                afiliate_link: "https://www.awin1.com/cread.php?awinmid=12425&awinaffid=525947&clickref=&p=%5B%5Bhttps%3A%2F%2Fwww.axa.co.uk%2Flandlord-insurance%2F%5D%5D",
                children_button:  null,
                issoyo: false,
            });
        } else if (name === 'issoyoenergy') {
            this.setState({
                insuranceComp: <Issoyoservice serviceName='insurance' handleClickAccount={() => this.handleClickAccount()} language={this.state.language} />,
                colorGaz: { color: '#7f3fce', backgroundColor: 'white' },
                colorHouse: { color: '#7f3fce', backgroundColor: 'white' },
                colorCar: { color: '#7f3fce', backgroundColor: 'white' },
                colorMoto: { color: '#7f3fce', backgroundColor: 'white' },
                colorHealth: { color: '#7f3fce', backgroundColor: 'white' },
                colorEmprunt: { color: "#7f3fce", backgroundColor: 'white' },
                colorTravel: { color: '#7f3fce', backgroundColor: 'white' },
                colorScooter: { color: '#7f3fce', backgroundColor: 'white' },
                colorLandlord: { color: '#7f3fce', backgroundColor: 'white' },
                colorElectricity: { color: '#7f3fce', backgroundColor: 'white' },
                colorBusiness: { color: '#7f3fce', backgroundColor: 'white' },
                colorCar: { color: '#7f3fce', backgroundColor: "white" },
                colorLandlord: { color: '#7f3fce', backgroundColor: "white" },
                colorIssoyoenergy: { color: 'white', backgroundColor: '#7f3fce' },
                logo: Issoyo_logo_blanc,
                afiliate_link: "",
                children_button:  null,
                issoyo: true,
            });
        }
    }

    render() {
        if (this.props.auth.user.location === 'GB') {
            return (
                <div className="Insurances">
                    <div className="bank_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                    <div className="title">
                        <FormattedMessage id="service.insurance.title" defaultMessage="You need an insurance ?" />
                    </div>
                    <div className="flex_chevron"><i className="fal fa-chevron-left"></i></div>
                    <div className="flex_chevron"><i className="fal fa-chevron-right"></i></div>
                    <div className="container_type">
                        <div className="container_flex_type">
                            <div className="flex_item_type">
                                <div className="box" onClick={() => this.handleClick('business')} style={this.state.colorBusiness}>
                                    <div className="col-12 no-border-padding">
                                        <div className="logo-box"><i className="fal fa-briefcase"></i></div>
                                    </div>
                                    <div className="col-12 no-border-padding"><div className="text text-box" style={{ color: this.state.colorBusiness.color }}>
                                        <FormattedMessage id="service.insurance.business" defaultMessage="Business" />
                                    </div></div>
                                </div>
                            </div>
                            <div className="flex_item_type">
                                <div className="box" onClick={() => this.handleClick('carGB')} style={this.state.colorCar}>
                                    <div className="col-12 no-border-padding">
                                        <div className="logo-box"><i className="fal fa-car-crash"></i></div>
                                    </div>
                                    <div className="col-12 no-border-padding"><div className="text text-box" style={{ color: this.state.colorCar.color }}>
                                        <FormattedMessage id="service.insurance.car" defaultMessage="Car" />
                                    </div></div>
                                </div>
                            </div>
                            <div className="flex_item_type">
                                <div className="box" onClick={() => this.handleClick('landlord')} style={this.state.colorLandlord}>
                                    <div className="col-12 no-border-padding">
                                        <div className="logo-box"><i className="fal fa-user-tie"></i></div>
                                    </div>
                                    <div className="col-12 no-border-padding"><div className="text text-box" style={{ color: this.state.colorLandlord.color }}>
                                        <FormattedMessage id="service.insurance.landlord" defaultMessage="Landlord" />
                                    </div></div>
                                </div>
                            </div>
                            <div className="flex_item_type">
                                <div className="box" onClick={() => this.handleClick('issoyoenergy')} style={this.state.colorIssoyoenergy}>
                                    <div className="col-12 no-border-padding">
                                        <div className="logo-box"><img src={this.state.logo} style={{ width: '40px' }}></img></div>
                                    </div>
                                    <div className="col-12 no-border-padding"><div className="text text-box" style={{ color: this.state.colorIssoyoenergy.color }}>
                                        Issoyo
                            </div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {this.state.insuranceComp}
                    </div>
                    {this.state.afiliate_link && this.state.issoyo === false ? <ButtonToService afiliateLink={this.state.afiliate_link}>
                        {this.state.children_button}
                    </ButtonToService> : null}
                </div>
            )
        } else if (this.props.auth.user.location === 'FR') {
            return (
                <div>
                    <div className="Insurances">
                        <div className="bank_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                        <div className="title">
                            <FormattedMessage id="service.insurance.title" defaultMessage="You need an insurance ?" />
                        </div>
                        <div className="flex_chevron"><i className="fal fa-chevron-left"></i></div>
                        <div className="flex_chevron"><i className="fal fa-chevron-right"></i></div>
                        <div className="container_type">
                            <div className="container_flex_type">
                                <div className="flex_item_type">
                                    <div className="box" onClick={() => this.handleClick('house')} style={this.state.colorHouse}>
                                        <div className="col-12 no-border-padding">
                                            <div className="logo-box"><i className="fal fa-house-damage"></i></div>
                                        </div>
                                        <div className="col-12 no-border-padding"><div className="text text-box" style={{ color: this.state.colorHouse.color }}>
                                            <FormattedMessage id="service.insurance.house" defaultMessage="House" />
                                        </div></div>
                                    </div>
                                </div>
                                <div className="flex_item_type">
                                    <div className="box" onClick={() => this.handleClick('car')} style={this.state.colorCar}>
                                        <div className="col-12 no-border-padding">
                                            <div className="logo-box"><i className="fal fa-car-crash"></i></div>
                                        </div>
                                        <div className="col-12 no-border-padding"><div className="text text-box" style={{ color: this.state.colorCar.color }}>
                                            <FormattedMessage id="service.insurance.car" defaultMessage="Car" />
                                        </div></div>
                                    </div>
                                </div>
                                <div className="flex_item_type">
                                    <div className="box" onClick={() => this.handleClick('moto')} style={this.state.colorMoto}>
                                        <div className="col-12 no-border-padding">
                                            <div className="logo-box"><i className="fal fa-motorcycle"></i></div>
                                        </div>
                                        <div className="col-12 no-border-padding"><div className="text text-box" style={{ color: this.state.colorMoto.color }}>
                                            <FormattedMessage id="service.insurance.moto" defaultMessage="Moto" />
                                        </div></div>
                                    </div>
                                </div>
                                <div className="flex_item_type">
                                    <div className="box" onClick={() => this.handleClick('health')} style={this.state.colorHealth}>
                                        <div className="col-12 no-border-padding">
                                            <div className="logo-box"><i className="fal fa-heartbeat"></i></div>
                                        </div>
                                        <div className="col-12 no-border-padding"><div className="text text-box" style={{ color: this.state.colorHealth.color }}>
                                            <FormattedMessage id="service.insurance.health" defaultMessage="Health" />
                                        </div></div>
                                    </div>
                                </div>
                                <div className="flex_item_type">
                                    <div className="box" onClick={() => this.handleClick('emprunt')} style={this.state.colorEmprunt}>
                                        <div className="col-12 no-border-padding">
                                            <div className="logo-box"><i className="fal fa-money-check-alt"></i></div>
                                        </div>
                                        <div className="col-12 no-border-padding"><div className="text text-box" style={{ color: this.state.colorEmprunt.color }}>
                                            <FormattedMessage id="service.insurance.borrow" defaultMessage="Loan" />
                                        </div></div>
                                    </div>
                                </div>
                                <div className="flex_item_type">
                                    <div className="box" onClick={() => this.handleClick('scooter')} style={this.state.colorScooter}>
                                        <div className="col-12 no-border-padding">
                                            <div className="logo-box"><i className="fal fa-bicycle"></i></div>
                                        </div>
                                        <div className="col-12 no-border-padding"><div className="text text-box" style={{ color: this.state.colorScooter.color }}>
                                            <FormattedMessage id="service.insurance.scooter" defaultMessage="e-Scooter" />
                                        </div></div>
                                    </div>
                                </div>
                                <div className="flex_item_type">
                                    <div className="box" onClick={() => this.handleClick('travel')} style={this.state.colorTravel}>
                                        <div className="col-12 no-border-padding">
                                            <div className="logo-box"><i className="fal fa-suitcase"></i></div>
                                        </div>
                                        <div className="col-12 no-border-padding"><div className="text text-box" style={{ color: this.state.colorTravel.color }}>
                                            <FormattedMessage id="service.insurance.travel" defaultMessage="Travel" />
                                        </div></div>
                                    </div>
                                </div>
                                <div className="flex_item_type">
                                    <div className="box" onClick={() => this.handleClick('issoyoenergy')} style={this.state.colorIssoyoenergy}>
                                        <div className="col-12 no-border-padding">
                                            <div className="logo-box"><img src={this.state.logo} style={{ width: '40px' }}></img></div>
                                        </div>
                                        <div className="col-12 no-border-padding"><div className="text text-box" style={{ color: this.state.colorIssoyoenergy.color }}>
                                            Issoyo
                            </div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            {this.state.insuranceComp}
                        </div>
                        {this.state.afiliate_link && this.state.issoyo === false ? <ButtonToService afiliateLink={this.state.afiliate_link}>
                            {this.state.children_button}
                        </ButtonToService> : null}
                        <ScrollUpButton ShowAtPosition={70} />
                    </div>
                </div>
            )
        } else {
            return (<div className="Insurances">
                <div className="bank_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                <div className="text" style={{ textAlign: "center" }}> <Issoyoservice serviceName='insurance' handleClickAccount={() => this.handleClickAccount()} language={this.state.language} /></div></div>)
        }
    }
}

Insurances.propTypes = {
    auth: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(
    mapStateToProps,
    {}
)(Insurances);