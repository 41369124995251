import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import netvox_logo from '../../../../../assets/img/providers/ISSOYO_netvox_logo.png';

require('./Car.scss');

class Car extends Component {
    render() {
        return (
            <div>
                <div className="car_description">
                    <div className="car_provider_logo">
                        <img className="car_provider-img" src={netvox_logo} alt='ici1' />
                    </div>
                    <div className="text car_title">
                        <FormattedMessage id="netvox.car.advantage" defaultMessage="Coverage for all risks:" />
                    </div>
                    <ul className="text">
                        <li><FormattedMessage id="netvox.car.standard" defaultMessage="Standard risks" /></li>
                        <li><FormattedMessage id="netvox.car.nohistory" defaultMessage="No history and young drivers below 21 years old" /></li>
                        <li><FormattedMessage id="netvox.car.risks" defaultMessage="High risks: penalties, cancellations and infringement" /></li>
                        <li><FormattedMessage id="netvox.car.compare" defaultMessage="Compare several offers of well-known insurers for the same risk and choose the best quality / solution" /></li>
                        <li><FormattedMessage id="netvox.car.numerous" defaultMessage="Numerous options for pricing customization." /></li>
                        <li><FormattedMessage id="netvox.car.competitive" defaultMessage="Competitive rates on high risks" /></li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default (Car);
