import React from 'react';
import Horizontal_white_fill from "../../../assets/img/Horizontal_white_fill.svg";
import linkedin_icon from "../../../assets/img/linkedin_icon.svg";
import facebook_icon from "../../../assets/img/facebook_icon.svg";
import instagram_icon from "../../../assets/img/instagram_icon.svg";

require('./Footer.scss');

export class Footer extends React.Component {
    render() {
        return (
            <div className="Footer">
                <div className="footer-container">
                    <div className="footer_logo">
                        <img className="footer_logo-img" src={Horizontal_white_fill} alt="" />
                    </div>
                    <div className="footer_social">
                        <a href="https://www.linkedin.com/company/issoyo-com" target="_blank" rel="noopener noreferrer">
                            <img className="logo-rs" src={linkedin_icon} alt="" />
                        </a>
                        <a href="https://www.facebook.com/app.issoyo" target="_blank" rel="noopener noreferrer">
                            <img className="logo-rs" src={facebook_icon} alt="" />
                        </a>
                        <a href="https://www.instagram.com/issoyo.app" target="_blank" rel="noopener noreferrer">
                            <img className="logo-rs" src={instagram_icon} alt="" />
                        </a>
                    </div>
                    <div className="footer_link">
                        <a className="issoyo_link" href="/conditions">CGU</a>
                        <a className="issoyo_link" href="/mentions">Mentions Légales</a>
                        <a className="issoyo_link" href="/cookies">Cookies</a>
                    </div>
                </div>
            </div>
        )
    }
}