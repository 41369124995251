import React from 'react'
import { FormattedMessage } from 'react-intl';

const promoList = [
    {
        date_debut: "2019-08-14",
        date_fin: "2019-09-05",
        pics: "/static/media/bforbank.png",
        name: "BforBank",
        detail_1: <FormattedMessage id="bank.promo.bforbank.detail1"/>,
        detail_2: <FormattedMessage id="bank.promo.bforbank.detail2"/>,
        detail_3: <FormattedMessage id="bank.promo.bforbank.detail3"/>,
        old_price: "",
        price: <FormattedMessage id="bank.promo.bforbank.price"/>,
        trackingLinkk: "https://www.awin1.com/cread.php?s=2385497&v=13267&q=343321&r=525947",
        country: "FR",
        more_info: false,
    },
    {
        date_debut: "2019-05-24",
        date_fin: "2019-08-29",
        pics: "/static/media/HelloBankLogo.jpg",
        name: "HelloBank",
        detail_1: <FormattedMessage id="bank.promo.hellobank.detail1"/>,
        detail_2: <FormattedMessage id="bank.promo.hellobank.detail2"/>,
        detail_3: "",
        old_price: "",
        more_info: true,
        price: <FormattedMessage id="bank.promo.hellobank.price"/>,
        trackingLinkk: "https://tracking.publicidees.com/clic.php?promoid=122093&progid=2934&partid=57757",
        country: "FR",
    },        
    {
    date_debut: "2019-05-24",
    date_fin: "2019-09-30",
    pics: "/static/media/HelloBankLogo.jpg",
    name: "HelloBank",
    detail_1: "80 euro offert + 1 CB gratuite",
    detail_2: "",
    detail_3: "",
    old_price: "",
    more_info: true,
    price: "80 euro offert",
    trackingLinkk: "https://tracking.publicidees.com/clic.php?promoid=122093&progid=2934&partid=57757",
    country: "FR",
},
]


export {promoList};