const placeList = [
    {
        'city': 'Paris',
        'place': ['Le Bourget', 'Clichy Mairie', 'Grande Bibliothèque', 'La Villette', 'Vélizy', 'Villejuif'],
    },
    {
        'city': 'Lille',
        'place': ['Euraille', 'Grand Palais'],
    },
    {
        'city': 'Lyon',
        'place': ['Part Dieu Villette', 'Cité Internationale', 'Vaise'],
    },
    {
        'city': 'Marseille',
        'place': ['Centre Prado Vélodrome', 'Prado'],
    },
    {
        'city': 'Montpellier',
        'place': ['Gare st Roch', 'Millenaire'],
    },
    {
        'city': 'Nantes',
        'place': ['Carquefou', 'Centre', 'Cité Des Congrès'],
    },
    {
        'city': 'St Quentin en Yvelines',
        'place': ["Bois d'Arcy"],
    },
    {
        'city': 'Strasbourg',
        'place': ['Aéroport', 'Centre'],
    },
    {
        'city': 'Toulouse',
        'place': ['Blagnac'],
    },
]

export {placeList};


