import React, { Component } from 'react'
//import nlogo from '../../../../../assets/img/providers/N26_Logo_White.png';
import ReactPlayer from 'react-player';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from '@material-ui/core/Button';
import Issoyo_logo from '../../../../../assets/img/fav_icon32@2x.png';
import Collapsible from 'react-collapsible';
import Modal from 'react-responsive-modal';
import { FormattedMessage } from 'react-intl';
import N26ModalPDF from './N26ModalPdf';
import card_standard from '../../../../../assets/img/n26_card_standard.jpeg';
import card_black from '../../../../../assets/img/n26_card_black.jpeg';
import card_metal from '../../../../../assets/img/n26_card_metal.jpeg';
import n26_spaces_fr from '../../../../../assets/img/providers/n26/N26_Spaces_FR.jpg';
import n26_notification_fr from '../../../../../assets/img/providers/n26/N26_Notification_FR.jpg';
import n26_card_fr from '../../../../../assets/img/providers/n26/N26_Card_FR.jpg';
import n26_setting_fr from '../../../../../assets/img/providers/n26/N26_Settings_FR.jpg';
import n26_transfer_fr from '../../../../../assets/img/providers/n26/N26_Transfers_FR.jpg';
import n26_spaces_en from '../../../../../assets/img/providers/n26/N26_Spaces_EN.jpg';
import n26_notification_en from '../../../../../assets/img/providers/n26/N26_Notification_EN.jpg';
import n26_card_en from '../../../../../assets/img/providers/n26/N26_Card_EN.jpg';
import n26_setting_en from '../../../../../assets/img/providers/n26/N26_Settings_EN.jpg';
import n26_statistic_en from '../../../../../assets/img/providers/n26/N26_Statistics_EN.jpg';

require('./N26Modal.scss');

function price_business() {
    return (
        <div>
            <div className="card-standard">
                <div className="title-description">N26 Business</div>
                <img src={card_standard} alt='' style={{ width: '100%' }}></img>
                <div className="text-price"><span className="price-black">€0.00</span><span style={{color: "rgba(68, 76, 165, 0.32)"}}><FormattedMessage id="bank.n26.price.month" defaultMessage="/month" /></span></div>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.business.price1" defaultMessage="Free ATM withdrawals in euros" /></p>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.business.price2" defaultMessage="0.1% cashback" /></p>
                <p className="text-description-no"><FormattedMessage id="bank.n26.business.price3" defaultMessage="No ATM fees in any currency" /></p>
                <p className="text-description-no"><FormattedMessage id="bank.n26.business.price4" defaultMessage="Allianz insurance package" /></p>

            </div>
            <div className="card-black">
                <div className="title-description">N26 Business Black</div>
                <img src={card_black} alt='' style={{ width: '100%' }}></img>
                <div className="text-price"><span className="price-black">€9.90</span><span style={{color: "rgba(68, 76, 165, 0.32)"}}><FormattedMessage id="bank.n26.price.month" defaultMessage="/month" /></span></div>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.business.price1" defaultMessage="Free ATM withdrawals in euros" /></p>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.business.price2" defaultMessage="0.1% cashback" /></p>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.business.price3" defaultMessage="No ATM fees in any currency" /></p>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.business.price4" defaultMessage="Allianz insurance package" /></p>
            </div>
        </div>
    )
}

function price_personal_fr() {
    return (
        <div>
            <div className="card-standard">
                <div className="title-description">N26</div>
                <img src={card_standard} alt='' style={{ width: '100%' }}></img>
                <div className="text-price"><span className="price-black">€0.00</span><span style={{color: "rgba(68, 76, 165, 0.32)"}}><FormattedMessage id="bank.n26.price.month" defaultMessage="/month" /></span></div>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.price1" defaultMessage="Free ATM withdrawals in euros" /></p>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.price2" defaultMessage="Free payments in any currency" /></p>
                <p className="text-description-no"><FormattedMessage id="bank.n26.price3" defaultMessage="Free withdrawals worldwide" /></p>
                <p className="text-description-no"><FormattedMessage id="bank.n26.price4" defaultMessage="Allianz insurance package" /></p>
                <p className="text-description-no"><FormattedMessage id="bank.n26.price5" defaultMessage="Dedicated Customer Support" /></p>
                <p className="text-description-no"><FormattedMessage id="bank.n26.price6" defaultMessage="Exclusive partner offers" /></p>
            </div>
            <div className="card-black">
                <div className="title-description">N26 Black</div>
                <img src={card_black} alt='' style={{ width: '100%' }}></img>
                <div className="text-price"><span className="price-black">€9.90</span><span style={{color: "rgba(68, 76, 165, 0.32)"}}><FormattedMessage id="bank.n26.price.month" defaultMessage="/month" /></span></div>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.price1" defaultMessage=" Free ATM withdrawals in euros" /></p>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.price2" defaultMessage=" Free payments in any currency" /></p>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.price3" defaultMessage="Free withdrawals worldwide" /></p>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.price4" defaultMessage="Allianz insurance package" /></p>
                <p className="text-description-no"><FormattedMessage id="bank.n26.price5" defaultMessage="Dedicated Customer Support" /></p>
                <p className="text-description-no"><FormattedMessage id="bank.n26.price6" defaultMessage="Exclusive partner offers" /></p>
            </div>
            <div className="card-metal">
                <div className="title-description">N26 Metal</div>
                <img src={card_metal} alt='' style={{ width: '100%' }}></img>
                <div className="text-price"><span className="price-black">€16.90</span><span style={{color: "rgba(68, 76, 165, 0.32)"}}><FormattedMessage id="bank.n26.price.month" defaultMessage="/month" /></span></div>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.price1" defaultMessage=" Free ATM withdrawals in euros" /></p>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.price2" defaultMessage=" Free payments in any currency" /></p>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.price3" defaultMessage="Free withdrawals worldwide" /></p>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.price4" defaultMessage="Allianz insurance package" /></p>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.price5" defaultMessage="Dedicated Customer Support" /></p>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.price6" defaultMessage="Exclusive partner offers" /></p>
            </div>
        </div>
    )
}

function price_personal_eu() {
    return (
        <div>
            <div className="card-standard">
                <div className="title-description">N26</div>
                <img src={card_standard} alt='' style={{ width: '100%' }}></img>
                <div className="text-price"><span className="price-black">€0.00</span><span style={{color: "rgba(68, 76, 165, 0.32)"}}><FormattedMessage id="bank.n26.price.month" defaultMessage="/month" /></span></div>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.price1" defaultMessage="Free ATM withdrawals in euros" /></p>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.price2" defaultMessage="Free payments in any currency" /></p>
                <p className="text-description-no"><FormattedMessage id="bank.n26.price3" defaultMessage="Free withdrawals worldwide" /></p>
                <p className="text-description-no"><FormattedMessage id="bank.n26.price4" defaultMessage="Allianz insurance package" /></p>
            </div>
            <div className="card-black">
                <div className="title-description">N26 Black</div>
                <img src={card_black} alt='' style={{ width: '100%' }}></img>
                <div className="text-price"><span className="price-black">€9.90</span><span style={{color: "rgba(68, 76, 165, 0.32)"}}><FormattedMessage id="bank.n26.price.month" defaultMessage="/month" /></span></div>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.price1" defaultMessage=" Free ATM withdrawals in euros" /></p>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.price2" defaultMessage=" Free payments in any currency" /></p>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.price3" defaultMessage="Free withdrawals worldwide" /></p>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.price4" defaultMessage="Allianz insurance package" /></p>
            </div>
        </div>
    )
}

function price_personal_gb() {
    return (
        <div>
            <div className="card-standard">
                <div className="title-description">N26</div>
                <img src={card_standard} alt='' style={{ width: '100%' }}></img>
                <div className="text-price"><span className="price-black">£0.00</span><span style={{color: "rgba(68, 76, 165, 0.32)"}}><FormattedMessage id="bank.n26.price.month" defaultMessage="/month" /></span></div>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.gb.price1" defaultMessage="Free card payments in any currency" /></p>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.gb.price2" defaultMessage="Free ATM withdrawals worldwide" /></p>
                <p className="text-description-no"><FormattedMessage id="bank.n26.gb.price3" defaultMessage="10 Spaces" /></p>
                <p className="text-description-no"><FormattedMessage id="bank.n26.gb.price4" defaultMessage="LoungeKey Membership" /></p>
                <p className="text-description-no"><FormattedMessage id="bank.n26.gb.price5" defaultMessage="Travel insurance and purchase protectionNot included" /></p>
                <p className="text-description-no"><FormattedMessage id="bank.n26.gb.price6" defaultMessage="Dedicated customer support" /></p>
                <p className="text-description-no"><FormattedMessage id="bank.n26.gb.price7" defaultMessage="Exclusive partner offers" /></p>
            </div>
            <div className="card-black">
                <div className="title-description">N26 Black</div>
                <img src={card_black} alt='' style={{ width: '100%' }}></img>
                <div className="text-price"><span className="price-black">£4.90</span><span style={{color: "rgba(68, 76, 165, 0.32)"}}><FormattedMessage id="bank.n26.gb.price.month" defaultMessage="/month" /></span></div>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.gb.price1" defaultMessage="Free card payments in any currency" /></p>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.gb.price2" defaultMessage="Free ATM withdrawals worldwide" /></p>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.gb.price3" defaultMessage="10 Spaces" /></p>
                <p className="text-description-no"><FormattedMessage id="bank.n26.gb.price4" defaultMessage="LoungeKey Membership" /></p>
                <p className="text-description-no"><FormattedMessage id="bank.n26.gb.price5" defaultMessage="Travel insurance and purchase protectionNot included" /></p>
                <p className="text-description-no"><FormattedMessage id="bank.n26.gb.price6" defaultMessage="Dedicated customer support" /></p>
                <p className="text-description-no"><FormattedMessage id="bank.n26.gb.price7" defaultMessage="Exclusive partner offers" /></p>
            </div>
            <div className="card-metal">
                <div className="title-description">N26 Metal</div>
                <img src={card_metal} alt='' style={{ width: '100%' }}></img>
                <div className="text-price"><span className="price-black">£14.90</span><span style={{color: "rgba(68, 76, 165, 0.32)"}}><FormattedMessage id="bank.n26.gb.price.month" defaultMessage="/month" /></span></div>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.gb.price1" defaultMessage="Free card payments in any currency" /></p>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.gb.price2" defaultMessage="Free ATM withdrawals worldwide" /></p>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.gb.price3" defaultMessage="10 Spaces" /></p>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.gb.price4" defaultMessage="LoungeKey Membership" /></p>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.gb.price5" defaultMessage="Travel insurance and purchase protectionNot included" /></p>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.gb.price6" defaultMessage="Dedicated customer support" /></p>
                <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.n26.gb.price7" defaultMessage="Exclusive partner offers" /></p>
            </div>
        </div>
    )
}

class N26Modal extends Component {
    constructor() {
        super();
        this.state = {
            position: '0',
            document: '',
            openCountry: false,
            openPdf: false,
            priceComponent: null,
            style: {
                modal: {
                    'maxWidth': '600px',
                    'width': '100%',
                    'color': '#3f2751',
                },
            },
            photos: {},
            youtubeHW: 'https://www.youtube.com/watch?v=R7qYFppbYEE',
        }
        this.handleClickAccount = this.handleClickAccount.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickPDF = this.handleClickPDF.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    };

    componentDidMount() {
        if (this.props.language === "fr-FR") {
            this.setState({ youtubeHW: 'https://www.youtube.com/watch?v=pDzoTAIZ-yI&feature=youtu.be' })
        } else {
            this.setState({ youtubeHW: 'https://www.youtube.com/watch?v=R7qYFppbYEE' })
        }
        if (this.props.bankType === 'business'){
            this.setState({ priceComponent: price_business()});
        } else if (this.props.auth.user.location === "FR" || this.props.auth.user.location === "IT" ||
                this.props.auth.user.location === "DE" || this.props.auth.user.location === "ES") {
            console.log('fr');
            this.setState({ priceComponent: price_personal_fr() })
        } else if (this.props.auth.user.location === "GB") {
            this.setState({ priceComponent: price_personal_gb() })            
        } else {
            console.log('eu');
            this.setState({ priceComponent: price_personal_eu() })       
        } 
    }

    handleClickPDF(value) {
        if (value === 'price') {
            if (this.props.auth.user.location === 'GB') {
                window.open("https://docs.n26.com/legal/09+UK/01+Account/en/02account-pricelist-uk-en.pdf", "_blank");
            } else if (this.props.auth.user.location === "FR") {
                window.open("https://docs.n26.com/legal/04+FR/01+Account/fr/13account-pricelist-fr.pdf", "_blank");
            } else {
                window.open("https://docs.n26.com/legal/05+IT/01+Account/en/13account-pricelist-en.pdf", "_blank");
                
            }
        } else if (value === 'condition') {
            if (this.props.auth.user.location === 'FR') {
                window.open("https://docs.n26.com/legal/04+FR/01+Account/fr/13account-pricelist-fr.pdf", "_blank");
            } else if (this.props.auth.user.location === 'GB') {
                window.open("https://docs.n26.com/cs/Support+Center/Verification/201804-supporteddocs-UK-EN.pdf", "_blank");
            } else if (this.props.auth.user.location === 'ES') {
                window.open("https://docs.n26.com/cs/Support+Center/Verification/201804-supporteddocs-ES-EN.pdf", "_blank");
            } else if (this.props.auth.user.location === 'DE') {
                window.open("https://docs.n26.com/cs/Support+Center/Verification/201804-supporteddocs-DE-EN.pdf", "_blank");
            } else {
                window.open("https://docs.n26.com/cs/Support+Center/Verification/201804-supporteddocs-nonDE-EN.pdf", "_blank");
            }
        }
    };

    handleClick(position) {
        if (this.state.position === position) {
            this.setState({ position: "0" })
        } else {
            this.setState({ position: position });
        }
    };

    handleClickAccount() {
        if (this.props.auth.user.location === 'FR') {
            this.props.handleClickAccount("https://n26.go2cloud.org/aff_c?offer_id=31&aff_id=4615");
        } else if (this.props.auth.user.location === 'GB') {
            this.props.handleClickAccount("https://n26.go2cloud.org/aff_c?offer_id=4&aff_id=4615");
        } else if (this.props.auth.user.location === 'IT') {
            this.props.handleClickAccount("https://n26.go2cloud.org/aff_c?offer_id=29&aff_id=4615");
        } else if (this.props.auth.user.location === 'ES') {
            this.props.handleClickAccount("https://n26.go2cloud.org/aff_c?offer_id=33&aff_id=4615");
        } else if (this.props.auth.user.location === 'DE') {
            this.props.handleClickAccount("https://n26.go2cloud.org/aff_c?offer_id=27&aff_id=4615");
        } else {
            this.props.handleClickAccount("https://n26.go2cloud.org/aff_c?offer_id=35&aff_id=4615");

        }
    };

    onOpenModal(name) {
        if (name === 'openPdf') {
            this.setState({ [name]: true, document: 'price' });
        } else if (name === 'openId') {
            this.setState({ openPdf: true, document: 'supportedId' });
        } else {
            this.setState({ [name]: true });
        }
    };

    onCloseModal(name) {
        this.setState({ [name]: false });
    };

    render() {
        let { img } = {};
        if (this.props.language === 'fr-FR') {
            img = <div><img src={n26_card_fr} alt='' style={{ width: '100%' }}></img>
                <img src={n26_transfer_fr} alt='' style={{ width: '100%' }}></img>
                <img src={n26_setting_fr} alt='' style={{ width: '100%' }}></img>
                <img src={n26_notification_fr} alt='' style={{ width: '100%' }}></img>
                <img src={n26_spaces_fr} alt='' style={{ width: '100%' }}></img></div>
        } else {
            img = <div><img src={n26_card_en} alt='' style={{ width: '100%' }}></img>
                <img src={n26_statistic_en} alt='' style={{ width: '100%' }}></img>
                <img src={n26_setting_en} alt='' style={{ width: '100%' }}></img>
                <img src={n26_notification_en} alt='' style={{ width: '100%' }}></img>
                <img src={n26_spaces_en} alt='' style={{ width: '100%' }}></img></div>
        }
        return (
            <div className="service-bank-N26">
                <div className="header">
                    <div className="header_logo">
                        <img /* className="header-pic" src={nlogo} alt=''*/></img>
                    </div>
                    <div className="header_title"><b><FormattedMessage id="service.bank.N26.title" defaultMessage="The first bank you will love" /></b></div>
                </div>
                <div className="cont-mod">
                    <FormattedMessage id="bank.n26.benefits" defaultMessage="Benefits / Features">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "1")} handleTriggerClick={this.handleClick} accordionPosition="1">
                                <ul className='text'>
                                    <li><FormattedMessage id="bank.n26.reason2" defaultMessage="Get your account in 8 minutes." /></li>
                                    <li><FormattedMessage id="bank.n26.reason3" defaultMessage="A clear pricing" /></li>
                                    <li><FormattedMessage id="bank.n26.reason4" defaultMessage="Transactions available in 19 currencies." /></li>
                                    <li><FormattedMessage id="bank.n26.reason5" defaultMessage="1 Mastercard including." /></li>
                                    <li><FormattedMessage id="bank.n26.reason6" defaultMessage="No paperwork, 100% online." /></li>
                                    <li><FormattedMessage id="bank.n26.reason7" defaultMessage="N26 dispose d’une licence bancaire permettant une garantie jusqu’a 100 000 euro." /></li>
                                    <li><FormattedMessage id="bank.n26.reason8" defaultMessage="Already 2.3 millions users." /></li>
                                    <li><FormattedMessage id="bank.n26.reason9" defaultMessage="Dedicated service teams in English, German, French, Spanish and Italian." /></li>
                                </ul>
                            </Collapsible>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="bank.n26.pricing" defaultMessage="Pricing">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "2")} handleTriggerClick={this.handleClick} accordionPosition="2">
                                <div className="title-description">
                                    <FormattedMessage id="bank.n26.pricing.subtitle" defaultMessage="Find a plan that works for you." />
                                </div>
                                <u><div style={{ color: 'rgba(68, 76, 165, 0.85)99', fontSize: '13px', textAlign: 'right' }}><div onClick={() => this.handleClickPDF('price')} style={{ cursor: 'pointer', textAlign: 'right' }}><FormattedMessage id="bank.n26.price.see" defaultMessage="See pricing (pdf)" /> </div></div></u>
                                    {this.state.priceComponent}
                                <u><div style={{ color: 'rgba(68, 76, 165, 0.85)99', fontSize: '13px', textAlign: 'right' }}><div onClick={() => this.handleClickPDF('price')} style={{ cursor: 'pointer', textAlign: 'right' }}><FormattedMessage id="bank.n26.price.see" defaultMessage="See pricing (pdf)" /> </div></div></u>
                            </Collapsible>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="bank.n26.condition" defaultMessage="Account holder requirement">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "3")} handleTriggerClick={this.handleClick} accordionPosition="3">
                                <ul className='text'>
                                    <li><FormattedMessage id="bank.n26.condition1" defaultMessage="At least 18 years old." /></li>
                                    <li><FormattedMessage id="bank.n26.condition2" defaultMessage="Living in the accepted " /><span onClick={() => this.onOpenModal("openCountry")} style={{ cursor: 'pointer', textDecoration: 'underline' }}><FormattedMessage id="bank.n26.condition2.1" defaultMessage="countries." /></span></li>
                                    <li><FormattedMessage id="bank.n26.condition3" defaultMessage="Using a compatible smartphone." /></li>
                                    <li><FormattedMessage id="bank.n26.condition4" defaultMessage="Don’t already have a N26 account." /></li>
                                    <li><FormattedMessage id="bank.n26.condition5" defaultMessage="Holding a " /><span onClick={() => this.handleClickPDF('condition')} style={{ cursor: 'pointer', textDecoration: 'underline' }}><FormattedMessage id="bank.n26.condition5.1" defaultMessage="supported ID." /></span></li>
                                </ul>
                            </Collapsible>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="bank.n26.howItWork" defaultMessage="How does N26 work ?">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "4")} handleTriggerClick={this.handleClick} accordionPosition="4">
                                <ReactPlayer
                                    width='100%'
                                    controls
                                    config={{
                                        youtube: {
                                            playerVars: { showinfo: 1 }
                                        }
                                    }}
                                    url={this.state.youtubeHW}
                                />
                            </Collapsible>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="bank.n26.photo" defaultMessage="Photos">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "5")} handleTriggerClick={this.handleClick} accordionPosition="5">
                                {img}
                            </Collapsible>
                        )}
                    </FormattedMessage>
                </div>
                <div className="footer" style={{ textAlign: 'center' }}>
                    <Button variant="contained" color="primary" className="button" onClick={this.handleClickAccount}>
                        <img src={Issoyo_logo} alt='' style={{ width: '11%', marginRight: '5%' }}></img>
                        <FormattedMessage id="bank.open.account"></FormattedMessage>
                    </Button>
                </div>
                <Modal open={this.state.openPdf} styles={this.state.style} onClose={() => this.onCloseModal('openPdf')} center blockScroll>
                    <N26ModalPDF document={this.state.document} />
                </Modal>
                <Modal open={this.state.openCountry} styles={this.state.style} onClose={() => this.onCloseModal('openCountry')} center blockScroll>
                    <div className="title"><FormattedMessage id="bank.n26.condition2.2" defaultMessage="Countries accepted" /></div>
                    <div className="text">
                        <ul>
                            <li>Germany</li>
                            <li>Austria</li>
                            <li>Ireland</li>
                            <li>France</li>
                            <li>Spain</li>
                            <li>Italy</li>
                            <li>Netherlands</li>
                            <li>Belgium</li>
                            <li>Portugal</li>
                            <li>Finland</li>
                            <li>Luxembourg</li>
                            <li>Slovenia</li>
                            <li>Estonia</li>
                            <li>Greece</li>
                            <li>Slovakia</li>
                            <li>UK</li>
                            <li>Poland</li>
                            <li>Sweden</li>
                            <li>Denmark</li>
                            <li>Norway</li>
                            <li>Liechtenstein</li>
                            <li>Iceland</li>
                        </ul>
                    </div>
                </Modal>
            </div>
        )
    }
}

N26Modal.propTypes = {
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    service: state.services,
});

export default connect(
    mapStateToProps, {}
)(N26Modal);