import React from 'react';
import { FormattedMessage } from 'react-intl';
import imagedesktop_section2 from "../../../assets/img/imagedesktop_section2@2x.png";
import icon_bank from "../../../assets/img/icon_bank.svg";
import icon_realstate from "../../../assets/img/icon_realstate.svg";
import icon_phone from "../../../assets/img/icon_phone.svg";
import icon_heart from "../../../assets/img/icon_heart.svg";
import icon_wifi from "../../../assets/img/icon_wifi.svg";
import icon_comm from "../../../assets/img/icon_comm.svg";
import img_phone_home_fr from "../../../assets/img/img_phone_home_fr-FR.png";
import img_phone_home_en from "../../../assets/img/img_phone_home_en-US.png";
//import img_phone_home_fr from "../../../assets/img/img_phone_home_fr-FR.webp";
//import img_phone_home_en from "../../../assets/img/img_phone_home_en-US.webp";


require('./Home.scss');

export class Sections extends React.Component {
    
    changeImage(){
        if (this.props.language === 'fr-FR')
            return this.img_phone = img_phone_home_fr;
        else
            return this.img_phone = img_phone_home_en;
    }

    render () {
        return (
        <section className="section section-2">
            <div className="col-md-12">
                <div className="title">
                    <div className="dedicated-to-expats">
                        <FormattedMessage id="home.titleExpatsCommunity" defaultMessage="Dedicated to the expat community" />
                    </div>
                </div>
                <div className="text">
                    <div className="issoyo-provides-tail">
                        <FormattedMessage id="home.textExpatsCommunity" defaultMessage="We offer tailor-made services and advices to facilitate both departure from home and arrival in hosting country" />
                    </div>
                </div>
            </div>
            <div className="col-md-12 row no-border-padding">
                <div className="col-md-4 no-border-padding">
                    <div className="col-md-12 row serviceItem">
                        <div className="col-md-6" style={{textAlign: 'right'}}>
                            <img className="pull-right" src={icon_bank} alt=""/>
                        </div>
                        <div className="col-md-6">
                            <div className="pull-left subtitle">
                                <a style={{color: "#3e4594"}} href="bank"><FormattedMessage id="home.bank" defaultMessage="Banking" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 row serviceItem">
                        <div className="col-md-6" style={{textAlign: 'right'}}>
                            <img className="pull-right" src={icon_realstate} alt=""/>
                        </div>
                        <div className="col-md-6">
                            <div className="pull-left subtitle">
                                <a style={{color: "#3e4594"}} href="/services"><FormattedMessage id="home.estate" defaultMessage="Housing" /></a>
                            </div>
                        </div>
                    </div>
                <div className="col-md-12 row serviceItem">
                    <div className="col-md-6" style={{textAlign: 'right'}}>
                        <img className="pull-right" src={icon_phone} alt=""/>
                    </div>
                    <div className="col-md-6">
                        <div className="pull-left subtitle">
                            <a style={{color: "#3e4594"}} href="/services"><FormattedMessage id="home.telecom" defaultMessage="Telecom" /></a>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 row serviceItem">
                    <div className="col-md-6" style={{textAlign: 'right'}}>
                        <img  className="pull-right" src={icon_heart} alt=""/>
                    </div>
                    <div className="col-md-6">
                        <div className="pull-left subtitle">
                        <a style={{color: "#3e4594"}} href="/services"><FormattedMessage id="home.insurance" defaultMessage="Insurances" /></a>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 row serviceItem">
                    <div className="col-md-6" style={{textAlign: 'right'}}>
                        <img className="pull-right" src={icon_wifi} alt=""/>
                    </div>
                    <div className="col-md-6">
                        <div className="pull-left subtitle">
                            <a style={{color: "#3e4594"}} href="/services"><FormattedMessage id="home.wifi" defaultMessage="Internet" /></a>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 row serviceItem">
                    <div className="col-md-6" style={{textAlign: 'right'}}>
                        <img className="pull-right" src={icon_comm} alt="" />
                    </div>
                    <div className="col-md-6">
                        <div className="pull-left subtitle">
                            <a style={{color: "#3e4594"}}  href="/services"><FormattedMessage id="home.meetup" defaultMessage="Meetup" /></a>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 row serviceItem">
                    <div className="col-md-6" style={{textAlign: 'right'}}>
                        <img className="pull-right" src="" alt=""/>
                    </div>
                    <div className="col-md-6">
                        <div className="pull-left subtitle">
                            <FormattedMessage id="home.more" defaultMessage="... And more to come" />
                        </div>
                    </div>
                </div>  
            </div>
            <div className="col-md-8 no-border-padding">
                <img className="image-center" src={imagedesktop_section2} alt='' />
                <img className="image-center image-phone" src={this.changeImage()} alt=''/>
            </div>
        </div>
        <div className="col-md-12"></div>
    </section>
        )
    }
}