import React, { Component } from 'react'
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from 'react-responsive-modal';
import Countries from "../Countries/Countries";
import countryList from 'react-select-country-list';
import { Helmet } from "react-helmet";
import { FormattedMessage } from 'react-intl';
import ReactCountryFlag from "react-country-flag";
import { setLocation } from '../../../actions/authActions';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Issoyoservicemodal from '../Services/Issoyoservice/Issoyoservicemodal.jsx';

require ('../Services/Services.scss')

const styles = {
    root: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    selectMenu: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    input: {
        borderColor: '#3e45948a !important',
    },
};

class IndexBlog extends Component {
    constructor() {
        super();
        this.countries = countryList().getData();
        this.state = {
            articles: [],
            country: '',
            country_name: '',
            open: false,
            typeArticle: 'all',
            save_articles: [],
            style: {
                modal: {
                    'maxWidth': '700px',
                    'width': '100%',
                    'color': '#3f2751',
                },
            },
            openForm: false,
        }
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onOpenModalService = this.onOpenModalService.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.onClose = this.onClose.bind(this);
        this.wishList = this.wishList.bind(this);
        this.changeCountry = this.changeCountry.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps() {
        axios.get('/api/service/getblog')
            .then(response => {
                var articles = response.data;
                var country = this.props.auth.user.location
                var langue = this.props.language
                var type = this.state.typeArticle
                var articles_array = articles.filter(function (data) {
                    if (data.country.toLowerCase() === country.toLowerCase()) {
                        if (data.publish) {
                            if (data.langueService.toLowerCase().includes(langue.toLowerCase().substring(0, 2))) {
                                if (type === "all") {
                                    return true;
                                }
                                else if (data.typeService.toLowerCase().includes(type) > 0) {
                                    return true;
                                }
                            }
                        }
                    }
                    return false;
                })
                this.setState({ articles: articles_array, save_articles: articles_array })
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    componentDidMount() {
        if (!this.state.country && !this.props.auth.user.location) {
            this.onOpenModal()
        } else if (this.props.auth.user.location) {
            this.setState({ country: this.props.auth.user.location });
            axios.get('/api/service/getblog')
                .then(response => {
                    var articles = response.data;
                    var country = this.props.auth.user.location
                    var langue = this.props.language
                    var articles_array = articles.filter(function (data) {
                        if (data.country.toLowerCase() === country.toLowerCase()) {
                            console.log(data.publish)
                            console.log(data.langueService.toLowerCase())
                            console.log(langue.toLowerCase().substring(0, 2))
                            if (data.publish) {
                                if (data.langueService.toLowerCase().includes(langue.toLowerCase().substring(0, 2))) {
                                    return true;
                                }
                            }
                        }
                        return false;
                    })
                    this.setState({ articles: articles_array, save_articles: articles_array })
                })
                .catch(function (error) {
                    console.log(error);
                })
        }
        window.scrollTo(0, 0);
    }

    componentWillMount() {
        if (window.matchMedia("(min-width: 436px)").matches) {
            /* The viewport is at least 400 pixels wide */
            this.setState({
                style: {
                    modal: {
                        'maxWidth': '700px',
                        'width': '100%',
                        'color': '#3f2751',
                    },
                }
            });
        } else {
            /* The viewport is less than 400 pixels wide */
            this.setState({
                style: {
                    modal: {
                        'height': '100%',
                        'left': '0',
                        'position': 'fixed',
                        'top': '0',
                        'width': '100%',
                        'color': '#3f2751',
                    },
                }
            });
        }
        if ((this.props.auth.user.location)) {
            const location = this.props.auth.user.location
            var country_name = this.countries.find(function (data) {
                return data.value === location;
            })
            this.setState({
                country_name: country_name.label,
            });
        }
    }

    onOpenModal() {
        this.setState({ open: true });
    };

    onOpenModalService() {
        this.setState({ openForm: true });
    }

    onCloseModal() {
        if (this.state.country === '') {

        } else {
            this.setState({ open: false, openForm: false });
        }
    };

    onClose() {
        this.setState({ open: false, openForm: false });
    };

    wishList() {
        this.props.history.push('/wishlist')
    }

    changeCountry(value, label) {
        this.setState({
            country: value,
            country_name: label,

        }, () => { this.onCloseModal() });
        this.props.setLocation({ user: this.props.auth.user, location: value, country: label });
        axios.get('/api/service/getblog')
            .then(response => {
                var articles = response.data;
                var country = this.props.auth.user.location
                var articles_array = articles.filter(function (data) {
                    if (data.country.toLowerCase() === country.toLowerCase()) {
                        return true;
                    }
                    return false;
                })
                this.setState({ articles: articles_array })
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    handleClick(id) {
        this.props.history.push(`/blog/show/${id}`)
    }

    handleChange(e) {
        console.log(e.target.name)
        if (e.target.name === 'typeArticle') {
            var country = this.props.auth.user.location
            var langue = this.props.language
            var type = e.target.value
            console.log('touche')
            var articles_array = this.state.save_articles.filter(function (data) {
                if (data.country.toLowerCase() === country.toLowerCase()) {
                    if (data.publish) {
                        if (data.langueService.toLowerCase().includes(langue.toLowerCase().substring(0, 2))) {
                            if (type === "all") {
                                return true;
                            }
                            else if (data.typeService.toLowerCase().includes(type) > 0) {
                                return true;
                            }
                        }
                    }
                }
                return false;
            })
            this.setState({ [e.target.name]: e.target.value, articles: articles_array })
        }
    }

    render() {
        const { open } = this.state;
        const { classes } = this.props;
        return (
            <div className="Services" style={{padding: '24px', background:'#b8aeca0d', borderRadius: '20px',  margin: 'auto', marginTop: '90px', maxWidth: '800px' }}>
                <Helmet>
                    <title>Issoyo - Blog</title>
                    <meta name="description" content="All infortmation you need to know for moving comfortable" />
                </Helmet>
                <div className="signup_back"><i style={{ color: '#593e96' }} className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                <div className="title">
                    <FormattedMessage id="blog.title" defaultMessage="Our articles in ">{msg => (`${msg} ${this.state.country_name}`)}</FormattedMessage>
                    <Button color="primary" className="service_location-button" onClick={this.onOpenModal}>
                        <ReactCountryFlag
                            styleProps={{
                                width: '29px',
                                height: '22px',
                                borderRadius: '15px',
                                boxShadow: 'rgba(25, 23, 23, 0.13) 4px 2px 16px 1px',
                            }}
                            svg
                            code={this.state.country}
                            onClick={this.onOpenModal}
                        />
                        <i className="fas fa-angle-down" style={{ marginLeft: '3px' }}></i>
                    </Button>
                </div>
                <div style={{ maxWidth: '300px', margin: 'auto' }}>
                    { this.state.articles.length > 0 ? 
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Service"
                        className="list taxi_airport"
                        value={this.state.typeArticle}
                        name="typeArticle"
                        onChange={this.handleChange}
                        SelectProps={{
                            MenuProps: {
                                className: classes.menu,
                            },
                        }}
                        InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                        margin="normal"
                        variant="outlined"
                    >
                        <MenuItem value='none' disabled classes={{ root: classes.root }}><FormattedMessage id="blog.select" defaultMessage="Select articles type" /></MenuItem>
                        <MenuItem value='all' classes={{ root: classes.root }}><FormattedMessage id="blog.select.all" defaultMessage="All articles" /></MenuItem>
                        <MenuItem value='bank' classes={{ root: classes.root }}><FormattedMessage id="blog.select.bank" defaultMessage="Bank articles" /></MenuItem>
                        <MenuItem value='housing' classes={{ root: classes.root }}><FormattedMessage id="blog.select.housing" defaultMessage="Housing articles" /></MenuItem>
                        <MenuItem value='telecom' classes={{ root: classes.root }}><FormattedMessage id="blog.select.telecom" defaultMessage="Telecom articles" /></MenuItem>
                        <MenuItem value='insurance' classes={{ root: classes.root }}><FormattedMessage id="blog.select.insurance" defaultMessage="Insurances articles" /></MenuItem>
                        <MenuItem value='internet' classes={{ root: classes.root }}><FormattedMessage id="blog.select.internet" defaultMessage="Internet articles" /></MenuItem>
                        <MenuItem value='ticketing' classes={{ root: classes.root }}><FormattedMessage id="blog.select.ticketing" defaultMessage="Ticketing articles" /></MenuItem>
                        <MenuItem value='utilities' classes={{ root: classes.root }}><FormattedMessage id="blog.select.utilities" defaultMessage="Utilities articles" /></MenuItem>
                        <MenuItem value='transport' classes={{ root: classes.root }}><FormattedMessage id="blog.select.transport" defaultMessage="Transport articles" /></MenuItem>
                        <MenuItem value='issoyo' classes={{ root: classes.root }}><FormattedMessage id="blog.select.issoyo" defaultMessage="Issoyo articles" /></MenuItem>
                    </TextField> : null }
                </div>
                <div className='row' style={{ alignItems: 'center' }}>
                    {this.state.articles.map((data, key) =>
                        <div className="div-card" key={key} style={{ width: '100%', marginTop: '20px' }}>
                            <Card key={key}>
                                <CardContent>
                                    <Typography color="textSecondary" gutterBottom>
                                        {data.typeService}
                                    </Typography>
                                    <Typography variant="h5" component="h2" style={{ color: "#3E4594" }}>
                                        {data.title}
                                    </Typography>
                                    <Typography color="textSecondary">
                                        {data.create_date.substring(0, 10)}
                                    </Typography>
                                    <Typography variant="body2" component="p" style={{ color: "#444ca5d9" }}>
                                        {data.description.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 280)} ...
                            </Typography>
                                </CardContent>
                                <CardActions style={{ backgroundColor: '#80808014', width: '100%' }}>
                                    <Button size="small" color="primary" onClick={() => this.handleClick(data._id)} style={{ color: '#3f51b5' }} >
                                        Read More
                            </Button>
                                </CardActions>
                            </Card>
                        </div>
                    )}
                </div>
                <div>
                    <div onClick={this.onOpenModalService} style={{cursor: 'pointer'}} className="text"><u><FormattedMessage id="blog.submit.article" defaultMessage="You whish to sumbit a specific topic or an article ? Clicke here"/></u></div>
                </div>
                <Modal open={this.state.openForm} onClose={this.onClose} center styles={this.state.style} >
                    <Issoyoservicemodal serviceName={"blog"} onClose={this.onClose} language={this.props.language}></Issoyoservicemodal>
                </Modal>
                <Modal closeOnOverlayClick={false} closeOnEsc={false} open={open} onClose={this.onCloseModal} center styles={this.state.style} blockScroll >
                    <Countries wishList={this.wishList} changeCountry={(country, label) => this.changeCountry(country, label)}></Countries>
                </Modal>
            </div>
        )
    }
}

IndexBlog.propTypes = {
    auth: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(
    mapStateToProps,
    { setLocation }
)(withStyles(styles)(IndexBlog));