import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import april_logo from '../../../../../assets/img/april.png';
import axa_logo_w412 from '../../../../../assets/img/providers/Axa_logo_w412.jpg';
import axa_bandeau_wide from '../../../../../assets/img/providers/Axa_bandeau_wide.jpg';
import magellan_brochureFR from '../../../../../assets/document/insurance/magellan_brochure_fr.pdf';
import magellan_garantieFR from '../../../../../assets/document/insurance/magellan_garantie_fr.pdf';
import magellan_ipidFR from '../../../../../assets/document/insurance/magellan_ipid_fr.pdf';
import magellan_brochureEN from '../../../../../assets/document/insurance/magellan_brochure_en.pdf';
import magellan_garantieEN from '../../../../../assets/document/insurance/magellan_garantie_en.pdf';
import magellan_ipidEN from '../../../../../assets/document/insurance/magellan_ipid_en.pdf';
import expat_brochureEN from '../../../../../assets/document/insurance/expat_brochure_en.pdf';
import expat_conditionFR from '../../../../../assets/document/insurance/expat_condition_fr.pdf';
import expat_ipidFR from '../../../../../assets/document/insurance/expat_ipid_fr.pdf';
import expat_brochureFR from '../../../../../assets/document/insurance/expat_brochure_fr.pdf';
import expat_conditionEN from '../../../../../assets/document/insurance/expat_condition_en.pdf';
import expat_ipidEN from '../../../../../assets/document/insurance/expat_ipid_en.pdf';
import crystal_brochureFR from '../../../../../assets/document/insurance/crystal_brochure_fr.pdf';
import crystal_conditionEN from '../../../../../assets/document/insurance/crystal_condition_en.pdf';
import crystal_ipidEN from '../../../../../assets/document/insurance/crystal_ipid_en.pdf';
import crystal_brochureEN from '../../../../../assets/document/insurance/crystal_brochure_en.pdf';
import crystal_conditionFR from '../../../../../assets/document/insurance/crystal_condition_fr.pdf';
import crystal_ipidFR from '../../../../../assets/document/insurance/crystal_ipid_fr.pdf';
import masante_brochureFR from '../../../../../assets/document/insurance/masante_brochure_fr.pdf';
import masante_conditionFR from '../../../../../assets/document/insurance/masante_condition_fr.pdf';
import masante_ipidFR from '../../../../../assets/document/insurance/masante_ipid_fr.pdf';
import masante_brochureEN from '../../../../../assets/document/insurance/masante_brochure_en.pdf';
import masante_conditionEN from '../../../../../assets/document/insurance/masante_condition_en.pdf';
import masante_ipidEN from '../../../../../assets/document/insurance/masante_ipid_en.pdf';
import schengen_brochureFR from '../../../../../assets/document/insurance/ISSOYO_schengen_brochure_fr.pdf';
import schengen_conditionEN from '../../../../../assets/document/insurance/ISSOYO_schengen_condition_en.pdf';
import schengen_conditionFR from '../../../../../assets/document/insurance/ISSOYO_schengen_condition_fr.pdf';
import schengen_ipidFR from '../../../../../assets/document/insurance/ISSOYO_schengen_ipid_fr.pdf';
import ButtonToService from '../../../ButtonToService/ButtonToService';
import YouTube from 'react-youtube';

require('./Health.scss');


function crystal_info(language) {
    const handleClick = (document) => {
        if (document === "brochure") {
            if (language === 'fr-FR') {
                window.open(crystal_brochureFR, "_blank");
            } else {
                window.open(crystal_brochureEN, "_blank");
            }
        } else if (document === "condition") {
            if (language === 'fr-FR') {
                window.open(crystal_conditionFR, "_blank");
            } else {
                window.open(crystal_conditionEN, "_blank");
            }
        } else if (document === "ipid") {
            if (language === 'fr-FR') {
                window.open(crystal_ipidFR, "_blank");
            } else {
                window.open(crystal_ipidEN, "_blank");
            }
        };
    }

    return (
        <div>
						<div className="health_logo perso">
								<img className="health_logo-img" src={april_logo} alt=''></img>
						</div>
            <div className="row crystal_info 1">
                <div className="col-md-4" style={{ marginTop: "24px" }}>
                    <div className="health_title">
                        <FormattedMessage id="insurance.health.crystal.student.title" defaultMessage="300,000 Students" />
                    </div>
                    <div className="health_text">
                        <FormattedMessage id="insurance.health.crystal.student.text" defaultMessage="have already chosen Crystal Studies" />
                    </div>
                </div>
                <div className="col-md-4" style={{ marginTop: "24px" }}>
                    <div className="health_title">
                        <FormattedMessage id="insurance.health.crystal.international.title" defaultMessage="International cover" />
                    </div>
                    <div className="health_text" >
                        <FormattedMessage id="insurance.health.crystal.international.text" defaultMessage="valid in all countries visited" />
                    </div>
                </div>
                <div className="col-md-4" style={{ marginTop: "24px" }}>
                    <div className="health_title">
                        <FormattedMessage id="insurance.health.crystal.compliant.title" defaultMessage="Compliant benefits" />
                    </div>
                    <div className="health_text">
                        <FormattedMessage id="insurance.health.crystal.compliant.text" defaultMessage="metting the requirements of international universities" />
                    </div>
                </div>
                <div className="col-md-4" style={{ marginTop: "24px" }}>
                    <div className="health_title">
                        <FormattedMessage id="insurance.health.crystal.medical.title" defaultMessage="Medical expenses" />
                    </div>
                    <div className="health_text" >
                        <FormattedMessage id="insurance.health.crystal.medical.text" defaultMessage="covered at 100% with no excess and no waiting period" />
                    </div>
                </div>
                <div className="col-md-4" style={{ marginTop: "24px" }}>
                    <div className="health_title">
                        <FormattedMessage id="insurance.health.crystal.reimbursements.title" defaultMessage="Reimbursements" />
                    </div>
                    <div className="health_text">
                        <FormattedMessage id="insurance.health.crystal.reimbursements.text" defaultMessage="just a click away with the Easy Claim app" />
                    </div>
                </div>
                <div className="col-md-4" style={{ marginTop: "24px" }}>
                    <div className="health_title">
                        <FormattedMessage id="insurance.health.crystal.cash.title" defaultMessage="No cash advance" />
                    </div>
                    <div className="health_text">
                        <FormattedMessage id="insurance.health.crystal.cash.text" defaultMessage="required for hospitalisation" />
                    </div>
                </div>
            </div>
            <div className="health_document_row" style={{ marginTop: "24px", marginBottom: "48px" }}>
                <div>
                    <div className="health_document-container test" onClick={() => handleClick("brochure")}>
                        <div>
                            <i className="fal fa-file-alt"></i> <FormattedMessage id="insurance.health.brochure" />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="health_document-container" onClick={() => handleClick("condition")}>
                        <div>
                            <i className="fal fa-file-alt"></i> <FormattedMessage id="insurance.health.condition" />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="health_document-container" onClick={() => handleClick("ipid")}>
                        <div>
                            <i className="fal fa-file-alt"></i> <FormattedMessage id="insurance.health.ipid" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function expat_info(language) {
    const handleClick = (document) => {
        if (document === "brochure") {
            if (language === 'fr-FR') {
                window.open(expat_brochureFR, "_blank");
            } else {
                window.open(expat_brochureEN, "_blank");
            }
        } else if (document === "condition") {
            if (language === 'fr-FR') {
                window.open(expat_conditionFR, "_blank");
            } else {
                window.open(expat_conditionEN, "_blank");
            }
        } else if (document === "ipid") {
            if (language === 'fr-FR') {
                window.open(expat_ipidFR, "_blank");
            } else {
                window.open(expat_ipidEN, "_blank");
            }
        };
    }

    return (
        <div>
						<div className="health_logo perso">
								<img className="health_logo-img" src={april_logo} alt=''></img>
						</div>
            <div className="row crystal_info 2">
                <div className="col-md-4" style={{ marginTop: "24px" }}>
                    <div className="health_title">
                        <FormattedMessage id="insurance.health.crystal.student.title" />
                    </div>
                    <div className="health_text" >
                        <FormattedMessage id="insurance.health.crystal.student.text" />
                    </div>
                </div>
                <div className="col-md-4" style={{ marginTop: "24px" }}>
                    <div className="health_title">
                        <FormattedMessage id="insurance.health.expat.international.title" defaultMessage="Long-term cover" />
                    </div>
                    <div className="health_text" >
                        <FormattedMessage id="insurance.health.expat.international.text" defaultMessage="Valid during the entire course abroad" />
                    </div>
                </div>
                <div className="col-md-4" style={{ marginTop: "24px" }}>
                    <div className="health_title">
                        <FormattedMessage id="insurance.health.expat.compliant.title" defaultMessage="Benefits which comply" />
                    </div>
                    <div className="health_text" >
                        <FormattedMessage id="insurance.health.expat.compliant.text" defaultMessage="with the requirements of international universities" />
                    </div>
                </div>
                <div className="col-md-4" style={{ marginTop: "24px" }}>
                    <div className="health_title">
                        <FormattedMessage id="insurance.health.crystal.medical.title" />
                    </div>
                    <div className="health_text" >
                        <FormattedMessage id="insurance.health.crystal.medical.text" />
                    </div>
                </div>
                <div className="col-md-4" style={{ marginTop: "24px" }}>
                    <div className="health_title">
                        <FormattedMessage id="insurance.health.crystal.reimbursements.title" />
                    </div>
                    <div className="health_text">
                        <FormattedMessage id="insurance.health.crystal.reimbursements.text" />
                    </div>
                </div>
                <div className="col-md-4" style={{ marginTop: "24px" }}>
                    <div className="health_title">
                        <FormattedMessage id="insurance.health.crystal.cash.title" />
                    </div>
                    <div className="health_text">
                        <FormattedMessage id="insurance.health.crystal.cash.text" />
                    </div>
                </div>
            </div>
            <div className="health_document_row" style={{ marginTop: "24px", marginBottom: "48px" }}>
                <div >
                    <div className="health_document-container test1" onClick={() => handleClick("brochure")}>
                        <div>
                            <i className="fal fa-file-alt"></i> <FormattedMessage id="insurance.health.brochure" />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="health_document-container" onClick={() => handleClick("condition")}>
                        <div>
                            <i className="fal fa-file-alt"></i> <FormattedMessage id="insurance.health.condition" />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="health_document-container" onClick={() => handleClick("ipid")}>
                        <div>
                            <i className="fal fa-file-alt"></i> <FormattedMessage id="insurance.health.ipid" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function magellan_info(language) {
    const handleClick = (document) => {
        if (document === "brochure") {
            if (language === 'fr-FR') {
                window.open(magellan_brochureFR, "_blank");
            } else {
                window.open(magellan_brochureEN, "_blank");
            }
        } else if (document === "garantie") {
            if (language === 'fr-FR') {
                window.open(magellan_garantieFR, "_blank");
            } else {
                window.open(magellan_garantieEN, "_blank");
            }
        } else if (document === "ipid") {
            if (language === 'fr-FR') {
                window.open(magellan_ipidFR, "_blank");
            } else {
                window.open(magellan_ipidEN, "_blank");
            }
        };
    }

    return (
        <div>
						<div className="health_logo perso">
								<img className="health_logo-img" src={april_logo} alt=''></img>
						</div>
            <div className="row crystal_info 3">
                <div className="col-md-4" style={{ marginTop: "24px" }}>
                    <div className="health_title">
                        <FormattedMessage id="insurance.health.crystal.international.title" />
                    </div>
                    <div className="health_text" >
                        <FormattedMessage id="insurance.health.crystal.international.text" />
                    </div>
                </div>
                <div className="col-md-4" style={{ marginTop: "24px" }}>
                    <div className="health_title">
                        <FormattedMessage id="insurance.health.crystal.medical.title" />
                    </div>
                    <div className="health_text" >
                        <FormattedMessage id="insurance.health.crystal.medical.text" />
                    </div>
                </div>
                <div className="col-md-4" style={{ marginTop: "24px" }}>
                    <div className="health_title">
                        <FormattedMessage id="insurance.health.crystal.reimbursements.title" />
                    </div>
                    <div className="health_text">
                        <FormattedMessage id="insurance.health.crystal.reimbursements.text" />
                    </div>
                </div>
                <div className="col-md-4" style={{ marginTop: "24px" }}>
                    <div className="health_title">
                        <FormattedMessage id="insurance.health.crystal.cash.title" />
                    </div>
                    <div className="health_text">
                        <FormattedMessage id="insurance.health.crystal.cash.text" />
                    </div>
                </div>
                <div className="col-md-4" style={{ marginTop: "24px" }}>
                    <div className="health_title">
                        <FormattedMessage id="insurance.health.magellan.wh.title" defaultMessage="Meet the requirements" />
                    </div>
                    <div className="health_text" >
                        <FormattedMessage id="insurance.health.magellan.wh.text" defaultMessage="of Working Holiday Visas" />
                    </div>
                </div>
                <div className="col-md-4" style={{ marginTop: "24px" }}>
                    <div className="health_title">
                        <FormattedMessage id="insurance.health.magellan.benefits.title" defaultMessage="Benefits valid" />
                    </div>
                    <div className="health_text" >
                        <FormattedMessage id="insurance.health.magellan.benefits.text" defaultMessage="during temporary return visits to the country of nationality" />
                    </div>
                </div>
            </div>
            <div className="health_document_row" style={{ marginTop: "24px", marginBottom: "48px" }}>
                <div>
                    <div className="health_document-container test2" onClick={() => handleClick("brochure")}>
                        <div>
                            <i className="fal fa-file-alt"></i> <FormattedMessage id="insurance.health.brochure" />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="health_document-container" onClick={() => handleClick("garantie")}>
                        <div>
                            <i className="fal fa-file-alt"></i> <FormattedMessage id="insurance.health.benefits" />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="health_document-container" onClick={() => handleClick("ipid")}>
                        <div>
                            <i className="fal fa-file-alt"></i> <FormattedMessage id="insurance.health.ipid" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function masante_info(language) {
    const handleClick = (document) => {
        if (document === "brochure") {
            if (language === 'fr-FR') {
                window.open(masante_brochureFR, "_blank");
            } else {
                window.open(masante_brochureEN, "_blank");
            }
        } else if (document === "condition") {
            if (language === 'fr-FR') {
                window.open(masante_conditionFR, "_blank");
            } else {
                window.open(masante_conditionEN, "_blank");
            }
        } else if (document === "ipid") {
            if (language === 'fr-FR') {
                window.open(masante_ipidFR, "_blank");
            } else {
                window.open(masante_ipidEN, "_blank");
            }
        };;
    }

    return (
        <div>
						<div className="health_logo perso">
								<img className="health_logo-img" src={april_logo} alt=''></img>
						</div>
            <div className="row crystal_info 4">
                <div className="col-md-4" style={{ marginTop: "24px" }}>
                    <div className="health_title">
                        <FormattedMessage id="insurance.health.masante.flexible.title" defaultMessage="Flexible benefits" />
                    </div>
                    <div className="health_text" >
                        <FormattedMessage id="insurance.health.masante.flexible.text" defaultMessage="Design your own international health insurance cover" />
                    </div>
                </div>
                <div className="col-md-4" style={{ marginTop: "24px" }}>
                    <div className="health_title">
                        <FormattedMessage id="insurance.health.masante.cover.title" defaultMessage="Long-term cover" />
                    </div>
                    <div className="health_text" >
                        <FormattedMessage id="insurance.health.masante.cover.text" defaultMessage="for as long as you want" />
                    </div>
                </div>
                <div className="col-md-4" style={{ marginTop: "24px" }}>
                    <div className="health_title">
                        <FormattedMessage id="insurance.health.crystal.reimbursements.title" />
                    </div>
                    <div className="health_text">
                        <FormattedMessage id="insurance.health.crystal.reimbursements.text" />
                    </div>
                </div>
                <div className="col-md-4" style={{ marginTop: "24px" }}>
                    <div className="health_title">
                        <FormattedMessage id="insurance.health.crystal.cash.title" />
                    </div>
                    <div className="health_text">
                        <FormattedMessage id="insurance.health.crystal.cash.text" />
                    </div>
                </div>
                <div className="col-md-4" style={{ marginTop: "24px" }}>
                    <div className="health_title">
                        <FormattedMessage id="insurance.health.magellan.custom.title" defaultMessage="Customised international cover" />
                    </div>
                    <div className="health_text" >
                        <FormattedMessage id="insurance.health.magellan.custom.text" defaultMessage="in the countries you choose" />
                    </div>
                </div>
                <div className="col-md-4" style={{ marginTop: "24px" }}>
                    <div className="health_title">
                        <FormattedMessage id="insurance.health.magellan.reduction.title" defaultMessage="Redcution between 2% and 10%" />
                    </div>
                    <div className="health_text" >
                        <FormattedMessage id="insurance.health.magellan.reduction.text" defaultMessage="on your premiums if you refer your friends" />
                    </div>
                </div>
            </div>
            <div className="health_document_row" style={{ marginTop: "24px", marginBottom: "48px" }}>
                <div >
                    <div className="health_document-container test3" onClick={() => handleClick("brochure")}>
                        <div>
                            <i className="fal fa-file-alt"></i> <FormattedMessage id="insurance.health.brochure" />
                        </div>
                    </div>
                </div>
                <div >
                    <div className="health_document-container" onClick={() => handleClick("condition")}>
                        <div>
                            <i className="fal fa-file-alt"></i> <FormattedMessage id="insurance.health.condition" />
                        </div>
                    </div>
                </div>
                <div >
                    <div className="health_document-container" onClick={() => handleClick("ipid")}>
                        <div>
                            <i className="fal fa-file-alt"></i> <FormattedMessage id="insurance.health.ipid" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function schengen_info(language) {
    const handleClick = (document) => {
        if (document === "brochure") {
            if (language === 'fr-FR') {
                window.open(schengen_brochureFR, "_blank");
            } else {
                window.open(schengen_brochureFR, "_blank");
            }
        } else if (document === "condition") {
            if (language === 'fr-FR') {
                window.open(schengen_conditionFR, "_blank");
            } else {
                window.open(schengen_conditionEN, "_blank");
            }
        } else if (document === "ipid") {
            if (language === 'fr-FR') {
                window.open(schengen_ipidFR, "_blank");
            } else {
                window.open(schengen_ipidFR, "_blank");
            }
        };;
    }

    return (
			
        <div>
						<div className="health_logo perso">
								<img className="health_logo-img" src={axa_logo_w412} alt=''></img>
						</div>
            <div className="row crystal_info 5" style={{ marginBottom: "38px", paddingBottom: "38px" }}>
                <div className="col-md" style={{ marginTop: "24px" }}>
                    <div className="health_title">
                        <FormattedMessage id="insurance.health.schengen.customer.title" defaultMessage="1000 customers" />
                    </div>
                    <div className="health_text" >
                        <FormattedMessage id="insurance.health.schengen.customer.text" defaultMessage="have already chosen APRIL SCHENGEN" />
                    </div>
                </div>
            </div>
						<div className="youtubePlayer">
              <div id="my-youtube-player-lg">
                <YouTube
                  videoId="tYY2eZx8ARE"
                  opts={{height: '222px', width: '365px'}}
                />
              </div></div>
							<div className="health_logo">
									<img className="health_logo-img" src={axa_bandeau_wide} alt='' style={{ marginTop: "24px" }}></img>

							</div>
            <div className="health_document_row" style={{ marginTop: "24px", marginBottom: "48px" }}>
                <div >
                    <div className="health_document-container test4" onClick={() => handleClick("brochure")}>
                        <div>
                            <i className="fal fa-file-alt"></i> <FormattedMessage id="insurance.health.schengen.brochure" />
                        </div>
                    </div>
                </div>
                <div >
                    <div className="health_document-container" onClick={() => handleClick("condition")}>
                        <div>
                            <i className="fal fa-file-alt"></i> <FormattedMessage id="insurance.health.schengen.condition" />
                        </div>
                    </div>
                </div>
                <div >
                    <div className="health_document-container" onClick={() => handleClick("ipid")}>
                        <div>
                            <i className="fal fa-file-alt"></i> <FormattedMessage id="insurance.health.schengen.ipid" />
                        </div>
                    </div>
                </div>
								<div>
								</div>
            </div>
						<ButtonToService afiliateLink="https://clk.tradedoubler.com/click?p=285301&a=3083026&url=https%3A%2F%2Fwww.axa­schengen.com%2Fen%3Ftduid%3D99de7541fea03a6877e16cc44b8dc8ec"></ButtonToService>
        </div>
    )
}

class Health extends Component {
    constructor() {
        super();
        this.state = {
            health_profile: 'none',
            health_time: 'none',
            timeClass: 'health_time--hide',
            buttonClassName: 'health_button--hide',
            question2: null,
            info: null,
            colorStudent: { color: '#7f3fce', backgroundColor: 'white' },
            colorSchengen: { color: '#7f3fce', backgroundColor: 'white' },
            colorOther: { color: '#7f3fce', backgroundColor: 'white' },
            colorMore: { color: '#7f3fce', backgroundColor: 'white' },
            colorLess: { color: '#7f3fce', backgroundColor: 'white' },
        }
        this.handleChange = this.handleChange.bind(this);
    }


    handleChange(target) {
        console.log(this.props.language);
        if (target.value === 'student') {
            this.setState({
                [target.name]: target.value,
                timeClass: 'health_time--appear',
                buttonClassName: 'health_button--hide',
                colorStudent: { color: "white", backgroundColor: '#7f3fce' },
                colorSchengen: { color: '#7f3fce', backgroundColor: 'white' },
                colorOther: { color: '#7f3fce', backgroundColor: 'white' },
                colorMore: { color: '#7f3fce', backgroundColor: 'white' },
                colorLess: { color: '#7f3fce', backgroundColor: 'white' },
            })
        } else if (target.value === 'other') {
            this.setState({
                [target.name]: target.value,
                timeClass: 'health_time--appear',
                health_time: 'none',
                buttonClassName: 'health_button--hide',
                colorStudent: { color: "#7f3fce", backgroundColor: 'white' },
                colorSchengen: { color: '#7f3fce', backgroundColor: 'white' },
                colorOther: { color: 'white', backgroundColor: '#7f3fce' },
                colorMore: { color: '#7f3fce', backgroundColor: 'white' },
                colorLess: { color: '#7f3fce', backgroundColor: 'white' },
            })
        } else if (target.value === 'schengen') {
            this.setState({
                [target.name]: target.value,
                timeClass: 'health_time--hide',
                health_time: 'none',
                buttonClassName: 'health_button--appear',
                info: schengen_info(this.props.language),
                colorStudent: { color: "#7f3fce", backgroundColor: 'white' },
                colorSchengen: { color: 'white', backgroundColor: "#7f3fce" },
                colorOther: { color: '#7f3fce', backgroundColor: 'white' },
                colorMore: { color: '#7f3fce', backgroundColor: 'white' },
                colorLess: { color: '#7f3fce', backgroundColor: 'white' },
            })
            this.props.linkChange("");
        } else if (this.state.health_profile === 'student') {
            if (target.value === 'less') {
                this.setState({
                    [target.name]: target.value,
                    buttonClassName: 'health_button--appear',
                    info: crystal_info(this.props.language),
                    colorMore: { color: '#7f3fce', backgroundColor: 'white' },
                    colorLess: { color: 'white', backgroundColor: '#7f3fce' },
                });
                this.props.linkChange(`https://tarif-expat.april-international.com/aie-public/souscription.jsf?parcours=58&cle=ECFP9W&inter=I79466&langue=${this.props.language}`);
            } else if (target.value === 'more') {
                this.setState({
                    [target.name]: target.value,
                    buttonClassName: 'health_button--appear',
                    info: expat_info(this.props.language),
                    colorMore: { color: 'white', backgroundColor: '#7f3fce' },
                    colorLess: { color: '#7f3fce', backgroundColor: 'white' },
                });
                this.props.linkChange(`https://tarif-expat.april-international.com/aie-public/souscription.jsf?parcours=112&cle=D3TGEJ&inter=I79466&langue=${this.props.language}`);
            }
        } else if (this.state.health_profile === 'other') {
            if (target.value === 'less') {
                this.setState({
                    [target.name]: target.value,
                    buttonClassName: 'health_button--appear',
                    info: magellan_info(this.props.language),
                    colorMore: { color: '#7f3fce', backgroundColor: 'white' },
                    colorLess: { color: 'white', backgroundColor: '#7f3fce' },
                });
                this.props.linkChange(`https://tarif-assurance-expat.april-international.com/convergence/?LI=E340FE9C-F225-4809-B926-3448B75F6060&Produit=MHIV1&Marque=APRIL&ContexteLayout=COBRAND&AON_ID=cTNUcFRwQkFZUEtSVCs5RFBITG12dz09&CO=I79466@APMOB&langue=${this.props.language}`);
            } else if (target.value === 'more') {
                this.setState({
                    [target.name]: target.value,
                    buttonClassName: 'health_button--appear',
                    info: masante_info(this.props.language),
                    colorMore: { color: 'white', backgroundColor: '#7f3fce' },
                    colorLess: { color: '#7f3fce', backgroundColor: 'white' },
                });
                this.props.linkChange(`https://tarif-expat.april-international.com/aie-public/souscription.jsf?parcours=55&cle=ZR75W1&inter=I79466&langue=${this.props.language}`);
            }
        }
    }

    render() {
        return (
            <div className="Health">
                <div className="health_form">
                    <div className="health_profile">
                        <div className="col-md-4 health_profile_question">
                            <FormattedMessage id="insurance.health.profile" defaultMessage="What is your profile? " />
                        </div>
                        <div className="col-md-8 health_profile_sentence">
                            <div style={this.state.colorStudent} onClick={() => this.handleChange({ name: "health_profile", value: "student" })} className="health_box">
                                <div>
                                    <FormattedMessage id="insurance.health.student" defaultMessage="Student" />
                                </div>
                            </div>
                            <div style={this.state.colorSchengen} onClick={() => this.handleChange({ name: "health_profile", value: "schengen" })} className="health_box">
                                <div>
                                    <FormattedMessage id="insurance.health.schengen" defaultMessage="Visa Schengen" />
                                </div>
                            </div>
                            <div style={this.state.colorOther} onClick={() => this.handleChange({ name: "health_profile", value: "other" })} className="health_box">
                                <div>
                                    <FormattedMessage id="insurance.health.other" defaultMessage="Other" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={this.state.timeClass} >
                        <div className="health_time">
                            <div className="col-md-4 health_time_question">
                                <FormattedMessage id="insurance.health.time" defaultMessage="How long will you live there? " />
                            </div>
                            <div className="col-md-8 health_time_sentence">
                                <div style={this.state.colorMore} onClick={() => this.handleChange({ name: "health_time", value: "more" })} className="health_box">
                                    <div>
                                        <FormattedMessage id="insurance.health.more" defaultMessage="More than 12 months" />
                                    </div>
                                </div>
                                <div style={this.state.colorLess} onClick={() => this.handleChange({ name: "health_time", value: "less" })} className="health_box">
                                    <div>
                                        <FormattedMessage id="insurance.health.less" defaultMessage="Less than 12 months" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={this.state.buttonClassName} defaultMessage="Student" >
                        {/*<div className="health_logo test1">
                            <img className="health_logo-img" src={axa_logo} alt=''></img>
                        </div>*/}
                        {this.state.info}
                    </div>
                </div>
            </div>
        )
    }
}


export default Health;
