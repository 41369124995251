import React from 'react'
import { FormattedMessage } from 'react-intl';

const promoList = [
    {
        date_debut: "2019-07-25",
        date_fin: "2019-08-19",
        pics: '/static/media/sfr_card.png',
        name: "SFR - ADSL Starter",
        detail_1: <FormattedMessage id="internet.promo.sfr.detail1"/>,
        detail_2: <FormattedMessage id="internet.promo.sfr.detail2"/>,
        detail_3: <FormattedMessage id="internet.promo.sfr.detail3"/>,
        old_price: "",
        price: "10€",
        trackingLinkk: "https://www.awin1.com/cread.php?awinmid=7315&awinaffid=525947&clickref=&p=%5B%5Bhttps%3A%2F%2Fwww.sfr.fr%2Foffre-internet%2Fbox-adsl%23sfrintid%3DV_Transverse_0%5D%5D",
        country: "FR",
        more_info: false,
    },
]


export {promoList};