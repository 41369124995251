import React from 'react';
import { FormattedMessage } from 'react-intl';
import youtube_img_load from "../../../assets/img/img_load_video_issoyo_home_w365.jpg";
import icon_good from "../../../assets/img/icon_good.svg";
import image_section3 from "../../../assets/img/image_section3@2x.png";

require('./Home.scss');

export class SmSectiont extends React.Component {
    
	componentDidMount(){
		var youtube = document.querySelectorAll( ".youtube" );
									
		for (var i = 0; i < youtube.length; i++) {
			var source = youtube_img_load;
			
			var image = new Image();
					image.src = source;
					image.addEventListener( "load", function() {
						youtube[ i ].appendChild( image );
					}( i ) );
			
					youtube[i].addEventListener( "click", function() {

						var iframe = document.createElement( "iframe" );

								iframe.setAttribute( "frameborder", "0" );
								iframe.setAttribute( "allowfullscreen", "allowfullscreen" );
								iframe.setAttribute( "src", "https://www.youtube.com/embed/"+ this.dataset.embed +"?autoplay=1&mute=1&enablejsapi=1&allow=autoplay");
								this.innerHTML = "";
								this.appendChild( iframe );
					} );	
		};
	}

    render() {
        return (
            <section className="section section-3">
                <div className="content-image">
                    <div className="title">
                        <div className="why-issoyo">
                            <FormattedMessage id="home.sm.titleWhyIssoyo" defaultMessage="Why Issoyo?" />
                        </div>
                    </div>
                    <div className="text">
                        <div className="select-all-the-servi">
                            <FormattedMessage id="home.sm.textWhyIssoyo" defaultMessage="Choisissez vos services et vous êtes mis directement en relation avec le meilleur prestataire." />
                        </div>
                    </div>
                </div>
                <img id="computerSm" className="image-center" src={image_section3} alt='' />
                <div className="youtubePlayer">
                    <div id="my-youtube-player-sm">
                        <div class="wrapper sm">
													<div class="youtube test1" data-embed="XLKuLKvFHJI" style={{ paddingTop: '63%'}}>
														<div class="play-button"></div>
													</div>
												</div>
                    </div>
                </div>
                <div className="benefits_description">
                    <div className="col-12 benefits">
                        <div className="oval">
                            <img src={icon_good} alt='' />
                        </div>
                        <div className="subtitle">
                            <div className="full-online-service">
                                <FormattedMessage id="home.sm.titleFullOnlineService" defaultMessage="100% online 200% human" />
                            </div>
                        </div>
                        <div className="text">
                            <div className="manage-all-your-serv">
                                <FormattedMessage id="home.sm.textFullOnlineService" defaultMessage="Find all your services providers in one single plaform and get assistance from our awesome team (Messenger, Whatsapp, email, ...)" />
                            </div></div>
        
                    </div>
                </div>
                <div className="col-12 benefits no-border-padding">
                    <div className="oval">
                        <img src={icon_good} alt='' />
                    </div>
                    <div className="subtitle">
                        <div className="easy-and-friendly">
                            <FormattedMessage id="home.sm.titleFreeOffer" defaultMessage="Easy and free" />
                        </div></div>
                    <div className="text">
                        <div className="we-have-selected-the">
                            <FormattedMessage id="home.sm.textFreeOffer" defaultMessage="Issoyo is fully transparent and free for users, we are only paid by our partners" />
                        </div>
                    </div>
                </div>
                <div className="col-12 benefits">
                        <div className="oval">
                            <img src={icon_good} alt='' />
                        </div>
                        <div className="subtitle">
                            <div className="connect-with-communi">
                                <FormattedMessage id="home.sm.titleConnectCommunity" defaultMessage="Connect with community" />
                            </div>
                        </div>
                        <div className="text">
                            <div className="meet-new-friends-sh">
                                <FormattedMessage id="home.sm.textConnectCommunity" defaultMessage="Meet new friends, share best practices and good tips thanks to our community and events" />
                            </div>
                        </div>
                    </div>
                <div className="col-12 benefits">
                    <div className="oval">
                        <img src={icon_good} alt='' />
                    </div>
                    <div className="subtitle">
                        <div className="easy-and-friendly">
                            <FormattedMessage id="home.sm.titleEasyFriendly" defaultMessage="Selected suppliers" />
                        </div>
                    </div>
                    <div className="text">
                        <div className="we-have-selected-the"></div>
                        <FormattedMessage id="home.sm.textEasyFriendly" defaultMessage="We have selected the best in class suppliers with a dedicated offer to expats to save your time and make your life easier" />
                    </div>
                </div>
                <div className="benefitsButton">
                </div>
            </section >
        )
    }
}
