import React from 'react';
import { FormattedMessage } from 'react-intl';
import issoyo_header_fr from '../../../assets/img/phoneissoyofr.png';
import issoyo_header_en from '../../../assets/img/phoneissoyoen.png';
//import issoyo_header_fr from '../../../assets/img/phoneissoyofr.webp';
//import issoyo_header_en from '../../../assets/img/phoneissoyoen.webp';
import PropTypes from "prop-types";
import { connect } from "react-redux";

require('./SmSectionf.scss');

class SmSectionf extends React.Component {
    constructor(props) {
        super(props);
        this.routeChange = this.routeChange.bind(this);
    }

    changeImage() {
        if (this.props.language === 'fr-FR')
            return issoyo_header_fr;
        else
            return issoyo_header_en;
    }

    routeChange() {
        this.props.history.push('/services');
    }
    render() {
        var button = <button className="menu_login" onClick={() => this.props.history.push('/login')}>Login <i className="fas fa-user user_nav" style={{color: 'red'}}></i></button>;
        if (this.props.auth.isAuthenticated) {
            button =  <button className="menu_login" onClick={() => this.props.history.push('/profile')}>Hello {this.props.auth.user.firstname}! <i className="fas fa-user user_nav" style={{color: 'green'}}></i></button>;
        }
        return (
            <section className="section sectionSm-1">
                <div className="issoyo_band">
                {button}
                    <div className="container_header">
                        <div className="container_text">
                            <div className="text-header">
                                <h2 className="brand">ISSOYO,<br />
                                    <span style={{ color: 'white' }}><FormattedMessage id="sm.sectionf.advisor" defaultMessage="Your relocation advisor" /></span><br />
                                    <FormattedMessage id="sm.sectionf.digital" defaultMessage="digital and free," /><br />
                                    <span style={{ color: 'white' }}><FormattedMessage id="sm.sectionf.expat" defaultMessage="for expats" /></span></h2>
                                <div className="container_start-button">
                                    <button className="start-button" onClick={this.routeChange}><FormattedMessage id="sm.sectionf.start" defaultMessage="Start now" /></button>
                                </div>
                            </div>
                        </div>
                        <div className="container_illustration">
                            <div className="illustration_device">
                                <img className="illustration_device-img" src={this.changeImage()} alt=""></img>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service_band">
                        <div className="service_container">
                                <div className="service offer">
                                    <div className="service_picture">
                                        <i className="fal fa-percent"></i>
                                    </div>
                                    <h5 className="service-title"><strong><FormattedMessage id="sm.sectionf.offers" defaultMessage="Best offers" /></strong></h5>
                                    <div className="service-text"><FormattedMessage id="sm.sectionf.offer.text" defaultMessage="We have chose the best offers to help you to settle-in (Banking, housing, telecom, insurances, …)" /></div>
                                </div>
                                <div className="service chat">
                                    <div className="service_picture">
                                        <i className="fal fa-comments-alt"></i>
                                    </div>
                                    <h5 className="service-title"><strong><FormattedMessage id="sm.sectionf.team" defaultMessage="Assistance 24/7" /></strong></h5>
                                    <div className="service-text"><FormattedMessage id="sm.sectionf.team.text" defaultMessage="Any question, assistance? No worries, our chat live is here to get it done!" /></div>
                                </div>
                                <div className="service meetup">
                                    <div className="service_picture">
                                        <i className="fal fa-child"></i>
                                    </div>
                                    <h5 className="service-title"><strong><FormattedMessage id="sm.sectionf.community" defaultMessage="Real community" /></strong></h5>
                                    <div className="service-text"><FormattedMessage id="sm.sectionf.community.text" defaultMessage="Living tips and meetup thanks to our community" /></div>
                                </div>
                                <div className="service idea">
                                    <div className="service_picture">
                                        <i className="fal fa-lightbulb-on"></i>
                                    </div>
                                    <h5 className="service-title"><strong><FormattedMessage id="sm.sectionf.here" defaultMessage="Here for you" /></strong></h5>
                                    <div className="service-text"><FormattedMessage id="sm.sectionf.here.text" defaultMessage="Don’t find what you need? Awesome! Our team will do it especially for you" /></div>
                                </div>
                    </div>
                </div>
            </section>
        )
    }
}

SmSectionf.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.auth.error
});
export default connect(
    mapStateToProps,
    {  }
)(SmSectionf);

/*

 <div className="service_band">
                    <div className="row">
                        <div className="service_container">
                            <div className="row container_service">
                                <div className="col-6 service offer">
                                    <div className="service_picture">
                                        <i className="fal fa-percent"></i>
                                    </div>
                                    <h5 className="service-title"><strong><FormattedMessage id="sm.sectionf.offers" defaultMessage="Best offers" /></strong></h5>
                                    <div className="service-text"><FormattedMessage id="sm.sectionf.offer.text" defaultMessage="We have chose the best offers to help you to settle-in (Banking, housing, telecom, insurances, …)" /></div>
                                </div>
                                <div className="col-6 service chat">
                                    <div className="service_picture">
                                        <i className="fal fa-comments-alt"></i>
                                    </div>
                                    <h5 className="service-title"><strong><FormattedMessage id="sm.sectionf.team" defaultMessage="Assistance 24/7" /></strong></h5>
                                    <div className="service-text"><FormattedMessage id="sm.sectionf.team.text" defaultMessage="Any question, assistance? No worries, our chat live is here to get it done!" /></div>
                                </div>
                                <div className="col-6 service meetup">
                                    <div className="service_picture">
                                        <i className="fal fa-child"></i>
                                    </div>
                                    <h5 className="service-title"><strong><FormattedMessage id="sm.sectionf.community" defaultMessage="Real community" /></strong></h5>
                                    <div className="service-text"><FormattedMessage id="sm.sectionf.community.text" defaultMessage="Living tips and meetup thanks to our community" /></div>
                                </div>
                                <div className="col-6 service idea">
                                    <div className="service_picture">
                                        <i className="fal fa-lightbulb-on"></i>
                                    </div>
                                    <h5 className="service-title"><strong><FormattedMessage id="sm.sectionf.here" defaultMessage="Here for you" /></strong></h5>
                                    <div className="service-text"><FormattedMessage id="sm.sectionf.here.text" defaultMessage="Don’t find what you need? Awesome! Our team will do it especially for you" /></div>
                                </div>
                            </div>

                        </div>
                    </div>*/