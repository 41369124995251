import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import countryList from 'react-select-country-list';
import Select from 'react-select';
import Modal from 'react-responsive-modal';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
import { addAddress } from '../../../actions/authActions';

require("./Addadresses.scss");
var noScroll = require('no-scroll');

const modalstyles = {
    modal: {
        'max-width': '500px',
        'width': '100%',
        'color': '#3f2751',
        error: {
            'color': '#cc0033',
            'display': 'flex',
            'font-size': '12px'
        }
    },
};

class Addadresses extends React.Component {
    constructor(props) {
        super(props);
        this.options = countryList().getData()
        this.state = {
            options: this.options,
            country: { label: '', value: '' },
            name: '',
            street: '',
            city: '',
            open: false,
            zip: '',
            errorName: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.onClickAdd = this.onClickAdd.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    onOpenModal() {
        noScroll.on();
        this.setState({ open: true });
    };

    onCloseModal() {
        noScroll.off();
        this.setState({ open: false });
    };

    componentWillReceiveProps(nextProps) {
        this.onCloseModal();
        this.props.history.push('/profile');
    }

    changeHandler = value => {
        this.setState({ country: value })
    }

    onClickAdd() {
        var err = false;
        for (var i = 0; i < this.props.auth.user.addresses.length; i++) {
            if (this.state.name === this.props.auth.user.addresses[i].name) {
                this.setState({ errorName: true });
                err = true
            }
        }
        if (!err) {
            const reqData = {
                address: {
                    name: this.state.name,
                    country: this.state.country,
                    street: this.state.street,
                    city: this.state.city,
                    zip: this.state.zip,
                },
                user: this.props.auth.user
            }
            this.props.addAddress(reqData);
        }
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    render() {
        const { open } = this.state;
        return (
            <React.Fragment>
                <div className="Addadresses">
                <div className="adresses_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                    <div className="col-12 title"><div>
                        <FormattedMessage id="addaddress.title" defaultMessage="Add a new address" />
                    </div></div>
                    <div className="div-input-country">
                        <Select
                            options={this.state.options}
                            value={this.state.value}
                            onChange={this.changeHandler}
                            className="input-country"
                        />
                    </div>
                    <div className="div-input-street-name">
                        <FormattedMessage id="addaddress.street" defaultMessage="Street and number" >
                            {msg => (<TextField
                                id="outlined-with-placeholder"
                                name='street'
                                onChange={this.handleChange}
                                value={this.state.street}
                                label={msg}
                                placeholder={msg}
                                className="input-street"
                                margin="normal"
                                variant="outlined"
                            />)}
                        </FormattedMessage>
                    </div>
                    <div className="div-input-city">
                        <FormattedMessage id="addaddress.city" defaultMessage="City name" >
                            {msg => (<TextField
                                id="outlined-with-placeholder"
                                name='city'
                                onChange={this.handleChange}
                                value={this.state.city}
                                label={msg}
                                placeholder={msg}
                                className="input-city"
                                margin="normal"
                                variant="outlined"
                            />)}
                        </FormattedMessage>
                    </div>
                    <div className="div-input-zipcode">
                        <FormattedMessage id="addaddress.zipcode" defaultMessage="Zip code" >
                            {msg => (<TextField
                                id="standard-number"
                                name='zip'
                                onChange={this.handleChange}
                                value={this.state.zip}
                                label={msg}
                                placeholder={msg}
                                className="input-zip"
                                margin="normal"
                                variant="outlined"
                            />)}
                        </FormattedMessage>
                    </div>
                    <div className='row'>
                        <div className="col send">
                            <Button variant="contained" color="primary" className="button" onClick={this.onOpenModal}>
                                <FormattedMessage id="addaddress.button.add" defaultMessage="Add" />
                            </Button>
                        </div>
                    </div>
                    <Modal open={open} onClose={this.onCloseModal} center styles={modalstyles}>
                        <p className='message-disconnect'><FormattedMessage id="addaddress.modal.title" defaultMessage="Give a name to the current address" /></p>
                        <div className="error" style={this.state.errorName ? {} : { display: 'none' }}>
                            <div style={{ color: '#cc0033' }}><FormattedMessage id="addaddress.error.name" defaultMessage="This name address already exist" /></div>
                        </div>
                        <div className="div-input-address-name">
                            <FormattedMessage id="addaddress.name" defaultMessage="Address name" >
                                {msg => (<TextField
                                    id="outlined-with-placeholder"
                                    label={msg}
                                    placeholder={msg}
                                    name="name"
                                    onChange={this.handleChange}
                                    value={this.state.name}
                                    className="input-address-name"
                                    margin="normal"
                                    variant="outlined"
                                />)}
                            </FormattedMessage>
                        </div>
                        <Button color="primary" className="button" onClick={this.onClickAdd} style={{ fontFamily: 'Nunito', backgroundColor: 'transparent', color: 'rgba(68, 76, 165, 0.85)' }} >
                            <FormattedMessage id="addaddress.modal.add" defaultMessage="Add" />
                        </Button>
                        <Button color="primary" className="button" onClick={this.onCloseModal} style={{ fontFamily: 'Nunito', backgroundColor: 'transparent', color: 'rgba(68, 76, 165, 0.85)' }} >
                            <FormattedMessage id="addaddress.modal.cancel" defaultMessage="Cancel" />
                        </Button>
                    </Modal>
                </div>
            </React.Fragment>
        )
    }
}
Addadresses.propTypes = {
    addAddress: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(
    mapStateToProps, { addAddress }
)(Addadresses);