import React from 'react';
import { FormattedMessage } from 'react-intl';
import imagedesktop_section2 from "../../../assets/img/image_section2@2x.png";
import icon_bank from "../../../assets/img/icon_bank.svg";
import icon_realstate from "../../../assets/img/icon_realstate.svg";
import icon_phone from "../../../assets/img/icon_phone.svg";
import icon_heart from "../../../assets/img/icon_heart.svg";
import icon_wifi from "../../../assets/img/icon_wifi.svg";
import icon_comm from "../../../assets/img/icon_comm.svg";
import img_phone_home_fr from "../../../assets/img/img_phone_home_fr-FR.png";
import img_phone_home_en from "../../../assets/img/img_phone_home_en-US.png";
//import img_phone_home_fr from "../../../assets/img/img_phone_home_fr-FR.webp";
//import img_phone_home_en from "../../../assets/img/img_phone_home_en-US.webp";

require('./Home.scss');

export class SmSections extends React.Component {

  changeImage() {
    if (this.props.language === 'fr-FR')
      return this.img_phone = img_phone_home_fr;
    else
      return this.img_phone = img_phone_home_en;
  }

  render() {
    return (
      <section className="section section-2">
        <img className="image-center" src={imagedesktop_section2} alt='' />
        <img className="image-center image-phone" src={this.changeImage()} alt='' />
        <div className="content-image">
          <div className="title">
            <div className="dedicated-to-expats">
              <FormattedMessage id="home.sm.titleExpatsCommunity" defaultMessage="Dedicated to the expat community" />
            </div>
          </div>
          <div className="text"><div className="issoyo-provides-tail">
            <FormattedMessage id="home.sm.textExpatsCommunity" defaultMessage="We offer tailor-made services and advices to facilitate both departure from home and arrival in hosting country" />
          </div>
          </div>
          <div className="service_description">
            <div className="row serviceLine">
              <div className="col-6">
                <div className="serviceItem">
                  <img src={icon_bank} alt=''/>
                  <div className="subtitle">
                  <a style={{color: "#3e4594"}} href="/services"><FormattedMessage id="home.sm.bank" defaultMessage="Banking" /></a>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="serviceItem">
                  <img src={icon_realstate} alt='' />
                  <div className="subtitle">
                  <a style={{color: "#3e4594"}} href="/services"><FormattedMessage id="home.sm.estate" defaultMessage="Housing" /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row serviceLine">
              <div className="col-6">
                <div className="serviceItem">
                  <img src={icon_phone} alt=''/>
                  <div className="subtitle">
                  <a style={{color: "#3e4594"}} href="/services"><FormattedMessage id="home.sm.telecom" defaultMessage="Telecom" /></a>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="serviceItem">
                  <img src={icon_heart} alt='' />
                  <div className="subtitle">
                  <a style={{color: "#3e4594"}} href="/services"><FormattedMessage id="home.sm.insurance" defaultMessage="Insurances" /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row serviceLine">
              <div className="col-6">
                <div className="serviceItem">
                  <img src={icon_wifi} alt='' />
                  <div className="subtitle">
                  <a style={{color: "#3e4594"}} href="/services"><FormattedMessage id="home.sm.wifi" defaultMessage="Internet" /></a>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="serviceItem">
                  <img src={icon_comm} alt=''/>
                  <div className="subtitle">
                  <a style={{color: "#3e4594"}} href="/services"><FormattedMessage id="home.sm.meetup" defaultMessage="Meetup" /></a>
                  </div>
                </div>
              </div>
            </div>
                  <div className="subtitle">
                  <FormattedMessage id="home.sm.more" defaultMessage="... And more to come" />
                  </div>
                </div>
          </div>
      </section>
    )
  }
}
