// authActions type
export const GET_ERRORS = "GET_ERRORS";
export const GET_LOCALUSER = "GET_LOCALUSER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_REQUEST_ID = "SET_REQUEST_ID";
export const SET_SUCCESS_VERIFY = "SET_SUCCESS_VERIFY";
export const RESET_LOCALUSER = "RESET_LOCALUSER";

// serviceActions type
export const SET_SERVICELIST = "GET_SERVICELIST";
export const SET_PHONELIST = "SET_PHONELIST";
export const SET_TICKETLIST = "SET_TICKETLIST";
export const SET_INTERNETLIST = "SET_INTERNETLIST";
export const SET_ANNONCELIST = "SET_ANNNONCELIST";
export const SET_MOBILELIST = "SET_MOBILELIST";
export const GET_ERRORLIST = "GET_ERRORLIST";

