import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Collapsible from 'react-collapsible';
import ReactPlayer from 'react-player'
import HelloBankLogo from '../../../../../assets/img/providers/HelloBankLogo.jpg';
import Button from '@material-ui/core/Button';
import Issoyo_logo from '../../../../../assets/img/fav_icon32@2x.png';
import HelloBank_doc from '../../../../../assets/document/lSSOYO_HelloBank_doc.pdf';
import HelloBank_pricing from '../../../../../assets/document/ISSOYO_HelloBank_tarif.pdf';

require('./HelloBankModal.scss');

class HelloBankModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            position: '0',
            littlepos: '',
            style: {
                modal: {
                    'maxWidth': '700px',
                    'width': '100%',
                    'color': '#3f2751',
                    'textAlign': 'justify'
                },
            },
            youtubeHW: 'https://youtu.be/CnD4Bj4Ce1s',
        }
        this.handleClick = this.handleClick.bind(this);
        this._onReady = this._onReady.bind(this);
        this.handleClickAccount = this.handleClickAccount.bind(this);
    }

    _onReady(event) {
        event.target.pauseVideo();
    }

    handleClick(position) {
        if (this.state.position === position) {
            this.setState({ position: "0" })
        } else {
            this.setState({ position: position });
        }
    };

    handleClickAccount() {
        this.props.handleClickAccount("https://tracking.publicidees.com/clic.php?promoid=122093&progid=2934&partid=57757");
    };

    render() {
        return (
            <div className="service-bank-transfertWise">
                <div className="header">
                    <div className="header_logo"><img className="header-pic" src={HelloBankLogo} alt=''></img></div>
                    <div className="header-title"><b><FormattedMessage id="service.bank.HelloBank.title" defaultMessage="La banque Mobile, comme vous." /></b></div>
                </div>
                <div className="cont-mod">
                    <FormattedMessage id="bank.BNP.benefits">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "1")} handleTriggerClick={this.handleClick} accordionPosition="1">
                                <ul className='text'>
                                    <li><FormattedMessage id="bank.HelloBank.reason1" defaultMessage="Free Visa Card" /></li>
                                    <li><FormattedMessage id="bank.HelloBank.reason2" defaultMessage="0 € - no account and hidden fees" /></li>
                                    <li><FormattedMessage id="bank.HelloBank.reason3" defaultMessage="Free withdrawals thanks to 52,000 ATM and 650 countries covered by the Global Alliance network and BNP Paribas Global Network" /></li>
                                    <li><FormattedMessage id="bank.HelloBank.reason4" defaultMessage="Apple Pay is available with your Hello Bank Visa card!" /></li>
                                    <li><FormattedMessage id="bank.HelloBank.reason5" defaultMessage="Hello bank! Team is available on most channels: Chat, Twitter, Facebook and Phone." /></li>
                                </ul>
                            </Collapsible>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="bank.HelloBank.pricing" defaultMessage="Pricing (Only in French)">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "2")} handleTriggerClick={this.handleClick} accordionPosition="2">
                                <div className="tarif_HelloBank" onClick={() => window.open(HelloBank_pricing, "_blank")}>
                                    <u style={{cursor: 'pointer'}}><FormattedMessage id="bank.HelloBank.tarif" defaultMessage="Click here to see the pricing"></FormattedMessage></u>
                                </div>
                            </Collapsible>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="bank.HelloBank.process" defaultMessage="Account opening process ">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "3")} handleTriggerClick={this.handleClick} accordionPosition="3">
                                <ul className='text'>
                                    <li><FormattedMessage id="bank.HelloBank.condition1" defaultMessage="Fill out and sign the online form by entering the secret code received by SMS" /></li>
                                    <li><FormattedMessage id="bank.HelloBank.condition2" defaultMessage="Attach the "/> <u style={{cursor: 'pointer'}} onClick={() => window.open(HelloBank_doc, "_blank")}><FormattedMessage id="bank.HelloBank.condition2.1" defaultMessage="supporting documents "/></u><FormattedMessage id="bank.HelloBank.condition2.2" defaultMessage="online by photographing them directly from your smartphone or by scanning them" /></li>
                                    <li><FormattedMessage id="bank.HelloBank.condition3" defaultMessage="Make a transfer of 300 euro" /></li>
                                    <li><FormattedMessage id="bank.HelloBank.condition4" defaultMessage="Your account will be activated within a few days, after receipt and acceptance of your file by Hello bank!" /></li>
                                </ul>
                            </Collapsible>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="bank.HelloBank.video" defaultMessage="Video">
                    {msg => ( 
                    <Collapsible trigger={msg} open={(this.state.position === "4")} handleTriggerClick={this.handleClick} accordionPosition="4">
                        <ReactPlayer
                            width='100%'
                            controls
                            config={{
                                youtube: {
                                    playerVars: { showinfo: 1 }
                                }
                            }}
                            url={this.state.youtubeHW}
                        />
                    </Collapsible>
                    )}
                    </FormattedMessage>

                </div>
                <div className="footer" style={{ textAlign: 'center' }}>
                    <Button variant="contained" color="primary" className="button" onClick={this.handleClickAccount}>
                        <img src={Issoyo_logo} alt='' style={{ width: '11%', marginRight: '5%' }}></img>
                        <FormattedMessage id="bank.open.account"></FormattedMessage>
                    </Button>
                </div>
            </div>
        )
    }
}

HelloBankModal.propTypes = {
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    service: state.services,
});

export default connect(
    mapStateToProps, {}
)(HelloBankModal);
