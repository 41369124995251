import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { withAlert } from 'react-alert';
import { addAnnonce } from '../../../../actions/serviceActions.js';

require('./AddModal.scss');

const styles = {
    root: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    selectMenu: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    input: {
        borderColor: '#3e45948a !important',
    },
    rootCheck: {
        color: '#836bc1bf !important',
        '&$checked': {
            color: '#836bc1bf !important',
        },
    },
};

class AddModal extends Component {
    constructor() {
        super();
        this.state = {
            title: '',
            description: '',
            price: '',
            contact: '',
            city: '',
            typeAds: '',
            file: [],
            errorEmail: false,
            errorRequired: false,
            data: new FormData(),
            messageMail: <FormattedMessage id="service.annonce.modal.add.contact.error" defaultMessage="Please enter valid email" />
        }
        this.handleChange = this.handleChange.bind(this);
        this.addAds = this.addAds.bind(this);
        this.checkEmail = this.checkEmail.bind(this);
    }

    checkEmail(email) {
        var re = /\S+@\S+\.\S+/;
        if (re.test(email)) {
            this.setState({ errorEmail: false });
        }
        else {
            this.setState({ errorEmail: true });
        }
    }

    componentDidMount() {
        this.setState({ contact: this.props.auth.user.email });
    }

    handleChange(e) {
        if (e.target.name === "contact") {
            this.checkEmail(e.target.value);
            this.setState({ [e.target.name]: e.target.value })
        } else if (e.target.name === 'file') {
            var tmp = this.state.data;
            for (var i = 0; i <= e.target.files.length; i++){
                tmp.append('productImage', e.target.files[i]);
            }
            this.setState({ [e.target.name]: tmp })
        } else if (e.target.name === 'description') {
            if (e.target.value.length <= 500) {
                this.setState({ [e.target.name]: e.target.value })
            }
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    addAds() {
        var data = this.state.data;
        data.append('title', this.state.title)
        data.append('description', this.state.description)
        data.append('author', this.props.auth.user.nickname)
        data.append('price', this.state.price)
        data.append('contact', this.state.contact)
        data.append('country', this.props.auth.user.location)
        data.append('city', this.state.city)
        data.append('typeAds', this.state.typeAds)
        if (this.state.title === '' || this.state.contact === '' || this.state.description === '' ||
            this.state.price === '' || this.errorEmail === true || this.state.typeAds === '') {
            this.setState({ errorRequired: true });
        } else {
            this.props.addAnnonce(data, this.props.service.annonceList);
            this.props.onCloseModal();
            this.props.alert.show('Annonce Ajoutée', { type: 'info' });
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="AddModal">
                <div className="addmodal_title">
                    <FormattedMessage id="service.annonce.modal.title" defaultMessage="Add your ads" />
                </div>
                <div className="annonce_form">
                    <div className="error required" style={this.state.errorRequired ? {} : { display: 'none' }}>
                        <FormattedMessage id="service.annonce.add.error.required" defaultMessage="You must fill correctly out all required fields" />
                    </div>
                    <div className="row annonce_info">
                        <div className="input annonce_title">
                            <FormattedMessage id="service.annonce.add.title" defaultMessage="Title" >
                                {msg => (<TextField
                                    required
                                    id="outlined-with-placeholder"
                                    label={msg}
                                    className="annonce_title-input"
                                    name="title"
                                    margin="normal"
                                    variant="outlined"
                                    value={this.state.title}
                                    InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                    onChange={this.handleChange}
                                />)}
                            </FormattedMessage>
                        </div>
                        <div className="select annonce_type">
                            <TextField
                                id="outlined-select-currency"
                                select
                                required
                                label="Type"
                                className="annonce_type-list"
                                value={this.state.typeAds}
                                name="typeAds"
                                onChange={this.handleChange}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                margin="normal"
                                variant="outlined"
                            >
                                <MenuItem disabled>Select type</MenuItem>
                                <MenuItem value={'sell'} classes={{ root: classes.root }}><FormattedMessage id="service.annonce.modal.sale" defaultMessage="To sell" /></MenuItem>
                                <MenuItem value={'event'} classes={{ root: classes.root }}><FormattedMessage id="service.annonce.modal.event" defaultMessage="Event" /></MenuItem>
                                <MenuItem value={'job'} classes={{ root: classes.root }}><FormattedMessage id="service.annnonce.modal.job" defaultMessage="Job" /></MenuItem>
                            </TextField>
                        </div>
                    </div>
                    <div className="input annonce_description">
                        <FormattedMessage id="service.annonce.description" defaultMessage="Description" >
                            {msg => (<TextField
                                required
                                id="outlined-with-placeholder"
                                label={msg}
                                multiline
                                rows="4"
                                rowsMax="4"
                                inputProps={{ maxLength: 500 }}
                                className="annonce_description-input"
                                name="description"
                                margin="normal"
                                variant="outlined"
                                value={this.state.description}
                                InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                onChange={this.handleChange}
                            />)}
                        </FormattedMessage>
                        <div className="annonce_limit">{this.state.description.length}/500</div>
                    </div>
                    <div className="annonce_upload">
                        <input type='file' name='file' multiple onChange={this.handleChange} />
                    </div>
                    <div className="row annonce_info">
                        <div className="input annonce_price">
                            <FormattedMessage id="service.annonce.price" defaultMessage="Price" >
                                {msg => (<TextField
                                    required
                                    id="outlined-with-placeholder"
                                    label={msg}
                                    className="annonce_price-input"
                                    name="price"
                                    margin="normal"
                                    variant="outlined"
                                    value={this.state.price}
                                    InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                    onChange={this.handleChange}
                                />)}
                            </FormattedMessage>
                        </div>
                        <div className="input annonce_city">
                            <FormattedMessage id="service.annonce.city" defaultMessage="City" >
                                {msg => (<TextField
                                    required
                                    id="outlined-with-placeholder"
                                    label={msg}
                                    className="annonce_city-input"
                                    name="city"
                                    margin="normal"
                                    variant="outlined"
                                    value={this.state.city}
                                    InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                    onChange={this.handleChange}
                                />)}
                            </FormattedMessage>
                        </div>
                        <div className="input annonce_contact">
                            <FormattedMessage id="service.annonce.add.contact" defaultMessage="Contact" >
                                {msg => (<TextField
                                    required
                                    error={this.state.errorEmail}
                                    id="outlined-with-placeholder"
                                    label={msg}
                                    className="annonce_contact-input"
                                    name="contact"
                                    margin="normal"
                                    variant="outlined"
                                    value={this.state.contact}
                                    InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                    onChange={this.handleChange}
                                />)}
                            </FormattedMessage>
                            <div className="error" style={this.state.errorEmail ? {} : { display: 'none' }}>{this.state.messageMail}</div>
                        </div>
                    </div>
                    <div className="annonce_send">
                        <Button variant="contained" color="primary" className='ads_new-button' onClick={this.addAds}>
                            <FormattedMessage id="service.annonce.add.send" defaultMessage="Add" />
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

AddModal.propTypes = {
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    service: state.services,
});

export default connect(
    mapStateToProps, { addAnnonce }
)(withStyles(styles)(withAlert(AddModal)));