import React, { Component } from 'react'
import { currencyCodeList } from './currencyList.js';
import ReactCountryFlag from "react-country-flag";

export default class TransferWiseCurrency extends Component {

  render() {
    const currency = currencyCodeList.map((data, key) => (
      <tr key={key}>
        <td><span key={key}>{data.name}
        <ReactCountryFlag
          styleProps={{
            width: '20px',
            height: '20px',
            marginLeft: '1%'
          }}
          code={data.country_code}
          svg
        />
        </span></td>
      </tr>
    ))
    return (
      <table className="table table-bordered">
        <tbody>
          {currency}
        </tbody>
      </table>
    )
  }
}
