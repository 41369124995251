import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Collapsible from 'react-collapsible';
import ReactPlayer from 'react-player'
import BNPLogo from '../../../../../assets/img/providers/BNPLogo.jpg';
import Button from '@material-ui/core/Button';
import Issoyo_logo from '../../../../../assets/img/fav_icon32@2x.png';
import BNP_pricing from '../../../../../assets/document/ISSOYO_bnp_tarif.pdf';
import BNP_doc from '../../../../../assets/document/ISSOYO_bnp_doc.pdf';

require('./BNPModal.scss');

class BNPModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            position: '0',
            littlepos: '',
            style: {
                modal: {
                    'maxWidth': '700px',
                    'width': '100%',
                    'color': '#3f2751',
                    'textAlign': 'justify'
                },
            },
            youtubeHW: 'https://youtu.be/FfLFB-x3snI',
        }
        this.handleClick = this.handleClick.bind(this);
        this._onReady = this._onReady.bind(this);
        this.handleClickAccount = this.handleClickAccount.bind(this);
    }

    _onReady(event) {
        event.target.pauseVideo();
    }

    handleClick(position) {
        if (this.state.position === position) {
            this.setState({ position: "0" })
        } else {
            this.setState({ position: position });
        }
    };

    handleClickAccount() {
        this.props.handleClickAccount("https://tracking.publicidees.com/clic.php?promoid=37985&progid=1598&partid=57757");
    };

    render() {
        return (
            <div className="service-bank-transfertWise">
                <div className="header">
                    <div className="header_logo"><img className="header-pic" src={BNPLogo} alt='Modal Logo'></img></div>
                    <div className="header-title"><b><FormattedMessage id="service.bank.BNP.title" defaultMessage="Positive banking." /></b></div>
                </div>
                <div className="cont-mod">
                    <FormattedMessage id="bank.BNP.benefits" defaultMessage="Benefits / Features">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "1")} handleTriggerClick={this.handleClick} accordionPosition="1">
                                <div className='text'><FormattedMessage id="bank.BNP.benefits.reason" defaultMessage="1 bank, 2 solutions: online or nearby your place" /></div>
                                <ul className='text'>
                                    <li><FormattedMessage id="bank.BNP.reason1" defaultMessage="Around 2000 branches in France" /></li>
                                    <li><FormattedMessage id="bank.BNP.reason2" defaultMessage="Experts to assist in your projects" /></li>
                                    <li><FormattedMessage id="bank.BNP.reason3" defaultMessage="A dedicated Relationship Manager available for a tailor-made follow-up" /></li>
                                    <li><FormattedMessage id="bank.BNP.reason4" defaultMessage="High performance of mobile app" /></li>
                                </ul>
                            </Collapsible>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="bank.BNP.pricing" defaultMessage="Pricing (Only in French)">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "2")} handleTriggerClick={this.handleClick} accordionPosition="2">
                                <div className="tarif_bnp" onClick={() => window.open(BNP_pricing, "_blank")}>
                                    <u style={{cursor: 'pointer'}}><FormattedMessage id="bank.BNP.tarif" defaultMessage="Click here to see the pricing"></FormattedMessage></u>
                                </div>
                            </Collapsible>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="bank.BNP.process" defaultMessage="Account opening process ">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "3")} handleTriggerClick={this.handleClick} accordionPosition="3">
                                <ul className='text'>
                                    <li><FormattedMessage id="bank.BNP.condition1" defaultMessage="Fill in the form (5 minutes)" /></li>
                                    <li onClick={() => window.open(BNP_doc, "_blank")} style={{cursor: 'pointer'}}><u><FormattedMessage id="bank.BNP.condition2" defaultMessage="Attach the supporting documents" /></u></li>
                                    <li><FormattedMessage id="bank.BNP.condition3" defaultMessage="Make a transfer of 300 euro" /></li>
                                </ul>
                            </Collapsible>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="bank.BNP.video" defaultMessage="Video">
                    {msg => ( 
                    <Collapsible trigger={msg} open={(this.state.position === "4")} handleTriggerClick={this.handleClick} accordionPosition="4">
                        <ReactPlayer
                            width='100%'
                            controls
                            config={{
                                youtube: {
                                    playerVars: { showinfo: 1 }
                                }
                            }}
                            url={this.state.youtubeHW}
                        />
                    </Collapsible>
                    )}
                    </FormattedMessage>

                </div>
                <div className="footer" style={{ textAlign: 'center' }}>
                    <Button variant="contained" color="primary" className="button" onClick={this.handleClickAccount}>
                        <img src={Issoyo_logo} alt='' style={{ width: '11%', marginRight: '5%' }}></img>
                        <FormattedMessage id="bank.open.account"></FormattedMessage>
                    </Button>
                </div>
            </div>
        )
    }
}

BNPModal.propTypes = {
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    service: state.services,
});

export default connect(
    mapStateToProps, {}
)(BNPModal);
