import React, { Component } from 'react'
import taxi_logo from '../../../../../assets/img/taxi_fr.jpg'
import { FormattedMessage } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';

require('./Taxi.scss');

const styles = {
    root: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    selectMenu: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    input: {
        borderColor: '#3e45948a !important',
    },
};

class Taxi extends Component {
    constructor() {
        super();
        this.state = {
            depart: 'charle',
        }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
        if (this.props.language === 'en') {
            this.props.linkChange("https://www.awin1.com/cread.php?awinmid=7151&awinaffid=525947&clickref=&p=https%3A%2F%2Fparis.ticketbar.eu%2Fen%2Ftransfer%2Ftaxi-paris-charles-de-gaulle-airport---hotel--%2F")
        }
    }

    handleChange(e) {
        if (this.props.language === 'en') {
            this.props.linkChange("https://www.awin1.com/cread.php?awinmid=7151&awinaffid=525947&clickref=&p=https%3A%2F%2Fparis.ticketbar.eu%2Fen%2Ftransfer%2Ftaxi-paris-charles-de-gaulle-airport---hotel--%2F");
        } else if (e.target.value === "charle") {
            this.props.linkChange("https://www.awin1.com/cread.php?awinmid=7151&awinaffid=525947&clickref=&p=https%3A%2F%2Fparis.ticketbar.eu%2Ffr%2Ftransfert-aeroport%2Ftaxi-paris-charles-de-gaulle-aeroport-hotel-%2F");
        } else {
            this.props.linkChange("https://www.awin1.com/cread.php?awinmid=7151&awinaffid=525947&clickref=&p=https%3A%2F%2Fparis.ticketbar.eu%2Ffr%2Ftransfert-aeroport%2Ftaxi-paris-aeroport-orly---hotel-%2F")
        }
        this.setState({ [e.target.name]: e.target.value})
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="taxi">
                <div className="taxi_depart">
                    <FormattedMessage id="taxi.depart" defaultMessage="Départ">
                        {msg => (<TextField
                            id="outlined-select-currency"
                            select
                            label={msg}
                            className="list taxi_airport"
                            value={this.state.depart}
                            name="depart"
                            onChange={this.handleChange}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                            margin="normal"
                            variant="outlined"
                        >
                            <MenuItem value='none' disabled classes={{ root: classes.root }}><FormattedMessage id="taxi.select" defaultMessage="Select airport" /></MenuItem>
                            <MenuItem value='charle' classes={{ root: classes.root }}>Paris Charles de Gaulle Aéroport</MenuItem>
                            <MenuItem value='orly' classes={{ root: classes.root }}>Paris Aéroport Orly</MenuItem>
                        </TextField>
                        )}
                    </FormattedMessage>
                </div>
                <div className="text taxi_describe">
                    <FormattedMessage id="transport.taxi.describe" defaultMessage="Private Airport Taxi vous attendra avec une pancarte avec votre nom et vous serez amené à votre hôtel sans stress et en toute tranquillité." />
                </div>
                <br></br>
                <div className="text taxi_title">
                    <FormattedMessage id="transport.taxi.howitwork" defaultMessage="Comment cela fonctionne ?" />
                </div>
                <div className="text">
                    <p><FormattedMessage id="transport.taxi.howitwork.text" defaultMessage="Après votre commande en ligne, vous recevrez un bon de réservation qui vous indiquera où trouver le taxi dans  l'aéroport. Le conducteur vous amènera à votre hôtel ou à votre logement quelque soit l’heure." /></p>
                    <p><b style={{ color: '#444ca5' }}><FormattedMessage id="transport.taxi.frequence" defaultMessage="Fréquence :" /></b> <FormattedMessage id="transport.taxi.depart" defaultMessage="départ à n’importe quelle heure du jour/nuit." /></p>
                    <p style={{ color: "#444ca5" }}><b><FormattedMessage id="transport.taxi.important" defaultMessage="Important à savoir ! Le taxi ne peut transporter que 3 personnes maximum. Lorsque vous êtes plus de 3 passagers, vous devez réserver des taxis supplémentaires." /></b></p>
                </div>
                <div className="taxi_description">
                    <div className="taxi_provider_logo">
                        <img className="taxi_provider-img" src={taxi_logo} alt='' />
                    </div>
                </div>
            </div>
        )
    }
}


export default withStyles(styles)(Taxi);