import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import agipi_logo from '../../../../../assets/img/providers/agipi_logo.jpg';
import agipi_brochure from '../../../../../assets/document/ISSOYO_agipi_Brochure.pdf';
import ReactPlayer from 'react-player';

require('./Emprunt.scss')

class Emprunt extends Component {
    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this);
        this._onReady = this._onReady.bind(this);
    }

    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
    
    handleClick(document) {
        if (document === "brochure") {
            window.open(agipi_brochure, "_blank");
        }
    }

    render() {
        return (
            <div className="emprunt">
                <div className="emprunt_logo">
                    <img src={agipi_logo} alt=''></img>
                </div>
                <div className="emprunt_description">
                    <div className="desc emprunt_pret">
                        <i className="fal fa-hands-usd"></i>
                        <p className="emprunt_text"><FormattedMessage id="insurance.emprunt.pret" defaultMessage="Insure your loan is now possible" /></p>
                    </div>
                    <div className="desc emprunt_eco">
                        <i className="fal fa-piggy-bank"></i>
                        <p className="emprunt_text" style={{width: '105px', margin: 'auto'}}><FormattedMessage id="insurance.emprunt.eco" defaultMessage="Save until 17 000€" /></p>
                    </div>
                    <div className="desc emprunt_devis">
                        <i className="fal fa-file-alt"></i>
                        <p className="emprunt_text"><FormattedMessage id="insurance.emprunt.devis" defaultMessage="Have a quote in 2mins" /></p>
                    </div>
                </div>
                <div className="habitation_document" style={{ marginTop: "24px", marginBottom: "48px" }}>
                    <div className="">
                        <div className="health_document-container" onClick={() => this.handleClick("brochure")}>
                            <div>
                                <i className="fal fa-file-alt" style={{fontSize: '16px'}}></i> <FormattedMessage id="insurance.health.brochure" />
                            </div>
                        </div>
                    </div>
                    </div>
                <div style={{ marginBottom: "20px" }}>
                    <ReactPlayer
                        width='100%'
                        controls
                        config={{
                            youtube: {
                                playerVars: { showinfo: 1 }
                            }
                        }}
                        url="https://youtu.be/kdkkWOehY3c"
                    />
                </div>
            </div>
        )
    }
}

export default Emprunt;