import React, { Component } from 'react'
import taxi_tarif from '../../../../../assets/img/providers/seoul_taxi_prix.png';
import taxi_map from '../../../../../assets/img/providers/seoul_taxi_map.png';
import taxi_img from '../../../../../assets/img/providers/seoulinternationaltaxi_image.png';
import { FormattedMessage } from 'react-intl';
import taxi_confirmation from '../../../../../assets/document/ISSOYO_KR_TAXI_CONFIMRATION_EN.pdf';
import taxi_driver from '../../../../../assets/document/ISSOYO_KR_TAXI_DRIVER.pdf';

require('./Taxi.scss');

class TaxiKR extends Component {
    render() {
        return (
            <div className="taxi">
                <div className="text taxi_describe">
                    <FormattedMessage id="transport.taxi.kr.describe" defaultMessage="Seoul City’s official foreign-Language Taxi service" />
                </div>
                <br></br>
                <div className="text taxi_title">
                    <FormattedMessage id="transport.taxi.kr.advantage" />
                </div>
                <div>
                    <ul className="text">
                        <li><FormattedMessage id="transport.taxi.allday" defaultMessage="24 hours a day / 365 days a year" /></li>
                        <li><FormattedMessage id="transport.taxi.reservation" defaultMessage="Easy reservation (website, phone or email)" /></li>
                        <li><FormattedMessage id="transport.taxi.convenient" defaultMessage="Convenient use (nameboard service and airport desk)" /></li>
                        <li><FormattedMessage id="transport.taxi.prices" defaultMessage="Reasonable prices " /></li>
                        <li><FormattedMessage id="transport.taxi.fixed" defaultMessage="Fixed rates for transportation from Incheon to downtown Seoul (one-way)" /></li>
                        <li><FormattedMessage id="transport.taxi.cash" defaultMessage="Either cash or credit card (visa, Amex, Master) is available " /></li>
                    </ul>
                </div>
                <div className="taxi_description">
                    <div className='title'><FormattedMessage id="transport.taxi.kr.car" defaultMessage="Car availables" /></div>
                    <div className="taxi_provider_logo-price">
                        <img className="taxi_provider-img-price" src={taxi_img} alt='' />
                    </div>
                    <div className="taxi_work" style={{padding: "12px", background: "#84818124"}}>
                        <div className='title'><FormattedMessage id="transport.taxi.kr.work" defaultMessage="How it works?" /></div>
                        <ol className="text" style={{ type: "1"}}>
                            <li><FormattedMessage id="transport.taxi.kr.list.reservation" defaultMessage="Make a reservation" /></li>
                            <li><FormattedMessage id="transport.taxi.kr.list.confirmation" defaultMessage="Got a confirmation letter by email. The confirmation are only sent during office hours as described below:" /></li>
                        </ol>
                        <div className="text" style={{padding: "20px"}}>
                            <h4><b>Contact information</b></h4>
                            Contact : +82 1644 2255<br />
                            E-mail : reserve@intltaxi.co.kr<br />
                            Office Operation Hour<br />
                            Mon to Fri : 09:00 ~ 18:00 (Korea Time) Saturday : 09:00 ~ 13:00 (Korea Time) Sunday / Holiday is Closed
                        </div>
                        <div class="text" style={{ textAlign: "center" }}>
                            <FormattedMessage id="transport.taxi.kr.example" defaultMessage="Example of confirmation letter." />
                        </div>
                        <div className="habitation_document" style={{ marginTop: "24px", marginBottom: "12px" }}>
                            <div className="">
                                <div className="health_document-container" style={{textAlign: "center"}} onClick={() => window.open(taxi_confirmation, '_blank')}>
                                    <div>
                                        <i className="fal fa-file-alt"></i> <FormattedMessage id="transport.taxi.kr.confirmation" defaultMessage="Confirmation letter" />
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <div className="health_document-container" style={{textAlign: "center"}}  onClick={() => window.open(taxi_driver, '_blank')}>
                                    <div>
                                        <i className="fal fa-file-alt"></i> <FormattedMessage id="transport.taxi.kr.driver" defaultMessage="Driver Information" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='title'><FormattedMessage id="transport.taxi.kr.price" defaultMessage="Prices and zones" /></div>
                    <div className="taxi_provider_logo-price">
                        <img className="taxi_provider-img-price" src={taxi_map} alt='' />
                        <img className="taxi_provider-img-price" src={taxi_tarif} alt='' />
                    </div>
                </div>
            </div>
        )
    }
}

export default TaxiKR;