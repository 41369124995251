import React, { Component } from 'react'
import { Footer } from './Footer.jsx';

export class Cookies extends Component {
    render() {
        return (
            <React.Fragment>
                <div style={{marginTop: "80px", paddingRight: "32px", paddingLeft: "32px", maxWidth: "1040px"}}>
                <div className="signup_back"><i style={{color: '#593e96'}}className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                    <div>
                        <h1>Cookies <span>Issoyo</span></h1>
                        <p>Lors de la consultation de notre site, des cookies sont déposés sur votre ordinateur, votre mobile ou
                            votre tablette.</p>
                        <p>Cette page vous permet de mieux comprendre comment fonctionnent les cookies et comment utiliser les
                            outils actuels afin de les paramétrer.</p>
                        <div>
                            <h2>Qu'est-ce qu'un cookie ?</h2>
                            <p>Un cookie est un petit fichier texte déposé sur votre ordinateur lors de la visite d'un site ou de la
                                consultation d'une publicité. Ils ont notamment pour but de collecter des informations relatives à
                                votre navigation sur les sites et de vous adresser des services personnalisés.</p>
                            <p>Dans votre ordinateur les cookies sont gérés par votre navigateur internet.</p>
                            <p>Les cookies enregistrés par nous ou par des tiers lorsque vous visitez nos sites et applications ne
                                vous reconnaissent pas personnellement en tant qu'individu, mais reconnaissent seulement l'appareil
                                que vous étes en train d'utiliser. Les cookies ne stockent aucune donnée personnelle sensible mais
                                donnent simplement une information sur votre navigation de faéon à ce que votre terminal puisse étre
                                reconnu plus tard. </p>
                            <p>Les cookies ne causent aucun dommage à votre appareil, mais permettent plus facilement par exemple de
                                retrouver vos préférences, de pré-remplir certains champs et d'adapter le contenu de nos services.
                            </p>
                            <p>Vous seul(e) choisissez si vous souhaitez avoir des cookies enregistrés sur votre appareil.</p>
                        </div>
                        <div>
                            <h2>Différents émetteurs</h2>
                            <h3>Les cookies fonctionnels</h3>
                            <p>Les cookies fonctionnels que nous émettons nous permettent :</p>
                            <br />
                            <ul>
                                <li>d'adapter la présentation de nos sites, applications et espaces publicitaires aux préférences
                                    d'affichage de votre terminal (langue utilisée, résolution d'affichage, systéme d'exploitation
                                    utilisé, etc), selon les matériels et les logiciels de visualisation ou de lecture que votre
                                        terminal comporte ;</li>
                                <li>de faciliter vos recherches en conservant vos recherches automatiques et vos annonces
                                        sauvegardées ;</li>
                                <li>d'améliorer votre expérience utilisateur en pré-remplissant certains champs de nos formulaires</li>
                            </ul>
                            <h3>Les cookies de statistiques (ou analytiques)</h3>
                            <p>Les cookies analytiques que nous émettons nous permettent de mieux connaétre nos sites et
                                applications et d'améliorer nos services en établissant des statistiques et volumes de fréquentation
                                    et d'utilisation (rubriques et contenus visités, parcours...).</p><br />
                            <h3>Les cookies publicitaires</h3>
                            <p>Il s'agit des cookies utilisés pour vous présenter des publicités ou vous adresser des informations
                                adaptées à vos centres d'intéréts sur nos sites ou en dehors de nos sites lors de votre navigation
                                sur Internet. Ils sont notamment utilisés pour limiter le nombre de fois que vous voyez une publicité
                                        et aider à mesurer l'efficacité d'une campagne publicitaire.</p>

                            <p>Le refus de ces cookies publicitaires n'a pas d'impact sur l'utilisation de nos sites. Cependant le
                                fait de refuser les cookies publicitaires n'entraénera pas l'arrét de la publicité sur nos sites ou
                                        sur Internet.</p>

                            <p>Cela aura seulement pour effet d'afficher une publicité qui ne tiendra pas compte de vos centres
                    d'intérét ou de vos préférences.</p>

                            <p>Ces cookies sont principalement des cookies tiers.</p>

                            <p>Pour plus d'informations sur les cookies enregistrés par nos partenaires, nous vous suggérons de
                                        visiter leur site:</p>
                            <ul>
                                <li>Public-idées: <a
                                    href="http://tracking.publicidees.com/PIk-retargeting/retargetingControl.php?PIk_lang=fr_FR"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >http://tracking.publicidees.com/PIk-retargeting/retargetingControl.php?PIk_lang=fr_FR</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h2>Accepter ou refuser les cookies</h2>
                            <p>Vous disposez de différents moyens pour gérer les cookies.</p>
                            <h3>Le paramétrage de votre navigateur internet</h3>
                            <p>Vous pouvez à tout moment choisir de désactiver ces cookies. Votre navigateur peut également étre
                                paramétré pour vous signaler les cookies qui sont déposés dans votre ordinateur et vous demander de
                                les accepter ou non. Vous pouvez accepter ou refuser les cookies au cas par cas ou bien les refuser
                    systématiquement une fois pour toutes.</p>
                            <p>Nous vous rappelons que le paramétrage est susceptible de modifier vos conditions d'accés à nos
                    services nécessitant l'utilisation de cookies.</p>
                            <p>Si votre navigateur est configuré de maniere à refuser l'ensemble des cookies, vous ne pourrez pas
                                effectuer d'achats ou profiter de fonctions essentielles de notre site, comme par exemple stocker
                                des articles dans votre panier ou recevoir des recommandations personnalisées. Afin de gérer les
                                cookies au plus prés de vos attentes nous vous invitons à paramétrer votre navigateur en tenant
                    compte de la finalité des cookies telle que mentionnée ci-avant.</p>
                            <p>
                                Pour la gestion des cookies, la configuration de chaque navigateur est différente. Elle est décrite
                                dans le menu d'aide de votre navigateur, qui vous permettra de savoir de quelle maniére modifier vos
                                souhaits :
                </p>
                            <ul>
                                <li>Pour Internet Explorer : <a
                                    href="http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies">http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies</a>
                                </li>
                                <li>Pour Safari : <a href="http://docs.info.apple.com/article.html?path=Safari/3.0/fr/9277.html">
                                    http://docs.info.apple.com/article.html?path=Safari/3.0/fr/9277.html </a></li>
                                <li>Pour Chrome : <a
                                    href="http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647">
                                    http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647 </a></li>
                                <li>Pour Firefox : <a
                                    href="http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies">
                                    http://support.mozilla.org/fr/kb/activer-desactiver-cookies </a></li>
                                <li>Pour Opera : <a href="http://help.opera.com/Windows/10.20/fr/cookies.html">
                                    http://help.opera.com/Windows/10.20/fr/cookies.html </a></li>
                            </ul>
                            <p></p>
                        </div>
                        <h3>Le paramétrage sur une plateforme de gestion des cookies</h3>
                        <p>Vous pouvez gérer vos cookies en vous rendant sur des plateformes de gestion des cookies proposées par
                les professionnels de la publicité.</p>
                    </div>
                    <div>
                        <h2>Plus d'information sur les cookies</h2>
                        <p>Sur le site de la CNIL : <a href="http://www.cnil.fr/vos-droits/vos-traces/les-cookies/" target="_blank" rel="noopener noreferrer"
                            class="moutarde">http://www.cnil.fr/vos-droits/vos-traces/les-cookies/</a></p>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}
export default (Cookies);