import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ReactPhoneInput from 'react-phone-input-2';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { sendVerify, checkNickName, nextRegister } from "../../../actions/authActions";
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';


require("./RegisterPart1.scss");


const gender = [
  {
    value: 'none',
    label: 'Select Gender',
  },
  {
    value: 'male',
    label: 'Mr.',
  },
  {
    value: 'female',
    label: 'Mrs.',
  },
];

const style = {
  root: {
      color: "rgba(68, 76, 165, 0.85)",
      fontFamily: "'Nunito', sans-serif !important",
  },
  input: {
      borderColor: '#3e45948a !important',
  },
};

class RegisterPart1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.localUser.name,
      firstName: this.props.localUser.firstName,
      phoneNumber: this.props.localUser.phoneNumber,
      title: this.props.localUser.title,
      nickname: this.props.localUser.nickname,
      country: this.props.localUser.country,
      email: this.props.localUser.email,
      password: this.props.localUser.password,
      repassword: this.props.localUser.repassword,
      errorRequired: false,
      errorName: false,
      errorFirstName: false,
      errorTitle: false,
      errorNickName: false,
      errorPhone: false,
			errorPhoneUndifined: false,
      messageNickName: [],
      isSent: false,
    };
		
    this.cheatcode = false;
    this.handleChange = this.handleChange.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.setErrorRed = this.setErrorRed.bind(this);
  }
  
  componentWillMount (){
    if (this.props.location.state){
        this.setState({
            email: this.props.location.state.email,
            password: this.props.location.state.password,
            repassword: this.props.location.state.password2,
            name: this.props.location.state.name,
            title: this.props.location.state.title,
            firstName: this.props.location.state.firstName,
            nickname: this.props.location.state.nickname,
            phoneNumber: this.props.location.state.phoneNumber,
        })
    }
}
  setErrorRed() {
		if(this.state.phoneNumber === undefined)
      this.setState({ errorPhoneUndifined: true });
    if (this.state.nickname === '')
      this.setState({ errorNickName: true });
    if (this.state.firstName === '')
      this.setState({ errorFirstName: true });
    if (this.state.name === '')
      this.setState({ errorName: true });
    if (this.state.title === 'none')
      this.setState({ errorTitle: true });
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.errors.status) {
      if (nextProps.errors.status === 'nickname') {
        this.setState({
          errorNickName: true,
          messageNickName: <FormattedMessage id="registerPart1.error.nickname.used" defaultMessage="This nickname is already used" />,
        })
      } else {
      this.setState({
        errors: nextProps.errors, errorPhone: true, isSent: false
      });
    }
    } else if ((this.state.isSent === true && nextProps.auth.requestid) || this.cheatcode === true) {
      this.props.nextRegister({
        email: this.state.email,
        password: this.state.password,
        repassword: this.state.repassword,
        name: this.state.name,
        firstName: this.state.firstName,
        nickname: this.state.nickname,
        title: this.state.title,
        phoneNumber: this.state.phoneNumber,
      })
      this.props.history.push('/signup2');
    } else {
    this.setState({ errorRequired: false });
      const userData = { number: this.state.phoneNumber };
      this.props.sendVerify(userData);
      this.setState({ isSent: true})
    }
  }

  handleClick() {
		console.log("iciiiiii");
		console.log("name =" +this.state.name);
		console.log("phone number =" +this.state.phoneNumber);
    if (this.state.name === '' || this.state.firstName === ''
      || this.state.phoneNumber === ''|| this.state.phoneNumber === undefined || this.state.nickname === '') {
			
			console.log("ici1_b2");
      this.setState({ errorRequired: true });
      this.setErrorRed();
			return true;
    } else {
			console.log("ici2_b2");
      const userData = {nickname: this.state.nickname};
      this.props.checkNickName(userData);
			
    }
  }

  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/");
    }
  }

  handleChange(event) {
		console.log("target name event ="+event.target.name);
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name === 'nickname')
      this.setState({ errorNickName: false });
    if (event.target.name === 'firstName')
      this.setState({ errorFirstName: false, nickname: event.target.value + '#' + Math.round(Math.random() * (9999- 1) + 1)});
    if (event.target.name === 'name')
      this.setState({ errorName: false});
    if (event.target.name === 'title')
      this.setState({ errorTitle: false });
		if(event.target.name === 'phoneNumber')
			this.setState({ errorPhoneUndifined: false});
  }

  handleChangePhone(value) {
    this.setState({ phone: value });
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className="Signup1">
        <div className="signup_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.goBack(this.state.email)}></i></div>
          <div className="col-12 title"><div>
            <FormattedMessage id="registerPart1.title" defaultMessage="A few more steps to get your account ready" />
          </div></div>
          <div className="error required" style={this.state.errorRequired ? {} : { display: 'none' }}>
            <FormattedMessage id="registerPart1.error.required" defaultMessage="You must fill out all required fields" />
          </div>
          <div className="error required" style={this.state.errorPhone ? {} : { display: 'none' }}>
            <FormattedMessage id="registerPart1.error.phone" defaultMessage="Something went wrong with your phone number" />
          </div>
					<div className="error required" style={this.state.errorPhoneUndifined ? {} : { display: 'none' }}>
            <FormattedMessage id="registerPart1.error.errorPhoneUndifined" defaultMessage="Number phone undifined.." />
          </div>
          <div className="div-input-phone">
            <FormattedMessage id="registerPart1.phone" defaultMessage="Phone number" >
              {msg => (<ReactPhoneInput
                defaultCountry='fr'
                regions={['europe', 'america', 'asia', 'africa']}
                preferredCountries={['fr', 'us', 'kr', 'gb', 'cn']}
                disableAreaCodes
                enableSearchField
                value={this.state.phoneNumber}
                className="input-phone"
                placeholder={msg}
                inputExtraProps={{
                  name: 'phoneNumber',
                  onChange: this.handleChange,
                }}
              />)}
            </FormattedMessage>
          </div>
          <div className="div-input-gender">
            <FormattedMessage id="registerPart1.gender" defaultMessage="Gender" >
              {msg => (<TextField
                error={this.state.errorTitle}
                id="outlined-select-gender-native"
                select
                label={"Gender"}
                className="select-gender"
                name="Gender"
                InputProps={{classes: {root: classes.root, notchedOutline: classes.input}}}
                SelectProps={{
                  native: true,
                  MenuProps: {
                    className: "test",
                  },
                }}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
              >
                {gender.map(option => (
                  <option key={option.value} value={this.state.title}>
                    {option.label}
                  </option>
                ))}
              </TextField>)}
            </FormattedMessage>
          </div>
          <div className="div-input-name">
            <FormattedMessage id="registerPart1.name" defaultMessage="Name" >
              {msg => (<TextField
                required
                error={this.state.errorName}
                id="outlined-with-placeholder"
                label={msg}
                className="input-name"
                name="name"
                margin="normal"
                variant="outlined"
                value={this.state.name}
                InputProps={{classes: {root: classes.root, notchedOutline: classes.input}}}
                onChange={this.handleChange}
              />)}
            </FormattedMessage>
          </div>
          <div className="div-input-first-name">
            <FormattedMessage id="registerPart1.firstname" defaultMessage="First name" >
              {msg => (<TextField
                required
                error={this.state.errorFirstName}
                id="outlined-with-placeholder"
                label={msg}
                className="input-first-name"
                name="firstName"
                margin="normal"
                variant="outlined"
                value={this.state.firstName}
                InputProps={{classes: {root: classes.root, notchedOutline: classes.input}}}
                onChange={this.handleChange}
              />)}
            </FormattedMessage>
          </div>
          <div className="div-input-nickname test24_1">
            <FormattedMessage id="registerPart1.nickname" defaultMessage="Nickname" >
              {msg => (<TextField
                required
                error={this.state.errorNickName}
                id="outlined-with-placeholder"
                label={msg}
                className="input-nickname"
                name="nickname"
                margin="normal"
                variant="outlined"
                value={this.state.nickname}
                onChange={this.handleChange}
                InputProps={{classes: {root: classes.root, notchedOutline: classes.input}}}
              />)}
            </FormattedMessage>
          </div>
          <div className="error-nick" style={this.state.errorNickName ? {} : { display: 'none' }}>{this.state.messageNickName}</div>

          <div className="row">
            <div className="col send">
              <Button variant="contained" color="primary" className="button" onClick={this.handleClick}>
                <FormattedMessage id="registerPart1.button.next" defaultMessage="Next" />
              </Button>
            </div>
          </div>
          <div className="progress">
            <div className="progress-bar" role="progressbar" style={{ width: "50%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

RegisterPart1.propTypes = {
  checkNickName: PropTypes.func.isRequired,
  nextRegister: PropTypes.func.isRequired,
  sendVerify: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  localUser: state.localData.localUser,
  errors: state.auth.error
});

export default connect(
  mapStateToProps,
  { sendVerify, checkNickName, nextRegister }
)(withStyles(style)(RegisterPart1));