import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import whitepaper from '../../../../../assets/document/energy/ISSOYO_eni_livreblanc.pdf';
import electricity_description from '../../../../../assets/document/energy/ISSOYO_eni_description_electricity.pdf';
import eni_logo from '../../../../../assets/img/providers/eni_logo.jpg'

require("./Electricity.scss")

class Electricity extends Component {
    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(document) {
        if (document === "brochure") {
            window.open(whitepaper, "_blank");
        } else if (document === "condition") {
            window.open(electricity_description, "_blank");
        } 
    }

    render() {
        return (
            <div className='Electricity'>
                <div className="habitation_description">
                    <div className="habitation_provider_logo">
                        <img className="habitation_provider-img" src={eni_logo} alt='' />
                    </div>
                    <div className="text lulo_title">
                        <FormattedMessage id="eni.advantage" />
                    </div>
                    <ul className="text">
                        <li><FormattedMessage id="eni.gaz.best" /></li>
                        <li><FormattedMessage id="eni.gaz.nocut"/></li>
                        <li><FormattedMessage id="eni.gaz.nodocument"/></li>
                        <li><FormattedMessage id="eni.gaz.nocomit"/></li>
                        <li><FormattedMessage id="eni.gaz.customer"/></li>
                    </ul>
                    <div className="text lulo_title">
                        <FormattedMessage id="eni.offer" />
                    </div>
                    <ul className="text">
                        <li><FormattedMessage id="eni.electricity.offer" defaultMessage="Economie de 35% sur le prix du kWh HT des heures creuses Eni comparé à celui des heures pleines" /></li>
                        <li><FormattedMessage id="eni.gaz.web"/></li>
                    </ul>
                </div>
                <div className="habitation_document" style={{ marginTop: "24px", marginBottom: "48px" }}>
                    <div className="">
                        <div className="health_document-container" onClick={() => this.handleClick("brochure")}>
                            <div>
                                <i className="fal fa-file-alt"></i> <FormattedMessage id="energy.eni.whitepaper" />
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="health_document-container" onClick={() => this.handleClick("condition")}>
                            <div>
                                <i className="fal fa-file-alt"></i> <FormattedMessage id="energy.eni.description" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
} 

export default Electricity;