import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import Axa_logo from '../../../../../assets/img/providers/Axa_logo.jpg';


require('./Travel.scss')

class Travel extends Component {
    render() {
        return (
            <div className="emprunt">
                <div className="emprunt_logo">
                    <img style={{ width: '250px' }} src={Axa_logo} alt=''></img>
                </div>
                <div className="travel_description">
                    <div className="row travel_info">
                        <div className="col-md-4" style={{ marginTop: "24px" }}>
                            <div className="travel_title">
                                <FormattedMessage id="insurance.travel.short" defaultMessage="ASSURANCE COURT SEJOUR" />
                            </div>
                            <div className="travel_text" >
                                <FormattedMessage id="insurance.travel.short.1" defaultMessage="Pour vos séjours de moins de 3 mois." />
                            </div>
                        </div>
                        <div className="col-md-4" style={{ marginTop: "24px" }}>
                            <div className="travel_title">
                                <FormattedMessage id="insurance.travel.multi" defaultMessage="ASSURANCE MULTI-VOYAGES" />
                            </div>
                            <div className="travel_text" >
                                <FormattedMessage id="insurance.travel.multi.1" defaultMessage="Couvrira tous vos séjours de moins de 3 mois dans l’année." />
                            </div>
                        </div>
                        <div className="col-md-4" style={{ marginTop: "24px" }}>
                            <div className="travel_title">
                                <FormattedMessage id="insurance.travel.study" defaultMessage="ETUDIANT A L'ETRANGER" />
                            </div>
                            <div className="travel_text">
                                <FormattedMessage id="insurance.travel.study.1" defaultMessage="Dédiée aux stages et études à l’étranger." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Travel;