import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import axios from "axios";
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ReactPhoneInput from 'react-phone-input-2';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { placeList } from './place.js';
import Issoyo_logo from '../../../../../assets/img/fav_icon32@2x.png';

require('./AppartcityModal.scss');

const styles = {
    root: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    selectMenu: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    input: {
        borderColor: '#3e45948a !important',
    },
    rootCheck: {
        color: '#836bc1bf !important',
        '&$checked': {
            color: '#836bc1bf !important',
        },
    },
};

class AppartcityModal extends Component {
    constructor() {
        super();
        this.state = {
            name: '',
            firstname: '',
            phone: '',
            email: '',
            company: '',
            destination: '',
            child_age: ['', '', '', '', '', '', '', '', '', ''],
            nbr_adult: 0,
            nbr_child: 0,
            comment: '',
            langue: '',
            presta: [],
            startDate: null,
            endDate: null,
            placeList: [],
            placeName: 'input appartcity_place',
            place: '',
            otherPlace: 'input appartcity_otherPlace',
            otherDest: '',
            errorRequired: false,
            errorName: false,
            errorFirstname: false,
            errorEmail: false,
            errorPhone: false,
            errorDestination: false,
            errorLangue: false,
            errorStartDate: false,
            errorEndDate: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeAge = this.handleChangeAge.bind(this);
        this.handleChangeDestination = this.handleChangeDestination.bind(this);
        this.addOne = this.addOne.bind(this);
        this.removeOne = this.removeOne.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.checkError = this.checkError.bind(this);
    }

    componentWillMount() {
        this.setState({
            name: this.props.auth.user.name,
            firstname: this.props.auth.user.firstname,
            email: this.props.auth.user.email,
            phone: this.props.auth.user.phone
        })
    }

    checkError() {
        var errorName, errorFirstname, errorPhone, errorEmail, errorDestination, errorLangue, errorStartDate,
            errorEndDate, errorRequired = false;
            console.log(this.state.name)
        if (!this.state.name) {
            errorName = true;
            errorRequired = true;
        }
        if (!this.state.firstname) {
            errorFirstname = true
            errorRequired = true;
        }
        if (!this.state.phone) {
            errorPhone = true
            errorRequired = true;
        }
        if (!this.state.email) {
            errorEmail = true
            errorRequired = true;
        }
        if (this.state.destination === '') {
            errorDestination = true
            errorRequired = true;
        }
        if (this.state.langue === '') {
            errorLangue = true
            errorRequired = true;
        }
        if (this.state.startDate === '' || this.state.endDate === '') {
            errorStartDate = true;
            errorEndDate = true;
        }
        this.setState({
            errorEmail: errorEmail,
            errorPhone: errorPhone,
            errorName: errorName,
            errorFirstname: errorFirstname,
            errorDestination: errorDestination,
            errorLangue: errorLangue,
            errorStartdate: errorStartDate,
            errorEndDate: errorEndDate,
            errorRequired: errorRequired,
        })
        if (errorRequired === true) {
            return true;
        }
        return false;
    }

    handleClick() {
        if (this.checkError()) {
            return;
        } else {
            var city, place;
            if (this.state.destination === "autre") {
                city = this.state.otherDest;
                place = ''
            } else {
                city = this.state.destination;
                place = this.state.place;
            }
            const rq = {
                provider: 'Appartcity',
                name: this.state.name,
                firstname: this.state.firstname,
                email: this.state.email,
                phone: this.state.phone,
                langue: this.state.langue,
                localisation: city,
                place: place,
                society: this.state.company,
                dateStart: this.state.startDate.toDate(),
                dateEnd: this.state.endDate.toDate(),
                nbr_adult: this.state.nbr_adult,
                nbr_child: this.state.nbr_child,
                child_age: this.state.child_age,
                prestation: this.state.presta,
                description: this.state.comment,
            }
            axios.post('api/mail/sendAppartEmail', rq);
            this.props.onClose();
        }
    }

    addOne(name) {
        if (name === 'adult') {
            if (this.state.nbr_adult < 10) {
                const nbr = this.state.nbr_adult + 1;
                this.setState({ nbr_adult: nbr });
            }
        } else if (name === 'child') {
            if (this.state.nbr_child < 10) {
                const nbr = this.state.nbr_child + 1;
                this.setState({ nbr_child: nbr });
            }
        }
    }

    removeOne(name) {
        if (name === 'adult') {
            if (this.state.nbr_adult > 0) {
                const nbr = this.state.nbr_adult - 1;
                this.setState({ nbr_adult: nbr });
            }
        } else if (name === 'child') {
            var tmp = this.state.child_age;
            if (this.state.nbr_child > 0) {
                const nbr = this.state.nbr_child - 1;
                tmp[this.state.nbr_child - 1] = ''
                this.setState({ nbr_child: nbr, child_age: tmp });
            }
        }
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleChangeAge(e) {
        var tmp = this.state.child_age;
        tmp[e.target.name] = e.target.value
        this.setState({ child_age: tmp })
    }

    handleChangeDestination(e) {
        var city = e.target.value;
        var place = placeList.filter(function (data) {
            if (data.city === city) {
                return true;
            } else {
                return false
            }
        })
        if (e.target.value === 'Autre') {
            this.setState({
                [e.target.name]: e.target.value,
                otherPlace: 'input appartcity_otherPlace--appear',
                placeName: 'input appartcity_place',
                place: '',
            })

        } else {
            this.setState({
                [e.target.name]: e.target.value,
                placeList: place[0].place,
                placeName: 'input appartcity_place--appear',
                otherPlace: 'appartcity_otherPlace',
                place: '',
            })
        }
    }

    render() {
        const { classes } = this.props;
        var listChild = [];
        for (var i = 0; i < this.state.nbr_child; i++) {
            listChild.push(
                <TextField
                    id="outlined-select-currency"
                    select
                    key={i}
                    label="Select Age"
                    className="appartcity_nbr_child-age"
                    value={this.state.child_age[i]}
                    name={i}
                    onChange={this.handleChangeAge}
                    SelectProps={{
                        MenuProps: {
                            className: classes.menu,
                        },
                    }}
                    InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                    margin="normal"
                    variant="outlined"
                >
                    <MenuItem disabled value={'none'}>Select age</MenuItem>
                    <MenuItem value={"0 an"} classes={{ root: classes.root }}>0 years old</MenuItem>
                    <MenuItem value={"1 an"} classes={{ root: classes.root }}>1 years old</MenuItem>
                    <MenuItem value={"2 ans"} classes={{ root: classes.root }}>2 years old</MenuItem>
                    <MenuItem value={"3 ans"} classes={{ root: classes.root }}>3 years old</MenuItem>
                    <MenuItem value={"4 ans"} classes={{ root: classes.root }}>4 years old</MenuItem>
                    <MenuItem value={"5 ans"} classes={{ root: classes.root }}>5 years old</MenuItem>
                    <MenuItem value={"6 ans"} classes={{ root: classes.root }}>6 years old</MenuItem>
                    <MenuItem value={"7 ans"} classes={{ root: classes.root }}>7 years old</MenuItem>
                    <MenuItem value={"8 ans"} classes={{ root: classes.root }}>8 years old</MenuItem>
                    <MenuItem value={"9 ans"} classes={{ root: classes.root }}>9 years old</MenuItem>
                    <MenuItem value={"10 ans"} classes={{ root: classes.root }}>10 years old</MenuItem>
                    <MenuItem value={"11 ans"} classes={{ root: classes.root }}>11 years old</MenuItem>
                    <MenuItem value={"12 ans"} classes={{ root: classes.root }}>12 years old</MenuItem>
                    <MenuItem value={"13 ans"} classes={{ root: classes.root }}>13 years old</MenuItem>
                    <MenuItem value={"14 ans"} classes={{ root: classes.root }}>14 years old</MenuItem>
                    <MenuItem value={"15 ans"} classes={{ root: classes.root }}>15 years old</MenuItem>
                    <MenuItem value={"16 ans"} classes={{ root: classes.root }}>16 years old</MenuItem>
                    <MenuItem value={"17 ans"} classes={{ root: classes.root }}>17 years old</MenuItem>
                </TextField>
            )
        }
        return (
            <div className="appartcity">
                <div className="appartcity_title"><FormattedMessage id="appartcityModal.title" defaultMessage="Application form" /></div>
                <div className="appartcity_subtitle"><FormattedMessage id="appartcityModal.subtitle" defaultMessage="Got an answer in 30 minutes" /></div>
                <hr></hr>
                <div>
                    <div className="error required" style={this.state.errorRequired ? {} : { display: 'none' }}>
                        <FormattedMessage id="registerPart1.error.required" />
                    </div>
                    <div className="apparticity_information_subtitle"><i className="fas fa-user"></i> <FormattedMessage id="appartcityModal.profil.information" defaultMessage="Your information" /></div>
                    <div className="appartcity_form">
                        <div className="row Name" style={{ justifyContent: "space-between" }}>
                            <div className="input appartcity_name">
                                <FormattedMessage id="appartcityModal.name" defaultMessage="Name" >
                                    {msg => (<TextField
                                        required
                                        id="outlined-with-placeholder"
                                        label={msg}
                                        className="appartcity_name-input"
                                        name="name"
                                        error={this.state.errorName}
                                        margin="normal"
                                        variant="outlined"
                                        value={this.state.name}
                                        InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                        onChange={this.handleChange}
                                    />)}
                                </FormattedMessage>
                            </div>
                            <div className="input appartcity_firstname">
                                <FormattedMessage id="appartcityModal.firstname" defaultMessage="First name" >
                                    {msg => (<TextField
                                        required
                                        id="outlined-with-placeholder"
                                        label={msg}
                                        error={this.state.errorFirstname}
                                        className="appartcity_firstname-input"
                                        name="firstname"
                                        margin="normal"
                                        variant="outlined"
                                        value={this.state.firstname}
                                        InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                        onChange={this.handleChange}
                                    />)}
                                </FormattedMessage>
                            </div>
                        </div>
                        <div className="row Email" style={{ justifyContent: "space-between" }}>
                            <div className="input appartcity_email">
                                <FormattedMessage id="appartcityModal.email" defaultMessage="Email" >
                                    {msg => (<TextField
                                        required
                                        id="outlined-with-placeholder"
                                        label={msg}
                                        className="appartcity_email-input"
                                        name="email"
                                        margin="normal"
                                        error={this.state.errorEmail}
                                        variant="outlined"
                                        value={this.state.email}
                                        InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                        onChange={this.handleChange}
                                    />)}
                                </FormattedMessage>
                            </div>
                            <div className="input appartcity_phone">
                                <FormattedMessage id="appartcityModal.phone" defaultMessage="Phone number" >
                                    {msg => (<ReactPhoneInput
                                        defaultCountry='fr'
                                        regions={['europe', 'america', 'asia', 'africa']}
                                        preferredCountries={['fr', 'us', 'kr', 'gb', 'cn']}
                                        disableAreaCodes
                                        enableSearchField
                                        value={this.state.phone}
                                        className="appartcity_phone-input"
                                        placeholder={msg}
                                        inputExtraProps={{
                                            name: 'phone',
                                            onChange: this.handleChange,
                                        }}
                                    />)}
                                </FormattedMessage>
                            </div>
                        </div>
                        <div className="input appartcity_company">
                            <FormattedMessage id="appartcityModal.company" defaultMessage="Company (optional)" >
                                {msg => (<TextField
                                    id="outlined-with-placeholder"
                                    label={msg}
                                    className="appartcity_company-input"
                                    name="company"
                                    margin="normal"
                                    variant="outlined"
                                    value={this.state.company}
                                    InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                    onChange={this.handleChange}
                                />)}
                            </FormattedMessage>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="apparticity_information_subtitle"><i className="fas fa-map-marker-alt"></i> <FormattedMessage id="appartcityModal.destination.information" defaultMessage="Destination information" /></div>
                    <div className="appartcity_form">
                        <div className="row destination" style={{ justifyContent: "space-between" }}>
                            <div className="input appartcity_destination">
                                <FormattedMessage id="appartcityModal.destination" defaultMessage="Ville de destination" >
                                    {msg => (<TextField
                                        id="outlined-select-currency"
                                        select
                                        label={msg}
                                        className="appartcity_destination-list"
                                        value={this.state.destination}
                                        name="destination"
                                        error={this.state.errorDestination}
                                        onChange={this.handleChangeDestination}
                                        SelectProps={{
                                            MenuProps: {
                                                className: classes.menu,
                                            },
                                        }}
                                        InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                        margin="normal"
                                        variant="outlined"
                                    >
                                        <MenuItem disabled value={"none"}>Select destination</MenuItem>
                                        <MenuItem value={"Lille"} classes={{ root: classes.root }}>Lille</MenuItem>
                                        <MenuItem value={"Lyon"} classes={{ root: classes.root }}>Lyon</MenuItem>
                                        <MenuItem value={"Marseille"} classes={{ root: classes.root }}>Marseille</MenuItem>
                                        <MenuItem value={"Montpellier"} classes={{ root: classes.root }}>Montpellier</MenuItem>
                                        <MenuItem value={"Nantes"} classes={{ root: classes.root }}>Nantes</MenuItem>
                                        <MenuItem value={"Paris"} classes={{ root: classes.root }}>Paris</MenuItem>
                                        <MenuItem value={"St Quentin en Yvelines"} classes={{ root: classes.root }}>St Quentin en Yvelines</MenuItem>
                                        <MenuItem value={"Strasbourg"} classes={{ root: classes.root }}>Strasbourg</MenuItem>
                                        <MenuItem value={"Toulouse"} classes={{ root: classes.root }}>Toulouse</MenuItem>
                                        <hr />
                                        <MenuItem value={"Autre"} classes={{ root: classes.root }}><FormattedMessage id="appartcityModal.other" defaultMessage="Other" /></MenuItem>
                                    </TextField>)}
                                </FormattedMessage>
                            </div>
                            <div className={this.state.placeName}>
                                <FormattedMessage id="appartcityModal.place" defaultMessage="Place (optional)" >
                                    {msg => (<TextField
                                        id="outlined-select-currency"
                                        select
                                        label={msg}
                                        className="appartcity_place-list"
                                        value={this.state.place}
                                        name="place"
                                        onChange={this.handleChange}
                                        SelectProps={{
                                            MenuProps: {
                                                className: classes.menu,
                                            },
                                        }}
                                        InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                        margin="normal"
                                        variant="outlined"
                                    >
                                        <MenuItem disabled value={"none"}>Select place</MenuItem>
                                        {this.state.placeList.map((data, key) =>
                                            (<MenuItem classes={{ root: classes.root }} key={key} value={data}>
                                                {data}
                                            </MenuItem>))}
                                        <hr />
                                        <MenuItem classes={{ root: classes.root }} value={"other"}><FormattedMessage id="appartcityModal.other" defaultMessage="Other" /></MenuItem>
                                    </TextField>)}
                                </FormattedMessage>
                            </div>
                            <div className={this.state.otherPlace}>
                                <FormattedMessage id="appartcityModal.otherPlace" defaultMessage="City name" >
                                    {msg => (<TextField
                                        required
                                        id="outlined-with-placeholder"
                                        label={msg}
                                        className="appartcity_otherPlace-input"
                                        name="otherDest"
                                        margin="normal"
                                        variant="outlined"
                                        value={this.state.otherDest}
                                        InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                        onChange={this.handleChange}
                                    />)}
                                </FormattedMessage>
                            </div>
                        </div>
                        <div className="appartcity_date">
                            <DateRangePicker
                                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
                                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                                keepOpenOnDateSelect={false}
                                startDatePlaceholderText="Check-in"
                                endDatePlaceholderText="Check-out"
                                showDefaultInputIcon
                                numberOfMonths={1}
                                openDirection="up"
                            />
                        </div>
                        <div className="appartcity_family">
                            <div className="appartcity_nbr_adult">
                                <div className="appartcity_nbr_adult-label"><FormattedMessage id="appartcityModal.adult" defaultMessage="Adults" /></div>
                                <div className="appartcity_nbr_adult-wrap">
                                    <button className="appartcity_nbr_adult-button" onClick={() => this.removeOne('adult')}><i className="fas fa-minus"></i></button>
                                    <div className="appartcity_nbr_adult-field">{this.state.nbr_adult}</div>
                                    <button className="appartcity_nbr_adult-button" onClick={() => this.addOne('adult')}><i className="fas fa-plus"></i></button>
                                </div>
                            </div>
                            <div className="appartcity_nbr_child">
                                <div className="appartcity_nbr_child-label"><FormattedMessage id="appartcityModal.children" defaultMessage="Children" /></div>
                                <div className="appartcity_nbr_child-wrap">
                                    <button className="appartcity_nbr_child-button" onClick={() => this.removeOne('child')}><i className="fas fa-minus"></i></button>
                                    <div className="appartcity_nbr_child-field">{this.state.nbr_child}</div>
                                    <button className="appartcity_nbr_child-button" onClick={() => this.addOne('child')}><i className="fas fa-plus"></i></button>
                                </div>
                            </div>
                            <div>
                                {listChild}
                            </div>
                        </div>
                        <div className="input appartcity_langue">
                            <FormattedMessage id="appartcityModal.langue" defaultMessage="Speaking language" >
                                {msg => (<TextField
                                    id="outlined-select-currency"
                                    select
                                    label={msg}
                                    className="appartcity_langue-list"
                                    value={this.state.langue}
                                    error={this.state.errorLangue}
                                    name="langue"
                                    onChange={this.handleChange}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    <MenuItem disabled value={"none"}><FormattedMessage id="appartcityModal.select.lang" defaultMessage="Select speaking language" /></MenuItem>
                                    <MenuItem value={"french"} classes={{ root: classes.root }}>French</MenuItem>
                                    <MenuItem value={"english"} classes={{ root: classes.root }}>English</MenuItem>
                                    <MenuItem value={"german"} classes={{ root: classes.root }}>German</MenuItem>
                                    <MenuItem value={"spanish"} classes={{ root: classes.root }}>Spanish</MenuItem>
                                    <MenuItem value={"chinese"} classes={{ root: classes.root }}>Chinese</MenuItem>
                                </TextField>)}
                            </FormattedMessage>
                        </div>
                        <div className="input appartcity_prestation">
                            <FormControl variant="outlined" className="appartcity_prestation-form">
                                <FormattedMessage id="appartcityModal.prestation" defaultMessage="Facilities" >
                                    {msg => (<InputLabel htmlFor="select-multiple-checkbox">{msg}</InputLabel>)}
                                </FormattedMessage>
                                <Select
                                    multiple
                                    value={this.state.presta}
                                    onChange={this.handleChange}
                                    input={<OutlinedInput labelWidth={0} id="select-multiple-checkbox" classes={{ notchedOutline: classes.input }} />}
                                    renderValue={selected => selected.join(', ')}
                                    name="presta"
                                    classes={{ root: classes.root }}
                                >
                                    <MenuItem disabled value={"none"}><FormattedMessage id="appartcityModal.select.facility" defaultMessage="Select facilities" /></MenuItem>
                                    <MenuItem value="Parking" classes={{ root: classes.root }}><Checkbox classes={{ root: classes.rootCheck }} checked={this.state.presta.indexOf("Parking") > -1} />Parking</MenuItem>
                                    <MenuItem value="Laundry" classes={{ root: classes.root }}><Checkbox classes={{ root: classes.rootCheck }} checked={this.state.presta.indexOf("Laundry") > -1} />Laundry</MenuItem>
                                    <MenuItem value="Fitness" classes={{ root: classes.root }}><Checkbox classes={{ root: classes.rootCheck }} checked={this.state.presta.indexOf("Fitness") > -1} />Fitness center</MenuItem>
                                    <MenuItem value="Seminar" classes={{ root: classes.root }}><Checkbox classes={{ root: classes.rootCheck }} checked={this.state.presta.indexOf("Seminar") > -1} />Seminar room</MenuItem>
                                    <MenuItem value="Breakfast" classes={{ root: classes.root }}><Checkbox classes={{ root: classes.rootCheck }} checked={this.state.presta.indexOf("Breakfast") > -1} />Breakfast</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="input appartcity_comment">
                            <FormattedMessage id="appartcityModal.comment" defaultMessage="Other particularity" >
                                {msg => (<TextField
                                    id="outlined-with-placeholder"
                                    label={msg}
                                    multiline
                                    rows="4"
                                    rowsMax="4"
                                    className="appartcity_comment-input"
                                    name="comment"
                                    margin="normal"
                                    variant="outlined"
                                    value={this.state.comment}
                                    InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                    onChange={this.handleChange}
                                />)}
                            </FormattedMessage>
                        </div>
                    </div>
                </div>
                <div className="footer" style={{ textAlign: 'center' }}>
                    <Button variant="contained" color="primary" className="button" onClick={this.handleClick}>
                        <img src={Issoyo_logo} alt='' style={{ width: '11%', marginRight: '5%' }}></img>
                        <FormattedMessage id="house.Appartcity.open" defaultMessage="Send" />
                    </Button>
                </div>
            </div>
        )
    }
}

AppartcityModal.propTypes = {
    auth: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(
    mapStateToProps, {}
)(withStyles(styles)(AppartcityModal));