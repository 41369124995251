import React, { Component } from 'react'
import ReactChartkick, { LineChart } from 'react-chartkick';
import Chart from 'chart.js'
import axios from "axios";
import TextField from '@material-ui/core/TextField';
import { currencyCodeList } from './currencyList.js';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';



ReactChartkick.addAdapter(Chart);
require('./TransferWiseSimulator.scss');

const style = {
    root: {
        color: "rgba(68, 76, 165, 0.85)",
        fontFamily: "'Nunito', sans-serif !important",
    },
    input: {
        borderColor: '#3e45948a !important',
    },
};

class TransferWiseSimulator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currency_source: 'EUR',
            currency_target: 'USD',
            amount_source: '100',
            amount_target: '',
            time_trans: '',
            cost_trans: '',
            data: {},
        }
        this.handleChange = this.handleChange.bind(this);
        this.changeData = this.changeData.bind(this);
    }

    handleChange(event) {
        const name = event.target.name;
        this.setState({
            [event.target.name]: event.target.value, 
        }, () => this.changeData(name));
    };


    changeData(name){
        axios({
            method: 'get',
            url: `https://issoyo:967a5f11-cd05-4bee-b2c2-28bb0eb7ec83@api.transferwise.com/v1/rates?source=${this.state.currency_source}&target=${this.state.currency_target}&from=2019-01-01T10:53:01&to=2019-02-01T14:53:01&group=day`,
            auth: {
                username: 'issoyo',
                password: '967a5f11-cd05-4bee-b2c2-28bb0eb7ec83'
            }
        }
        ).then(res => {
            var obj = this.state.data;
            res.data.map(d => obj = { ...obj, ...{ [d.time]: d.rate } });
            this.setState({ data: obj });
        })
        if (name === 'amount_target' || name === 'currency_target')
        {
            axios({
                method: 'get',
                url: `https://issoyo:967a5f11-cd05-4bee-b2c2-28bb0eb7ec83@api.transferwise.com/v1/quotes?source=${this.state.currency_source}&target=${this.state.currency_target}&targetAmount=${this.state.amount_target}&rateType=FIXED`,
                auth: {
                    username: 'issoyo',
                    password: '967a5f11-cd05-4bee-b2c2-28bb0eb7ec83'
                }
            }
            ).then(res => {
                const hours = Math.floor((new Date(res.data.deliveryEstimate).getTime() - new Date(res.data.createdTime).getTime()) / (1000 * 60 * 60));
                if (hours < 24) {
                    this.setState({amount_source: res.data.sourceAmount, time_trans: `${hours} hours`, cost_trans: res.data.fee}) 
                    return;
                }
                let days = hours / 24;
                let hourNumber = Math.floor((days - Math.floor(days)) * 100 * 24 / 100);
            if(hourNumber >= 1){
                this.setState({amount_source: res.data.sourceAmount, time_trans: `${Math.floor(days)} days ${hourNumber} hours`, cost_trans: res.data.fee})
            } else {
                this.setState({amount_source: res.data.sourceAmount, time_trans: `${Math.floor(days)} days`, cost_trans: res.data.fee})                
            }
            })
        } else if (name === 'amount_source' || name === 'currency_source') {
            axios({
                method: 'get',
                url: `https://issoyo:967a5f11-cd05-4bee-b2c2-28bb0eb7ec83@api.transferwise.com/v1/quotes?source=${this.state.currency_source}&target=${this.state.currency_target}&sourceAmount=${this.state.amount_source}&rateType=FIXED`,
                auth: {
                    username: 'issoyo',
                    password: '967a5f11-cd05-4bee-b2c2-28bb0eb7ec83'
                }
            }
            ).then(res => {
                const hours = Math.floor((new Date(res.data.deliveryEstimate).getTime() - new Date(res.data.createdTime).getTime()) / (1000 * 60 * 60));
                if (hours < 24) {
                    this.setState({amount_target: res.data.targetAmount, time_trans: `${hours} hours`, cost_trans: res.data.fee}) 
                    return;
                }
                let days = hours / 24;
                let hourNumber = Math.floor((days - Math.floor(days)) * 100 * 24 / 100);
            if(hourNumber >= 1){
                this.setState({amount_target: res.data.targetAmount, time_trans: `${Math.floor(days)} days ${hourNumber} hours`, cost_trans: res.data.fee})
            } else {
                this.setState({amount_target: res.data.targetAmount, time_trans: `${Math.floor(days)} days`, cost_trans: res.data.fee})                
            }
            })
        }
    }

    componentDidMount() {
        axios({
            method: 'get',
            url: `https://issoyo:967a5f11-cd05-4bee-b2c2-28bb0eb7ec83@api.transferwise.com/v1/rates?source=${this.state.currency_source}&target=${this.state.currency_target}&from=2019-01-01T10:53:01&to=2019-02-01T14:53:01&group=day`,
            auth: {
                username: 'issoyo',
                password: '967a5f11-cd05-4bee-b2c2-28bb0eb7ec83'
            }
        }
        ).then(res => {
            var obj = this.state.data;
            res.data.map(d => obj = { ...obj, ...{ [d.time]: d.rate } });
            this.setState({ data: obj });
        })

        axios({
            method: 'get',
            url: `https://issoyo:967a5f11-cd05-4bee-b2c2-28bb0eb7ec83@api.transferwise.com/v1/quotes?source=${this.state.currency_source}&target=${this.state.currency_target}&sourceAmount=${this.state.amount_source}&rateType=FIXED`,
            auth: {
                username: 'issoyo',
                password: '967a5f11-cd05-4bee-b2c2-28bb0eb7ec83'
            }
        }
        ).then(res => {
            const hours = Math.floor((new Date(res.data.deliveryEstimate).getTime() - new Date(res.data.createdTime).getTime()) / (1000 * 60 * 60));
            if (hours < 24) {
                this.setState({amount_target: res.data.targetAmount, time_trans: `${hours} hours`, cost_trans: res.data.fee}) 
                return;
            }
            let days = hours / 24;
            let hourNumber = Math.floor((days - Math.floor(days)) * 100 * 24 / 100);
        if(hourNumber >= 1){
            this.setState({amount_target: res.data.targetAmount, time_trans: `${Math.floor(days)} days ${hourNumber} hours`, cost_trans: res.data.fee})
        } else {
            this.setState({amount_target: res.data.targetAmount, time_trans: `${Math.floor(days)} days`, cost_trans: res.data.fee})                
        }
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <div className="form_currency">
                <FormattedMessage id="bank.TransfertWise.simulator.sourceAmount" defaultMessage="Source amount">
                {msg => (
                    <TextField
                        id="outlined-number"
                        label={msg}
                        value={this.state.amount_source}
                        onChange={this.handleChange}
                        name="amount_source"
                        type="tel"
                        className="amount_source"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{classes: {root: classes.root, notchedOutline: classes.input}}}
                        margin="normal"
                        variant="outlined"
                    />
                    )}
                    </FormattedMessage>
                <FormattedMessage id="bank.TransfertWise.simulator.sourceCurrency" defaultMessage="Currency source">
                { msg => (
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        name="currency_source"
                        label={msg}
                        className="select_currency"
                        value={this.state.currency_source}
                        onChange={this.handleChange}
                        margin="normal"
                        InputProps={{classes: {root: classes.root, notchedOutline: classes.input}}}
                        SelectProps={{
                            native: true,
                        }}
                        variant="outlined"
                    >
                        {currencyCodeList.map((option, key) => (
                            <option key={key} value={option.code}>
                                {option.code} - {option.name} 
                            </option>
                        ))}
                    </TextField>
                    )}
                    </FormattedMessage>
                <FormattedMessage id="bank.TransfertWise.simulator.targetAmount" defaultMessage="Target amount">
                {msg => (
                    <TextField
                        id="outlined-number"
                        label={msg}
                        value={this.state.amount_target}
                        onChange={this.handleChange}
                        name="amount_target"
                        type="tel"
                        className="amount_target"
                        InputProps={{classes: {root: classes.root, notchedOutline: classes.input}}}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin="normal"
                        variant="outlined"
                    />
                    )}
                    </FormattedMessage>
                <FormattedMessage id="bank.TransfertWise.simulator.targetCurrency" defaultMessage="Target currency">                    
                {msg => (
                    <TextField
                        id="outlined-select-currency-native"
                        select
                        name="currency_target"
                        label={msg}
                        className="select_currency"
                        value={this.state.currency}
                        onChange={this.handleChange}
                        InputProps={{classes: {root: classes.root, notchedOutline: classes.input}}}
                        SelectProps={{
                            native: true,
                        }}
                        margin="normal"
                        variant="outlined"
                    >
                        {currencyCodeList.map((option, key) => (
                            <option key={key} value={option.code}>
                                {option.code} - {option.name} 
                            </option>
                        ))}
                    </TextField>
                    )}
                </FormattedMessage>
                </div>
                <div>
                
                    <div className="text"><FormattedMessage id="bank.TransfertWise.simulator.fx" defaultMessage="Fx rate: "/> {this.state.currency_source}/{this.state.currency_target}: {this.state.data[Object.keys(this.state.data)[0]]}</div>
                    <div className="text"><FormattedMessage id="bank.TransfertWise.simulator.transactionCost" defaultMessage="Transaction cost: "/> {this.state.cost_trans} {this.state.currency_source}</div>
                    <div className="text"><FormattedMessage id="bank.TransfertWise.simulator.transactionTime" defaultMessage="Estimated transaction time: "/> {this.state.time_trans}</div>
                </div>
                <div>
                    <LineChart data={this.state.data} responsive colors={["rgba(68, 76, 165, 0.85)", "#3E4594"]} />
                </div>
            </React.Fragment>
        )
    }
}

export default withStyles(style)(TransferWiseSimulator);