import React, { Component } from 'react'
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';
import { connect } from "react-redux";
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TiqetAvailability from './TiqetAvailability.jsx';
import axios from 'axios';
import StarRatings from 'react-star-ratings';
import { trackPromise } from 'react-promise-tracker';


require('./Ticketing.scss');

const devises = [
    {
        name: 'EUR',
        symbole: '€',
        country: ['fr', 'be', 'de', 'es', 'gr', 'pt', 'at', 'cy', 'fi', 'bu', 'ee', 'lt', 'lu', 'nl', 'it', 'pl', 'li', 'ir', 'ho', 'cz']
    },
    {
        name: 'AUD',
        symbole: 'A$',
        country: ['au']
    },
    {
        name: 'CAD',
        symbole: 'C$',
        country: ['ca']
    },
    {
        name: 'CHF',
        symbole: 'Fr',
        country: ['ch']
    },
    {
        name: 'GBP',
        symbole: '£',
        country: ['gb']
    },
    {
        name: 'HKD',
        symbole: 'HK$',
        country: ['hk']
    },
    {
        name: 'HUF',
        symbole: 'Ft',
        country: ['hu']
    },
    {
        name: 'JPY',
        symbole: '¥',
        country: ['jp']
    },
    {
        name: 'NOK',
        symbole: 'kr',
        country: ['no']
    },
    {
        name: 'SEK',
        symbole: 'kr',
        country: ['se']
    },
    {
        name: 'SGD',
        symbole: 'S$',
        country: ['si']
    },
    {
        name: 'USD',
        symbole: '$',
        country: ['us']
    }
]

const styles = {
    root: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    rootCurrency: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
        fontSize: '0.6rem',
    },
    selectMenu: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    input: {
        borderColor: '#3e45948a !important',
    },
};

class TicketingUnitedKingdom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            widgetWeight: "410",
            widgetHeight: "3900",
            idEvent: "none",
            displayAvailable: "none",
            activityList: [{ name: '', productId: '' }],
            tiqetLanguage: 'en',
            deviseSelected: [],
            deviseSelect: '',
            tiqetWidgetDisplay: 'none',
            tiqetWidgetDiscovery: null,
            cityID: "0",
            cityList: [],
            test: null,
            cardComponent: null,
            lowprice: false,
            displaySort: 'none',
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDevise = this.handleChangeDevise.bind(this);
        this.handleChangeCity = this.handleChangeCity.bind(this);
        this.cardConstruct = this.cardConstruct.bind(this);
        this.onClickSort = this.onClickSort.bind(this);

    }

    componentWillMount() {
        if (window.matchMedia("(min-width: 436px)").matches) {
            /* The viewport is at least 400 pixels wide */
            this.setState({ widgetWeight: "410", widgetHeight: "3900" });
        } else {
            /* The viewport is less than 400 pixels wide */
            this.setState({ widgetWeight: "220", widgetHeight: "3900" });
        }
    }

    componentDidMount() {
        var tmp = this.props.service.ticketList.cities;
        tmp.sort(function (a, b) {
            a = a.name.toLowerCase();
            b = b.name.toLowerCase();
            return a < b ? -1 : a > b ? 1 : 0;
        });
        var location = this.props.auth.user.location.toLowerCase();
        var devise = devises.filter(function (data) {
            if (data.country.indexOf(location) > -1) {
                return data;
            }
        })
        if (devise.length > 0) {
            this.setState({ deviseSelected: devise[0], deviseSelect: devise[0].name })
        } else {
            this.setState({
                deviseSelect: 'EUR', deviseSelected: {
                    name: 'EUR',
                    symbole: '€',
                    country: ['fr', 'be', 'de', 'es', 'gr', 'pt', 'at', 'cy', 'fi', 'bu', 'ee', 'lt', 'lu', 'nl', 'it', 'pl', 'li', 'ir', 'ho', 'cz']
                },
            })
        }
        this.setState({ internetList: tmp, lowprice: true });
        if (this.props.language === "fr-FR") {
            this.setState({ tiqetLanguage: 'fr', cityList: this.props.service.ticketList.cities })
        } else {
            this.setState({ tiqetLanguage: 'en', cityList: this.props.service.ticketList.cities })
        }
    }

    cardConstruct(list) {
        var devise = this.state.deviseSelected
        var tmp = <div style={{color: 'rgba(68, 76, 165, 0.85)'}}>
            <FormattedMessage id="ticketing.no.activities" defaultMessage="No activities available in this city"/></div>
        if (list.length > 0) {
            var tmp = list.map((data, key) => (
                <div onClick={() => window.open(data.product_url, "_blank")} className="tiqet_card" key={key}>
                    <div className="tiqet_card_picture">
                        <img className="tiqet_card_picture-img" src={data.images[0].medium} alt=''></img>
                    </div>
                    <div className="tiqet_card_desc">
                        <div className="tiqet_card_title">
                            {data.title}
                        </div>
                        <div className="tiqet_card_rate">
                            <StarRatings
                                rating={data.ratings.average === null ? 0 : data.ratings.average}
                                starRatedColor="yellow"
                                numberOfStars={5}
                                name='rating'
                                starDimension="15px"
                                starSpacing='1px'
                            /> ({data.ratings.total} Votes)
                    </div>
                        <div className="tiqet_card_price">
                            <span>{data.price} {devise.symbole}</span>
                        </div>
                    </div>
                </div>
            ))
            this.setState({displaySort: 'block'})
        } else {
            this.setState({displaySort: 'none'})
        }
        return tmp;
    }

    handleChangeCity(event) {
        console.log(this.state.deviseSelected.name);
        this.setState({ [event.target.name]: event.target.value, tiqetWidgetClass: 'tiqetWidget', tiqetWidgetDisplay: "none" }, () =>
            trackPromise(
                axios.get('/api/service/gettiqetproduct', { params: { devise: this.state.deviseSelected.name, city_id: event.target.value, lang: this.props.language } }).then(res => {
                    this.setState({
                        [event.target.name]: event.target.value,
                        activityList: res.data,
                        cardComponent: this.cardConstruct(res.data),
                        displayAvailable: "none",
                        tiqetWidgetDisplay: "unset",
                        tiqetWidgetClass: 'tiqetWidget-appear',
                        idEvent: "none",
                    })
                })))
    }

    handleChangeDevise(event) {
        var devise = devises.filter((function (data) {
            if (data.name.indexOf(event.target.value) > -1) {
                return data;
            }
        }))
        if (this.state.cityID === "0") {
            this.setState({ deviseSelected: devise[0], deviseSelect: devise[0].name })
        } else {
            this.setState({ deviseSelected: devise[0], deviseSelect: devise[0].name, tiqetWidgetClass: 'tiqetWidget', tiqetWidgetDisplay: "none" }, () =>
                trackPromise(
                    axios.get('/api/service/gettiqetproduct', { params: { devise: this.state.deviseSelected.name, city_id: this.state.cityID, lang: this.props.language } }).then(res => {
                        this.setState({
                            [event.target.name]: event.target.value,
                            activityList: res.data,
                            cardComponent: this.cardConstruct(res.data),
                            displayAvailable: "none",
                            tiqetWidgetDisplay: "unset",
                            tiqetWidgetClass: 'tiqetWidget-appear',
                            idEvent: "none",
                        })
                    }))
            );
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value, displayAvailable: "unset",
        });
    }

    onClickSort() {
        var tmp = this.state.activityList;
        if (this.state.lowprice === false) {
            tmp.sort((a, b) => parseInt(a.price, 10) - parseInt(b.price, 10));
            this.setState({ activityList: tmp, lowprice: true, cardComponent: this.cardConstruct(tmp) });
        } else {
            tmp.sort((a, b) => parseInt(b.price, 10) - parseInt(a.price, 10));
            this.setState({ activityList: tmp, lowprice: false, cardComponent: this.cardConstruct(tmp) });
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <div className="wrap_select_currency">
                    <div className="select_currency">
                        <FormattedMessage id="ticketing.currency" defaultMessage="Currency">
                            {msg => (<TextField
                                id="outlined-select-currency"
                                select
                                label={msg}
                                className="list search_city_list"
                                value={this.state.deviseSelect}
                                name="deviseSelected"
                                onChange={this.handleChangeDevise}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                InputProps={{ classes: { root: classes.rootCurrency } }}
                                margin="normal"
                            >
                                <MenuItem value='0' disabled classes={{ root: classes.root }}><FormattedMessage id="tiqet.select.currency" defaultMessage="Select currency" /></MenuItem>
                                {devises.map((data, key) => (
                                    <MenuItem key={key} value={data.name} classes={{ root: classes.root }}>{data.name} / {data.symbole}</MenuItem>
                                ))}
                            </TextField>
                            )}
                        </FormattedMessage>
                    </div>
                </div>
                <div className="scrolltest" style={{ textAlign: "center" }}>
                    <div className="select search_city">
                        <FormattedMessage id="ticketing.city">
                            {msg => (<TextField
                                id="outlined-select-currency"
                                select
                                label={msg}
                                className="list search_city_list"
                                value={this.state.cityID}
                                name="cityID"
                                onChange={this.handleChangeCity}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                margin="normal"
                                variant="outlined"
                            >
                                <MenuItem value='0' disabled classes={{ root: classes.root }}><FormattedMessage id="tiqet.select" defaultMessage="Select city" /></MenuItem>
                                {this.state.cityList.map((data, key) => (
                                    <MenuItem key={key} value={data.id} classes={{ root: classes.root }}>{data.name}</MenuItem>
                                ))}
                            </TextField>
                            )}
                        </FormattedMessage>
                    </div>
                    <div style={{ display: this.state.tiqetWidgetDisplay }}>
                        <div className="disponibility_mobile" style={{display: this.state.displaySort}}>
                            <div className="title_ticket"><FormattedMessage id="ticketing.tiqet.title" defaultMessage="Select your date and book now" /></div>
                            <TextField
                                id="outlined-select-currency"
                                select
                                label="Activity"
                                className="tiqet_activity-list"
                                value={this.state.idEvent}
                                name="idEvent"
                                onChange={this.handleChange}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                style={{ marginBottom: '24px' }}
                                InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                margin="normal"
                                variant="outlined"
                            >
                                <MenuItem disabled value={"none"}><FormattedMessage id="ticketing.tiqet.select" defaultMessage="Select your activity" /></MenuItem>
                                {
                                    this.state.activityList.map((data, key) => (
                                        <MenuItem value={data.id} key={key} classes={{ root: classes.root }}>{data.title}</MenuItem>
                                    ))
                                }
                            </TextField>
                            <div style={{ display: this.state.displayAvailable }} >
                                <TiqetAvailability idEvent={this.state.idEvent} devise={this.state.deviseSelected} language={this.state.tiqetLanguage} />
                            </div>
                        </div>
                        <div className="tiqet_card_container">
                            <div className="telecom_sort_price" onClick={this.onClickSort} style={{ textAlign: 'right', display: this.state.displaySort}}>
                                <FormattedMessage id="telecom.sort" /> <i className="fas fa-sort" ></i>
                            </div>
                            <div className="card_container">
                                {this.state.cardComponent}
                            </div>
                        </div>
                        <div className="disponibility" style={{display: this.state.displaySort}}>
                            <div className="title_ticket"><FormattedMessage id="ticketing.tiqet.title" defaultMessage="Select your date and book now" /></div>
                            <TextField
                                id="outlined-select-currency"
                                select
                                label="Activity"
                                className="tiqet_activity-list"
                                value={this.state.idEvent}
                                name="idEvent"
                                onChange={this.handleChange}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                style={{ marginBottom: '24px' }}
                                InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                margin="normal"
                                variant="outlined"
                            >
                                <MenuItem disabled value={"none"}><FormattedMessage id="ticketing.tiqet.select" defaultMessage="Select your activity" /></MenuItem>
                                {
                                    this.state.activityList.map((data, key) => (
                                        <MenuItem value={data.id} key={key} classes={{ root: classes.root }}>{data.title}</MenuItem>
                                    ))
                                }
                            </TextField>
                            <div style={{ display: this.state.displayAvailable }} >
                                <TiqetAvailability idEvent={this.state.idEvent} devise={this.state.deviseSelected} language={this.state.tiqetLanguage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

TicketingUnitedKingdom.propTypes = {
    auth: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    service: state.services,
});

export default connect(
    mapStateToProps,
    {}
)(withStyles(styles)(TicketingUnitedKingdom));