import React from 'react';
import { FormattedMessage } from 'react-intl';
import icon_good from "../../../assets/img/icon_good.svg";
import youtube_img_load from "../../../assets/img/img_load_video_issoyo_home_w365.jpg";
import imagedesktop_section3 from "../../../assets/img/imagedesktop_section3@2x.webp";

require('./Home.scss');

export class Sectiont extends React.Component {
  
	componentDidMount(){
    	var youtube = document.querySelectorAll( ".youtube" );
    								
    	for (var i = 0; i < youtube.length; i++) {
    		var source = youtube_img_load;
    		
    		var image = new Image();
    			image.src = source;
    			image.addEventListener( "load", function() {
    				youtube[ i ].appendChild( image );
    			}( i ) );
    	
    			youtube[i].addEventListener( "click", function() {
    
    				var iframe = document.createElement( "iframe" );
    
    						iframe.setAttribute( "frameborder", "0" );
    						iframe.setAttribute( "allowfullscreen", "allowfullscreen" );
    						iframe.setAttribute( "src", "https://www.youtube.com/embed/"+ this.dataset.embed +"?autoplay=1&mute=1&enablejsapi=1&allow=autoplay");
    						this.innerHTML = "";
    						this.appendChild( iframe );
    			} );	
    	};
  }
	
  render() {
    return (
      <section className="section section-3">
        <div className="col-md-12 content-image">
          <div className="title">
            <div className="why-issoyo">
              <FormattedMessage id="home.titleWhyIssoyo" defaultMessage="Why Issoyo?aa" />
            </div>
          </div>
          <div className="text">
            <div className="select-all-the-servi">
              <FormattedMessage id="home.textWhyIssoyo" defaultMessage="Choisissez vos services et vous êtes mis directement en relation avec le meilleur prestataire." />
            </div>
          </div>
        </div>
        <div className="col-md-12 row align-items-center no-border-padding">
          <div className="col-md-7 no-border-padding">
            <img id="computerLg" className="image-center" src={imagedesktop_section3} alt="" />
            <div className="youtubePlayer">
              <div id="my-youtube-player-lg">

								<div class="wrapper dk">
									<div class="youtube" data-embed="XLKuLKvFHJI"  style={{ paddingTop: '63%'}}>
										<div class="play-button"></div>
									</div>
								</div>
								
              </div></div>
          </div>
          <div className="col-md-5" style={{ marginTop: '5%' }}>
            <div className="col-md-12 benefits no-border-padding">
              <div className="col-md-12 row no-border-padding">
                <div className="col-md-3">
                  <img className="pull-right oval" src={icon_good} alt="" />
                </div>
                <div className="col-md-9">
                  <div className="pull-left subtitle">
                    <div className="full-online-service">
                      <FormattedMessage id="home.titleFullOnlineService" defaultMessage="100% online 200% human" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 text no-border-padding">
                <div className="manage-all-your-serv">
                  <FormattedMessage id="home.textFullOnlineService" defaultMessage="Find all your services providers in one single plaform and get assistance from our awesome team (Messenger, Whatsapp, email, ...)" />
                </div>
              </div>
            </div>
            <div className="col-md-12 benefits no-border-padding">
              <div className="col-md-12 row no-border-padding">
                <div className="col-md-3">
                  <img className="pull-right oval" src={icon_good} alt="" />
                </div>
                <div className="col-md-9">
                  <div className="pull-left subtitle">
                    <div className="easy-and-friendly">
                      <FormattedMessage id="home.titleEasyFriendly" defaultMessage="Selected suppliers" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 text no-border-padding">
                <div className="we-have-selected-the">
                  <FormattedMessage id="home.textEasyFriendly" defaultMessage="We have selected the best in class suppliers with a dedicated offer to expats to save your time and make your life easier" />
                </div>
              </div>
            </div>
            <div className="col-md-12 benefits no-border-padding">
              <div className="col-md-12 row no-border-padding">
                <div className="col-md-3">
                  <img className="pull-right oval" src={icon_good} alt="" />
                </div>
                <div className="col-md-9">
                  <div className="pull-left subtitle">
                    <div className="easy-and-friendly">
                      <FormattedMessage id="home.titleFreeOffer" defaultMessage="Easy and free" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 text no-border-padding">
                <div className="we-have-selected-the">
                  <FormattedMessage id="home.textFreeOffer" defaultMessage="Issoyo is fully transparent and free for users, we are only paid by our partners" />
                </div>
              </div>
            </div>
            <div className="col-md-12 benefits no-border-padding">
              <div className="col-md-12 row no-border-padding">
                <div className="col-md-3">
                  <img className="pull-right oval" src={icon_good} alt="" />
                </div>
                <div className="col-md-9">
                  <div className="pull-left subtitle">
                    <div className="connect-with-communi">
                      <FormattedMessage id="home.titleConnectCommunity" defaultMessage="Connect with community" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 text no-border-padding">
                <div className="meet-new-friends-sh">
                  <FormattedMessage id="home.textConnectCommunity" defaultMessage="Meet new friends, share best practices and good tips thanks to our community and events" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12" />
      </section>
    )
  }
}
