import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ReactPhoneInput from 'react-phone-input-2';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
import { updateInfo } from '../../../actions/authActions'


require("./Information.scss");

const currencies = [
    {
        value: 'male',
        label: 'Male',
    },
    {
        value: 'female',
        label: 'Female',
    },
];


class Information extends React.Component {
    constructor() {
        super();
        this.state = {
            firstname: '',
            nickname: '',
            name: '',
            title: '',
            phone: '',
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            firstname: this.props.auth.user.firstname,
            nickname: this.props.auth.user.nickname,
            name: this.props.auth.user.name,
            title: this.props.auth.user.title,
            phone: this.props.auth.user.phone
        });
    }

    componentWillReceiveProps(nextProps) {
        this.props.history.push('/profile');
    }

    handleClick() {
        const reqData = { change: this.state, user: this.props.auth.user }
        this.props.updateInfo(reqData);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    render() {
        return (
            <React.Fragment>
                <div className="Information">
                <div className="information_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                    <div className="col-12 title"><div><FormattedMessage id="settings.information.title" defautlMessage="My information"/></div></div>
                    <div className="div-input-phone">
                    <FormattedMessage id="settings.information.phone" defautlMessage="Your new phone number ...">
                    { msg => (
                        <ReactPhoneInput
                            defaultCountry='fr'
                            regions={['europe', 'america', 'asia', 'africa']}
                            preferredCountries={['fr', 'us', 'kr', 'gb', 'cn']}
                            disableAreaCodes
                            enableSearchField
                            className="input-phone"
                            placeholder= {msg}
                            value={this.state.phone}
                            onChange={this.handleChange}
                        />
                    )}
                    </FormattedMessage>
                    </div>
                    <div className="div-input-name">
                    <FormattedMessage id="settings.information.name" defautlMessage="Your new name ...">
                    { msg => (
                        <TextField
                            id="outlined-with-placeholder"
                            label="New Name"
                            placeholder={msg}
                            className="input-name"
                            margin="normal"
                            name='name'
                            variant="outlined"
                            value={this.state.name}
                            onChange={this.handleChange}
                        />
                        )}
                    </FormattedMessage>
                    </div>
                    <div className="div-input-first-name">
                    <FormattedMessage id="settings.information.firstname" defautlMessage="Your new first name ...">
                    { msg => (
                        <TextField
                            id="outlined-with-placeholder"
                            label="New first name"
                            placeholder={msg}
                            className="input-first-name"
                            margin="normal"
                            variant="outlined"
                            name='firstname'
                            value={this.state.firstname}
                            onChange={this.handleChange}
                        />
                        )}
                    </FormattedMessage>
                    </div>
                    <div className="div-input-nickname">
                    <FormattedMessage id="settings.information.nickname" defautlMessage="Your new nickname ...">
                    { msg => (
                        <TextField
                            id="outlined-with-placeholder"
                            label="New nickname"
                            placeholder={msg}
                            className="input-nickname"
                            margin="normal"
                            variant="outlined"
                            name='nickname'
                            value={this.state.nickname}
                            onChange={this.handleChange}
                        />
                        )}
                    </FormattedMessage>
                    </div>
                    <div className="div-input-gender">
                        <TextField
                            id="outlined-select-currency-native"
                            select
                            label="Gender"
                            className="select-gender"
                            name='title'
                            value={this.state.title}
                            onChange={this.handleChange}
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: "test",
                                },
                            }}
                            margin="normal"
                            variant="outlined"
                        >
                            {currencies.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </TextField>
                    </div>
                    <div className='row' style={{ justifyContent: 'space-between' }}>
                        <div className="col send">
                            <Button variant="contained" color="primary" className="button" onClick={this.handleClick}>
                                <FormattedMessage id="settings.information.update" defaultMessage="Update"/>
                        </Button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
Information.propTypes = {
    updateInfo: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(
    mapStateToProps, { updateInfo }
)(Information)

