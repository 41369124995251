import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import netvox_logo from '../../../../../assets/img/providers/ISSOYO_netvox_logo.png';

require('./Moto.scss')

class Moto extends Component {
    render() {
        return (
            <div>
                <div className="moto_description">
                    <div className="moto_provider_logo">
                        <img className="moto_provider-img" src={netvox_logo} alt='' />
                    </div>
                    <div className="text moto_title">
                        <FormattedMessage id="netvox.moto.advantage" defaultMessage="Benefits" />
                    </div>
                    <ul className="text">
                        <li><FormattedMessage id="netvox.moto.choice" defaultMessage="The choice among several scooters and motorcycles products" /></li>
                        <li><FormattedMessage id="netvox.moto.options" defaultMessage="Many options to meet all needs (wintering, driver's warranty, helmet, clothing and accessories ...)" /></li>
                        <li><FormattedMessage id="netvox.moto.rnpp" defaultMessage="RNPP and claim history accepted" /></li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Moto;
