import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import PropTypes from "prop-types";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { phoneList } from './phone.js';
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';
import { SliderRail, Handle, Track } from '../Internet/SliderComponent';
import FormLabel from '@material-ui/core/FormLabel';

const styles = {
    root: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    selectMenu: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    input: {
        borderColor: '#3e45948a !important',
    },
};

const sliderStyle = {  // Give the slider some width
    position: 'relative',
    width: 200,
    height: 60,
    marginTop: 35,
}


class Mobile extends Component {
    constructor() {
        super();
        this.state = {
            mobile_list: null,
            result_list: null,
            brand_list: null,
            phone_brand: 'none',
            phone_brandList: phoneList.map(data => data.name),
            phone_colorList: [],
            phone_color: 'none',
            phone_sizeList: [],
            phone_size: 'none',
            lowprice: false,
            list_phone: null,
            minUp: 32,
            maxUp: 256,
            min: 10,
            max: 40,
        }
        this.handleChangePhone = this.handleChangePhone.bind(this);
        this.getPhone = this.getPhone.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.onClickSort = this.onClickSort.bind(this);
    }

    onUpdate = update => {
        this.setState({ minUp: update[0], maxUp: update[1] })
    }

    onChange = values => {
        const min = values[0]
        const max = values[1]
        var list = this.state.mobile_list.filter(function (data) {
            var size = data.title.substring(data.title.indexOf('Go') - 4, data.title.indexOf('Go') +1)
            console.log(size)
            console.log(parseInt(size))
            if (min <= parseInt(size) && max >= parseInt(size)) {
                return true;
            }
            return false;
        })
        this.setState({ start: values[0], end: values[1], result_list: list })
    };

    componentWillMount() {
        var tmpset = Array.from(new Set(this.props.service.mobileList.map(data => data.trademark)));
        this.setState({
            mobile_list: this.props.service.mobileList,
            result_list: this.props.service.mobileList,
            brand_list: tmpset
        })
    }

    handleClick(link) {
        if (!this.props.auth.isAuthenticated) {
            this.props.handleClickAccount();
        } else {
            window.open(link, "_blank");
        }
    }

    onClickSort() {
        var tmp = this.state.result_list;
        if (this.state.lowprice === false) {
            tmp.sort((a, b) => parseInt(a.price, 10) - parseInt(b.price, 10));
            this.setState({ result_list: tmp, lowprice: true });
        } else {
            tmp.sort((a, b) => parseInt(b.price, 10) - parseInt(a.price, 10));
            this.setState({ result_list: tmp, lowprice: false });
        }
    }

    getPhone() {
        const brand = this.state.phone_brand.toLowerCase();
        var color = '';
        var size = '';
        if (this.state.phone_size !== 'none') {
            size = this.state.phone_size.toLowerCase();
        }
        if (this.state.phone_color !== 'none') {
            color = this.state.phone_color.toLowerCase();
        }
        const res = this.props.service.mobileList.filter(function (data) {
            if (data.trademark.toLowerCase().includes(brand)) {
                if (data.title.toLowerCase().includes(color)) {
                    if (data.title.toLowerCase().includes(size)) {
                        return true
                    }
                }
            }
            return false;
        })
        if (size === '') {
            const size_list = this.state.phone_sizeList.filter(function (data) {
                var size_available = data.toLowerCase();
                var size_result = res.filter(function (data) {
                    if (data.title.toLowerCase().includes(brand)) {
                        if (data.title.toLowerCase().includes(size_available)) {
                            return true;
                        }
                    }
                    return false;
                })
                if (size_result.length > 0) {
                    return true;
                }
                return false;
            })
            this.setState({ phone_sizeList: size_list });
        }
        if (size !== '' && color === '') {
            const color_list = this.state.phone_colorList.filter(function (data) {
                var color_available = data.toLowerCase();
                var test = res.filter(function (data) {
                    if (data.title.toLowerCase().includes(size)) {
                        if (data.title.toLowerCase().includes(color_available)) {
                            return true
                        }
                    }
                    return false;
                })
                if (test.length > 0) {
                    return true;
                }
                return false;
            })
            this.setState({ phone_colorList: color_list })
        }
        this.setState({ mobile_list: res, result_list: res });
    }

    handleChangePhone(event) {
        var brand = phoneList.filter(({ name }) => name === event.target.value)
        if (this.state.phone_brand !== 'none') {
            brand = phoneList.filter(({ name }) => name === this.state.phone_brand)
        }
        var size = brand[0].size;
        if (event.target.name === 'phone_brand') {
            if (event.target.value === 'none') {
                this.setState({
                    [event.target.name]: event.target.value,
                    phone_selected: 'none',
                    phone_color: 'none',
                    phone_size: 'none',
                    disableColor: true,
                    disableSize: true,
                })
            } else {
                this.setState({
                    [event.target.name]: event.target.value,
                    isDisablePhone: false,
                    phone_colorList: brand[0].color,
                    phone_sizeList: size,
                    phone_selected: 'none',
                    phone_size: 'none',
                    phone_color: 'none',
                    disableColor: true,
                    disableSize: false,
                }, () => this.getPhone());
            }
        } else if (event.target.name === 'phone_size') {
            this.setState({
                [event.target.name]: event.target.value,
                isDisablePhone: false,
                phone_colorList: brand[0].color,
                phone_selected: 'none',
                phone_color: 'none',
                disableColor: false,
            }, () => this.getPhone())
        } else {
            this.setState({
                [event.target.name]: event.target.value,
                isDisablePhone: false,
                phone_selected: 'none',
            }, () => this.getPhone())
        }
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                <div className='row linemob' style={{alignItems: "baseline"}}>
                    <div className="col-sm-6 telecom_phone_brand">
                        <FormattedMessage id="telecom.linemob.brand">
                            {msg => (<TextField
                                id="outlined-select-currency"
                                select
                                label={msg}
                                className="telecom_phone_brand-list"
                                value={this.state.phone_brand}
                                name="phone_brand"
                                onChange={this.handleChangePhone}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                margin="normal"
                                variant="outlined"
                            >
                                <MenuItem value='none' disabled classes={{ root: classes.root }}><FormattedMessage id="telecom.linemob.select.brand" /></MenuItem>
                                {this.state.brand_list.map((data, key) => (
                                    <MenuItem classes={{ root: classes.root }} key={key} value={data} onClick={this.handleChangePhone}>
                                        {data}
                                    </MenuItem>
                                ))}
                            </TextField>
                            )}
                        </FormattedMessage>
                    </div>
                    <div className="internet_price">
                        <FormLabel component="legend" classes={{ root: classes.rootLabel }}><FormattedMessage id="mobile.choose.size" defaultMessage="Choose size:" /> {this.state.minUp} Go - {this.state.maxUp} Go </FormLabel>
                        <Slider
                            mode={2}
                            step={1}
                            domain={[32, 256]}
                            rootStyle={sliderStyle}
                            onChange={this.onChange}
                            onUpdate={this.onUpdate}
                            values={[32, 256]}
                            className="internet_slider"
                        >
                            <Rail>
                                {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
                            </Rail>
                            <Handles>
                                {({ handles, getHandleProps }) => (
                                    <div className="slider-handles">
                                        {handles.map(handle => (
                                            <Handle
                                                key={handle.id}
                                                handle={handle}
                                                domain={[32, 256]}
                                                getHandleProps={getHandleProps}
                                            />
                                        ))}
                                    </div>
                                )}
                            </Handles>
                            <Tracks left={false} right={false}>
                                {({ tracks, getTrackProps }) => (
                                    <div className="slider-tracks">
                                        {tracks.map(({ id, source, target }) => (
                                            <Track
                                                key={id}
                                                source={source}
                                                target={target}
                                                getTrackProps={getTrackProps}
                                            />
                                        ))}
                                    </div>
                                )}
                            </Tracks>
                        </Slider>
                    </div>
                </div>
                <div>
                <div className="telecom_sort_price" style={{cursor: "pointer"}}onClick={this.onClickSort}>
                    <FormattedMessage id="telecom.sort" /> <i className="fas fa-sort" ></i>
                </div>
                </div>
                <div className="container_flex">
                    {this.state.result_list.map((data, key) => (
                        <div className='wrapper_mobile' key={key}>
                            <div style={{ cursor: 'pointer' }} className="card_bg" onClick={() => this.handleClick(data.url)} >
                                <div className="card_title">{data.trademark}</div>
                                <div className="card_info" style={{ height: "225px" }}>
                                    <div className="card_img"><img className="card-img" style={{ width: '100px' }} src={data.image} alt=''></img></div>
                                    <div className="card_desc">
                                        <div className="card_desc-text">{data.title.length < 100 ? data.title : data.title.substring(0, 100) + "..."}</div>
                                    </div>
                                </div>
                                <div className="card_container_footer">
                                    <div className="card_price">
                                        <div className="card-price">{data.price} €</div>
                                    </div>
                                    <div className="card_desc-button">
                                        <Button variant="contained" color="primary" className="button" onClick={() => this.handleClick(data.url)}>
                                            <FormattedMessage id="telecom.get" />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        )
    }
}

Mobile.propTypes = {
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    service: state.services,
});

export default connect(
    mapStateToProps, {}
)(withStyles(styles)(Mobile));

/*
<div className='row linemob'>
                    <div className="col-sm-6 telecom_phone_brand">
                        <FormattedMessage id="telecom.linemob.brand" defaultMessage="brand">
                            {msg => (<TextField
                                id="outlined-select-currency"
                                select
                                label={msg}
                                className="telecom_phone_brand-list"
                                value={this.state.phone_brand}
                                name="phone_brand"
                                onChange={this.handleChangePhone}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                margin="normal"
                                variant="outlined"
                            >
                                <MenuItem value='none' disabled classes={{ root: classes.root }}><FormattedMessage id="telecom.linemob.select.brand" defaultMessage="Select brand" /></MenuItem>
                                {this.state.phone_brandList.map((data, key) => (
                                    <MenuItem classes={{ root: classes.root }} key={key} value={data}>
                                        {data}
                                    </MenuItem>
                                ))}
                            </TextField>
                            )}
                        </FormattedMessage>
                    </div>
                </div>
                */