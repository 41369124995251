import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import HouseModal from './HouseModal.jsx';
import Modal from 'react-responsive-modal';
import { apartcity } from '../../../../assets/img/providers/apartcity/apartcity1.jpg';
import { expatimmo } from '../../../../assets/img/providers/ExpatimmoLogo.jpg';
import { issoyoimmo } from '../../../../assets/img/providers/issoyo_logo.png';


require('./House.scss')

class House extends Component {
    constructor() {
        super();
        this.state = {
            open: false,
            index: 0,
            name: '',
            type: 'temp',
            listService: [],
            buttonTemp: 'button-cat-select',
            buttonPerm: 'button-cat',
            style: {
                modal: {
                    'maxWidth': '800px',
                    'width': '100%',
                    'color': '#3f2751',
                    'zIndex': '10000 !important',

                },
            },
        }
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.handleClickAccount = this.handleClickAccount.bind(this);
        this.handleClickType = this.handleClickType.bind(this);

    }

    componentWillMount() {
        if (window.matchMedia("(min-width: 436px)").matches) {
            /* The viewport is at least 400 pixels wide */
            this.setState({
                style: {
                    modal: {
                        'maxWidth': '800px',
                        'width': '100%',
                        'color': '#3f2751',
                        'zIndex': '10000 !important',
                    },
                }
            });
        } else {
            /* The viewport is less than 400 pixels wide */
            this.setState({
                style: {
                    modal: {
                        'height': '100%',
                        'left': '0',
                        'position': 'fixed',
                        'top': '0',
                        'width': '100%',
                        'color': '#3f2751',
                        'zIndex': '10000 !important',

                    },
                }
            });
        }
        var result = this.props.service.serviceList.filter(function(data){if (data.cat.includes('temp')){ return true} return false})
        const list = this.props.service.serviceList.filter(function (data) {
            if (result.length > 0) {
                if (data.cat.includes('temp')) {
                    return true;
                } else {
                    return false;
                }
            } else {
                if (data.cat.includes('perm')) {
                    return true;
                } else {
                    return false
                }
            }
        })
        console.log(list)
        this.setState({ listService: list });
    }

    componentDidMount() {
        if (!this.props.auth.user.location){
            this.props.history.push('/services');
        }
        window.scrollTo(0, 0)
    }

    onOpenModal(value, name) {
        this.setState({ index: value, open: true, name: name });
    };

    onCloseModal() {
        this.setState({ open: false });
    };

    handleClickAccount(link) {
        if (!this.props.auth.isAuthenticated) {
            this.props.history.push("/login");
        } else {
            window.open(link, "_blank");
        }
    }

    handleClickType(value) {
        const list = this.props.service.serviceList.filter(function (data) {
            if (data.cat.includes(value)) {
                return true;
            } else {
                return false;
            }
        })
        if (value === 'temp') {
            this.setState({ type: value, listService: list, buttonTemp: 'button-cat-select', buttonPerm: 'button-cat' });
        } else {
            this.setState({ type: value, listService: list, buttonPerm: 'button-cat-select', buttonTemp: 'button-cat' });
        }
    }

    render() {
        const listServices = this.state.listService.map((data, key) => {
            var backposition = "cover";
            var height = '560px'
            if (data.name === 'Expat.immo') {
                backposition = 'contain'
            }
            if (data.name === 'Issoyoimmo') {
                height= '490px'
            }
            return (
                    <div className="div-card" key={key}>
                        <Card className={"card " + data.name} key={key} style={{ height: height  }} >
                            <CardActionArea onClick={() => this.onOpenModal(this.props.service.serviceList.findIndex(x => x.name === data.name), data.name)}>
                                <CardMedia
                                    style={{ borderBottom: '#a5a3a359 solid 1px', backgroundSize: backposition }}
                                    className={"media " + data.name}
                                    image={data.image}
                                    title={data.name}
                                />
                                <CardContent>
                                    <Typography className={"title-card " + data.name} gutterBottom variant="h5" component="h2" style={{ textAlign: 'center', fontWeight: '700' }}>
                                        <FormattedMessage id={`service.house.${data.name}.title`} />
                                    </Typography>
                                    <Typography className={"subtitle-card " + data.name} gutterBottom variant="h5" component="h2" style={{ textAlign: 'center', fontWeight: '700' }}>
                                        <FormattedMessage id={`service.house.${data.name}.subtitle`} />
                                    </Typography>
                                    <Typography className="text" component="p">
                                        <FormattedMessage id={`service.house.${data.name}.description`} values={{ br: <br /> }} />
                                    </Typography>
                                    <br />
                                    <Typography className="text" component="p" style={{ fontWeight: '500' }}>
                                        <FormattedMessage id={`service.house.${data.name}.description2`} />
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions style={{ justifyContent: 'space-evenly', backgroundColor: '#80808014', position: 'absolute', bottom: '0', width: '100%' }}>
                                <Button size="small" color="primary" style={{ backgroundColor: '#60239a', color: 'white' }} onClick={() => this.onOpenModal(this.props.service.serviceList.findIndex(x => x.name === data.name), data.name)} >
                                    <FormattedMessage id="service.house.apply" defaultMessage="Get a quotation" />
                                </Button>
                            </CardActions>
                        </Card>
                    </div>
            )
        });
        return (
            <div className="House">
                <div className="house_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                <div className="col-12 title">
                    <div>
                        <FormattedMessage id="service.house.live" defaultMessage="Where to stay?" />
                    </div>
                </div>
                <div className='wrap_button'>
                    { this.props.service.serviceList.filter(function(data){ if (data.cat.indexOf('temp') > -1) { return true} return false}).length > 0 ?
                        <div className={`${this.state.buttonTemp} temporary`}>
                            <div onClick={() => this.handleClickType('temp')}><FormattedMessage id="service.house.temporary" defaultMessage="Temporary residence" /></div>
                        </div> : null
                    }
                    { this.props.service.serviceList.filter(function(data){ if (data.cat.indexOf('perm') > -1) { return true} return false}).length > 0 ?
                        <div className={`${this.state.buttonPerm} permanent`}>
                            <div onClick={() => this.handleClickType('perm')}><FormattedMessage id="service.house.permanent" defaultMessage="Real estate agent" /></div>
                        </div> : null
                    }
                    </div>
                <div className='row' style={{ marginBottom: "4%" }}>
                    {listServices}
                </div>
                <Modal open={this.state.open} onClose={this.onCloseModal} center styles={this.state.style} >
                    <HouseModal onCloseModal={this.onCloseModal} index={this.state.index} language={this.props.language} name={this.state.name} handleClickAccount={link => this.handleClickAccount(link)}></HouseModal>
                </Modal>
            </div>
        )
    }
}

House.propTypes = {
    auth: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    service: state.services,
});

export default connect(
    mapStateToProps, {}
)(House);