import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
import Issoyo_logo from '../../../assets/img/fav_icon32@2x.png';


class ButtonToService extends Component {
    constructor() {
        super();
        this.state = {
            children: null,
        }
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick() {
        if (this.props.issoyoService){
            this.props.onOpenModal()
        }
        else if (!this.props.auth.isAuthenticated) {
            window.open("/login", "_self")
        } else {
            window.open(this.props.afiliateLink, "_blank");
        }
    }


    render() {
        var children = null
        if (this.props.children){
            children = this.props.children
        } else {
            children = <FormattedMessage id="service.transport.carRent.apply" />
        }
        return (
            <div className="insurances_buy" style={{ textAlign: 'center' }}>
                <Button variant="contained" color="primary" className="insurances_buy-button" onClick={this.handleClick}>
                    <img src={Issoyo_logo} alt='' style={{ width: '11%', marginRight: '5%' }}></img>
                    {children}
                </Button>
            </div>
        )
    }
}

ButtonToService.propTypes = {
    auth: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    service: state.services,
});

ButtonToService.defaultProps = {
    issoyoService: false,
};

export default connect(
    mapStateToProps,
    {}
)(ButtonToService);