import React from 'react';
import PropTypes from 'prop-types';
import Logo_horizontal_flat from "../../../assets/img/Logo_horizontal_flat.svg";
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menuapp from './Menu.jsx'
import Menu from 'react-burger-menu/lib/menus/slide'
import Modal from 'react-responsive-modal';
import { logoutUser, loginUserGoogle } from '../../../actions/authActions';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';

require('./Navbar.scss');

var noScroll = require('no-scroll');

const stylesLogout = {
    modal: {
        'maxWidth': '500px',
        'width': '100%',
        'color': '#3f2751',
    }
};

const styles = {
    root: {
        zIndex: '15 !important',
    },
};

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarOpen: false,
            sidebarWidth: "50%",
            openLogout: false,
            openSignin: false,
        };
        this.langChange = this.langChange.bind(this);
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.onClickLogOut = this.onClickLogOut.bind(this);
        this.onOpenModalLogout = this.onOpenModalLogout.bind(this);
        this.onCloseModalLogout = this.onCloseModalLogout.bind(this);
        this.onOpenModalSignin = this.onOpenModalSignin.bind(this);
        this.onCloseModalSignin = this.onCloseModalSignin.bind(this);
        this.changePage = this.changePage.bind(this);
    }

    componentWillMount() {
        if (window.matchMedia("(min-width: 500px)").matches) {
            /* The viewport is at least 400 pixels wide */
            this.setState({ sidebarWidth: '30%' });
        } else {
            /* The viewport is less than 400 pixels wide */
            this.setState({ sidebarWidth: '50%' });
        }
    }

    onClickLogOut() {
        this.onCloseModalLogout();
        this.props.logoutUser();
    }

    changePage = (path) => {
        this.props.history.push(path);
        this.setState({ openSignin: false });
    }

    onOpenModalLogout() {
        this.setState({ openLogout: true });
    };

    onCloseModalLogout() {
        this.setState({ openLogout: false });
    };
    onOpenModalSignin() {
        this.setState({ openSignin: true });
    };

    onCloseModalSignin() {
        this.setState({ openSignin: false });
    };

    onSetSidebarOpen(open) {
        this.setState({ sidebarOpen: open });
    }


    langChange(value) {
        var lang = value;
        this.props.handleLanguage(lang);
    }

    closeMenu() {
        this.setState({ sidebarOpen: false });
    }

    render() {
        if (this.state.sidebarOpen) {
            noScroll.on();
        } else {
            noScroll.off();
        }
        const { classes } = this.props
        return (
            <div className="my-navbar" >
                <AppBar position="fixed" classes={{ root: classes.root }} style={{ backgroundColor: "white", boxShadow: "0 6px 18px 0 #E3E5F6" }}>
                    <div className="row bar">
                        <div className="menu-wrap">
                            <Menu customBurgerIcon={<IconButton style={{ backgroundColor: 'transparent' }} className="menubutton" onClick={() => this.onSetSidebarOpen(true)} color="inherit" aria-label="Menu"><MenuIcon /></IconButton>} isOpen={false}>
                                <Menuapp history={this.props.history} onOpenModalLogout={() => this.onOpenModalLogout} onOpenModalSignin={() => this.onOpenModalSignin} langChange={(langValue) => this.langChange(langValue)} />
                            </Menu>
                        </div>
                        <img onClick={() => this.props.history.push('/')} src={Logo_horizontal_flat} className="logo-horizontal-flat" alt='' style={{ cursor: 'pointer' }}></img>
                        <div className="menu_language-wrap"><span style={{cursor:"pointer", color: "#240b38"}} onClick={() => this.langChange('fr-FR')}>FR</span> | <span style={{cursor:"pointer"}} onClick={() => this.langChange('en-US')}>EN</span></div>
                    </div>
                </AppBar>
                <Modal open={this.state.openLogout} onClose={this.onCloseModalLogout} center styles={stylesLogout} blockScroll>
                    <p className='message-disconnect'><FormattedMessage id="menu.modal.disconnect" defaultMessage="Are you sure you want to disconnect ?" /></p>
                    <Button color="primary" className="button" onClick={this.onClickLogOut} style={{ fontFamily: 'Nunito', backgroundColor: 'transparent', color: 'rgba(68, 76, 165, 0.85)' }} >
                        <FormattedMessage id="menu.modal.disconnect.yes" defaultMessage="Yes" />
                    </Button>
                    <Button color="primary" className="button" onClick={this.onCloseModalLogout} style={{ fontFamily: 'Nunito', backgroundColor: 'transparent', color: 'rgba(68, 76, 165, 0.85)' }} >
                        <FormattedMessage id="menu.modal.disconnect.no" defaultMessage="No" />
                    </Button>
                </Modal>
            </div>
        )
    }
}

Navbar.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    handleLanguage: PropTypes.func,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logoutUser, loginUserGoogle })(withStyles(styles)(Navbar));