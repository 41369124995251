import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
import Gaz from './Gaz/Gaz.jsx';
import Electricity from './Electricity/Electricity.jsx';
import ScrollUpButton from "react-scroll-up-button";
import Issoyo_logo from '../../../../assets/img/fav_icon32@2x.png';
import Issoyo_logo_blanc from '../../../../assets/img/issoyo_blanc.png';
import Issoyoservice from '../Issoyoservice/Issoyoservice.jsx';
import { countryAvailable } from '../listCountry.js';
import ButtonToService from '../../ButtonToService/ButtonToService';

require("./Energy.scss");

class Energy extends Component {
    constructor() {
        super();
        this.state = {
            carName: 'car_hide',
            colorGaz: { color: '#7f3fce', backgroundColor: 'white' },
            colorElectricity: { color: '#7f3fce', backgroundColor: 'white' },
            colorIssoyoenergy: { color: '#7f3fce', backgroundColor: 'white' },
            logo: Issoyo_logo,
            insuranceComp: null,
            afiliate_link: "",
            children_button: null,
            issoyo: false,
            user_location: '',
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleClickAccount = this.handleClickAccount.bind(this);
    }

    componentWillReceiveProps() {
        this.setState({
            carName: 'car_hide',
            colorGaz: { color: '#7f3fce', backgroundColor: 'white' },
            colorElectricity: { color: '#7f3fce', backgroundColor: 'white' },
            colorIssoyoenergy: { color: '#7f3fce', backgroundColor: 'white' },
            insuranceComp: null,
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        if (!this.props.auth.user.location) {
            this.props.history.push('/services');
        } else {
            this.setState({ user_location: this.props.auth.user.location})
        }
    }

    handleClickAccount() {
        this.props.history.push('/login');
    }

    handleClick(name) {
        if (name === 'gaz') {
            this.setState({
                insuranceComp: <Gaz handleClickAccount={() => this.handleClickAccount()} language={this.props.language} />,
                colorGaz: { color: "white", backgroundColor: '#7f3fce' },
                colorElectricity: { color: '#7f3fce', backgroundColor: 'white' },
                colorIssoyoenergy: { color: '#7f3fce', backgroundColor: 'white' },
                logo: Issoyo_logo,
                afiliate_link: "https://tracking.publicidees.com/clic.php?promoid=160832&progid=1934&partid=57757",
                children_button:  null,
                issoyo: false,
            });
        } else if (name === 'electricity') {
            this.setState({
                insuranceComp: <Electricity handleClickAccount={() => this.handleClickAccount()} />,
                colorGaz: { color: '#7f3fce', backgroundColor: 'white' },
                colorElectricity: { color: "white", backgroundColor: '#7f3fce' },
                colorIssoyoenergy: { color: '#7f3fce', backgroundColor: 'white' },
                logo: Issoyo_logo,
                afiliate_link: "https://tracking.publicidees.com/clic.php?promoid=160831&progid=1934&partid=57757",
                children_button:  null,
                issoyo: false,
            });
        }
        else if (name === 'issoyoenergy') {
            this.setState({
                insuranceComp: <Issoyoservice serviceName="energy" handleClickAccount={() => this.handleClickAccount()} language={this.state.language} />,
                colorGaz: { color: '#7f3fce', backgroundColor: 'white' },
                colorElectricity: { color: '#7f3fce', backgroundColor: 'white' },
                colorIssoyoenergy: { color: 'white', backgroundColor: '#7f3fce' },
                logo: Issoyo_logo_blanc,
                afiliate_link: "",
                children_button:  null,
                issoyo: true,
            });
        }
    }

    render() {
        if (countryAvailable[0].energy.indexOf(this.state.user_location.toLowerCase()) < 0) {
            return (
                <div className="Energy">
                    <div className="bank_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                    <div className="text" style={{ textAlign: "center" }}> <Issoyoservice serviceName="energy" handleClickAccount={() => this.handleClickAccount()} language={this.state.language} /></div></div>
            )
        } else {
        return (
            <div className="Energy">
                <div className="bank_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                <div className="title">
                    <FormattedMessage id="service.energy.title" defaultMessage="You need an energy provider ?" />
                </div>
                <div className="container_type">
                    <div className="container_flex_type">
                        <div className="flex_item_type">
                            <div className="box" onClick={() => this.handleClick('gaz')} style={this.state.colorGaz}>
                                <div className="col-12 no-border-padding">
                                    <div className="logo-box"><i className="fal fa-fire"></i></div>
                                </div>
                                <div className="col-12 no-border-padding"><div className="text text-box" style={{ color: this.state.colorGaz.color }}>
                                    <FormattedMessage id="service.enery.gaz" defaultMessage="Gaz" />
                                </div></div>
                            </div>
                        </div>
                        <div className="flex_item_type">
                            <div className="box" onClick={() => this.handleClick('electricity')} style={this.state.colorElectricity}>
                                <div className="col-12 no-border-padding">
                                    <div className="logo-box"><i className="fal fa-bolt"></i></div>
                                </div>
                                <div className="col-12 no-border-padding"><div className="text text-box" style={{ color: this.state.colorElectricity.color }}>
                                    <FormattedMessage id="service.energy.electricity" defaultMessage="Electricity" />
                                </div></div>
                            </div>
                        </div>
                        <div className="flex_item_type">
                        <div className="box" onClick={() => this.handleClick('issoyoenergy')} style={this.state.colorIssoyoenergy}>
                            <div className="col-12 no-border-padding">
                                <div className="logo-box"><img src={this.state.logo} style={{width: '40px'}}></img></div>
                            </div>
                            <div className="col-12 no-border-padding"><div className="text text-box" style={{ color: this.state.colorIssoyoenergy.color }}>
                                Issoyo
                            </div></div>
                        </div>
                        </div>
                    </div>
                </div>
                <div>
                    {this.state.insuranceComp}
                </div>
                { this.state.insuranceComp && this.state.issoyo === false? <ButtonToService afiliateLink={this.state.afiliate_link}>
                        {this.state.children_button}
                    </ButtonToService> : null }
                <ScrollUpButton ShowAtPosition={70} />
            </div>
        )
        }
    } 
}

Energy.propTypes = {
    auth: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(
    mapStateToProps,
    {}
)(Energy);