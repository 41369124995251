import React, { Component } from 'react'
import PriceEN from '../../../../../assets/document/n26_price_en.pdf';
import SupportID from '../../../../../assets/document/n26_supported_id.pdf';
import { Document, Page, pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

require('./N26ModalPdf.scss');

class N26ModalPdf extends Component {
    constructor(){
        super();
        this.state ={
            numPages: null,
            pageNumber: 1,
            pdfname: '',
            page: <Page pageIndex={1}/>
        }
        this.pagearr = [];
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
        this.changePage = this.changePage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
    }
    
    componentDidMount(){
      if (this.props.document === 'price'){
        this.setState({pdfname: PriceEN})
      } else {
        this.setState({pdfname: SupportID})
      }
    }
      onDocumentLoadSuccess(document) {
        const { numPages } = document;
        this.setState({numPages, pageNumber: 1 });
        
        for (var i = 0; i < numPages; i++) {
          this.pagearr.push(i);
        }
        let tmp = this.pagearr.map((num, key) => <Page pageIndex={num} key={key}/>)
        this.setState({page: tmp});
      };
    
      changePage(offset) {
          this.setState(prevState => ({pageNumber: prevState.pageNumber + offset }));
      }

      previousPage() {
         this.changePage(-1);
      }
      
      nextPage() { 
          this.changePage(1);
      }

  render() {
    return (
      <div className="cont-mod" style={{width: '100%'}}>
        <Document
          file={this.state.pdfname}
          onLoadSuccess={this.onDocumentLoadSuccess}
        >
        {this.state.page}
        </Document>

      </div>
    )
  }
}

export default (N26ModalPdf);
