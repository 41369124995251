import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Collapsible from 'react-collapsible';
import ReactPlayer from 'react-player'
import QontoLogo from '../../../../../assets/img/providers/qonto.jpg';
import Modal from 'react-responsive-modal';
import Button from '@material-ui/core/Button';
import Issoyo_logo from '../../../../../assets/img/fav_icon32@2x.png';
import qonto_card from '../../../../../assets/img/providers/qonto/qonto_card.jpg';
import qonto_app from '../../../../../assets/img/providers/qonto/qonto_app.jpg';
import qonto_card_app from '../../../../../assets/img/providers/qonto/qonto_card_app.jpg';
import qonto_user from '../../../../../assets/img/providers/qonto/qonto_user.jpg';
import qonto_team from '../../../../../assets/img/providers/qonto/qonto_team.jpg';
require('./QontoModal.scss');

class QontoModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            position: '0',
            littlepos: '',
            open: false,
            style: {
                modal: {
                    'maxWidth': '700px',
                    'width': '100%',
                    'color': '#3f2751',
                    'textAlign': 'justify'
                },
            },
            youtubeHW: 'https://www.youtube.com/watch?v=Bw93kDKmhlY',
        }
        this.handleClick = this.handleClick.bind(this);
        this._onReady = this._onReady.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.handleClickAccount = this.handleClickAccount.bind(this);
    }

    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }

    onOpenModal() {
        this.setState({ open: true });
    };

    onCloseModal() {
        this.setState({ open: false });
    };

    handleClick(position) {
        if (this.state.position === position) {
            this.setState({ position: "0" })
        } else {
            this.setState({ position: position });
        }
    };

    handleClickAccount() {
        this.props.handleClickAccount("https://www.awin1.com/cread.php?awinmid=12534&awinaffid=525947&p=");
    };

    render() {
        const { open } = this.state;
        let img = <div><img src={qonto_card} alt='' style={{width:'100%'}}></img>
            <img src={qonto_app} alt='' style={{width:'100%'}}></img>
            <img src={qonto_card_app} alt='' style={{width:'100%'}}></img>
            <img src={qonto_user} alt='' style={{width:'100%'}}></img>
            <img src={qonto_team} alt='' style={{width:'100%'}}></img></div>
        return (
            <div className="service-bank-transfertWise">
                <div className="header">
                    <div className="header_logo"><img className="header-pic" src={QontoLogo} alt=''></img></div>
                    <div className="header-title"><b><FormattedMessage id="service.bank.Qonto .title" defaultMessage="La néobanque des entreprises et des indépendants." /></b></div>
                </div>
                <div className="cont-mod">
                    <FormattedMessage id="bank.Qonto.benefits" defaultMessage="Benefits / Features">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "1")} handleTriggerClick={this.handleClick} accordionPosition="1">
                                <ul className='text'>
                                    <li><FormattedMessage id="bank.Qonto.reason1" defaultMessage="Ouverture de compte en ligne en moins de 10 minutes" /></li>
                                    <li><FormattedMessage id="bank.Qonto.reason2" defaultMessage="Faster and clearer than a bank." /></li>
                                    <li><FormattedMessage id="bank.Qonto.reason3" defaultMessage="A professional account for companies from 1 to 200 team members." /></li>
                                    <li><FormattedMessage id="bank.Qonto.reason4" defaultMessage="Tools and integrations designed to make your life much easier." /></li>
                                    <li><FormattedMessage id="bank.Qonto.reason5" defaultMessage="Already approved by +40K companies." /></li>
                                    <li><FormattedMessage id="bank.Qonto.reason6" defaultMessage="A customer services very reactive and available from Monday to Saturday 9am - 7pm." /></li>
                                </ul>
                            </Collapsible>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="bank.Qonto.pricing" defaultMessage="Pricing">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "2")} handleTriggerClick={this.handleClick} accordionPosition="2">
                                <div className="card-standard">
                                    <div className="title-description">Freelancers - Solo</div>
                                    <div className="text-price"><span className="price-black">€9.00</span><span><FormattedMessage id="bank.Qonto.price.month" defaultMessage="/month" /></span></div>
                                    <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.Qonto.freelance.price1" defaultMessage=" 1 user max" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.Qonto.freelance.price2" defaultMessage=" 1 Mastercard debit cards" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i><FormattedMessage id="bank.Qonto.freelance.price3" defaultMessage=" 20 incoming/outgoing transfers or SEPA Direct Debits / month" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i><FormattedMessage id="bank.Qonto.freelance.price4" defaultMessage=" Unlimited card transactions" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i><FormattedMessage id="bank.Qonto.freelance.price5" defaultMessage=" Check deposits in option" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i><FormattedMessage id="bank.Qonto.freelance.price6" defaultMessage=" Cards with business insurance guarantees" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i><FormattedMessage id="bank.Qonto.freelance.price7" defaultMessage=" Unlimited transactions and receipts history" /></p>
                                </div>
                                <div className="card-standard">
                                    <div className="title-description">Entreprises - Standard</div>
                                    <div className="text-price"><span className="price-black">€29.00</span><span><FormattedMessage id="bank.Qonto.price.month" defaultMessage="/month" /></span></div>
                                    <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.Qonto.standard.price1" defaultMessage=" 5 user max" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.Qonto.standard.price2" defaultMessage=" 2 physical Mastercard debit cards" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i><FormattedMessage id="bank.Qonto.standard.price3" defaultMessage=" 2 virtual Mastercard debit cards" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i><FormattedMessage id="bank.Qonto.standard.price4" defaultMessage=" 100 incoming/outgoing transfers or SEPA Direct Debit / month" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i><FormattedMessage id="bank.Qonto.standard.price5" defaultMessage=" 3 check deposits / month" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i><FormattedMessage id="bank.Qonto.standard.price6" defaultMessage=" Transaction categorization (custom labels)" /></p>
                                    <p className="text-description-no"><FormattedMessage id="bank.Qonto.standard.price7" defaultMessage=" Employees transfer request option (submitted for approval to admins)" /></p>
                                    <p className="text-description-no"><FormattedMessage id="bank.Qonto.standard.price8" defaultMessage=" Automatic VAT detection on invoices" /></p>
                                    <p className="text-description-no"><FormattedMessage id="bank.Qonto.standard.price9" defaultMessage=" Dedicated VIP support" /></p>

                                </div>
                                <div className="card-standard">
                                    <div className="title-description">Entreprises - Premium</div>
                                    <div className="text-price"><span className="price-black">€99.00</span><span><FormattedMessage id="bank.Qonto.price.month" defaultMessage="/month" /></span></div>
                                    <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.Qonto.premium.price1" defaultMessage=" 15 user max" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.Qonto.premium.price2" defaultMessage=" 5 physical Mastercard debit cards" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i><FormattedMessage id="bank.Qonto.premium.price3" defaultMessage=" 5 virtual Mastercard debit cards" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i><FormattedMessage id="bank.Qonto.premium.price4" defaultMessage=" 500 incoming/outgoing transfers or SEPA Direct Debit / month" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i><FormattedMessage id="bank.Qonto.premium.price5" defaultMessage=" 10 check deposits / month" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i><FormattedMessage id="bank.Qonto.premium.price6" defaultMessage=" Transaction categorization (custom labels)" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i><FormattedMessage id="bank.Qonto.premium.price7" defaultMessage=" Employees transfer request option (submitted for approval to admins)" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i><FormattedMessage id="bank.Qonto.premium.price8" defaultMessage=" Automatic VAT detection on invoices" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i><FormattedMessage id="bank.Qonto.premium.price9" defaultMessage=" Dedicated VIP support" /></p>
                                </div>
                                <div className="card-corporate">
                                    <div className="title-description">Entreprises - Corporate</div>
                                    <div className="text-price"><span className="price-black">€299.00</span><span><FormattedMessage id="bank.Qonto.price.month" defaultMessage="/month" /></span></div>
                                    <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.Qonto.corporate.price1" defaultMessage=" unlimited number of users" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i> <FormattedMessage id="bank.Qonto.corporate.price2" defaultMessage=" 15 physical Mastercard debit cards" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i><FormattedMessage id="bank.Qonto.corporate.price3" defaultMessage=" 15 virtual Mastercard debit cards" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i><FormattedMessage id="bank.Qonto.corporate.price4" defaultMessage=" 2000 incoming/outgoing transfers or SEPA Direct Debit / month" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i><FormattedMessage id="bank.Qonto.corporate.price5" defaultMessage=" 10 check deposits / month" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i><FormattedMessage id="bank.Qonto.corporate.price6" defaultMessage=" Transaction categorization (custom labels)" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i><FormattedMessage id="bank.Qonto.corporate.price7" defaultMessage=" Employees transfer request option (submitted for approval to admins)" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i><FormattedMessage id="bank.Qonto.corporate.price8" defaultMessage=" Automatic VAT detection on invoices" /></p>
                                    <p className="text-description"><i className="fas fa-check"></i><FormattedMessage id="bank.Qonto.corporate.price9" defaultMessage=" Dedicated VIP support" /></p>
                                </div>
                            </Collapsible>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="bank.Qonto.condition" defaultMessage="Account holder requirement">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "3")} handleTriggerClick={this.handleClick} accordionPosition="3">
                                <div className='text'><FormattedMessage id="bank.Qonto.conditionTitle" defaultMessage="The Qonto current account is available to French companies formed with the following legal entities :" /></div>
                                <ul className='text'>
                                    <li><FormattedMessage id="bank.Qonto.condition1" defaultMessage="SA ('Société Anonyme')" /></li>
                                    <li><FormattedMessage id="bank.Qonto.condition2" defaultMessage="SAS ('Société par Actions Simplifiée')" /><div onClick={() => this.onOpenModal("openCountry")} style={{ cursor: 'pointer', textDecoration: 'underline' }}><FormattedMessage id="bank.Qonto.condition2.1" defaultMessage="countries." /></div></li>
                                    <li><FormattedMessage id="bank.Qonto.condition3" defaultMessage="SASU ('Société par Actions Simplifiée Unipersonnelle')" /></li>
                                    <li><FormattedMessage id="bank.Qonto.condition4" defaultMessage="SARL ('Société à Responsabilité Limitée')" /></li>
                                    <li><FormattedMessage id="bank.Qonto.condition5" defaultMessage="SC & SCI ('Société civile')" /></li>
                                    <li><FormattedMessage id="bank.Qonto.condition6" defaultMessage="EURL ('Entreprise Unipersonnelle à Responsabilité Limitée')" /></li>
                                    <li><FormattedMessage id="bank.Qonto.condition7" defaultMessage="Affaire Personnelle Profession Libérale (Independent occupations)" /></li>
                                    <li><FormattedMessage id="bank.Qonto.condition8" defaultMessage="Micro-Entreprise (Sole Traders or Micro-Company)" /></li>
                                    <li><FormattedMessage id="bank.Qonto.condition9" defaultMessage="Association (registered with a siren number)" /></li>
                                    <li><FormattedMessage id="bank.Qonto.condition10" defaultMessage="Holding (an organisation who owns control of a small group of other companies)" /></li>
                                </ul>
                                <p><FormattedMessage id="bank.Qonto.condition11" defaultMessage="If your company is not registered yet, please note that you can open a Qonto account and proceed with your initial capital deposit with us (except for SCI). As a reminder, self-entrepreneurs, as well as micro-entreprises, are not required to go through a deposit capital process, since these organizations are already registered in their own name" /></p>
                                <p><FormattedMessage id="bank.Qonto.condition12" defaultMessage="Foreign companies which are registered in France are allowed to open a Qonto account as well!" /></p>
                                <p><FormattedMessage id="bank.Qonto.condition13" defaultMessage="Existing French holding companies, both active or passive, are fully allowed to open a Qonto account 100% online, in a few minutes." /></p>
                            </Collapsible>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="bank.Qonto.howItWork" defaultMessage="How does Qonto work ?">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "4")} handleTriggerClick={this.handleClick} accordionPosition="4">
                                <ReactPlayer
                                    width='100%'
                                    controls
                                    config={{
                                        youtube: {
                                            playerVars: { showinfo: 1 }
                                        }
                                    }}
                                    url={this.state.youtubeHW}
                                />
                            </Collapsible>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="bank.Qonto.photo" defaultMessage="Photos">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "5")} handleTriggerClick={this.handleClick} accordionPosition="5">
                                {img}
                            </Collapsible>
                        )}
                    </FormattedMessage>
                </div>
                <div className="footer" style={{ textAlign: 'center' }}>
                    <Button variant="contained" color="primary" className="button" onClick={this.handleClickAccount}>
                        <img src={Issoyo_logo} alt='' style={{ width: '11%', marginRight: '5%' }}></img>
                        <FormattedMessage id="bank.open.account"></FormattedMessage>
                    </Button>
                </div>
                <Modal open={open} styles={this.state.style} onClose={this.onCloseModal} center blockScroll>
                    <div className='title' style={{ fontSize: '1.25rem', color: '#3E4594' }}>Reasons</div>
                    <ul className='text'>
                        <li><FormattedMessage id="bank.Qonto.reason1.2" defaultMessage="Money never crosses borders. When TransferWise is exchanging euros into pounds, euros are sent into the TransferWise euro account. And when TransferWise receive that, TransferWise send the equivalent amount in pounds from our pounds account." /></li>
                    </ul>
                </Modal>
            </div>
        )
    }
}

QontoModal.propTypes = {
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    service: state.services,
});

export default connect(
    mapStateToProps, {}
)(QontoModal);
