const regionList = [
    {
        'nord_est': ['besançon', 'colmar', 'metz', 'nancy', 'reims', 'strasbourg', 'troyes', 'verdun', 'dijon', 'nord-est', 'région nord-est'],
        'nord_ouest': ['brest', 'landerneau', 'roscoff', 'morlaix', 'douarnenez', 'carhaix', 'concarneau', 'quimperlé', 'lannion', 'dinan', 'loudéac', 'paimpol', 'rennes', 'saint-malo', 'lorient', 'auray', 'carnac', 'quiberon', 'pontivy', 'ploermel', 'nantes', 'saint-nazaire', 'angers', 'saumur', 'cholet', 'laval', 'le mans', 'la fleche', 'la roche-sur-yon', 'bourges', 'tours', 'orleans', 'caen', 'rouen', 'giverny', 'mont saint-michel', 'nord-ouest', 'région nord-ouest'],
        'haut_de_france': ['lille', 'calais', 'dunkerque', 'douai', 'amiens', 'saint-quentin', 'beauvais', 'compiègne', 'le touquet', 'berck', 'haut-de-france', 'région haut-de-france'],
        'ile_de_france': ['boulogne-billancourt', 'fontainebleau', 'maisons-laffitte', 'mantes-la-jolie', 'meaux', 'nogent-sur-marne', 'paris', 'poissy', 'provins', 'rambouillet', 'rueil-malmaison', 'saint-denis', 'saint-germain-en-laye', 'sceaux', 'versailles', 'vitry-sur-seine', 'auvers-sur-oise', 'barbizon', 'beaumont-sur-oise', 'bievres', 'blandy-les-tours', 'boissy-saint-leger', 'bougival', 'bourget', 'breteuil', 'champs-sur-marne', 'landon', 'malabry', 'chatou', 'clamart', 'conflans-sainte-honorine', 'courances', 'dampierre', 'disneyland paris', 'la defense', 'dourdan', 'ecouen', 'enghien-les-bains', 'etampes', 'evry', 'houdan', 'issy-les-moulineaux', 'jouarre', 'jouy-en-josas', 'larchant', "l'isle-adam", 'luzarches', 'maisons-alfort', 'marly-le-roi', 'marne-la-vallée', 'melun', 'meudon', 'milly-la-foret', "montfort-l'amaury", 'montmorency', 'montreyuil-sous-bois', 'moret-sur-loing', 'nemours', 'neuilly-sur-marne', 'ozoir-la-ferriere', 'chevreuse', 'vexin', 'gâtinais', 'monte-cristo', 'la roche-guyon', 'rosny-sur-seine', 'royaumont', 'saint-cloud', 'saint-ouen', 'saint-quentin-en-yvelines', 'sannois', 'sénart', 'sévres', 'thoiry', 'grand morin', 'juine', 'rémarde', 'vincennes'],
        'sud_ouest': ['agen', 'angouleme', 'arcachon', 'bayonne', 'biarritz', 'bordeaux', 'limoges', 'mont-de-marsan', 'pau', 'perigueux', 'royan', 'sarlat-la-canéda', 'albi', 'carcassonne', 'foix', 'lourdes', 'montauban', 'montpellier', 'perpignan', 'toulouse', 'la roche sur yon', 'sud-ouest', 'région sud-ouest'],
        'sud_est': ['ajaccio', 'avignon', 'bastia', 'cannes', 'digne-les-bains', 'gap', 'grenoble', 'lyon', 'marseille', 'nice', 'nîmes', 'toulons', 'valence', 'villeneuve-loubet', 'camargue', 'verdon', 'clermont-ferrand', 'ardèche', 'genève', 'aix-en-provence',  'sud-est', 'région sud-est', 'region sud-est'],
    },
]
export {regionList}