import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import { Helmet } from "react-helmet";
import ReactCountryFlag from "react-country-flag";
import icon_bank_service from "../../../assets/img/icon_bank_service.svg";
import icon_realstate_service from "../../../assets/img/icon_realstate_service.svg";
import icon_phone_service from "../../../assets/img/icon_phone_service.svg";
import icon_heart_service from "../../../assets/img/icon_heart_service.svg";
import icon_signal_service from "../../../assets/img/icon_signal_service.svg";
import Modal from 'react-responsive-modal';
import Countries from "../Countries/Countries";
import countryList from 'react-select-country-list';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getServiceList, getTicketList, getInternetList, getAnnonceList, getMobileList } from '../../../actions/serviceActions';
import { setLocation } from '../../../actions/authActions';
import { countryAvailable } from './listCountry.js';
import Loader from 'react-loader-spinner';

require("./Services.scss");

class Services extends React.Component {
    constructor() {
        super()
        this.countries = countryList().getData();
        this.state = {
            open: false,
            style: {
                modal: {
                    'maxWidth': '700px',
                    'width': '100%',
                    'color': '#3f2751',
                },
            },
            path: '',
            country: '',
            country_name: '',
            disableBank: "",
            disableHouse: "",
            disableTelecom: "",
            disableInsurances: "",
            disableInternet: "",
            disableTicketing: "",
            disableTransport: "",
            disableTelevision: "",
            disableEnergy: "",
            displayLoading: "none",
            displayService: 'unset',
        }
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.goToServices = this.goToServices.bind(this);
        this.changeCountry = this.changeCountry.bind(this);
        this.wishList = this.wishList.bind(this);
    }

    componentDidMount() {
        // If logged in and user navigates to Register page, should redirect them to dashboard
        if (!this.state.country && !this.props.auth.user.location) {
            this.onOpenModal()
        } else if (this.props.auth.user.location) {
            this.setState({ country: this.props.auth.user.location });
        }
        window.scrollTo(0, 0);
    }

    componentWillMount() {
        if (window.matchMedia("(min-width: 436px)").matches) {
            /* The viewport is at least 400 pixels wide */
            this.setState({
                style: {
                    modal: {
                        'maxWidth': '700px',
                        'width': '100%',
                        'color': '#3f2751',
                    },
                }
            });
        } else {
            /* The viewport is less than 400 pixels wide */
            this.setState({
                style: {
                    modal: {
                        'height': '100%',
                        'left': '0',
                        'position': 'fixed',
                        'top': '0',
                        'width': '100%',
                        'color': '#3f2751',
                    },
                }
            });
        }
        if (this.props.auth.user.location) {
            const location = this.props.auth.user.location
            var bank, house, telecom, insurances, internet, ticketing, transport, energy, television = "";
            var country_name = this.countries.find(function (data) {
                return data.value === location;
            })
            if (countryAvailable[0].bank.indexOf(this.props.auth.user.location.toLowerCase()) < 0) {
                bank = "service_disabled";
            }
            if (countryAvailable[0].telecom.indexOf(this.props.auth.user.location.toLowerCase()) < 0) {
                telecom = "service_disabled";
            }
            if (countryAvailable[0].insurances.indexOf(this.props.auth.user.location.toLowerCase()) < 0) {
                insurances = "service_disabled";
            }
            if (countryAvailable[0].internet.indexOf(this.props.auth.user.location.toLowerCase()) < 0) {
                internet = "service_disabled";
            }
            if (countryAvailable[0].ticketing.indexOf(this.props.auth.user.location.toLowerCase()) < 0) {
                ticketing = "service_disabled";
            }
            if (countryAvailable[0].transport.indexOf(this.props.auth.user.location.toLowerCase()) < 0) {
                transport = "service_disabled";
            }
            if (countryAvailable[0].house.indexOf(this.props.auth.user.location.toLowerCase()) < 0) {
                house = "service_disabled";
            }
            if (countryAvailable[0].energy.indexOf(this.props.auth.user.location.toLowerCase()) < 0) {
                energy = "service_disabled";
            }
            if (countryAvailable[0].television.indexOf(this.props.auth.user.location.toLowerCase()) < 0) {
                television = "service_disabled";
            }
            this.setState({
                country_name: country_name.label,
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.service.errors.status) {
            console.log('error');
        }
        else if (this.state.path !== '') {
            this.props.history.push(this.state.path);
        }
    };

    wishList() {
        this.props.history.push('/wishlist')
    }

    changeCountry(value, label) {
        var bank, house, telecom, insurances, internet, ticketing, transport, energy, television = "";
        if (countryAvailable[0].bank.indexOf(value.toLowerCase()) < 0) {
            bank = "service_disabled";
        }
        if (countryAvailable[0].telecom.indexOf(value.toLowerCase()) < 0) {
            telecom = "service_disabled";
        }
        if (countryAvailable[0].insurances.indexOf(value.toLowerCase()) < 0) {
            insurances = "service_disabled";
        }
        if (countryAvailable[0].internet.indexOf(value.toLowerCase()) < 0) {
            internet = "service_disabled";
        }
        if (countryAvailable[0].ticketing.indexOf(value.toLowerCase()) < 0) {
            ticketing = "service_disabled";
        }
        if (countryAvailable[0].transport.indexOf(value.toLowerCase()) < 0) {
            transport = "service_disabled";
        }
        if (countryAvailable[0].house.indexOf(value.toLowerCase()) < 0) {
            house = "service_disabled";
        }
        if (countryAvailable[0].energy.indexOf(value.toLowerCase()) < 0) {
            energy = "service_disabled";
        }
        if (countryAvailable[0].television.indexOf(value.toLowerCase()) < 0) {
            television = "service_disabled";
        }
        this.setState({
            country: value,
            country_name: label,

        }, () => { this.onCloseModal() });
        this.props.setLocation({ user: this.props.auth.user, location: value, country: label });
    }

    onOpenModal() {
        this.setState({ open: true });
    };

    onCloseModal() {
        if (this.state.country === '') {

        } else {
            this.setState({ open: false });
        }
    };

    goToServices(service) {
        this.setState({ path: `/${service}` })
        if (service === 'ticketing') {
            if (this.props.auth.user.location === 'FR') {
                this.setState({ displayService: 'none', displayLoading: 'flex' })
                this.props.getTicketList({ country: this.state.country, location: 'FR' });
            } else {
                this.setState({ displayService: 'none', displayLoading: 'flex' })
                this.props.getTicketList({ lang: this.props.language, country: this.state.country_name });
            }
        } else if (service === 'internet') {
            this.props.getInternetList({ name: 'box', country: this.state.country });
        } else if (service === 'annonce') {
            this.props.getAnnonceList({ country: this.state.country });
        } else if (service === 'telecom') {
            this.props.getMobileList({ country: this.state.country });
        } else {
            this.props.getServiceList({ country: this.state.country, service: service });
        }
    };

    render() {
        const { open } = this.state;
        if (this.props.auth.user.location === "SG" || this.props.auth.user.location === "HK") {
            return (
                <React.Fragment>
                    <Helmet>
                        <title>Issoyo - Services</title>
                        <meta name="description" content="Select your country and see all the services available" />
                    </Helmet>
                    <div className="Services">
                        <div className="signup_back"><i style={{ color: '#593e96' }} className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                        <div className="row" >
                            <div className="col-12 title">
                                <FormattedMessage id="services.title" defaultMessage="Services in ">{msg => (`${msg} ${this.state.country_name}`)}</FormattedMessage>
                                <Button color="primary" className="service_location-button" onClick={this.onOpenModal}>
                                    <ReactCountryFlag
                                        styleProps={{
                                            width: '29px',
                                            height: '22px',
                                            borderRadius: '15px',
                                            boxShadow: 'rgba(25, 23, 23, 0.13) 4px 2px 16px 1px',
                                        }}
                                        svg
                                        code={this.state.country}
                                        onClick={this.onOpenModal}
                                    />
                                    <i className="fas fa-angle-down" style={{ marginLeft: '3px' }}></i>
                                </Button>
                            </div>
                        </div>
                        <div className="card-deck row" style={{ justifyContent: 'space-evenly' }}>
                            <div className={`box ${this.state.disableBank}`} onClick={() => this.goToServices("bank")}>
                                <div className="col-12 no-border-padding">
                                    <img className="image-box" src={icon_bank_service} alt="Card cap" />
                                </div>
                                <div className="col-12 no-border-padding">
                                    <div className="text text-box">
                                        <FormattedMessage id="services.bank" defaultMessage="Banking" />
                                    </div>
                                </div>
                            </div>
                            <div className={`box ${this.state.disableHouse}`} onClick={() => this.goToServices("house")}>
                                <div className="col-12 no-border-padding">
                                    <img className="image-box" src={icon_realstate_service} alt="Card cap" />
                                </div>
                                <div className="col-12 no-border-padding">
                                    <div className="text text-box">
                                        <FormattedMessage id="services.house" defaultMessage="House Rental" />
                                    </div></div>
                            </div>
                            <div className={`box ${this.state.disableTelecom}`} onClick={() => this.goToServices("telecom")}>
                                <div className="col-12 no-border-padding">
                                    <img className="image-box" src={icon_phone_service} alt="Card cap" />
                                </div>
                                <div className="col-12 no-border-padding"><div className="text text-box">
                                    <FormattedMessage id="services.telecom" defaultMessage="Telecom" />
                                </div></div>
                            </div>
                            <div className={`box ${this.state.disableInsurances}`} onClick={() => this.goToServices("insurance")}>
                                <div className="col-12 no-border-padding">
                                    <img className="image-box" src={icon_heart_service} alt="Card cap" />
                                </div>
                                <div className="col-12 no-border-padding"><div className="text text-box">
                                    <FormattedMessage id="services.insurance" defaultMessage="Insurances" />
                                </div></div>
                            </div>
                            <div className={`box ${this.state.disableInternet}`} onClick={() => this.goToServices("internet")}>
                                <div className="col-12 no-border-padding">
                                    <img className="image-box" src={icon_signal_service} alt="Card cap" />
                                </div>
                                <div className="col-12 no-border-padding"><div className="text text-box">
                                    <FormattedMessage id="services.wifi" defaultMessage="Internet" />
                                </div></div>
                            </div>
                            <div className={`box ${this.state.disableTicketing}`} onClick={() => this.goToServices("ticketing")}>
                                <div className="col-12 no-border-padding">
                                    <div className="logo-box"><i className="fal fa-ticket-alt"></i></div>
                                </div>
                                <div className="col-12 no-border-padding"><div className="text text-box">
                                    <FormattedMessage id="services.ticketing" defaultMessage="Ticketing" />
                                </div></div>
                            </div>
                            <div className={`box ${this.state.disableEnergy}`} onClick={() => this.goToServices("energy")}>
                                <div className="col-12 no-border-padding">
                                    <div className="logo-box"><i className="fal fa-lightbulb-on"></i></div>
                                </div>
                                <div className="col-12 no-border-padding"><div className="text text-box">
                                    <FormattedMessage id="menu.energy" />
                                </div></div>
                            </div>
                            <div className={`box ${this.state.disableTransport}`} onClick={() => this.goToServices("transport")}>
                                <div className="col-12 no-border-padding">
                                    <div className="logo-box"><i className="fal fa-bus"></i></div>
                                </div>
                                <div className="col-12 no-border-padding"><div className="text text-box">
                                    <FormattedMessage id="service.transport" defaultMessage="Transport" />
                                </div></div>
                            </div>
                            <div className={`box ${this.state.disableTelevision}`} onClick={() => this.goToServices("television")}>
                                <div className="col-12 no-border-padding">
                                    <div className="logo-box"><i className="fal fa-tv-retro"></i></div>
                                </div>
                                <div className="col-12 no-border-padding"><div className="text text-box">
                                    <FormattedMessage id="service.tv" defaultMessage="TV" />
                                </div></div>
                            </div>
                            <div className="box" onClick={() => this.goToServices("corporate")}>
                                <div className="col-12 no-border-padding">
                                    <div className="logo-box"><i className="fal fa-suitcase"></i></div>
                                </div>
                                <div className="col-12 no-border-padding"><div className="text text-box">
                                    <FormattedMessage id="services.corporate" defaultMessage="Corporate" />
                                </div></div>
                            </div>
                            <div className="box" onClick={() => this.goToServices("annonce")}>
                                <div className="col-12 no-border-padding">
                                    <div className="logo-box"><i className="fal fa-newspaper"></i></div>
                                </div>
                                <div className="col-12 no-border-padding"><div className="text text-box">
                                    <FormattedMessage id="services.annonce" defaultMessage="Classified ads" />
                                </div></div>
                            </div>
                            <div className="box" onClick={() => this.goToServices("wishlist")}>
                                <div className="col-12 no-border-padding">
                                    <div className="logo-box"><i className="fal fa-vote-yea"></i></div>
                                </div>
                                <div className="col-12 no-border-padding"><div className="text text-box">
                                    <FormattedMessage id="menu.wishlist" />
                                </div></div>
                            </div>
                        </div>
                        <div
                            style={{
                                width: "100%",
                                height: "100",
                                justifyContent: "center",
                                alignItems: "center",
                                display: this.state.displayLoading
                            }}
                        >
                            <Loader type="ThreeDots" color="#944ecc" height="100" width="100" />
                        </div>
                    </div>
                    <Modal closeOnOverlayClick={false} closeOnEsc={false} open={open} onClose={this.onCloseModal} center styles={this.state.style} blockScroll >
                        <Countries wishList={this.wishList} changeCountry={(country, label) => this.changeCountry(country, label)}></Countries>
                    </Modal>
                </React.Fragment >
            )
        } else {
            return (
                <React.Fragment>
                    <Helmet>
                        <title>Issoyo - Services</title>
                        <meta name="description" content="Select your country and see all the services available" />
                    </Helmet>
                    <div className="Services">
                        <div className="signup_back"><i style={{ color: '#593e96' }} className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                        <div className="row" >
                            <div className="col-12 title">
                                <FormattedMessage id="services.title" defaultMessage="Services in ">{msg => (`${msg} ${this.state.country_name}`)}</FormattedMessage>
                                <Button color="primary" className="service_location-button" onClick={this.onOpenModal}>
                                    <ReactCountryFlag
                                        styleProps={{
                                            width: '29px',
                                            height: '22px',
                                            borderRadius: '15px',
                                            boxShadow: 'rgba(25, 23, 23, 0.13) 4px 2px 16px 1px',
                                        }}
                                        svg
                                        code={this.state.country}
                                        onClick={this.onOpenModal}
                                    />
                                    <i className="fas fa-angle-down" style={{ marginLeft: '3px' }}></i>
                                </Button>
                            </div>
                        </div>
                        <div className="card-deck row" style={{justifyContent: 'space-evenly'}}>
                            <div className={`box ${this.state.disableBank}`} onClick={() => this.goToServices("bank")}>
                                <div className="col-12 no-border-padding">
                                    <img className="image-box" src={icon_bank_service} alt="Card cap" />
                                </div>
                                <div className="col-12 no-border-padding">
                                    <div className="text text-box">
                                        <FormattedMessage id="services.bank" defaultMessage="Banking" />
                                    </div>
                                </div>
                            </div>
                            <div className={`box ${this.state.disableHouse}`} onClick={() => this.goToServices("house")}>
                                <div className="col-12 no-border-padding">
                                    <img className="image-box" src={icon_realstate_service} alt="Card cap" />
                                </div>
                                <div className="col-12 no-border-padding">
                                    <div className="text text-box">
                                        <FormattedMessage id="services.house" defaultMessage="House Rental" />
                                    </div></div>
                            </div>
                            <div className={`box ${this.state.disableTelecom}`} onClick={() => this.goToServices("telecom")}>
                                <div className="col-12 no-border-padding">
                                    <img className="image-box" src={icon_phone_service} alt="Card cap" />
                                </div>
                                <div className="col-12 no-border-padding"><div className="text text-box">
                                    <FormattedMessage id="services.telecom" defaultMessage="Telecom" />
                                </div></div>
                            </div>
                            <div className={`box ${this.state.disableInsurances}`} onClick={() => this.goToServices("insurance")}>
                                <div className="col-12 no-border-padding">
                                    <img className="image-box" src={icon_heart_service} alt="Card cap" />
                                </div>
                                <div className="col-12 no-border-padding"><div className="text text-box">
                                    <FormattedMessage id="services.insurance" defaultMessage="Insurances" />
                                </div></div>
                            </div>
                            <div className={`box ${this.state.disableInternet}`} onClick={() => this.goToServices("internet")}>
                                <div className="col-12 no-border-padding">
                                    <img className="image-box" src={icon_signal_service} alt="Card cap" />
                                </div>
                                <div className="col-12 no-border-padding"><div className="text text-box">
                                    <FormattedMessage id="services.wifi" defaultMessage="Internet" />
                                </div></div>
                            </div>
                            <div className={`box ${this.state.disableTicketing}`} onClick={() => this.goToServices("ticketing")}>
                                <div className="col-12 no-border-padding">
                                    <div className="logo-box"><i className="fal fa-ticket-alt"></i></div>
                                </div>
                                <div className="col-12 no-border-padding"><div className="text text-box">
                                    <FormattedMessage id="services.ticketing" defaultMessage="Ticketing" />
                                </div></div>
                            </div>
                            <div className={`box ${this.state.disableEnergy}`} onClick={() => this.goToServices("energy")}>
                                <div className="col-12 no-border-padding">
                                    <div className="logo-box"><i className="fal fa-lightbulb-on"></i></div>
                                </div>
                                <div className="col-12 no-border-padding"><div className="text text-box">
                                    <FormattedMessage id="menu.energy" />
                                </div></div>
                            </div>
                        <div className={`box ${this.state.disableTransport}`} onClick={() => this.goToServices("transport")}>
                            <div className="col-12 no-border-padding">
                                <div className="logo-box"><i className="fal fa-bus"></i></div>
                            </div>
                            <div className="col-12 no-border-padding"><div className="text text-box">
                                <FormattedMessage id="service.transport" defaultMessage="Transport" />
                            </div></div>
                        </div>
                        {this.props.auth.user.location === "GB" ?
                            <div className={`box ${this.state.disableTelevision}`} onClick={() => this.goToServices("television")}>
                                <div className="col-12 no-border-padding">
                                    <div className="logo-box"><i className="fal fa-tv-retro"></i></div>
                                </div>
                                <div className="col-12 no-border-padding"><div className="text text-box">
                                    <FormattedMessage id="service.tv" defaultMessage="TV" />
                                </div></div>
                            </div> : null}
                        <div className="box" onClick={() => this.goToServices("annonce")}>
                            <div className="col-12 no-border-padding">
                                <div className="logo-box"><i className="fal fa-newspaper"></i></div>
                            </div>
                            <div className="col-12 no-border-padding"><div className="text text-box">
                                <FormattedMessage id="services.annonce" defaultMessage="Classified ads" />
                            </div></div>
                        </div>
                        <div className="box" onClick={() => this.goToServices("wishlist")}>
                            <div className="col-12 no-border-padding">
                                <div className="logo-box"><i className="fal fa-vote-yea"></i></div>
                            </div>
                            <div className="col-12 no-border-padding"><div className="text text-box">
                                <FormattedMessage id="menu.wishlist" />
                            </div></div>
                        </div>
                        </div>
                        <div
                            style={{
                                width: "100%",
                                height: "100",
                                justifyContent: "center",
                                alignItems: "center",
                                display: this.state.displayLoading
                            }}
                        >
                            <Loader type="ThreeDots" color="#944ecc" height="100" width="100" />
                        </div>
                    </div>
                    <Modal closeOnOverlayClick={false} closeOnEsc={false} open={open} onClose={this.onCloseModal} center styles={this.state.style} blockScroll >
                        <Countries wishList={this.wishList} changeCountry={(country, label) => this.changeCountry(country, label)}></Countries>
                    </Modal>
                </React.Fragment >
            )
        }
    }
}

Services.propTypes = {
    getServiceList: PropTypes.func.isRequired,
    getTicketList: PropTypes.func.isRequired,
    getInternetList: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    service: state.services
});

export default connect(
    mapStateToProps,
    { getServiceList, setLocation, getTicketList, getInternetList, getAnnonceList, getMobileList }
)(Services);


/*            disableBank: bank,
            disableHouse: house,
            disableTelecom: telecom,
            disableInsurances: insurances,
            disableInternet: internet,
            disableTicketing: ticketing,
            disableTransport: transport,
            disableTelevision: television,
            disableEnergy: energy, */