import React, { Component } from 'react'
import auto_euro_logo from '../../../../../assets/img/providers/auto_europe.png';
import { FormattedMessage } from 'react-intl';

require("./CarLocation.scss")

class CarLocation extends Component {
    render() {
        return (
            <div>
                <div className="carLocation_description">
                    <div className="carLocation_provider_logo">
                        <img className="carLocation_provider-img" src={auto_euro_logo} alt='' />
                    </div>
                    <div className="text carLocation_title">
                        <FormattedMessage id="transport.auto_europe.advantage" />
                    </div>
                    <ul className="text">
                        <li><FormattedMessage id="transport.auto_europe.compare" defaultMessage="Compare companies for the best rates" /></li>
                        <li><FormattedMessage id="transport.auto_europe.cancel" defaultMessage="Free cancellation 48hours before pick-up" /></li>
                        <li><FormattedMessage id="transport.auto_europe.credit" defaultMessage="No credit card fees" /></li>
                        <li><FormattedMessage id="transport.auto_europe.avaible" defaultMessage="Avaible 7 days a week." /></li>
                        <li><FormattedMessage id="transport.auto_europe.wordwide" defaultMessage="20,000 pick-up location worldwide " /></li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default CarLocation;