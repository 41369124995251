import axios from "axios";
import {
    SET_SERVICELIST,
    SET_PHONELIST,
    SET_TICKETLIST,
    SET_INTERNETLIST,
    SET_MOBILELIST,
    SET_ANNONCELIST,
    GET_ERRORLIST,
} from "./types";

export const setServiceList = (payload) => {
    return {
        type: SET_SERVICELIST,
        payload: payload
    }
};

export const setPhoneList = (payload) => {
    return {
        type: SET_PHONELIST,
        payload: payload
    }
};

export const setMobileList = (payload) => {
    return {
        type: SET_MOBILELIST,
        payload: payload
    }
};

export const setTicketList = (payload) => {
    return {
        type: SET_TICKETLIST,
        payload: payload
    }
};

export const setInternetList = (payload) => {
    return {
        type: SET_INTERNETLIST,
        payload: payload
    }
};

export const setAnnonceList = (payload) => {
    return {
        type: SET_ANNONCELIST,
        payload: payload
    }
};

export const setErrorList = (payload) => {
    return {
        type: GET_ERRORLIST,
        payload: payload
    }
};





// Get list of service depend of the country
export const getServiceList = (serviceData) => dispatch => {
        axios.get('/api/service/getservicelist', { params: serviceData }).then(res => {
            dispatch(setServiceList(res.data));
        }).catch(err => {
            dispatch(setErrorList(err.response.data));
        })
};

// Get list of phone depend of the line phone
export const getPhoneList = (serviceData) => dispatch => {
    axios.get('/api/service/getphonelist', { params: serviceData }).then(res => {
        dispatch(setPhoneList(res.data));
    }).catch(err => {
        dispatch(setErrorList(err.response.data));
    })
};

// Get all the list of mobile from pixmania and other providers
export const getMobileList = (serviceData) => dispatch =>{
    axios.get('/api/service/getmobilelist', { params: serviceData}).then(res => {
        dispatch(setMobileList(res.data));
    }).catch(err => {
        dispatch(setErrorList(err.response.data));
    })
}

// Get list of ticket
export const getTicketList = (serviceData) => dispatch => {
    if (serviceData.location === 'FR') {
    axios.get('/api/service/getticketlist', { params: serviceData }).then(res => {
        dispatch(setTicketList(res.data));
    }).catch(err => {
        dispatch(setErrorList(err.response.data));
    }) } else {
        axios.get('/api/service/gettiqetcity', {
            params: { lang: serviceData.lang, country_name: serviceData.country }
        }).then(res => {
            dispatch(setTicketList(res.data));
        }).catch(err => {
            dispatch(setErrorList(err.response.data));
        })
    }
};

// Get list of internet providers
export const getInternetList = (serviceData) => dispatch => {
    axios.get('/api/service/getinternetlist', { params: serviceData }).then(res => {
        dispatch(setInternetList(res.data));
    }).catch(err => {
        dispatch(setErrorList(err.response.data));
    })
};

// Get list of internet providers
export const getAnnonceList = (country) => dispatch => {
    axios.get('/api/service/getannoncelist', { params: country }).then(res => {
        dispatch(setAnnonceList(res.data));
    }).catch(err => {
        dispatch(setErrorList(err.response.data));
    })
};

// Add new annonce
export const addAnnonce = (reqData, list) => dispatch => {
    axios.post('/api/service/createoffer', reqData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
        var tmp = list;
        tmp.push(res.data);
        dispatch(setAnnonceList(tmp));
    });
}

// Update annonce
export const updateAnnonce = (reqData, list) => dispatch => {
    axios.put('/api/service/updateoffer', reqData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
        var tmp = list;
        var index = tmp.findIndex(data => data._id === res.data._id);
        tmp[index] = res.data;
        dispatch(setAnnonceList(tmp));
    });
}

// Delete annonce
export const deleteAnnonce = (reqData, list) => dispatch => {
    axios.delete('api/service/deleteannonce', { data: reqData }).then(res => {
        var tmp = list.filter(function (data) {
            if (data._id === reqData.id) {
                return false;
            } else {
                return true;
            }
        });
        dispatch(setAnnonceList(tmp))
    });
}
