import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import Taxi from './Taxi/Taxi.jsx';
import TaxiKR from './Taxi/TaxiKR.jsx';
import TaxiGB from './Taxi/TaxiGB.jsx';
import Issoyoservice from '../Issoyoservice/Issoyoservice.jsx';
import CarLocation from './CarLocation/CarLocation.jsx';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Issoyo_logo from '../../../../assets/img/fav_icon32@2x.png';
import Issoyo_logo_blanc from '../../../../assets/img/issoyo_blanc.png';
import { countryAvailable } from '../listCountry.js';
import ButtonToService from '../../ButtonToService/ButtonToService';


require('./Transport.scss')

class Transport extends Component {
    constructor() {
        super();
        this.state = {
            colorTaxi: { color: '#7f3fce', backgroundColor: 'white' },
            colorCarLocation: { color: '#7f3fce', backgroundColor: 'white' },
            colorIssoyocar: { color: '#7f3fce', backgroundColor: 'white' },
            disableTaxi: '',
            language: 'fr',
            transportComp: null,
            logo: Issoyo_logo,
            afiliate_link: "",
            children_button: null,
            issoyo: false,
            user_location: '',
        }
        this.handleClick = this.handleClick.bind(this);
        this.linkChange = this.linkChange.bind(this);
        this.handleClickAccount = this.handleClickAccount.bind(this);
    }

    componentWillMount() {
        if (this.props.language === 'fr-FR') {
            this.setState({ language: 'fr' });
        } else if (this.props.language === 'en-EN' || this.props.language === 'en-US') {
            this.setState({ language: 'en' });
        }
    }
    
    componentDidMount() {
        console.log(this.props.auth.user.location)
        if (!this.props.auth.user.location) {
            console.log('test')
            this.props.history.push('/services');
        } else {
            this.setState({ user_location: this.props.auth.user.location})
        }
        if (this.props.auth.user.location !== 'FR') {
            this.setState({ disableTaxi: 'service_disabled' });
        }
        window.scrollTo(0, 0);
    }

    componentWillReceiveProps() {
        this.setState({
            colorTaxi: { color: '#7f3fce', backgroundColor: 'white' },
            colorCarLocation: { color: '#7f3fce', backgroundColor: 'white' },
            colorIssoyocar: { color: '#7f3fce', backgroundColor: 'white' },
            disableTaxi: '',
            language: 'fr',
            transportComp: null,
        })
    }

    linkChange(link){
        this.setState({afiliate_link: link})
    }

    handleClickAccount() {
        this.props.history.push('/login');
    }

    handleClick(name) {
        if (name === 'taxi') {
            this.setState({
                transportComp: <Taxi linkChange={(link) => this.linkChange(link)} language={this.state.language} />,
                colorTaxi: { color: "white", backgroundColor: '#7f3fce' },
                colorCarLocation: { color: '#7f3fce', backgroundColor: 'white' },
                colorIssoyocar: { color: '#7f3fce', backgroundColor: 'white' },
                logo: Issoyo_logo,
                afiliate_link: "https://www.awin1.com/cread.php?awinmid=7151&awinaffid=525947&clickref=&p=https%3A%2F%2Fparis.ticketbar.eu%2Ffr%2Ftransfert-aeroport%2Ftaxi-paris-charles-de-gaulle-aeroport-hotel-%2F",
                children_button:  <FormattedMessage id="service.transport.taxi.apply" />,
                issoyo: false,
            });
        }
        if (name === 'taxikr') {
            this.setState({
                transportComp: <TaxiKR handleClickAccount={() => this.handleClickAccount()} language={this.state.language} />,
                colorTaxi: { color: "white", backgroundColor: '#7f3fce' },
                colorCarLocation: { color: '#7f3fce', backgroundColor: 'white' },
                colorIssoyocar: { color: '#7f3fce', backgroundColor: 'white' },
                logo: Issoyo_logo,
                afiliate_link: "http://www.intltaxi.co.kr/",
                children_button:  <FormattedMessage id="service.transport.taxi.apply" />,
                issoyo: false,
            });
        }
        if (name === 'taxigb') {
            this.setState({
                transportComp: <TaxiGB handleClickAccount={() => this.handleClickAccount()} language={this.state.language} />,
                colorTaxi: { color: "white", backgroundColor: '#7f3fce' },
                colorCarLocation: { color: '#7f3fce', backgroundColor: 'white' },
                colorIssoyocar: { color: '#7f3fce', backgroundColor: 'white' },
                logo: Issoyo_logo,
                afiliate_link: "https://www.rentalcars.com/",
                children_button:  <FormattedMessage id="service.transport.taxi.apply" />,
                issoyo: false,
            });
        }
        if (name === 'carLocation') {
            this.setState({
                transportComp: <CarLocation handleClickAccount={() => this.handleClickAccount()} language={this.state.language} />,
                colorCarLocation: { color: "white", backgroundColor: '#7f3fce' },
                colorTaxi: { color: '#7f3fce', backgroundColor: 'white' },
                colorIssoyocar: { color: '#7f3fce', backgroundColor: 'white' },
                logo: Issoyo_logo,
                afiliate_link: "https://clk.tradedoubler.com/click?p=232582&a=3083026&g=21718038",
                children_button: null,
                issoyo: false,

            });
        }
        if (name === 'issoyoservice') {
            this.setState({
                transportComp: <Issoyoservice serviceName='transport' handleClickAccount={() => this.handleClickAccount()} language={this.state.language} />,
                colorCarLocation: { color: "#7f3fce", backgroundColor: 'white' },
                colorTaxi: { color: '#7f3fce', backgroundColor: 'white' },
                colorIssoyocar: { color: 'white', backgroundColor: '#7f3fce' },
                logo: Issoyo_logo_blanc,
                issoyo: true,
            });
        }
    }

    render() {
        if (countryAvailable[0].transport.indexOf(this.state.user_location.toLowerCase()) < 0) {
            return (
                <div className="Energy">
                    <div className="bank_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                    <div className="text" style={{ textAlign: "center" }}> <Issoyoservice serviceName='transport' service='transport' handleClickAccount={() => this.handleClickAccount()} language={this.state.language} /></div></div>
            )
        } else if (this.props.auth.user.location !== 'KR' && this.props.auth.user.location !== 'GB') {
            return (
                <div className="transport">
                    <div className="bank_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                    <div className="title">
                        <FormattedMessage id="service.transport.title" defaultMessage="Which transport ?" />
                    </div>
                    <div className="card_transport">
                        <div className={`box ${this.state.disableTaxi}`} onClick={() => this.handleClick('taxi')} style={this.state.colorTaxi}>
                            <div className="col-12 no-border-padding">
                                <div className="logo-box"><i className="fal fa-taxi"></i></div>
                            </div>
                            <div className="col-12 no-border-padding"><div className="text text-box" style={{ color: this.state.colorTaxi.color }}>
                                <FormattedMessage id="service.transport.taxi" defaultMessage="Transfert aéroport" />
                            </div></div>
                        </div>
                        <div className="box" onClick={() => this.handleClick('carLocation')} style={this.state.colorCarLocation}>
                            <div className="col-12 no-border-padding">
                                <div className="logo-box"><i className="fal fa-car-side"></i></div>
                            </div>
                            <div className="col-12 no-border-padding"><div className="text text-box" style={{ color: this.state.colorCarLocation.color }}>
                                <FormattedMessage id="service.transport.carLocation" defaultMessage="Car Rental" />
                            </div></div>
                        </div>
                        <div className="box" onClick={() => this.handleClick('issoyoservice')} style={this.state.colorIssoyocar}>
                            <div className="col-12 no-border-padding">
                                <div className="logo-box"><img src={this.state.logo} style={{width: '40px'}}></img></div>
                            </div>
                            <div className="col-12 no-border-padding"><div className="text text-box" style={{ color: this.state.colorIssoyocar.color }}>
                                Issoyo
                            </div></div>
                        </div>
                    </div>
                    <div>
                        {this.state.transportComp}
                    </div>
                    { this.state.transportComp && this.state.issoyo === false? <ButtonToService afiliateLink={this.state.afiliate_link}>
                        {this.state.children_button}
                    </ButtonToService> : null }
                </div>
            )
        } else if (this.props.auth.user.location === 'KR') {
            return (
                <div className="transport">
                    <div className="bank_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                    <div className="title">
                        <FormattedMessage id="service.transport.title" defaultMessage="Which transport ?" />
                    </div>
                    <div className="card_transport">
                        <div className='box' onClick={() => this.handleClick('taxikr')} style={this.state.colorTaxi}>
                            <div className="col-12 no-border-padding">
                                <div className="logo-box"><i className="fal fa-taxi"></i></div>
                            </div>
                            <div className="col-12 no-border-padding"><div className="text text-box" style={{ color: this.state.colorTaxi.color }}>
                                Taxi
                            </div></div>
                        </div>
                        <div className="box" onClick={() => this.handleClick('issoyoservice')} style={this.state.colorIssoyocar}>
                            <div className="col-12 no-border-padding">
                                <div className="logo-box"><img src={this.state.logo} style={{width: '40px'}}></img></div>
                            </div>
                            <div className="col-12 no-border-padding"><div className="text text-box" style={{ color: this.state.colorIssoyocar.color }}>
                                Issoyo
                            </div></div>
                        </div>
                    </div>
                    <div>
                        {this.state.transportComp}
                    </div>
                    { this.state.transportComp && this.state.issoyo === false? <ButtonToService afiliateLink={this.state.afiliate_link}>
                        {this.state.children_button}
                    </ButtonToService> : null }
                </div>
            )
        } else if (this.props.auth.user.location === 'GB') {
            return (
                <div className="transport">
                    <div className="bank_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                    <div className="title">
                        <FormattedMessage id="service.transport.title"/>
                    </div>
                    <div className="card_transport">
                        <div className='box' onClick={() => this.handleClick('taxigb')} style={this.state.colorTaxi}>
                            <div className="col-12 no-border-padding">
                                <div className="logo-box"><i className="fal fa-car-side"></i></div>
                            </div>
                            <div className="col-12 no-border-padding"><div className="text text-box" style={{ color: this.state.colorTaxi.color }}>
                                <FormattedMessage id="service.transport.carLocation" defaultMessage="Car Rental" />
                            </div></div>
                        </div>
                        <div className="box" onClick={() => this.handleClick('issoyoservice')} style={this.state.colorIssoyocar}>
                            <div className="col-12 no-border-padding">
                                <div className="logo-box"><img src={this.state.logo} style={{width: '40px'}}></img></div>
                            </div>
                            <div className="col-12 no-border-padding"><div className="text text-box" style={{ color: this.state.colorIssoyocar.color }}>
                                Issoyo
                            </div></div>
                        </div>
                    </div>
                    <div>
                        {this.state.transportComp}
                    </div>
                    { this.state.transportComp && this.state.issoyo === false? <ButtonToService afiliateLink={this.state.afiliate_link}>
                        {this.state.children_button}
                    </ButtonToService> : null }
                </div>
            )
        }
    }
}

Transport.propTypes = {
    auth: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(
    mapStateToProps,
    {}
)(Transport);