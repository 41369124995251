import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from 'react-responsive-modal';
import AddModal from './AddModal.jsx';
import ContactModal from './ContactModal.jsx';
import { withAlert } from 'react-alert'
import { deleteAnnonce } from '../../../../actions/serviceActions.js'
import EditModal from './EditModal.jsx';

require('./Annonce.scss');

const styles = {
    root: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    selectMenu: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    input: {
        borderColor: '#3e45948a !important',
    },
    rootCheck: {
        color: '#836bc1bf !important',
        '&$checked': {
            color: '#836bc1bf !important',
        },
    },
};

class Annonce extends Component {
    constructor() {
        super();
        this.state = {
            openAdd: false,
            openContact: false,
            openImg: false,
            openEdit: false,
            emailContact: '',
            authorContact: '',
            adsTitle: '',
            typeAds: '',
            imgSelected: '',
            dataAds: null,
            style: {
                modal: {
                    'maxWidth': '800px',
                    'width': '100%',
                    'color': '#3f2751',
                    'zIndex': '10000 !important',
                },
                closeIcon: {
                    'fill': '#c3c0c7e8',
                }
            },
        }
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onOpenModalEdit = this.onOpenModalEdit.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.contact = this.contact.bind(this);
        this.deleteAnnonce = this.deleteAnnonce.bind(this);
    }

    componentWillMount() {
        if (window.matchMedia("(min-width: 436px)").matches) {
            /* The viewport is at least 400 pixels wide */
            this.setState({
                style: {
                    modal: {
                        'maxWidth': '800px',
                        'width': '100%',
                        'color': '#3f2751',
                        'zIndex': '10000 !important',
                    },
                    closeIcon: {
                        'fill': '#c3c0c7e8',
                    }
                }
            });
        } else {
            /* The viewport is less than 400 pixels wide */
            this.setState({
                style: {
                    modal: {
                        'height': '100%',
                        'left': '0',
                        'position': 'fixed',
                        'top': '0',
                        'width': '100%',
                        'color': '#3f2751',
                        'zIndex': '10000 !important',

                    },
                    closeIcon: {
                        'fill': '#c3c0c7e8',
                    }
                }
            });
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    deleteAnnonce(id) {
        var request = { id: id, country: this.props.auth.user.location }
        this.props.deleteAnnonce(request, this.props.service.annonceList);
        this.props.alert.show('Annonce supprimée', { type: 'info' });
    }

    onOpenModal(adsImg) {
        if (!this.props.auth.isAuthenticated && adsImg === '') {
            this.props.history.push("/login");
        } else if (adsImg !== '') {
            this.setState({imgSelected: adsImg, openImg: true})
        } else {
            this.setState({ openAdd: true });
        }
    };

    onOpenModalEdit(data){
        this.setState({openEdit: true, dataAds: data});
    }
    onCloseModal() {
        this.setState({ openAdd: false, openContact: false, openImg: false, imgSelected: '', openEdit: false });
    };

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    contact(author, email, title) {
        if (!this.props.auth.isAuthenticated) {
            this.props.history.push("/login");
        } else {
            this.setState({ authorContact: author, emailContact: email, adsTitle: title }, this.setState({ openContact: true }));
        }
    }

    render() {
        const { classes } = this.props;
        var type = this.state.typeAds;
        var list = this.props.service.annonceList.reverse().map((data, key) => {
            var button;
            var edit;
            if (data.author === this.props.auth.user.nickname) {
                button = <button className='annonce_delete-button' onClick={() => this.deleteAnnonce(data._id)}>
                <div className="card_icon"><i className="far fa-trash-alt"></i></div></button>
                edit = <button className='annonce_edit-button' onClick={() => this.onOpenModalEdit(data)}>
                    <div className="card_icon"><i className="fas fa-edit"></i></div>
                </button>
            } else {
                button = <button className='annonce_contact-button' onClick={() => this.contact(data.author, data.contact, data.title)}>
                    <FormattedMessage id="service.annonce.contact" defaultMessage="Contact" />
                </button>
            }
            if (type === data.typeAds || type === 'all' || type === '') {
                return (
                    <div key={key}>
                        <div className="annonce_card">
                            <div className="annonce_card-title">
                                {data.title}
                                {edit}
                            </div>
                            <div className="annonce_card-description">
                                {data.description}
                            </div>
                            <div className="annonce_card_pics">
                            {data.productImage.map((data, key) => {
                                return <img className="annonce_card_pics-img" key={key} src={data} onClick={() => this.onOpenModal(data)} alt=''></img>
                            })}
                            </div>
                            <div className="row annonce_card_info">
                                <div className="annonce_card-price"><i className="fas fa-tag"></i> {data.price}</div>
                                <div className="annonce_card-city"><i className="fas fa-city"></i> {data.city}</div>
                                <div className="annonce_contact">
                                    {button}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return ('');
            }
        })
        return (
            <React.Fragment>
                <div className='Annonce'>
                    <div className="annonce_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                    <div className="col-12 title">
                        <div>
                            <FormattedMessage id="service.annonce.title" defaultMessage="Les petites annonces" />
                        </div>
                    </div>
                    <div className='row annonce_search'>
                        <div className="select annonce_type">
                            <TextField
                                id="outlined-select-currency"
                                select
                                label="Type"
                                className="annonce_type-list"
                                value={this.state.typeAds}
                                name="typeAds"
                                onChange={this.handleChange}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                margin="normal"
                                variant="outlined"
                            >
                                <MenuItem disabled>Select type</MenuItem>
                                <MenuItem value={'all'} classes={{ root: classes.root }}><FormattedMessage id="service.annonce.all" defaultMessage="All" /></MenuItem>
                                <MenuItem value={'sell'} classes={{ root: classes.root }}><FormattedMessage id="service.annonce.sale" defaultMessage="To sell" /></MenuItem>
                                <MenuItem value={'event'} classes={{ root: classes.root }}><FormattedMessage id="service.annonce.event" defaultMessage="Event" /></MenuItem>
                                <MenuItem value={'job'} classes={{ root: classes.root }}><FormattedMessage id="service.annnonce.job" defaultMessage="Job" /></MenuItem>
                            </TextField>
                        </div>
                        <div className='ads_new'>
                            <Button variant="contained" color="primary" className='ads_new-button' onClick={() => this.onOpenModal('')}>
                                <FormattedMessage id="service.annonce.add" defaultMessage="Add your ads"></FormattedMessage>
                            </Button>
                        </div>
                    </div>
                    <div>
                        {this.props.service.annonceList.length > 0 ? list : <div style={{ color: 'rgba(68, 76, 165, 0.85)eb', marginTop: '42px' }}><FormattedMessage id="service.annonce.first" defaultMessage="Be the first one to post an ads here" /></div>}
                    </div>
                </div>
                <Modal open={this.state.openAdd} onClose={this.onCloseModal} center styles={this.state.style}>
                    <AddModal onCloseModal={this.onCloseModal} />
                </Modal>
                <Modal open={this.state.openContact} onClose={this.onCloseModal} center styles={this.state.style}>
                    <ContactModal
                        onCloseModal={this.onCloseModal}
                        emailContact={this.state.emailContact}
                        authorContact={this.state.authorContact}
                        adsTitle={this.state.adsTitle} />
                </Modal>
                <Modal open={this.state.openImg} onClose={this.onCloseModal} center>
                <div className="modal-picture" style={{width: '100%'}}>
                    <img className="img-modal" style={{width: '100%'}} src={this.state.imgSelected} alt=''></img>
                </div>
                </Modal>
                <Modal open={this.state.openEdit} onClose={this.onCloseModal} center>
                    <EditModal dataAds={this.state.dataAds} onCloseModal={this.onCloseModal}/>
                </Modal>
            </React.Fragment>
        )
    }
}

Annonce.propTypes = {
    auth: PropTypes.object.isRequired,
    service: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    service: state.services,
});

export default connect(
    mapStateToProps, { deleteAnnonce }
)(withStyles(styles)(withAlert(Annonce)));