import React, { Component } from 'react'
import axios from 'axios';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    EmailIcon,
} from 'react-share';
export default class ShowBlog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: "",
            title: "",
            country: '',
        }
    }

    componentDidMount() {
        axios.get('/api/service/showblog/' + this.props.match.params.id)
            .then(response => {
                this.setState({
                    text: response.data.description,
                    title: response.data.title,
                    country: response.country
                });
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    render() {
        return (
            <div style={{ margin: 'auto', marginTop: '100px', maxWidth: '800px', padding: '50px', background: '#b8aeca0d', borderRadius: '20px' }}>
                <div className="signup_back"><i style={{ color: '#593e96' }} className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                <h3 className="title" style={{ color: '#3E4594' }}>{this.state.title}</h3>
                <div className='text'>
                    <div dangerouslySetInnerHTML={{ __html: this.state.text }} />
                </div>
                <div style={{display: 'flex', justifyContent: 'space-evenly', maxWidth: '150px'}}>
                    <TwitterShareButton url={window.location.href} title={this.state.title}>
                        <TwitterIcon size={32} round={true}/>
                    </TwitterShareButton>
                    <FacebookShareButton url={window.location.href} quote={this.state.title}>
                        <FacebookIcon size={32} round={true}/>
                    </FacebookShareButton>
                    <LinkedinShareButton url={window.location.href} quote={this.state.title}>
                        <LinkedinIcon size={32} round={true}/>
                    </LinkedinShareButton>
                    <EmailShareButton url={window.location.href} body={this.state.title}>
                        <EmailIcon size={32} round={true}/>
                    </EmailShareButton>
                </div>
            </div>
        )
    }
}
