import React, { Component } from 'react';
import './App.css';
import Navbar from './app/components/Home/Navbar.jsx';
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { IntlProvider, addLocaleData } from 'react-intl';
import fr from "react-intl/locale-data/fr";
import en from "react-intl/locale-data/en";
import localeData from "../public/locales/data.json";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import { Route, Switch } from 'react-router-dom';
import RegisterPart1 from './app/components/RegisterPart1/RegisterPart1.jsx';
import RegisterPart2 from './app/components/RegisterPart2/RegisterPart2.jsx';
import RegisterPart3 from './app/components/RegisterPart3/RegisterPart3.jsx';
import RegisterPart4 from './app/components/RegisterPart4/RegisterPart4.jsx';
import Profile from './app/components/Profile/Profile.jsx';
import Information from './app/components/Information/Information.jsx';
import Account from './app/components/Account/Account.jsx';
import Adresses from './app/components/Adresses/Adresses.jsx';
import Addadresses from './app/components/Adresses/Addadresses.jsx';
import SuccesRegister from './app/components/SuccesRegister/SuccesRegister.jsx';
import { Home } from './app/components/Home/Home.jsx';
import { Condition } from './app/components/Home/Condition.jsx';
import { Mention } from './app/components/Home/Mention.jsx';
import { Cookies } from './app/components/Home/Cookies.jsx';
import Login from './app/components/Login/Login.jsx';
import BlogNew  from './app/components/Blog/BlogNew.jsx';
import Blog  from './app/components/Blog/Blog.jsx';
import ShowBlog  from './app/components/Blog/ShowBlog.jsx';
import EditBlog  from './app/components/Blog/EditBlog.jsx';
import IndexBlog  from './app/components/Blog/IndexBlog.jsx';
import Services from './app/components/Services/Services.jsx';
import Bank from './app/components/Services/Bank/Bank.jsx';
import Telecom from './app/components/Services/Telecom/Telecom.jsx';
import House from './app/components/Services/House/House.jsx';
import Insurances from './app/components/Services/Insurances/Insurances.jsx';
import Energy from './app/components/Services/Energy/Energy.jsx';
import Ticketing from './app/components/Services/Ticketing/Ticketing.jsx';
import Internet from './app/components/Services/Internet/Internet.jsx';
import Annonce from './app/components/Services/Annonce/Annonce.jsx';
import Transport from './app/components/Services/Transport/Transport.jsx';
import Television from './app/components/Services/Television/Television.jsx';
import Wishlist from './app/components/Wishlist/Wishlist.jsx';
import Corporate from './app/components/Services/Corporate/Corporate.jsx';
import PrivateRoute from "./app/components/private-route/PrivateRoute";
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from './app/components/AlertTemplate/AlertTemplate.js';
import { PersistGate } from 'redux-persist/integration/react';
import Recover from './app/components/Recover/Recover.jsx';


addLocaleData([...fr, ...en]);

let language = 'en'

// Split locales with a region code
const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

// Try full locale, try locale without region code, fallback to 'en'
let messages = localeData.en ||
    localeData[languageWithoutRegionCode] ||
    localeData[language];


// Check for token to keep user logged in
if (localStorage.jwtToken) {
    // Set auth token header auth
    const token = localStorage.jwtToken;
    setAuthToken(token);
    // Decode token and get user info and exp
    const decoded = jwt_decode(token);
    // Set user and isAuthenticated
    store.dispatch(setCurrentUser(decoded));
    // Check for expired token
    const currentTime = Date.now() / 1000; // to get in milliseconds
    if (decoded.exp < currentTime) {
        // Logout user
        store.dispatch(logoutUser());
        // Redirect to login
        window.location.href = "./login";
    }
}

const options = {
    position: 'top right',
    timeout: 5000,
    offset: '30px',
    transition: 'scale'
}

class App extends Component {
    constructor(props) {
        super(props);
        this.state = ({ language: language });
        this.langChange = this.langChange.bind(this);
    }

    langChange(langValue) {
        this.setState({ language: langValue });
        language = langValue;
        navigator.userLanguage = langValue;
        messages = localeData[language.toLowerCase().split(/[_-]+/)[0]] ||
            localeData[language] ||
            localeData.en;
    }

    render() {
        return (
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <AlertProvider template={AlertTemplate} {...options}>
                        <IntlProvider locale={language} messages={messages}>
                            <div className="App">
                                <div className="App-content">
                                    <Navbar handleLanguage={langValue => this.langChange(langValue)} language={language} history={this.props.history} />
                                    <div className="bg">
                                        <Switch >
                                            <Route exact path="/" render={(props) => <Home {...props} language={language} />} ></Route>
                                            <Route path='/conditions' component={Condition} ></Route>
                                            <Route path='/mentions' component={Mention} ></Route>
                                            <Route path='/cookies' component={Cookies} ></Route>
                                            <Route path='/signup' component={RegisterPart2} ></Route>
                                            <Route path='/signup1' component={RegisterPart1} ></Route>
                                            <Route path='/signup2' component={RegisterPart3} ></Route>
                                            <Route path='/signup3' component={RegisterPart4} ></Route>
                                            <Route path='/login' component={Login} ></Route>
                                            <Route path='/services' render={(props) => <Services {...props} language={language} />}></Route>
                                            <Route path='/recover/:token' component={Recover} ></Route>
                                            <Route path='/bank' render={(props) => <Bank {...props} language={language} />}></Route>
                                            <Route path='/telecom' render={(props) => <Telecom {...props} language={language} />}></Route>
                                            <Route path='/insurance' render={(props) => <Insurances {...props} language={language} />}></Route>
                                            <Route path='/house' render={(props) => <House {...props} language={language} />}></Route>
                                            <Route path='/energy' render={(props) => <Energy {...props} language={language} />}></Route>
                                            <Route path='/ticketing' render={(props) => <Ticketing {...props} language={language} />}></Route>
                                            <Route path='/internet' render={(props) => <Internet {...props} language={language} />}></Route>
                                            <Route path='/annonce' render={(props) => <Annonce {...props} language={language} />}></Route>
                                            <Route path='/transport' render={(props) => <Transport {...props} language={language} />}></Route>
                                            <Route path='/television' render={(props) => <Television {...props} language={language} />}></Route>
                                            <Route path='/corporate' render={(props) => <Corporate {...props} language={language} />}></Route>
                                            <Route path='/welcome' render={(props) => <SuccesRegister {...props} language={language} />}></Route>
                                            <Route path='/admin-issoyo/blog/new' render={(props) => <BlogNew {...props} language={language} />}></Route>
                                            <Route path='/admin-issoyo/blog' render={(props) => <Blog {...props} language={language} />}></Route>
                                            <Route path='/admin-issoyo/edit/:id' render={(props) => <EditBlog {...props} language={language} />}></Route>
                                            <Route path='/blog/show/:id' render={(props) => <ShowBlog {...props} language={language} />}></Route>
                                            <Route path='/blog' render={(props) => <IndexBlog {...props} language={language} />}></Route>
                                        </Switch>
                                        <Switch >
                                            <PrivateRoute path='/profile' component={Profile} ></PrivateRoute>
                                            <PrivateRoute path='/information' component={Information} ></PrivateRoute>
                                            <PrivateRoute path='/account' component={Account} ></PrivateRoute>
                                            <PrivateRoute path='/address' component={Adresses} ></PrivateRoute>
                                            <PrivateRoute path='/addadress' component={Addadresses} ></PrivateRoute>
                                            <PrivateRoute path='/wishlist' component={Wishlist} ></PrivateRoute>
                                        </Switch>
                                        <div style={{color: 'transparent'}}>t</div>
                                    </div>
                                </div>
                            </div>
                        </IntlProvider>
                    </AlertProvider>
                </PersistGate>
            </Provider>
        );
    }
}

export default (App);

//<Route path='/edit/:id' component={Edit} />
