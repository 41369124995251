var serviceList = [{
  "allowed_country": ["fr", "be", "at", "cy", "ee", "fi", "de", "gi", "is", "lv", "li", "lt", "lu", "mt", "mc", "nl", "pt", "sm", "sk", "si", "es", "at", "au", "bd", "br", "bg", "ca", "cl", "cn", "hr", "cz", "cy", "dk", "eg", "ge", "gh", "gi", "gr", "hk", "hu", "in", "id", "ie", "il", "it", "jp", "ke", "my", "mx", "ma", "np", "nz", "ng", "no", "pk", "pe", "pn", "pl", "ro", "ru", "sk", "si", "sg", "za", "lk", "se", "ch", "th", "tr", "ua", "gb", "us", "vn", "ae"],
  "name": "TransfertWise",
  "title": "The TransferWise bordeless account",
  "description": "One account to send, receive and spend money around the world up to 8x cheaper than the banks. Including a debit mastercard.",
  "description2": "Bye bye bank fees, hello world.",
  "type": "bank",
  "cat": ["personnal", "business"],
  "image": ["/static/media/transfertWise_card_new.png"],
  "link": "https://prf.hn/click/camref:1011l7tRd/pubref:TF/destination:https%3A%2F%2Ftransferwise.com%2Fregister%2F"
}, {
  "allowed_country": ["fr", "gb", "it", "es", 'de', 'at', 'ie', 'nl', 'be', 'pt', 'fn', 'lu', 'si', 'gr', 'pl', 'sk', 'se', 'dk', 'no', 'li', 'is'],
  "name": "N26",
  "title": "The first bank you will love #themobilebank",
  "subtitle": "#themobilebank",
  "description": " Manage everything online, set payment limit, lock your card, get instant notification on all your transactions with transparent pricing.",
  "description2": "Forget paying for the basic stuff",
  "type": "bank",
  "cat": ["personnal", "business"],
  "image": ["/static/media/N26_Logo_White.png"],
  "link": "https://n26.go2cloud.org/aff_c?offer_id=4&aff_id=4615"
}, {
  "allowed_country": ["fr"],
  "name": "SFR",
  "title": "sfr title",
  "description": "sfr content 1",
  "description2": "sfr content 2.",
  "type": "telecom",
  "image": ["/static/media/sfr_card.png"],
  "link": "https://sfr.com"
}, {
  "allowed_country": ["fr"],
  "name": "APPARTCITY",
  "title": "N°1 DE L'APPART-HÔTEL EN FRANCE",
  "description": " Plus de 23 Appart-Hôtel disponible partout en France.",
  "description2": "Avec de nombreux services disponible (petit-déjeuner, sauna, salle de fitness ...)",
  "type": "house",
  "image": ["/static/media/apartcity1.jpg"],
  "cat": ["temp"],
  "link": " ",
  "__v": 0.0
}, {
  "allowed_country": ["fr"],
  "name": "Expat.immo",
  "title": "L’immobilier pour les expatriés",
  "description": " Expat.immo est l’agence immobiliere qui vous offre une solution sur-mesure pour gérer à distance tous vos projets immobiliers - Acquisition / Investissement - Vente de votre bien - Location Expat.immo vous libère également de toutes les démarches administratives et des recherches nécessaires pour la concretization de votre projet immobilier.",
  "description2": " ",
  "type": "house",
  "image": ["/static/media/ExpatimmoLogo.jpg"],
  "cat": ["perm"],
  "link": "https://www.expat.immo/",
  "__v": 0.0
}, {
  "allowed_country": ["be", "bh", "sp", "at", "cy", "ee", "fi", "de", "gi", "is", "lv", "li", "lt", "lu", "mt", "mc", "nl", "kr", "pt", "qa", "sm", "sk", "si", "es", "au", "bd", "br", "bg", "ca", "cl", "cn", "cm", "hr", "cz", "cy", "dk", "eg", "ge", "gh", "gi", "gr", "hk", "om", "hu", "in", "id", "ie", "il", "it", "jp", "jo", "ke", "my", "mx", "ma", "np", "nz", "ng", "no", "pk", "pe", "pn", "pl", "ro", "ru", "sk", "si", "sg", "za", "lk", "se", "ch", "th", "tr", "ua", "gb", "us", "vn", "ae", "tw", 'gb', 'ca', 'ar', 'au', 'at', 'be', 'kh', 'ch', 'co', 'cr', 'cz', 'dk', 'ec', 'ee', 'de', 'gr', 'gt', 'hu', 'is', 'in', 'id', 'ir', 'it', 'jp', 'lv', 'lt', 'my', 'mt', 'mx', 'mc', 'no', 'om', 'pe', 'pl', 'pt', 'ro', 'ru', 'sg', 'si', 'kr', 'sp', 'se', 'ch', 'tw', 'nl', 'tr', 'ae', 'us', 'uy'],
  "name": "Issoyoimmo",
  "title": "ISSOYO s’engage à tout mettre en oeuvre pour trouver votre appartement!",
  "description": "Nous vous mettons en relation avec un intermédiaire capable de trouver ce que vous souhaitez localement (agent immobilier, communauté, etc…), juste pour vous. ",
  "description2": "PARCE QUE VOUS COMPTEZ POUR NOUS ",
  "type": "house",
  "image": ["/static/media/issoyo_logo.png"],
  "cat": ["perm"],
  "link": "https://www.expat.immo/",
  "__v": 0.0
}, {
  "allowed_country": ["fr"],
  "name": "Qonto",
  "title": "The neobank for SMEs and freelancers",
  "description": "The ideal banking alternative for freelancers, startups and SMEs, in replacement or in addition to your business banking account.",
  "description2": "",
  "type": "bank",
  "cat": ["business"],
  "image": ["/static/media/qonto.jpg"],
  "link": "https://www.awin1.com/cread.php?awinmid=12534&awinaffid=525947&p="
}, {
  "allowed_country": ["fr"],
  "name": "BforBank",
  "title": " ",
  "description": "Filiale du groupe Crédit Agricole, BforBank est une banque 100% en ligne proposant des produits de banque au quotidien (comme la carte Visa Premier gratuite)",
  "description2": "une offre d’épargne complète, des offres de crédit (consommation ou immobilier), et des services personnalisés ainsi qu’un accompagnement 7/24",
  "type": "bank",
  "cat": ["personnal"],
  "image": ["/static/media/bforbank.png"],
  "link": "https://www.awin1.com/cread.php?s=2385497&v=13267&q=343321&r=525947"
}, {
  "allowed_country": ["fr"],
  "name": "BNP",
  "title": "La banque d'un monde qui change",
  "description": "Enjoy an exclusive offer: free card + Essential Services \"Free Spirit\" offered for one year for an account opening online or in BNP Paribas branch!",
  "description2": "Offer valid until 30 of June 2019",
  "type": "bank",
  "cat": ["personnal"],
  "image": ["/static/media/BNPLogo.jpg"],
  "link": "https://tracking.publicidees.com/clic.php?promoid=37985&progid=1598&partid=57757"
}, {
  "allowed_country": ["fr"],
  "name": "HelloBank",
  "title": "La banque Mobile, comme vous",
  "description": "Hello bank! is the 100% digital offer of BNP Paribas. This is a new approach to banking, simpler, faster and that meets the needs of customers always connected on their mobile and on the web.",
  "description2": " ",
  "type": "bank",
  "cat": ["personnal"],
  "image": ["/static/media/HelloBankLogo.jpg"],
  "link": "https://tracking.publicidees.com/clic.php?promoid=122093&progid=2934&partid=57757"
}, {
  "allowed_country": ["fr", "au", "at", "bh", "be", "br", "bg", "cm", "ca", "hr", "cy", "cz", "dk", "ee", "fi", "de", "gi", "gr", "hk", "hu", "is", "ie", "it", "jp", "jo", "kw", "lv", "lt", "lu", "mt", "nl", "nz", "no", "om", "pl", "pt", "qa", "ro", "sa", "sg", "sk", "so", "za", "kr", "sp", "se", "ch", "tw", "gb", "us"],
  "name": "Worldremit",
  "title": " ",
  "description": "WorldRemit is a leading global digital money transfer service that enables customers to send remittances to family and friends from 50+ countries to more than 150 receiving destinations.",
  "description2": " Welcome offer: no fees on your first money transfer using code FREE ",
  "type": "bank",
  "cat": ["personnal"],
  "image": ["/static/media/Worldremit_logo.png"],
  "link": "https://www.awin1.com/cread.php?awinmid=5877&awinaffid=525947&clickref=&p=https%3A%2F%2Fwww.worldremit.com%2Fen%2Faccount%2Fsignup"
}, {
  "allowed_country": ["be", "bh", "sp", "at", "cy", "ee", "fi", "de", "gi", "is", "lv", "li", "lt", "lu", "mt", "mc", "nl", "kr", "pt", "qa", "sm", "sk", "si", "es", "au", "bd", "br", "bg", "ca", "cl", "cn", "cm", "hr", "cz", "cy", "dk", "eg", "ge", "gh", "gi", "gr", "hk", "om", "hu", "in", "id", "ie", "il", "it", "jp", "jo", "ke", "my", "mx", "ma", "np", "nz", "ng", "no", "pk", "pe", "pn", "pl", "ro", "ru", "sk", "si", "sg", "za", "lk", "se", "ch", "th", "tr", "ua", "gb", "us", "vn", "ae", "tw", 'gb', 'ca', 'ar', 'au', 'at', 'be', 'kh', 'ch', 'co', 'cr', 'cz', 'dk', 'ec', 'ee', 'de', 'gr', 'gt', 'hu', 'is', 'in', 'id', 'ir', 'it', 'jp', 'lv', 'lt', 'my', 'mt', 'mx', 'mc', 'no', 'om', 'pe', 'pl', 'pt', 'ro', 'ru', 'sg', 'si', 'kr', 'sp', 'se', 'ch', 'tw', 'nl', 'tr', 'ae', 'us', 'uy'],
  "name": "Issoyobank",
  "title": "ISSOYO s’engage à tout mettre en oeuvre pour trouver votre appartement!",
  "description": "Nous vous mettons en relation avec un intermédiaire capable de trouver ce que vous souhaitez localement (agent immobilier, communauté, etc…), juste pour vous. ",
  "description2": "PARCE QUE VOUS COMPTEZ POUR NOUS ",
  "type": "bank",
  "image": ["/static/media/issoyo_logo.png"],
  "cat": ["personnal", "business"],
  "link": "https://www.expat.immo/",
  "__v": 0.0
}];
module.exports.serviceList = serviceList;