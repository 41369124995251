import React from 'react';
import { FormattedMessage } from 'react-intl';
import ContactForm from './ContactForm';
import Button from '@material-ui/core/Button';
import Issoyo_logo from '../../../assets/img/fav_icon32@2x.png';

require('./Home.scss');

export class SmSectionfive extends React.Component {
    constructor() {
        super();
        this.routeChange = this.routeChange.bind(this);
    }
    
    routeChange() {
        this.props.history.push('/services');
    }

    render() {
        return (
            <section className="section section-5">
                <div className="title">
                    <div className="contact-us">
                        <FormattedMessage id="home.titleContactUs" defaultMessage="Contact us" />
                    </div>
                </div>
                <div className="ContactForm">
                    <ContactForm/>
                </div>
                <div className="title">
                    <div className="relocation-is-now-ea">
                        <FormattedMessage id="home.titleRelocationEasy" defaultMessage="Relocation is now easy" />
                    </div>
                </div>
                <div className="container_start-button">
                <Button variant="contained" color="primary" className="button-start" onClick={this.routeChange}>
                        <img src={Issoyo_logo} alt='' style={{ width: '11%', marginRight: '5%' }}></img>
                        <FormattedMessage id="sectionf.start"></FormattedMessage>
                    </Button>
                </div>
            </section>
        )
    }
}
