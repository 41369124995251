import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ReactPhoneInput from 'react-phone-input-2';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Issoyo_logo from '../../../../assets/img/fav_icon32@2x.png';
import { withAlert } from 'react-alert';
import { SingleDatePicker } from 'react-dates';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { RadioGroup } from '@material-ui/core';



require('./Issoyoservice.scss');

const styles = {
    root: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    selectMenu: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    input: {
        borderColor: '#3e45948a !important',
    },
    rootCheck: {
        color: '#836bc1bf !important',
        '&$checked': {
            color: '#836bc1bf !important',
        },
    },
};


class Issoyoservicemodal extends Component {
    constructor() {
        super();
        this.state = {
            name: '',
            firstname: '',
            phone: '00',
            email: '',
            company: '',
            destination: '',
            comment: '',
            type: '',
            budget: '',
            room: 0,
            area: '',
            article: '',
            errorRequired: false,
            errorName: false,
            errorFirstname: false,
            errorDestination: false,
            errorBudget: false,
            errorEmail: false,
            errorPhone: false,
            errorSituation: false,
            errorArticle: false,
            date: null,
            type: '',
            situation: '',
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.select_form = this.select_form.bind(this);
        this.checkError = this.checkError.bind(this);
        this.addOne = this.addOne.bind(this);
        this.removeOne = this.removeOne.bind(this);
    }

    select_form() {
        const { classes } = this.props;
        if (this.props.serviceName === 'transport') {
            return (
                <div className="row destination" style={{ justifyContent: "space-between" }}>
                    <div className="input appartcity_destination">
                        <FormattedMessage id="ExpatimmoModal.destination" >
                            {msg => (<TextField
                                required
                                error={this.state.errorDestination}
                                id="outlined-with-placeholder"
                                label={msg}
                                className="appartcity_destination-list"
                                name="destination"
                                margin="normal"
                                variant="outlined"
                                value={this.state.destination}
                                InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                onChange={this.handleChange}
                            />)}
                        </FormattedMessage>
                    </div>
                    <div className="radio apparcity_type">
                        <FormLabel component="legend"><FormattedMessage id="Carmodal.service" defaultMessage="Service required" /></FormLabel>
                        <RadioGroup aria-label="position" name="type" value={this.state.type} onChange={this.handleChange} row>
                            <FormControlLabel
                                value="Transport public"
                                control={<Radio color="primary" />}
                                label={<FormattedMessage id="Carmodal.public" defaultMessage="Public transportation" />}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="Bus"
                                control={<Radio color="primary" />}
                                label={<FormattedMessage id="Carmodal.bus" defaultMessage="Bus" />}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="Train"
                                control={<Radio color="primary" />}
                                label={<FormattedMessage id="Carmodal.train" defaultMessage="Train" />}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="Private driver"
                                control={<Radio color="primary" />}
                                label={<FormattedMessage id="Carmodal.private" defaultMessage="Private driver" />}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="Taxi"
                                control={<Radio color="primary" />}
                                label={<FormattedMessage id="Carmodal.taxi" defaultMessage="Taxi" />}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="Rent a car"
                                control={<Radio color="primary" />}
                                label={<FormattedMessage id="Carmodal.rent" defaultMessage="Rent a car" />}
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    </div>
                    <div className="appartcity_date">
                        <SingleDatePicker
                            date={this.state.date} // momentPropTypes.momentObj or null
                            onDateChange={date => this.setState({ date })} // PropTypes.func.isRequired
                            focused={this.state.focused} // PropTypes.bool
                            onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                            id="your_unique_id" // PropTypes.string.isRequired,
                            showDefaultInputIcon
                            numberOfMonths={1}
                            openDirection="up"
                            placeholder="Date"
                        />
                    </div>
                </div>
            )
        } else if (this.props.serviceName === 'telecom') {
            return (
                <div className="radio apparcity_type">
                   {/* <FormLabel component="legend"><FormattedMessage id="Carmodal.service" /></FormLabel> */}
                    <RadioGroup aria-label="position" name="type" value={this.state.type} onChange={this.handleChange} row>
                        <FormControlLabel
                            value="Forfait mobile"
                            control={<Radio color="primary" />}
                            label={<FormattedMessage id="Telecomodal.forfait" defaultMessage="Lineplan" />}
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="Prepaid"
                            control={<Radio color="primary" />}
                            label={<FormattedMessage id="Telecomodal.prepaid" defaultMessage="Prepaid" />}
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="Plan + mobile"
                            control={<Radio color="primary" />}
                            label={<FormattedMessage id="Telecomodal.mobileplan" defaultMessage="Plan + mobile" />}
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="Mobile only"
                            control={<Radio color="primary" />}
                            label={<FormattedMessage id="Telecomodal.mobile" defaultMessage="Mobile only" />}
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="Broadband"
                            control={<Radio color="primary" />}
                            label={<FormattedMessage id="Telecomodal.broadband" defaultMessage="Broadband" />}
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="Mobile wifi"
                            control={<Radio color="primary" />}
                            label={<FormattedMessage id="Telecomodal.wifi" defaultMessage="Mobile wifi" />}
                            labelPlacement="end"
                        />
                    </RadioGroup>
                </div>
            )
        } else if (this.props.serviceName === 'insurance') {
            return (
                <div className="row destination" style={{ justifyContent: "space-between" }}>
                    <div className="input appartcity_destination">
                        <FormattedMessage id="ExpatimmoModal.destination" >
                            {msg => (<TextField
                                required
                                error={this.state.errorDestination}
                                id="outlined-with-placeholder"
                                label={msg}
                                className="appartcity_destination-list"
                                name="destination"
                                margin="normal"
                                variant="outlined"
                                value={this.state.destination}
                                InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                onChange={this.handleChange}
                            />)}
                        </FormattedMessage>
                    </div>
                    <div className="radio apparcity_type">
                        <FormLabel component="legend"><FormattedMessage id="Carmodal.service" /></FormLabel>
                        <RadioGroup aria-label="position" name="type" value={this.state.type} onChange={this.handleChange} row>
                            <FormControlLabel
                                value="Habitation"
                                control={<Radio color="primary" />}
                                label={<FormattedMessage id="insurancemodal.habitation" defaultMessage="Home" />}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="Health"
                                control={<Radio color="primary" />}
                                label={<FormattedMessage id="insurancemodal.health" defaultMessage="Health" />}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="car / motorbike"
                                control={<Radio color="primary" />}
                                label={<FormattedMessage id="insurancemodal.car" defaultMessage="Car / motorbike" />}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="Loan"
                                control={<Radio color="primary" />}
                                label={<FormattedMessage id="insurancemodal.loan" defaultMessage="Loan" />}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="e-scooter"
                                control={<Radio color="primary" />}
                                label={<FormattedMessage id="insurancemodal.scooter" defaultMessage="E-Scooter" />}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="travel"
                                control={<Radio color="primary" />}
                                label={<FormattedMessage id="insurancemodal.travel" defaultMessage="Travel" />}
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    </div>
                </div>
            )
        } else if (this.props.serviceName === 'ticket') {
            return (
                <div className="row destination" style={{ justifyContent: "space-between" }}>
                    <div className="input appartcity_destination">
                        <FormattedMessage id="ExpatimmoModal.destination" >
                            {msg => (<TextField
                                required
                                error={this.state.errorDestination}
                                id="outlined-with-placeholder"
                                label={msg}
                                className="appartcity_destination-list"
                                name="destination"
                                margin="normal"
                                variant="outlined"
                                value={this.state.destination}
                                InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                onChange={this.handleChange}
                            />)}
                        </FormattedMessage>
                    </div>
                </div>
            )
        } else if (this.props.serviceName === 'energy') {
            return (
                <div className="row destination" style={{ justifyContent: "space-between" }}>
                    <div className="input appartcity_destination">
                        <FormattedMessage id="ExpatimmoModal.destination" >
                            {msg => (<TextField
                                required
                                error={this.state.errorDestination}
                                id="outlined-with-placeholder"
                                label={msg}
                                className="appartcity_destination-list"
                                name="destination"
                                margin="normal"
                                variant="outlined"
                                value={this.state.destination}
                                InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                onChange={this.handleChange}
                            />)}
                        </FormattedMessage>
                    </div>
                    <div className="radio apparcity_type">
                        <FormLabel component="legend"><FormattedMessage id="Carmodal.service" /></FormLabel>
                        <RadioGroup aria-label="position" name="type" value={this.state.type} onChange={this.handleChange} row>
                            <FormControlLabel
                                value="Electricity"
                                control={<Radio color="primary" />}
                                label={<FormattedMessage id="Energymodal.electricity" defaultMessage="Electricity" />}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="Gaz"
                                control={<Radio color="primary" />}
                                label={<FormattedMessage id="Energymodal.gaz" defaultMessage="Gaz" />}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="Eau"
                                control={<Radio color="primary" />}
                                label={<FormattedMessage id="Energymodal.water" defaultMessage="Water" />}
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    </div>
                </div>
            )
        } else if (this.props.serviceName === 'internet') {
            return (
                <div className="row destination" style={{ justifyContent: "space-between" }}>
                    <div className="input appartcity_destination">
                        <FormattedMessage id="ExpatimmoModal.destination" >
                            {msg => (<TextField
                                required
                                error={this.state.errorDestination}
                                id="outlined-with-placeholder"
                                label={msg}
                                className="appartcity_destination-list"
                                name="destination"
                                margin="normal"
                                variant="outlined"
                                key="test"
                                value={this.state.destination}
                                InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                onChange={this.handleChange}
                            />)}
                        </FormattedMessage>
                    </div>
                    <div className="radio apparcity_type">
                        <FormLabel component="legend"><FormattedMessage id="Carmodal.service" /></FormLabel>
                        <RadioGroup aria-label="position" name="type" value={this.state.type} onChange={this.handleChange} row>
                            <FormControlLabel
                                value="Internet à domicile"
                                control={<Radio color="primary" />}
                                label={<FormattedMessage id="internetmodal.broadband" defaultMessage="Broadband" />}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="Internet mobile"
                                control={<Radio color="primary" />}
                                label={<FormattedMessage id="internetmodal.mobile" defaultMessage="Internet mobile" />}
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    </div>
                </div>
            )
        } else if (this.props.serviceName === 'bank') {
            return (
                <div className="row destination" style={{ justifyContent: "space-between" }}>
                    <div className="input appartcity_destination">
                        <FormattedMessage id="ExpatimmoModal.destination" >
                            {msg => (<TextField
                                required
                                error={this.state.errorDestination}
                                id="outlined-with-placeholder"
                                label={msg}
                                className="appartcity_destination-list"
                                name="destination"
                                margin="normal"
                                variant="outlined"
                                value={this.state.destination}
                                InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                onChange={this.handleChange}
                            />)}
                        </FormattedMessage>
                    </div>
                    <div className="radio apparcity_type">
                        <FormLabel component="legend"><FormattedMessage id="Carmodal.service" /></FormLabel>
                        <RadioGroup aria-label="position" name="type" value={this.state.type} onChange={this.handleChange} row>
                            <FormControlLabel
                                value="Current account"
                                control={<Radio color="primary" />}
                                label={<FormattedMessage id="bankmodal.courant" defaultMessage="Current account" />}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="Saving account"
                                control={<Radio color="primary" />}
                                label={<FormattedMessage id="bankmodal.saving" defaultMessage="Saving account" />}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="Debit / credit card"
                                control={<Radio color="primary" />}
                                label={<FormattedMessage id="bankmodal.debit" defaultMessage="Debit / credit card" />}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="Stock exchange"
                                control={<Radio color="primary" />}
                                label={<FormattedMessage id="bankmodal.stock" defaultMessage="Stock exchange" />}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="Foreign exchange"
                                control={<Radio color="primary" />}
                                label={<FormattedMessage id="bankmodal.foreign" defaultMessage="Foreign exchange" />}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="Others"
                                control={<Radio color="primary" />}
                                label={<FormattedMessage id="bankmodal.Others" defaultMessage="Others" />}
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    </div>
                </div>
            )
        } else if (this.props.serviceName === 'house') {
            return (
                <React.Fragment>
                    <div className="row destination" style={{ justifyContent: "space-between" }}>
                        <div className="input appartcity_destination">
                            <FormattedMessage id="ExpatimmoModal.destination" >
                                {msg => (<TextField
                                    required
                                    error={this.state.errorDestination}
                                    id="outlined-with-placeholder"
                                    label={msg}
                                    className="appartcity_destination-list"
                                    name="destination"
                                    margin="normal"
                                    variant="outlined"
                                    value={this.state.destination}
                                    InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                    onChange={this.handleChange}
                                />)}
                            </FormattedMessage>
                        </div>
                        <div className="input appartcity_budget">
                            <FormattedMessage id="ExpatimmoModal.area" >
                                {msg => (<TextField
                                    id="outlined-with-placeholder"
                                    label={msg}
                                    className="appartcity_destination-list"
                                    name="area"
                                    margin="normal"
                                    variant="outlined"
                                    value={this.state.area}
                                    InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                    onChange={this.handleChange}
                                />)}
                            </FormattedMessage>
                        </div>
                        <div className="appartcity_date">
                            <SingleDatePicker
                                date={this.state.date} // momentPropTypes.momentObj or null
                                onDateChange={date => this.setState({ date })} // PropTypes.func.isRequired
                                focused={this.state.focused} // PropTypes.bool
                                onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                                id="your_unique_id" // PropTypes.string.isRequired,
                                showDefaultInputIcon
                                numberOfMonths={1}
                                openDirection="up"
                                placeholder="Check-in"
                            />
                        </div>
                        <div className="input appartcity_budget">
                            <FormattedMessage id="ExpatimmoModal.budget" >
                                {msg => (<TextField
                                    required
                                    error={this.state.errorBudget}
                                    id="outlined-adornment-amount"
                                    className="appartcity_budget-input"
                                    variant="outlined"
                                    label={msg}
                                    name="budget"
                                    margin="normal"
                                    value={this.state.budget}
                                    onChange={this.handleChange}
                                    style={{ width: '195px' }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                        classes: { root: classes.root, notchedOutline: classes.input }
                                    }}
                                />)}
                            </FormattedMessage>
                        </div>
                    </div>
                    <div className="input appartcity_destination">
                        <div className="appartcity_nbr_adult">
                            <div className="appartcity_nbr_adult-label"><FormattedMessage id="ExpatimmoModal.room" /></div>
                            <div className="appartcity_nbr_adult-wrap">
                                <button className="appartcity_nbr_adult-button" onClick={() => this.removeOne('adult')}><i className="fas fa-minus"></i></button>
                                <div className="appartcity_nbr_adult-field">{this.state.room}</div>
                                <button className="appartcity_nbr_adult-button" onClick={() => this.addOne('adult')}><i className="fas fa-plus"></i></button>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        } else if (this.props.serviceName === 'blog') {
            return (
                    <div className="input appartcity_comment">
                        <FormattedMessage id="ExpatimmoModal.article" >
                            {msg => (<TextField
                                required
                                multiline
                                rows="4"
                                rowsMax="4"
                                error={this.state.errorArticle}
                                id="outlined-with-placeholder"
                                label={msg}
                                className="appartcity_comment-input"
                                name="article"
                                margin="normal"
                                variant="outlined"
                                value={this.state.article}
                                InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                onChange={this.handleChange}
                            />)}
                        </FormattedMessage>
                    </div>
            )
        }
    }

    addOne(name) {
        if (name === 'adult') {
            if (this.state.room < 10) {
                const nbr = this.state.room + 1;
                this.setState({ room: nbr });
            }
        }
    }

    removeOne(name) {
        if (name === 'adult') {
            if (this.state.room > 0) {
                const nbr = this.state.room - 1;
                this.setState({ room: nbr });
            }
        }
    }

    componentWillMount() {
        this.setState({
            name: this.props.auth.user.name,
            firstname: this.props.auth.user.firstname,
            email: this.props.auth.user.email,
            phone: this.props.auth.user.phone
        })
    }

    checkError() {
        var errorName, errorFirstname, errorPhone, errorEmail, errorDestination, errorBudget, errorSituation, errorRequired, errorArticle = false;
        if (!this.state.name) {
            errorName = true;
            errorRequired = true;
        }
        if (!this.state.firstname) {
            errorFirstname = true
            errorRequired = true;
        }
        if (!this.state.phone) {
            errorPhone = true
            errorRequired = true;
        }
        if (!this.state.email) {
            errorEmail = true
            errorRequired = true;
        }
        if (this.props.serviceName !== 'blog' && this.props.serviceName !== 'telecom' && this.state.destination === ''  ) {
            errorDestination = true
            errorRequired = true;
        } 
        if (this.state.situation === '') {
            errorSituation = true
            errorRequired = true;
        } 
        if (this.state.budget === '' && this.props.serviceName === 'house') {
            errorBudget = true
            errorRequired = true;
        }
        if (this.state.article === '' && this.props.serviceName === 'blog') {
            errorArticle = true
            errorRequired = true;
        }
        this.setState({
            errorEmail: errorEmail,
            errorPhone: errorPhone,
            errorName: errorName,
            errorFirstname: errorFirstname,
            errorRequired: errorRequired,
            errorDestination: errorDestination,
            errorBudget: errorBudget,
            errorSituation: errorSituation,
            errorArticle: errorArticle,
        })
        if (errorRequired === true) {
            return true;
        }
        return false;
    }

    handleClick() {
        if (this.checkError()) {
            return;
        } else {
            const rq = {
                provider: this.props.serviceName,
                name: this.state.name,
                firstname: this.state.firstname,
                email: this.state.email,
                phone: this.state.phone,
                localisation: this.state.destination,
                society: this.state.company,
                description: this.state.comment,
                country: this.props.auth.user.country,
                type: this.state.type,
                date: this.state.date,
                situation: this.state.situation,
                article: this.state.article
            }
            axios.post('api/mail/sendAppartEmail', rq);
            this.props.onClose();
            this.props.alert.show('Your message has been send', { type: 'info' });
        }
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="appartcity">
                <div className="appartcity_title"><FormattedMessage id="ExpatimmoModal.title" /></div>
                <div className="appartcity_subtitle"><FormattedMessage id="Issoyoimmo.subtitle" /></div>
                <hr></hr>
                <div>
                    <div className="error required" style={this.state.errorRequired ? {} : { display: 'none' }}>
                        <FormattedMessage id="registerPart1.error.required" />
                    </div>
                    <div className="apparticity_information_subtitle"><i className="fas fa-user"></i> <FormattedMessage id="ExpatimmoModal.profil.information" /></div>
                    <div className="appartcity_form">
                        <div className="row Name" style={{ justifyContent: "space-between" }}>
                            <div className="input appartcity_name">
                                <FormattedMessage id="ExpatimmoModal.name" >
                                    {msg => (<TextField
                                        required
                                        id="outlined-with-placeholder"
                                        label={msg}
                                        className="appartcity_name-input"
                                        name="name"
                                        error={this.state.errorName}
                                        margin="normal"
                                        variant="outlined"
                                        value={this.state.name}
                                        InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                        onChange={this.handleChange}
                                    />)}
                                </FormattedMessage>
                            </div>
                            <div className="input appartcity_firstname">
                                <FormattedMessage id="ExpatimmoModal.firstname"  >
                                    {msg => (<TextField
                                        required
                                        id="outlined-with-placeholder"
                                        label={msg}
                                        error={this.state.errorFirstname}
                                        className="appartcity_firstname-input"
                                        name="firstname"
                                        margin="normal"
                                        variant="outlined"
                                        value={this.state.firstname}
                                        InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                        onChange={this.handleChange}
                                    />)}
                                </FormattedMessage>
                            </div>
                        </div>
                        <div className="row Email" style={{ justifyContent: "space-between" }}>
                            <div className="input appartcity_email">
                                <FormattedMessage id="ExpatimmoModal.email"  >
                                    {msg => (<TextField
                                        required
                                        id="outlined-with-placeholder"
                                        label={msg}
                                        className="appartcity_email-input"
                                        name="email"
                                        margin="normal"
                                        error={this.state.errorEmail}
                                        variant="outlined"
                                        value={this.state.email}
                                        InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                        onChange={this.handleChange}
                                    />)}
                                </FormattedMessage>
                            </div>
                            <div className="input appartcity_phone">
                                <FormattedMessage id="ExpatimmoModal.phone" >
                                    {msg => (<ReactPhoneInput
                                        defaultCountry='fr'
                                        regions={['europe', 'america', 'asia', 'africa']}
                                        preferredCountries={['fr', 'us', 'kr', 'gb', 'cn']}
                                        disableAreaCodes
                                        enableSearchField
                                        value={this.state.phone}
                                        className="appartcity_phone-input"
                                        placeholder={msg}
                                        inputExtraProps={{
                                            name: 'phone',
                                            onChange: this.handleChange,
                                        }}
                                    />)}
                                </FormattedMessage>
                            </div>
                        </div>
                        <div className="input appartcity_company">
                            <FormattedMessage id="energymodal.situation" >
                                {msg => (<TextField
                                    id="outlined-select-currency"
                                    select
                                    required
                                    label={msg}
                                    error={this.state.errorSituation}
                                    className="appartcity_company-input"
                                    value={this.state.situation}
                                    name="situation"
                                    onChange={this.handleChange}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    <MenuItem disabled value={"none"}>Select situation</MenuItem>
                                    <MenuItem classes={{ root: classes.root }} value="student">
                                        <FormattedMessage id="energymodal.student" />
                                    </MenuItem>
                                    <MenuItem classes={{ root: classes.root }} value="retired">
                                        <FormattedMessage id="energymodal.retired" />
                                    </MenuItem>
                                    <MenuItem classes={{ root: classes.root }} value="employee">
                                        <FormattedMessage id="energymodal.employee" />
                                    </MenuItem>
                                    <MenuItem classes={{ root: classes.root }} value="independant">
                                        <FormattedMessage id="energymodal.independant" />
                                    </MenuItem>
                                </TextField>)}
                            </FormattedMessage>
                        </div>
                        <div className="input appartcity_company">
                            <FormattedMessage id="ExpatimmoModal.company" >
                                {msg => (<TextField
                                    id="outlined-with-placeholder"
                                    label={msg}
                                    className="appartcity_company-input"
                                    name="company"
                                    margin="normal"
                                    variant="outlined"
                                    value={this.state.company}
                                    InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                    onChange={this.handleChange}
                                />)}
                            </FormattedMessage>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="apparticity_information_subtitle"><i className="fas fa-map-marker-alt"></i> <FormattedMessage id="Carmodal.service" /></div>
                    <div className="appartcity_form">
                        {this.select_form()}
                        <div className="input appartcity_comment">
                            <FormattedMessage id="ExpatimmoModal.comment" >
                                {msg => (<TextField
                                    id="outlined-with-placeholder"
                                    label={msg}
                                    multiline
                                    rows="4"
                                    rowsMax="4"
                                    className="appartcity_comment-input"
                                    name="comment"
                                    margin="normal"
                                    variant="outlined"
                                    value={this.state.comment}
                                    InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                    onChange={this.handleChange}
                                />)}
                            </FormattedMessage>
                        </div>
                    </div>
                </div>
                <div className="footer" style={{ textAlign: 'center' }}>
                    <Button variant="contained" color="primary" className="button" onClick={this.handleClick}>
                        <img src={Issoyo_logo} alt='' style={{ width: '11%', marginRight: '5%' }}></img>
                        <FormattedMessage id="house.Appartcity.open" />
                    </Button>
                </div>
            </div>
        )
    }
}

Issoyoservicemodal.propTypes = {
    auth: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(
    mapStateToProps, {}
)(withStyles(styles)(withAlert(Issoyoservicemodal)));