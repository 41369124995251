import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ReactPhoneInput from 'react-phone-input-2';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Issoyo_logo from '../../../../../assets/img/fav_icon32@2x.png';
import { RadioGroup } from '@material-ui/core';
import { withAlert } from 'react-alert';


require('./ExpatimmoModal.scss');

const styles = {
    root: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    selectMenu: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    input: {
        borderColor: '#3e45948a !important',
    },
    rootCheck: {
        color: '#836bc1bf !important',
        '&$checked': {
            color: '#836bc1bf !important',
        },
    },
};

class ExpatimmoModal extends Component {
    constructor() {
        super();
        this.state = {
            name: '',
            firstname: '',
            phone: '',
            email: '',
            company: '',
            destination: '',
            comment: '',
            budget: '',
            type: '',
            errorRequired: false,
            errorName: false,
            errorFirstname: false,
            errorEmail: false,
            errorPhone: false,
            errorDestination: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.checkError = this.checkError.bind(this);
    }

    componentWillMount() {
        this.setState({
            name: this.props.auth.user.name,
            firstname: this.props.auth.user.firstname,
            email: this.props.auth.user.email,
            phone: this.props.auth.user.phone
        })
    }

    checkError() {
        var errorName, errorFirstname, errorPhone, errorEmail, errorDestination, errorRequired = false;
        console.log(this.state.name)
        if (!this.state.name) {
            errorName = true;
            errorRequired = true;
        }
        if (!this.state.firstname) {
            errorFirstname = true
            errorRequired = true;
        }
        if (!this.state.phone) {
            errorPhone = true
            errorRequired = true;
        }
        if (!this.state.email) {
            errorEmail = true
            errorRequired = true;
        }
        if (this.state.destination === '') {
            errorDestination = true
            errorRequired = true;
        }
        this.setState({
            errorEmail: errorEmail,
            errorPhone: errorPhone,
            errorName: errorName,
            errorFirstname: errorFirstname,
            errorDestination: errorDestination,
            errorRequired: errorRequired,
        })
        if (errorRequired === true) {
            return true;
        }
        return false;
    }

    handleClick() {
        if (this.checkError()) {
            return;
        } else {
            const rq = {
                provider: 'Expat.immo',
                name: this.state.name,
                firstname: this.state.firstname,
                email: this.state.email,
                phone: this.state.phone,
                localisation: this.state.destination,
                society: this.state.company,
                description: this.state.comment,
                type: this.state.type,
                budget: this.state.budget,
            }
            axios.post('api/mail/sendAppartEmail', rq);
            this.props.onClose();
            this.props.alert.show('Your message has been send', { type: 'info' });
        }
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="appartcity">
                <div className="appartcity_title"><FormattedMessage id="ExpatimmoModal.title" defaultMessage="Application form" /></div>
                <div className="appartcity_subtitle"><FormattedMessage id="ExpatimmoModal.subtitle" defaultMessage="L’agence Expat.immo vous contactera sous 24h." /></div>
                <hr></hr>
                <div>
                    <div className="error required" style={this.state.errorRequired ? {} : { display: 'none' }}>
                        <FormattedMessage id="registerPart1.error.required" />
                    </div>
                    <div className="apparticity_information_subtitle"><i className="fas fa-user"></i> <FormattedMessage id="ExpatimmoModal.profil.information" defaultMessage="Your information" /></div>
                    <div className="appartcity_form">
                        <div className="row Name" style={{ justifyContent: "space-between" }}>
                            <div className="input appartcity_name">
                                <FormattedMessage id="ExpatimmoModal.name" defaultMessage="Name" >
                                    {msg => (<TextField
                                        required
                                        id="outlined-with-placeholder"
                                        label={msg}
                                        className="appartcity_name-input"
                                        name="name"
                                        error={this.state.errorName}
                                        margin="normal"
                                        variant="outlined"
                                        value={this.state.name}
                                        InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                        onChange={this.handleChange}
                                    />)}
                                </FormattedMessage>
                            </div>
                            <div className="input appartcity_firstname">
                                <FormattedMessage id="ExpatimmoModal.firstname" defaultMessage="First name" >
                                    {msg => (<TextField
                                        required
                                        id="outlined-with-placeholder"
                                        label={msg}
                                        error={this.state.errorFirstname}
                                        className="appartcity_firstname-input"
                                        name="firstname"
                                        margin="normal"
                                        variant="outlined"
                                        value={this.state.firstname}
                                        InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                        onChange={this.handleChange}
                                    />)}
                                </FormattedMessage>
                            </div>
                        </div>
                        <div className="row Email" style={{ justifyContent: "space-between" }}>
                            <div className="input appartcity_email">
                                <FormattedMessage id="ExpatimmoModal.email" defaultMessage="Email" >
                                    {msg => (<TextField
                                        required
                                        id="outlined-with-placeholder"
                                        label={msg}
                                        className="appartcity_email-input"
                                        name="email"
                                        margin="normal"
                                        error={this.state.errorEmail}
                                        variant="outlined"
                                        value={this.state.email}
                                        InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                        onChange={this.handleChange}
                                    />)}
                                </FormattedMessage>
                            </div>
                            <div className="input appartcity_phone">
                                <FormattedMessage id="ExpatimmoModal.phone" defaultMessage="Phone number" >
                                    {msg => (<ReactPhoneInput
                                        defaultCountry='fr'
                                        regions={['europe', 'america', 'asia', 'africa']}
                                        preferredCountries={['fr', 'us', 'kr', 'gb', 'cn']}
                                        disableAreaCodes
                                        enableSearchField
                                        value={this.state.phone}
                                        className="appartcity_phone-input"
                                        placeholder={msg}
                                        inputExtraProps={{
                                            name: 'phone',
                                            onChange: this.handleChange,
                                        }}
                                    />)}
                                </FormattedMessage>
                            </div>
                        </div>
                        <div className="input appartcity_company">
                            <FormattedMessage id="ExpatimmoModal.company" defaultMessage="Company (optional)" >
                                {msg => (<TextField
                                    id="outlined-with-placeholder"
                                    label={msg}
                                    className="appartcity_company-input"
                                    name="company"
                                    margin="normal"
                                    variant="outlined"
                                    value={this.state.company}
                                    InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                    onChange={this.handleChange}
                                />)}
                            </FormattedMessage>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="apparticity_information_subtitle"><i className="fas fa-map-marker-alt"></i> <FormattedMessage id="ExpatimmoModal.destination.information" defaultMessage="Destination information" /></div>
                    <div className="appartcity_form">
                        <div className="row destination" style={{ justifyContent: "space-between" }}>
                            <div className="input appartcity_destination">
                                <FormattedMessage id="ExpatimmoModal.destination" defaultMessage="Ville"  >
                                    {msg => (<TextField
                                        required
                                        id="outlined-with-placeholder"
                                        label={msg}
                                        className="appartcity_destination-list"
                                        name="destination"
                                        margin="normal"
                                        variant="outlined"
                                        value={this.state.destination}
                                        error={this.state.errorDestination}
                                        InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                        onChange={this.handleChange}
                                    />)}
                                </FormattedMessage>
                            </div>
                            <div className="input appartcity_budget">
                                <FormattedMessage id="ExpatimmoModal.budget" defaultMessage="Budget">
                                    {msg => (<TextField
                                        id="outlined-adornment-amount"
                                        className="appartcity_budget-input"
                                        variant="outlined"
                                        label={msg}
                                        name="budget"
                                        margin="normal"
                                        value={this.state.budget}
                                        onChange={this.handleChange}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                            classes: { root: classes.root, notchedOutline: classes.input }
                                        }}
                                    />)}
                                </FormattedMessage>
                            </div>
                            <div className="radio apparcity_type">
                                <FormLabel component="legend"><FormattedMessage id="ExpatimmoModal.type" defaultMessage="Que souhaitez vous ?" /></FormLabel>
                                <RadioGroup aria-label="position" name="type" value={this.state.type} onChange={this.handleChange} row>
                                    <FormControlLabel
                                        value="Acheter"
                                        control={<Radio color="primary" />}
                                        label={<FormattedMessage id="ExpatimmoModal.buy" defaultMessage="Acheter"/>}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="Vendre"
                                        control={<Radio color="primary" />}
                                        label={<FormattedMessage id="ExpatimmoModal.sell" defaultMessage="Vendre"/>}
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="Louer"
                                        control={<Radio color="primary" />}
                                        label={<FormattedMessage id="ExpatimmoModal.rent" defaultMessage="Louer"/>}
                                        labelPlacement="end"
                                    />
                                        <FormControlLabel
                                        value="Mettre en location"
                                        control={<Radio color="primary" />}
                                        label={<FormattedMessage id="ExpatimmoModal.torent" defaultMessage="Mettre en location"/>}
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </div>
                        </div>
                        <div className="input appartcity_comment test">
                            <FormattedMessage id="ExpatimmoModal.comment" defaultMessage="Description de ce que vous recherchez" >
                                {msg => (<TextField
                                    id="outlined-with-placeholder"
                                    label={msg}
                                    multiline
                                    rows="4"
                                    rowsMax="4"
                                    className="appartcity_comment-input"
                                    name="comment"
                                    margin="normal"
                                    variant="outlined"
                                    value={this.state.comment}
                                    InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                    onChange={this.handleChange}
                                />)}
                            </FormattedMessage>
                        </div>
                    </div>
                </div>
                <div className="footer" style={{ textAlign: 'center' }}>
                    <Button variant="contained" color="primary" className="button" onClick={this.handleClick}>
                        <img src={Issoyo_logo} alt='' style={{ width: '11%', marginRight: '5%' }}></img>
                        <FormattedMessage id="house.Appartcity.open" />
                    </Button>
                </div>
            </div>
        )
    }
}

ExpatimmoModal.propTypes = {
    auth: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(
    mapStateToProps, {}
)(withStyles(styles)(withAlert(ExpatimmoModal)));