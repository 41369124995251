import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TransferWiseModal from './TransferWise/TransferWiseModal';
import N26Modal from './N26/N26Modal';
import QontoModal from './Qonto/QontoModal';
import Bforbank from './Bforbank/Bforbank.jsx';
import BNPModal from './BNP/BNPModal.jsx';
import HelloBankModal from './HelloBank/HelloBankModal.jsx';
import WorldremitModal from './Worldremit/WorldremitModal.jsx';
import Issoyoservicemodal from '../Issoyoservice/Issoyoservicemodal.jsx';

class BankModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: [],
        }
        this.handleClickAccount = this.handleClickAccount.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    handleClickAccount(link){
        this.props.handleClickAccount(link);
    }

    onClose() {
        this.props.onCloseModal();
    };

    componentWillMount(){
        if (this.props.name === "TransfertWise") {
            this.setState({modal: <TransferWiseModal handleClickAccount={link => this.handleClickAccount(link)} index={this.props.index} language={this.props.language}/>});
        } else if (this.props.name === "N26") {
            this.setState({modal: <N26Modal bankType={this.props.bankType} handleClickAccount={link => this.handleClickAccount(link)} index={this.props.index} language={this.props.language}/>});
        } else if (this.props.name === "Qonto") {
            this.setState({modal: <QontoModal handleClickAccount={link => this.handleClickAccount(link)} index={this.props.index} language={this.props.language}/>});
        } else if (this.props.name === "BforBank") {
            this.setState({modal: <Bforbank handleClickAccount={link => this.handleClickAccount(link)} index={this.props.index} language={this.props.language}/>});
        } else if (this.props.name === "BNP") {
            this.setState({modal: <BNPModal handleClickAccount={link => this.handleClickAccount(link)} index={this.props.index} language={this.props.language}/>});
        } else if (this.props.name === "HelloBank") {
            this.setState({modal: <HelloBankModal handleClickAccount={link => this.handleClickAccount(link)} index={this.props.index} language={this.props.language}/>});
        } else if (this.props.name === "Worldremit") {
            this.setState({modal: <WorldremitModal handleClickAccount={link => this.handleClickAccount(link)} index={this.props.index} language={this.props.language}/>});
        } else if (this.props.name === "Issoyobank") {
            this.setState({modal: <Issoyoservicemodal serviceName='bank' onClose={this.onClose} index={this.props.index} language={this.props.language}/>});
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.modal}
            </React.Fragment>
        )
    }
}

BankModal.propTypes = {
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    service: state.services,
});

export default connect(
    mapStateToProps, {}
)(BankModal);