import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';

require('./TransferWiseModal.scss')
export default class TransferWisePricing extends Component {
    render() {
        return (
            <div>
                <table className="table table-bordered">
                    <tbody>
                        <tr>
                            <td><span className="text">
                            <FormattedMessage id="bank.TransfertWise.price.create" defaultMessage="Creating your account" />
                            </span></td>
                            <td><b><span className="text-success"><FormattedMessage id="bank.TransfertWise.price.free" defaultMessage="Free" /></span></b>
                            </td>
                        </tr>
                        <tr>
                            <td><span className="text">
                            <FormattedMessage id="bank.TransfertWise.price.currency" defaultMessage="Hold +40 currency balances" />
                           </span></td>
                            <td><span className="text-success"><FormattedMessage id="bank.TransfertWise.price.free" defaultMessage="Free" /></span>
                            </td>
                        </tr>
                        <tr>
                            <td><span className="text">
                            <FormattedMessage id="bank.TransfertWise.price.accountNumber" defaultMessage="Get a UK account number and sort code" />
                            </span></td>
                            <td><span className="text-success"><FormattedMessage id="bank.TransfertWise.price.free" defaultMessage="Free" /></span>
                            </td>
                        </tr>
                        <tr>
                            <td><span className="text">
                            <FormattedMessage id="bank.TransfertWise.price.routing" defaultMessage="Get a U.S. routing and wiring number" />
                           </span></td>
                            <td><span className="text-success"><FormattedMessage id="bank.TransfertWise.price.free" defaultMessage="Free" /></span>
                            </td>
                        </tr>
                        <tr>
                            <td><span className="text">
                            <FormattedMessage id="bank.TransfertWise.price.european" defaultMessage="Get a European IBAN" />
                            </span></td>
                            <td><span className="text-success"><FormattedMessage id="bank.TransfertWise.price.free" defaultMessage="Free" /></span>
                            </td>
                        </tr>
                        <tr>
                            <td><span className="text">
                            <FormattedMessage id="bank.TransfertWise.price.australian" defaultMessage="Get an Australian account and BSB number" />
                            </span></td>
                            <td><span className="text-success"><FormattedMessage id="bank.TransfertWise.price.free" defaultMessage="Free" /></span>
                            </td>
                        </tr>
                        <tr>
                            <td><span className="text">
                            <FormattedMessage id="bank.TransfertWise.price.newZealand" defaultMessage="Get a New Zealand account number" />
                            </span></td>
                            <td><span className="text-success"><FormattedMessage id="bank.TransfertWise.price.free" defaultMessage="Free" /></span>
                            </td>
                        </tr>
                        <tr>
                            <td><span className="text">
                            <FormattedMessage id="bank.TransfertWise.price.convert" defaultMessage="Convert currencies" />
                            Convert currencies</span></td>
                            <td><span className="text text-nowrap"><p>0.35%-2%</p>
                            </span>
                            </td>
                        </tr>
                        <tr>
                            <td><span className="text">
                            <FormattedMessage id="bank.TransfertWise.price.send" defaultMessage="Fixed fee to send money (varies by currency)" />
                            </span></td>
                            <td><span className="text text-nowrap"><p>65p</p>
                            </span>
                            </td>
                        </tr>
                        <tr>
                            <td><span className="text">
                            <FormattedMessage id="bank.TransfertWise.price.receive" defaultMessage="Receive money in EUR, USD, GBP, AUD &amp; NZD" />
                            </span></td>
                            <td><span className="text-success"><FormattedMessage id="bank.TransfertWise.price.free" defaultMessage="Free" /></span>
                            </td>
                        </tr>
                        <tr>
                            <td><span className="text">
                            <FormattedMessage id="bank.TransfertWise.price.add" defaultMessage="Add money to your account" />
                            </span></td>
                            <td><span className="text text-nowrap"><p>0%-0.2%</p>
                            </span>
                            </td>
                        </tr>
                    </tbody></table>
            </div>
        )
    }
}
