import React, { Component } from 'react'
import { Footer } from './Footer.jsx';

require('./Mention.scss')

export class Mention extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="mentions">
                <div className="signup_back"><i style={{color: '#593e96'}} className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                    <div>
                        <h1>Mentions légales</h1>
                        <div>
                            <h3>Publication du site </h3>
                            <p>ISSOYO</p>
                            <p>18, allée Julien Manceau</p>
                            <p>95580 Margency – France</p>
                            <p>mikael@issoyo.com</p>
                            <p>Directeur de la publication : Mikael Yitzhakov</p>
                        </div>
                        <div>
                            <h3>Hébergement du site</h3>
                            <p>Microsoft Ireland Operations Ltd</p>
                            <p>siège social : One Microsoft Place, South County Business Park, Leopardstown, Dublin 18, D18 PS21, Irlande. </p>
                            <p>Numéro d’enregistrement TVA FR00419423728</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}

export default (Mention);
