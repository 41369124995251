import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from 'react-responsive-modal';
import BankModal from './BankModal';
import ScrollUpButton from "react-scroll-up-button";
import bank_change from '../../../../assets/document/ISSOYO_guide_change_banque.pdf';
import bank_depot from '../../../../assets/document/ISSOYO_guide_depot_banque.pdf';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { promoList } from './promoList.js';
import TransferwiseLogo from '../../../../assets/img/providers/transfertWise_card_new.png';
import nlogo from '../../../../assets/img/providers/N26_Logo_White.png';
import qonto from '../../../../assets/img/providers/qonto.jpg';
import bforbank from '../../../../assets/img/providers/bforbank.png';
import bnp from '../../../../assets/img/providers/BNPLogo.jpg';
import worldremit from '../../../../assets/img/providers/Worldremit_logo.png';
import { issoyoimmo } from '../../../../assets/img/providers/issoyo_logo.png';
import { withStyles } from '@material-ui/core/styles';
const serviceList = require('../../../../../../server/service.js');


const styles = {
    root: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    selectMenu: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    input: {
        borderColor: '#3e45948a !important',
    },
    rootCheck: {
        color: '#836bc1bf !important',
        '&$checked': {
            color: '#836bc1bf !important',
        },
    },
};

require("./Bank.scss");

class Bank extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openInformation: false,
            index: 0,
            name: '',
            listService: serviceList,
            type: 'Personnal',
            buttonPersonnal: 'button-cat-select',
            buttonBusiness: 'button-cat',
            bankType: 'personal',
            anchorEl: null,
            today_promo: [],
            style: {
                modal: {
                    'maxWidth': '750px',
                    'width': '100%',
                    'color': '#3f2751',
                    'zIndex': '100000 !important',
                    'minHeight': '0',

                },
            },
        }
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.openModalInformation = this.openModalInformation.bind(this);
        this.closeModalInformation = this.closeModalInformation.bind(this);
        this.handleClickAccount = this.handleClickAccount.bind(this);
        this.handleClickType = this.handleClickType.bind(this);
        this.handleClickDocument = this.handleClickDocument.bind(this);
        this.shuffle = this.shuffle.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };


    componentWillMount() {
        if (window.matchMedia("(min-width: 436px)").matches) {
            /* The viewport is at least 400 pixels wide */
            this.setState({
                style: {
                    modal: {
                        'maxWidth': '750px',
                        'width': '100%',
                        'color': '#3f2751',
                        'minHeight': '0',
                        'zIndex': '100000 !important',
                    },
                }
            });
        } else {
            /* The viewport is less than 400 pixels wide */
            this.setState({
                style: {
                    modal: {
                        'height': '100%',
                        'left': '0',
                        'position': 'fixed',
                        'top': '0',
                        'width': '100%',
                        'max-width': '100vw',
                        'color': '#3f2751',
                        'zIndex': '100000 !important',
                    },
                }
            });
        }
        const list = this.props.service.serviceList.filter(function (data) {
            if (data.cat.includes('personnal')) {
                return true;
            } else {
                return false;
            }
        })
        const rand = this.shuffle(list);
        this.setState({ listService: rand });
    }

    componentDidMount() {
        if (!this.props.auth.user.location) {
            this.props.history.push('/services');
        }
        window.scrollTo(0, 0)
        const country = this.props.auth.user.location;
        var promo_list = promoList.map((data, key) => {
            var date = new Date(Date.parse(data.date_fin));
            const now_date = new Date();
            if (data.country === country) {
                if (now_date >= new Date(Date.parse(data.date_debut)) && now_date <= date) {
                    return (
                        <div key={key}>
                            <div className="telecom_promo-container">
                                <div className="card-telecom">
                                    <div style={{ position: 'absolute' }}>
                                        <div className="ribbon">
                                            <div><FormattedMessage id="telecom.promo.until"/> {("0" + date.getDate()).slice(-2)} / {("0" + (date.getMonth() + 1)).slice(-2)}</div>
                                        </div>
                                    </div>
                                    <div className="card_name-com">{data.name}</div>
                                    <div className="card-price comp" style={{width: '250px', margin: 'auto'}}><div className="card-old-price"><s>{data.old_price}</s></div>{data.price}<span className="card-unit"></span></div>
                                    <div className="row card-display-promo">
                                        <div className="card-display-details-promo">
                                            <div className="telecom_operator-logo promo" style={{marginRight: '20px'}}><img className="telecom_operator-logo--img img-promo" src={data.pics} alt=''></img> <span className="card_name-com-m">{data.name}</span></div>
                                            <div className="telecom_operator-details-wrap">
                                                <div className="telecom_operator-details" >{data.detail_1}</div>
                                                <div className="telecom_operator-details" >{data.detail_2}</div>
                                                <div className="telecom_operator-details" >{data.detail_3}</div>
                                            </div>
                                        </div>
                                        <hr className="mobile-hr"></hr>
                                        <div className="card_price-wrap">
                                        <div className="card-price mob"><div className="card-old-price"><s>{data.old_price}</s></div>{data.price}<span className="card-unit"></span></div>
                                            <div className="card-button">
                                                <Button variant="contained" color="primary" className="button" onClick={() => window.open(data.trackingLinkk, "_blank")}>
                                                    <FormattedMessage id="telecom.get" />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text card-sim" style={{display: "flex", justifyContent: 'space-between', color: 'rgba(68, 76, 165, 0.85)',fontSize: "12px", textAling:'left'}}><span><FormattedMessage id="bank.promo.french.only" /></span>{data.more_info === true ? <div onClick={this.openModalInformation}><u><FormattedMessage id="bank.promo.more" defaultMessage="More information" /></u></div> : null}</div>
                                </div>
                            </div>
                        </div>
                    )
                }
            } else {
                return null;
            }
        })
        promo_list = promo_list.filter(function (data) {
            if (data === null) {
                return false;
            } else {
                return data;
            }
        })
        this.setState({ today_promo: promo_list })
    }

    onOpenModal(value, name) {
        this.setState({ index: value, open: true, name: name });
    };

    onCloseModal() {
        this.setState({ open: false });
    };

    openModalInformation() {
        this.setState({ openInformation: true });
    };

    closeModalInformation() {
        this.setState({ openInformation: false });
    };


    handleClickAccount(link) {
        if (!this.props.auth.isAuthenticated) {
            this.props.history.push("/login");
        } else {
            window.open(link, "_blank");
        }
    }

    handleClickDocument(document) {
        if (document === "change") {
            window.open(bank_change, "_blank");
        } else if (document === "depot") {
            window.open(bank_depot, "_blank");
        }
        this.setState({ anchorEl: null });
    }

    handleClickType(value) {
        const loc = this.props.auth.user.location;
        const list = this.props.service.serviceList.filter(function (data) {
            if (value === 'business' && loc === "GB" && data.name === "N26") {
                return false;
            }
            if (data.cat.includes(value)) {
                return true;
            } else {
                return false;
            }
        })
        if (value === 'personnal') {
            this.setState({ type: value, listService: list, bankType: "personal", buttonPersonnal: 'button-cat-select', buttonBusiness: 'button-cat' });
        } else {
            this.setState({ type: value, listService: list, bankType: "business", buttonBusiness: 'button-cat-select', buttonPersonnal: 'button-cat' });
        }
    }

    shuffle(array) {
        var currentIndex = array.length, temporaryValue, randomIndex;
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;
            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
        return array;
    }

    render() {
        const { classes } = this.props;
        const { open } = this.state;
        const openMenu = Boolean(this.state.anchorEl);
        const listServices = this.state.listService.map((data, key) =>
            <div className="col-md-6 div-card" key={key}>
                <Card className={"card " + data.name} key={key} style={{ height: '521px' }}>
                    <CardActionArea onClick={() => this.onOpenModal(this.props.service.serviceList.findIndex(x => x.name === data.name), data.name)}>
                        <CardMedia
                            style={{ borderBottom: '#a5a3a359 solid 1px' }}
                            className={"media " + data.name}
                            image={data.image[0]}
                            title={data.name}
                        />
                        <CardContent>
                            <Typography className={"title-card " + data.name} gutterBottom variant="h5" component="h2" style={{ textAlign: 'center', fontWeight: '700' }}>
                                <FormattedMessage id={`service.bank.${data.name}.title`} />
                            </Typography>
                            {data.subtitle ? <Typography className={"subtitle-card " + data.name} gutterBottom variant="h5" component="h2" style={{ textAlign: 'center', fontWeight: '700' }}>
                                <FormattedMessage id={`service.bank.${data.name}.subtitle`} />
                            </Typography> : <br />}
                            <Typography className="text" component="p">
                                <FormattedMessage id={`service.bank.${data.name}.description`} />
                            </Typography>
                            <br />
                            <Typography className="text" component="p" style={{ fontWeight: '500' }}>
                                <FormattedMessage id={`service.bank.${data.name}.description2`} />
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions style={{ justifyContent: 'space-evenly', backgroundColor: '#80808014', position: 'absolute', bottom: '0', width: '100%' }}>
                        {data.name === "Issoyobank" ?
                            <Button size="small" color="primary" style={{ backgroundColor: '#60239a', color: 'white' }} onClick={() => this.onOpenModal(this.props.service.serviceList.findIndex(x => x.name === data.name), data.name)} >
                                <FormattedMessage id="service.house.apply"/>
                            </Button>
                            :
                            <div>
                            <Button size="small" style={{marginRight: '15px'}}color="primary" onClick={() => this.onOpenModal(this.props.service.serviceList.findIndex(x => x.name === data.name), data.name)}>
                                <FormattedMessage id="service.bank.more" defaultMessage="More info" />
                            </Button>
                            <Button size="small" color="primary" onClick={() => this.handleClickAccount(data.link)} style={{ backgroundColor: '#60239a', color: 'white' }} >
                                <FormattedMessage id="service.bank.openaccount" defaultMessage="Open Account" />
                            </Button>
                            </div>
                        }
                    </CardActions>
                </Card>
            </div>
        );
        return (
            <React.Fragment>
                <Helmet>
                    <title>Issoyo - Bank</title>
                    <meta name="description" content="See all our bank providers and make a contract with them" />
                </Helmet>
                <div className="Bank">
                    <div className="bank_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                    <div className="col-12 title">
                        <div>
                            <FormattedMessage id="service.bank.title" defaultMessage="Personal or Business account ?" />
                        </div>
                    </div>
                    {this.props.auth.user.location === 'FR' ?
                        <div style={{ marginRight: '12px', textAlign: 'right' }}>
                            <IconButton
                                aria-label="More"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={this.handleClick}
                                style={{
                                    backgroundColor: '#240b38',
                                    height: '32px',
                                    width: '32px',
                                    borderRadius: '50%',
                                    padding: '0px'
                                }}>
                                <div className="bank_info-icon"><i className="fal fa-info"></i></div>
                            </IconButton>
                            <Menu
                                id="long-menu"
                                open={openMenu}
                                anchorEl={this.state.anchorEl}
                                onClose={this.handleClose}
                            >
                                <MenuItem disabled classes={{ root: classes.root }} selected={false}>
                                    <FormattedMessage id="bank.document.info" defaultMessage="Infos & guidelines" />
                                </MenuItem>
                                <MenuItem selected={false} classes={{ root: classes.root }} onClick={() => this.handleClickDocument('change')}>
                                    <FormattedMessage id="bank.document.guide_change" defaultMessage="Changer de banque" />
                                </MenuItem>
                                <MenuItem selected={false} classes={{ root: classes.root }} onClick={() => this.handleClickDocument('depot')}>
                                    <FormattedMessage id="bank.document.guide_depot" defaultMessage="Fond de garantie" />
                                </MenuItem>
                            </Menu></div> : null
                    }
                    <div className='wrap_button'>
                        <div className={`${this.state.buttonPersonnal} personnal`}>
                            <div onClick={() => this.handleClickType('personnal')}><FormattedMessage id="service.bank.personnal" defaultMessage="Personnal" /></div>
                        </div>
                        <div className={`${this.state.buttonBusiness} business`}>
                            <div onClick={() => this.handleClickType('business')}><FormattedMessage id="service.bank.business" defaultMessage="Business" /></div>
                        </div>
                    </div>
                    <div className='row'>
                        {listServices}
                    </div>
                    {this.state.today_promo.length > 0 ?
                        <div>
                            <hr />
                            <div className='title'><FormattedMessage id='telecom.promo'/></div>
                            <div className="telecom_promo">
                                <div className="telecom_promo-container">
                                    {this.state.today_promo}
                                </div>
                            </div>
                        </div> : null}
                </div >
                <Modal open={open} onClose={this.onCloseModal} center styles={this.state.style} blockScroll >
                    <BankModal onCloseModal={this.onCloseModal} index={this.state.index} language={this.props.language} name={this.state.name} bankType={this.state.bankType} handleClickAccount={link => this.handleClickAccount(link)}></BankModal>
                </Modal>
                <Modal open={this.state.openInformation} onClose={this.closeModalInformation} center styles={this.state.style} blockScroll >
                    <div>
                        <h3>Conditions</h3>
                        <p className="text">L'offre 50 € offerts est réservée aux personnes physiques, capables, majeures justifiant d'un minimum de revenus mensuels de 1 000 € pour un compte individuel ou de 2 000 € pour un compte joint (ou d'un dépôt minimum de 5 000 € sur votre compte Hello bank!) et résidant fiscalement en France, ouvrant un premier compte courant individuel ou joint chez Hello bank! assorti d'une/de carte(s) bancaire(s) Visa Classic ou Premier.</p>
                        <p className="text">La demande d'ouverture de compte doit être validée en ligne au plus tard le 29/08/2019.La prime sera versée 3 mois après la réception des moyens de paiement, sous réserve d'acceptation du dossier de demande d'ouverture de compte par Hello bank! et qu'il soit complet et conforme dans les 15 jours calendaires à compter de la date de demande. Une seule prime par personne et par compte.</p>
                        <p className="text">Offre non cumulable avec toute offre commerciale ou de parrainage en cours. BNP Paribas se réserve le droit d'annuler ou de modifier les modalités de l'offre sans avertissement préalable, en cas de force majeure selon l'interprétation faite par la jurisprudence française. Cette offre est régie par la loi française. Offre réservée aux particuliers ou entrepreneurs individuels (exerçant à titre privé). L'ouverture d'un compte Hello bank! est soumise aux conditions d'éligibilité fixées par la banque.</p>
                    </div>
                </Modal>
                <ScrollUpButton ShowAtPosition={70} />
            </React.Fragment >
        );
    }
}

Bank.propTypes = {
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    service: state.services,
});

export default connect(
    mapStateToProps, {}
)(withStyles(styles)(Bank));



