import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPhoneList } from "../../../../actions/serviceActions.js";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { forfaitList } from './forfait.js';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import { phoneList } from './phone.js';
import { sfr_card } from '../../../../assets/img/providers/sfr_card.png';
import { bouygues } from '../../../../assets/img/providers/bouygues.png';
import { laposte } from '../../../../assets/img/providers/laposte.png';
import { lebara } from '../../../../assets/img/providers/lebara.png';
import { orange } from '../../../../assets/img/providers/orange.png';
import { red } from '../../../../assets/img/providers/red.png';
import { sosh } from '../../../../assets/img/providers/sosh.png';
import { coriolis } from '../../../../assets/img/providers/coriolis.png';
import { vodafone } from '../../../../assets/img/providers/vodafone_logo.jpg';
import { tello_logo } from '../../../../assets/img/providers/tello_logo.png';


const devises = [
    {
        name: 'EUR',
        symbole: '€',
        country: ['fr', 'be', 'de', 'es', 'gr', 'pt', 'at', 'cy', 'fi', 'bu', 'ee', 'lt', 'lu', 'nl', 'it', 'pl', 'li', 'ir', 'ho', 'cz']
    },
    {
        name: 'AUD',
        symbole: 'A$',
        country: ['au']
    },
    {
        name: 'CAD',
        symbole: 'C$',
        country: ['ca']
    },
    {
        name: 'CHF',
        symbole: 'Fr',
        country: ['ch']
    },
    {
        name: 'GBP',
        symbole: '£',
        country: ['gb']
    },
    {
        name: 'HKD',
        symbole: 'HK$',
        country: ['hk']
    },
    {
        name: 'HUF',
        symbole: 'Ft',
        country: ['hu']
    },
    {
        name: 'JPY',
        symbole: '¥',
        country: ['jp']
    },
    {
        name: 'NOK',
        symbole: 'kr',
        country: ['no']
    },
    {
        name: 'SEK',
        symbole: 'kr',
        country: ['se']
    },
    {
        name: 'SGD',
        symbole: 'S$',
        country: ['si']
    },
    {
        name: 'USD',
        symbole: '$',
        country: ['us']
    }
]

const styles = {
    root: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    selectMenu: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },

    input: {
        borderColor: '#3e45948a !important',
    },
    rootLabel: {
        fontFamily: "'Nunito', sans-serif !important",
    },
    rootControlLabel: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    rootCheck: {
        color: '#836bc1bf !important',
        '&$checked': {
            color: '#836bc1bf !important',
        },
    },
};

class Line extends Component {
    constructor() {
        super();
        this.state = {
            operator: [],
            operator_selected: 'none',
            operator_info: [],
            internet_list: [],
            internet_list_size: [],
            price_forfait: '?',
            phone_selected: 'none',
            buttonDisable: true,
            internet_value: 'none',
            phone_value: 1,
            isDisableOperator: true,
            radioEng: true,
            radioSaneng: true,
            lowprice: false,
            displayInternet: 'internet',
            displayOperator: 'operator',
            displayInfo: 'telecom',
            displayEng: 'telecom_operator-checkbox',
            countryTelecom: [],
            deviseSelected: [],
        }
        this.handleChangeOperator = this.handleChangeOperator.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleChangeLine = this.handleChangeLine.bind(this);
        this.getOperator = this.getOperator.bind(this);
        this.handleChangeCheck = this.handleChangeCheck.bind(this);
        this.onClickSort = this.onClickSort.bind(this);
    }

    componentWillMount() {
        var country = this.props.auth.user.location;
        var tmp = forfaitList.filter(function (data) {
            if (data.country.toLowerCase() === country.toLowerCase()) {
                return true;
            }
            return false;
        })
        var location = this.props.auth.user.location.toLowerCase();
        var devise = devises.filter(function (data) {
            if (data.country.indexOf(location) > -1) {
                return data;
            }
        })
        if (devise.length > 0) {
            this.setState({ deviseSelected: devise[0], countryTelecom: tmp })
        } else {
            this.setState({
                deviseSelect: 'EUR', deviseSelected: {
                    name: 'EUR',
                    symbole: '€',
                    country: ['fr', 'be', 'de', 'es', 'gr', 'pt', 'at', 'cy', 'fi', 'bu', 'ee', 'lt', 'lu', 'nl', 'it', 'pl', 'li', 'ir', 'ho', 'cz']
                },
                countryTelecom: tmp
            })
        }
        this.setState({ countryTelecom: tmp })
    }

    onClickSort() {
        var tmp = this.state.operator;
        if (this.state.lowprice === false) {
            tmp.sort((a, b) => parseInt(a.price, 10) - parseInt(b.price, 10));
            this.setState({ operator: tmp, lowprice: true });
        } else {
            tmp.sort((a, b) => parseInt(b.price, 10) - parseInt(a.price, 10));
            this.setState({ operator: tmp, lowprice: false });
        }
    }

    handleChangeCheck(e) {
        if (e.target.value === "radioEng") {
            if (this.state.radioSaneng === false && this.state.radioEng === true) {
                this.setState({ radioEng: false, radioSaneng: true }, () => this.getOperator());
            } else {
                this.setState({ [e.target.value]: !this.state.radioEng }, () => this.getOperator());
            }
        } else {
            if (this.state.radioEng === false && this.state.radioSaneng === true) {
                this.setState({ radioEng: true, radioSaneng: false }, () => this.getOperator());
            } else {
                this.setState({ [e.target.value]: !this.state.radioSaneng }, () => this.getOperator());
            }
        }
    }

    handleClick(id) {
        if (!this.props.auth.isAuthenticated) {
            this.props.handleClickAccount();
        } else {
            window.open(this.state.operator[id].trackingLink, "_blank");
        }
    }

    handleChangeOperator = event => {
        if (event.target.value === 'none') {
            this.setState({ [event.target.name]: event.target.value })
        } else {
            const operator = forfaitList.filter(({ name }) => name === event.target.value);
            const info = <div className="row">
                <div className="telecom_operator-logo"><img style={{ height: '100px' }} src={operator[0].pics} alt=''></img></div>
                <div className="telecom_operator-details" ><p><i className="fas fa-check" /> {operator[0].detail_1}</p><p><i className="fas fa-check" /> {operator[0].detail_2}</p></div>
            </div>
            this.setState({
                [event.target.name]: event.target.value,
                operator_info: info,
                price_forfait: operator[0].price,
                buttonDisable: false,
                displayInfo: "telecom--appear",
                displayEng: "telecom_operator-checkbox--appear",
            });
        }
    };

    getOperator() {
        const internet = this.state.internet_value;
        const eng = this.state.radioEng;
        const saneng = this.state.radioSaneng;
        const op = this.state.internet_list.filter(function (data) {
            if (data.type === 'line') {
                if ((internet === "0Mo à 1Go") &&
                    (data.internet_value >= 0 && data.internet_value <= 1000)) {
                    if (((!data.eng.includes("Sans") && !data.eng.includes("Without")) && eng === true)
                        || (data.eng.includes("Sans") || data.eng.includes("Without")) && saneng === true) {
                        return true;
                    }
                } else if ((internet === "2Go à 10Go") &&
                    (data.internet_value >= 2000 && data.internet_value <= 10000)) {
                    if (((!data.eng.includes("Sans") && !data.eng.includes("Without")) && eng === true)
                        || (data.eng.includes("Sans") || data.eng.includes("Without")) && saneng === true) {
                        return true;
                    }
                } else if ((internet === "12Go à 30Go") &&
                    (data.internet_value >= 12000 && data.internet_value <= 30000)) {
                    if (((!data.eng.includes("Sans") && !data.eng.includes("Without")) && eng === true)
                        || (data.eng.includes("Sans") || data.eng.includes("Without")) && saneng === true) {
                        return true;
                    }
                } else if ((internet === "50Go à 150Go") &&
                    (data.internet_value >= 50000 && data.internet_value <= 150000)) {
                    if (((!data.eng.includes("Sans") && !data.eng.includes("Without")) && eng === true)
                        || (data.eng.includes("Sans") || data.eng.includes("Without")) && saneng === true) {
                        return true;
                    }
                } else if ((internet === "Unlimited") &&
                    (data.internet_value === 200000)) {
                    if (((!data.eng.includes("Sans") && !data.eng.includes("Without")) && eng === true)
                        || (data.eng.includes("Sans") || data.eng.includes("Without")) && saneng === true) {
                        return true;
                    }
                } else {
                    return false;
                }
            }
            return false;
        })
        if (op.length < 1) {
            this.setState({ operator: op, isDisableOperator: true })
        } else {
            this.setState({ operator: op, isDisableOperator: false })
        }
    }

    handleChangeLine(event) {
        if (event.target.name === 'phone_value') {
            const call = event.target.value;
            const location = this.props.auth.user.location;
            var tmparray = []
            var internet = forfaitList.filter(function (data) {
                if (data.type === 'line') {
                    if (data.country === location) {
                        if (data.call === call) {
                            tmparray.push(data.internet_value);
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
                return false;
            });
            var tmpset = Array.from(new Set(tmparray));
            var size = [
                {
                    name: <FormattedMessage id="telecom.line.from.0" defaultMessage="from 0 Mo to 1 GO" />,
                    value: [],
                    data_size: "0Mo à 1Go",
                },
                {
                    name: <FormattedMessage id="telecom.line.from.2" defaultMessage="from 2 Go to 10 GO" />,
                    value: [],
                    data_size: "2Go à 10Go",
                },
                {
                    name: <FormattedMessage id="telecom.line.from.12" defaultMessage="from 12 Go to 30 GO" />,
                    value: [],
                    data_size: "12Go à 30Go",
                },
                {
                    name: <FormattedMessage id="telecom.line.from.50" defaultMessage="from 50 Go to 150 GO" />,
                    value: [],
                    data_size: "50Go à 150Go",
                },
                {
                    name: <FormattedMessage id="telecom.call.unlimited" />,
                    value: [],
                    data_size: "Unlimited",
                },
            ];
            tmpset.map(data => {
                if (data > 0 && data <= 1000) {
                    size[0].value.push(data);
                } else if (data > 2000 && data <= 10000) {
                    size[1].value.push(data);
                } else if (data > 12000 && data <= 30000) {
                    size[2].value.push(data);
                } else if (data > 50000 && data <= 150000) {
                    size[3].value.push(data);
                } else if (data === 200000) {
                    size[4].value.push(data);
                }
            })
            this.setState({
                [event.target.name]: event.target.value,
                operator_selected: 'none',
                internet_list: internet,
                internet_value: 'none',
                displayInternet: 'internet--appear',
                internet_list_size: size,
            })
        } else {
            if (event.target.value === 'none') {
                this.setState({
                    [event.target.name]: event.target.value,
                    operator_selected: 'none',
                    displayInternet: 'internet--appear'

                })
            } else {
                this.setState({
                    [event.target.name]: event.target.value,
                    operator_selected: 'none',
                    displayInfo: 'telecom--appear',
                    displayEng: "telecom_operator-checkbox--appear",
                }, () => this.getOperator())
            }
        }
    }

    render() {
        const { classes } = this.props;
        if (this.state.countryTelecom.length === 0) {
            return (
                <div className="text" style={{ textAlign: "center" }}>
                    <FormattedMessage id="service.telecom.avaible" />
                </div>
            )
        } else {
            return (
                <React.Fragment>
                    <div className='row linemob'>
                        <div className="select phone">
                            <TextField
                                id="outlined-select-currency"
                                select
                                label="Communication"
                                className="telecom_operator-list"
                                value={this.state.phone_value}
                                name="phone_value"
                                onChange={this.handleChangeLine}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                margin="normal"
                                variant="outlined"
                            >
                                <MenuItem disabled value={1}><FormattedMessage id="telecom.line.select.timeCall" defaultMessage="Select time call" /></MenuItem>
                                {this.props.auth.user.location === "US" ?
                                    <MenuItem value={0} classes={{ root: classes.root }}><FormattedMessage id="telecom.no.call"/></MenuItem>
                                    :
                                    null
                                }
                                {this.props.auth.user.location === "US" ?
                                    <MenuItem value={100} classes={{ root: classes.root }}><FormattedMessage id="telecom.call">{msg => (`${msg} 100min`)}</FormattedMessage></MenuItem>
                                    :
                                    null
                                }
                                {this.props.auth.user.location === "US" ?
                                    <MenuItem value={300} classes={{ root: classes.root }}><FormattedMessage id="telecom.call">{msg => (`${msg} 300min`)}</FormattedMessage></MenuItem>
                                    :
                                    null
                                }
                                {this.props.auth.user.location === 'FR' ?
                                    <MenuItem value={2} classes={{ root: classes.root }}><FormattedMessage id="telecom.call">{msg => (`${msg} 2h`)}</FormattedMessage></MenuItem>
                                    : 
                                    null
                                }
                                <MenuItem value={11} classes={{ root: classes.root }}><FormattedMessage id="telecom.call.unlimited" /></MenuItem>
                            </TextField>
                        </div>
                        <div className={this.state.displayInternet}>
                            <div className="select">
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Internet"
                                    className="telecom_operator-list"
                                    value={this.state.internet_value}
                                    name="internet_value"
                                    onChange={this.handleChangeLine}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    <MenuItem disabled value='none'><FormattedMessage id="telecom.line.select.data" defaultMessage="Select data" /></MenuItem>
                                    {this.state.internet_list_size.map((data, key) => {
                                        if (data.value.length > 0) {
                                            return (<MenuItem classes={{ root: classes.root }} key={key} value={data.data_size}>
                                                {data.name}
                                            </MenuItem>)
                                        }
                                        return null;
                                    })}
                                </TextField>
                            </div>
                        </div>
                        {this.props.auth.user.location === "GB" || this.props.auth.user.location === "US" ? null :
                            <div className={this.state.displayEng}>
                                <FormLabel component="legend" classes={{ root: classes.rootLabel }}><FormattedMessage id="telecom.line.select.commitment" defaultMessage="Filter by commitment" /></FormLabel>
                                <FormattedMessage id="telecom.line.with" defaultMessage="With">
                                    {msg => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={this.state.radioEng} onChange={this.handleChangeCheck} value="radioEng" name="radioEng" classes={{ root: classes.rootCheck }} />
                                            }
                                            label={msg}
                                            classes={{ label: classes.rootControlLabel }}
                                        />
                                    )}</FormattedMessage>
                                <FormattedMessage id="telecom.line.without" defaultMessage="Without">
                                    {msg => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={this.state.radioSaneng} onChange={this.handleChangeCheck} value="radioSaneng" name="radioSaneng" classes={{ root: classes.rootCheck }} />
                                            }
                                            label={msg}
                                            classes={{ label: classes.rootControlLabel }}
                                        />
                                    )}</FormattedMessage>
                            </div>
                        }
                    </div>
                    <div className={this.state.displayInfo}>
                        <div className="telecom_sort_price" onClick={this.onClickSort}>
                            <FormattedMessage id="telecom.sort" /> <i className="fas fa-sort" ></i>
                        </div>
                    </div>
                    <div className={this.state.displayInfo}>
                        <div className="telecom_operator-info">
                            {this.state.operator.map((data, key) => {
                                var call;
                                if (data.call === 11) {
                                    call = <FormattedMessage id="telecom.call.unlimited" />
                                } else if (data.country === "GB" || data.country === "US") {
                                    call = data.call.toString() + "min";
                                } else {
                                    call = data.call.toString() + "h";
                                }
                                return (
                                    <div key={key}>
                                        <div className="card-telecom">
                                            <div className="row card-display">
                                                <div className="telecom_operator-logo"><img className="telecom_operator-logo--img" src={data.pics} alt=''></img> <span className="card_name-m">{data.operator}</span></div>
                                                <div className="telecom_operator-details-wrap">
                                                    <div className="telecom_operator-details" >{data.detail_1}</div>
                                                    <div className="telecom_operator-details" >{data.detail_2}</div>
                                                    <div className="telecom_operator-details" >{data.detail_3}</div>
                                                </div>
                                                <hr className="mobile-hr"></hr>
                                                <div className="card_price-wrap">
                                                    <div className="card-price">{data.price}<span className="card-unit"> {this.state.deviseSelected.symbole}/<FormattedMessage id="telecom.linemob.month" /></span><p className="card-eng-m">{data.trad_eng}</p></div>
                                                    <div className="card-button">
                                                        <Button variant="contained" color="primary" className="button" onClick={() => this.handleClick(key)}>
                                                            <FormattedMessage id="telecom.get" />
                                                        </Button>
                                                    </div>
                                                    <div className="card-eng-d">{data.trad_eng}</div>
                                                </div>
                                            </div>
                                            <div className="card-sim"><div className="col telecom_icon"><i className="fas fa-sim-card" style={{ color: 'rgba(68, 76, 165, 0.85)' }}></i> <FormattedMessage id="telecom.sim" /> {data.sim} {this.state.deviseSelected.symbole} </div><div className="col telecom_icon"><i className="fas fa-phone" style={{ color: 'rgba(68, 76, 165, 0.85)' }}></i> <FormattedMessage id="telecom.call" /> {call} </div><div className="col telecom_icon"><i className="fas fa-signal" style={{ color: 'rgba(68, 76, 165, 0.85)' }}></i> Internet {data.internet}</div></div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </React.Fragment >
            )
        }
    }
}

Line.propTypes = {
    auth: PropTypes.object.isRequired,
    getPhoneList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    service: state.services,
});

export default connect(
    mapStateToProps, { getPhoneList }
)(withStyles(styles)(Line));

// <MenuItem value={5} classes={{ root: classes.root }}><FormattedMessage id="telecom.call">{msg => (`${msg} 5h`)}</FormattedMessage></MenuItem>
