import React from "react"
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import axios from "axios";
import { FormattedMessage } from 'react-intl';
import { withAlert } from 'react-alert'

class ContactForm extends React.Component {
    constructor() {
        super();
        this.state = {
            email: '',
            name: '',
            subject: '',
            message: '',
            errorEmail: false,
            errorName: false,
            errorSubject: false,
            errorMessage: false,
            errorRequired: false,
            isButtonDisabled: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    setErrorRed() {
        if (this.state.email === '')
            this.setState({ errorEmail: true });
        if (this.state.name === '')
            this.setState({ errorName: true });
        if (this.state.subject === '')
            this.setState({ errorSubject: true });
        if (this.state.message === '')
            this.setState({ errorMessage: true });
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.name === 'email')
            this.setState({ errorEmail: false });
        if (event.target.name === 'name')
            this.setState({ errorName: false });
        if (event.target.name === 'subject')
            this.setState({ errorSubject: false });
        if (event.target.name === 'message')
            this.setState({ errorMessage: false });
    }

    onSubmit() {
        if (this.state.email === '' || this.state.name === ''
            || this.state.subject === '' || this.state.message === '') {
            this.setState({ errorRequired: true });
            this.setErrorRed();
        } else {
            const formData = {
                email: this.state.email,
                name: this.state.name,
                subject: this.state.subject,
                message: this.state.subject,
            };
            this.setState({ 
                errorRequired: false,
                errorEmail: false,
                errorName: false,
                errorSubject: false,
                errorMessage: false,
            });

						/*
						axios.get('http://webcode.me').then(resp => {
								console.log(resp.data);
						});*/

				
            setTimeout(() => this.setState({ isButtonDisabled: true }), 30000)
            /*axios.post('/api/mail/sendContactMail', formData).then(res => {
                if (res.data.success) {
                    this.props.alert.show('Email sent', { type: 'success' });
                    this.setState({ email: '', name: '', subject: '', message: '' })
                }
            });*/
            
            
			const formDataNom = "fazile";
            axios.post('/api/mail/testpost',formDataNom).then(res => {
							console.log("go");
							console.log(res.data);
							console.log("name = "+formDataNom);
                if (res.data.success) {
										console.log("success");
                    this.props.alert.show('Email sent', { type: 'success' });
                    this.setState({ email: '', name: '', subject: '', message: '' })
                }
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="error required" style={this.state.errorRequired ? {} : { display: 'none' }}>
                    <FormattedMessage id="Home.error.required" defaultMessage="You must fill out all required fields " />
                </div>
                <form className="form-contact" noValidate autoComplete="off">
                    <div className="personnal-information">
                        <FormattedMessage id="home.name" defaultMessage="Name" >
                            {msg => (<TextField
                                required
                                error={this.state.errorName}
                                id="outlined-uncontrolled"
                                label={msg}
                                name='name'
                                value={this.state.name}
                                onChange={this.handleChange}
                                className="text-field name"
                                style={{ borderRadius: 100 + 'px' }}
                                margin="normal"
                                variant="outlined"
                            />)}
                        </FormattedMessage>
                        <TextField
                            required
                            error={this.state.errorEmail}
                            id="outlined-email-input"
                            label="Email"
                            className="text-field mail"
                            type="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            name="email"
                            autoComplete="email"
                            margin="normal"
                            variant="outlined"
                        />
                    </div>
                    <div className="subject">
                        <FormattedMessage id="home.subject" defaultMessage="Subject" >
                            {msg => (
                                <TextField
                                    required
                                    error={this.state.errorSubject}
                                    id="outlined-textarea"
                                    label={msg}
                                    placeholder={msg}
                                    name='subject'
                                    value={this.state.subject}
                                    onChange={this.handleChange}
                                    className="text-field subject"
                                    margin="normal"
                                    variant="outlined"
                                />)}
                        </FormattedMessage>
                    </div>
                    <div className="message">
                        <FormattedMessage id="home.message" defaultMessage="Message" >
                            {msg => (
                                <TextField
                                    required
                                    error={this.state.errorMessage}
                                    id="outlined-textarea"
                                    name='message'
                                    value={this.state.message}
                                    onChange={this.handleChange}
                                    label={msg}
                                    placeholder={msg}
                                    multiline
                                    rows="4"
                                    className="text-field message"
                                    margin="normal"
                                    variant="outlined"
                                />)}
                        </FormattedMessage>
                    </div>
                    <div className="send">
                        <Button variant="contained" color="primary" className="button" disabled={this.state.isButtonDisabled} onClick={this.onSubmit}>
                            <FormattedMessage id="home.send" defaultMessage="Send..." />
                        </Button>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default withAlert(ContactForm);
