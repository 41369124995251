import { 
  SET_SERVICELIST, 
  SET_PHONELIST, 
  SET_TICKETLIST, 
  SET_INTERNETLIST, 
  SET_ANNONCELIST, 
  SET_MOBILELIST,
  GET_ERRORLIST 
} from "../actions/types";

const initialState = {
    serviceList: [],
    phoneList: [],
    ticketList: [],
    internetList: [],
    annonceList: [],
    errors: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_SERVICELIST: 
      return {
        ...state,
        errors: {},
        serviceList: action.payload,
      }
      case SET_PHONELIST: 
      return {
        ...state,
        errors: {},
        phoneList: action.payload,
      }
      case SET_MOBILELIST: 
      return {
        ...state,
        errors: {},
        mobileList: action.payload,
      }
      case SET_TICKETLIST:
      return {
        ...state,
        errors: {},
        ticketList: action.payload,
      }
      case SET_INTERNETLIST:
      return {
        ...state,
        errors: {},
        internetList: action.payload,
      }
      case SET_ANNONCELIST:
      return {
        ...state,
        errors: {},
        annonceList: action.payload,
      }
      case GET_ERRORLIST:
      return {
        ...state,
        errors: action.payload
      }
    default:
      return state;
  }
}