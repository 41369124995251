import React, { Component } from 'react'
import Axa_logo from '../../../../../assets/img/providers/Axa_logo.jpg';
import axa_policy from '../../../../../assets/document/insurance/ISSOYO_axa_landlord_policy_summary.pdf';
import { FormattedMessage } from 'react-intl';


require("./Landlord.scss")

class Landlord extends Component {
    render() {
        return (
            <div className='scooter'>
                <div className="habitation_description">
                    <div className="habitation_provider_logo">
                        <img className="habitation_provider-img" src={Axa_logo} alt='' />
                    </div>
                    <div className="text lulo_title">
                        <FormattedMessage id="axa.advantage" />
                    </div>
                    <ul className="text">
                        <li><FormattedMessage id="axa.landlord.insurance" defaultMessage="Get landlord insurance from just £120 a year" /></li>
                        <li><FormattedMessage id="axa.landlord.repair" defaultMessage="Buildings insurance to repair or rebuild your property following a loss" /></li>
                        <li><FormattedMessage id="axa.landlord.property" defaultMessage="Property owners’ liability cover (up to £10 million)" /></li>
                        <li><FormattedMessage id="axa.landlord.tenant" defaultMessage="Cover to re-house your tenants in alternative accommodation after a flood or fire" /></li>
                        <li><FormattedMessage id="axa.landlord.policy" defaultMessage="Cover for up to 10 properties in one policy" /></li>
                        <li><FormattedMessage id="axa.landlord.interest" defaultMessage="0% interest on installments when you choose to pay monthly" /></li>
                    </ul>
                </div>
                <div className="habitation_document" style={{ marginTop: "24px", marginBottom: "48px" }}>
                    <div className="">
                        <div className="health_document-container" onClick={() => window.open(axa_policy, "_blank")}>
                            <div>
                                <i className="fal fa-file-alt"></i> <FormattedMessage id="insurance.car.gb.summary" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Landlord;