import React, { Component } from 'react'
import luko_logo from '../../../../../assets/img/providers/luko.png';
import luko_brochure from '../../../../../assets/document/insurance/luko_brochure_scooter.pdf';
import luko_condition from '../../../../../assets/document/insurance/luko_condition_scooter.pdf';
import forbes_brandvoice from '../../../../../assets/img/ISSOYO_forbes_brandvoice.jpg';
import les_echos from '../../../../../assets/img/ISSOYO_lesechos.jpg';
import { FormattedMessage } from 'react-intl';
import ReactPlayer from 'react-player';


require("./Scooter.scss")

class Scooter extends Component {
    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this);
        this._onReady = this._onReady.bind(this);
        this.state = {
            youtubeHW: 'https://www.youtube.com/watch?v=YsThRyxUqgU',
        }
    }

    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }

    handleClick(document) {
        if (document === "brochure") {
            window.open(luko_brochure, "_blank");
        } else if (document === "condition") {
            window.open(luko_condition, "_blank");
        }
    }

    render() {
        return (
            <div className='scooter'>
                <div className="habitation_description">
                    <div className="habitation_provider_logo">
                        <img className="habitation_provider-img" src={luko_logo} alt='' />
                    </div>
                    <div className="text lulo_title">
                        <FormattedMessage id="luko.advantage" />
                    </div>
                    <ul className="text">
                        <li><FormattedMessage id="luko.scooter.covered" defaultMessage="Body damage" /></li>
                        <li><FormattedMessage id="luko.scooter.price" defaultMessage="Legal defense" /></li>
                        <li><FormattedMessage id="luko.scooter.commitment" defaultMessage="Civic responsibility" /></li>
                        <li><FormattedMessage id="luko.scooter.refund" defaultMessage="Theft and material damage" /></li>
                    </ul>
                </div>
                <div className="habitation_document" style={{ marginTop: "24px", marginBottom: "48px" }}>
                    <div className="">
                        <div className="health_document-container" onClick={() => this.handleClick("brochure")}>
                            <div>
                                <i className="fal fa-file-alt"></i> <FormattedMessage id="insurance.health.brochure" />
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="health_document-container" onClick={() => this.handleClick("condition")}>
                            <div>
                                <i className="fal fa-file-alt"></i> <FormattedMessage id="insurance.health.condition" />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <ReactPlayer
                        width='100%'
                        controls
                        config={{
                            youtube: {
                                playerVars: { showinfo: 1 }
                            }
                        }}
                        url={this.state.youtubeHW}
                    />
                </div>
                <div className="insurance_description_presse">
                    <div className="title"><FormattedMessage id="insurance.presse" /></div>
                    <div className="presse_link">
                        <a className="presse_link-link" rel="noopener noreferrer" target="_blank" href="https://www.forbes.fr/brandvoice/luko-la-neoassurance-qui-a-seduit-des-milliers-de-francais-en-quelques-mois/">
                            <img className="image_presse" src={forbes_brandvoice} alt='forbes logo'></img>
                        </a>
                        <a className="presse_link-link" rel="noopener noreferrer" target="_blank" href="https://business.lesechos.fr/entrepreneurs/marketing-vente/0600041121153-luko-compte-conquerir-des-clients-sans-budget-marketing-325450.php">
                            <img className="image_presse" src={les_echos} alt='echos logo'></img>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Scooter;