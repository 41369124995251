import React, { Component } from 'react'
import worldremitLogo from '../../../../../assets/img/providers/Worldremit_logo.png';
import ReactPlayer from 'react-player'
import Button from '@material-ui/core/Button';
import Issoyo_logo from '../../../../../assets/img/fav_icon32@2x.png';
import Collapsible from 'react-collapsible';
import { FormattedMessage } from 'react-intl';

require('./WorldremitModal.scss');


class WorldremitModal extends Component {
    constructor() {
        super();
        this.state = {
            position: '0',
            openCountry: false,
            photos: {},
            youtubeHW: 'https://www.youtube.com/watch?v=R7qYFppbYEE',
        }
        this.handleClickAccount = this.handleClickAccount.bind(this);
        this.handleClick = this.handleClick.bind(this);
    };

    componentDidMount() {
        if (this.props.language === "fr-FR") {
            this.setState({ youtubeHW: 'https://youtu.be/diwvyxuE1pw' })
        } else {
            this.setState({ youtubeHW: 'https://youtu.be/diwvyxuE1pw' })
        }
    }

    handleClick(position) {
        if (this.state.position === position) {
            this.setState({ position: "0" })
        } else {
            this.setState({ position: position });
        }
    };

    handleClickAccount() {
        if (this.props.language === 'fr-FR') {
            this.props.handleClickAccount("https://www.awin1.com/cread.php?awinmid=5877&awinaffid=525947&clickref=&p=https%3A%2F%2Fwww.worldremit.com%2Ffr");
        } else {
            this.props.handleClickAccount("https://www.awin1.com/cread.php?awinmid=5877&awinaffid=525947&clickref=&p=https%3A%2F%2Fwww.worldremit.com%2Fen%2Faccount%2Fsignup");
        }
    };

    render() {
        return (
            <div className="service-bank-Worldremit">
                <div className="header">
                    <div className="header_logo">
                        <img className="header-pic" src={worldremitLogo} alt=''></img>
                    </div>
                    <div className="header_title"><b><FormattedMessage id="service.bank.Worldremit.title" defaultMessage="WorldRemit" /></b></div>
                </div>
                <div className="cont-mod">
                    <FormattedMessage id="bank.Worldremit.benefits" defaultMessage="Benefits">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "1")} handleTriggerClick={this.handleClick} accordionPosition="1">
                                <ul className='text'>
                                    <li><FormattedMessage id="bank.Worldremit.reason2" defaultMessage="+ 4 million happy customers already – and counting!" /></li>
                                    <li><FormattedMessage id="bank.Worldremit.reason3" defaultMessage="Over 120,000 5* reviews, you know your money’s in safe hands" /></li>
                                    <li><FormattedMessage id="bank.Worldremit.reason4" defaultMessage="Customer Service is available 24/7 for whatever you need. There’s a customer service team in the UK, one in the US and one in the Philippines able to help in many different languages" /></li>
                                    <li><FormattedMessage id="bank.Worldremit.reason5" defaultMessage="There’s no need to visit an agent or bank in person – It is all online!" /></li>
                                    <li><FormattedMessage id="bank.Worldremit.reason6" defaultMessage="Worldremit is secure and FCA regulated, your safety is taken very seriously" /></li>
                                    <li><FormattedMessage id="bank.Worldremit.reason7" defaultMessage="Worldremit offer fair and competitive fees" /></li>
                                    <li><FormattedMessage id="bank.Worldremit.reason8" defaultMessage="Worldremit is a top FinTech company changing the way people send money" /></li>
                                    <li><FormattedMessage id="bank.Worldremit.reason9" defaultMessage="However you need to send money, Worldremit send from 50 countries to 150 countries around the world!" /></li>
                                </ul>
                            </Collapsible>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="bank.Worldremit.features" defaultMessage="Features">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "2")} handleTriggerClick={this.handleClick} accordionPosition="2">
                                <div className="text">
                                    <p><b style={{color: 'rgb(32, 37, 103)'}}><FormattedMessage id="bank.Worldremit.features.fast" defaultMessage="It’s fast: " /></b> <FormattedMessage id="bank.Worldremit.fast.2" defaultMessage="you can make a transfer to family and friends in a matter of minutes on your laptop, or phone. And, in most cases, they’ll receive it instantly depending on your location and recipient country." /></p>
                                    <p><b style={{color: 'rgb(32, 37, 103)'}}><FormattedMessage id="bank.Worldremit.features.easy" defaultMessage="It’s easy to use: " /></b> <FormattedMessage id="bank.Worldremit.features.easy.2" defaultMessage="It’s so simple to make a transfer with WorldRemit, " /> <u><span style={{cursor: 'pointer'}} onClick={() => window.open("https://www.awin1.com/cread.php?awinmid=5877&awinaffid=525947&clickref=&p=https%3A%2F%2Fwww.worldremit.com%2Fen%2Fhow-it-works", "_blank")}><FormattedMessage id="bank.Worldremit.features.easy.3" defaultMessage="see how it works here. " /></span></u>
                                        <FormattedMessage id="bank.Worldremit.features.easy.4" defaultMessage="If you do have any queries, the team is " /><u><span style={{cursor: 'pointer'}} onClick={() => window.open("https://www.awin1.com/cread.php?awinmid=5877&awinaffid=525947&clickref=&p=https%3A%2F%2Fwww.worldremit.com%2Fen%2Fcontact-us", "_blank")} ><FormattedMessage id="bank.Worldremit.features.easy.5" defaultMessage="here to help anytime, " /></span></u>
                                        <FormattedMessage id="bank.Worldremit.features.easy.6" defaultMessage="night or day." /></p>
                                    <p><b style={{color: 'rgb(32, 37, 103)'}}><FormattedMessage id="bank.Worldremit.features.low" defaultMessage="It’s low cost: " /></b> <FormattedMessage id="bank.Worldremit.features.low.2" defaultMessage="There are never any nasty surprises. It’s totally transparent and show the fees and exchange rates up front. See just " />
                                        <u><span style={{cursor: 'pointer'}} onClick={() => window.open("(https://www.awin1.com/cread.php?awinmid=5877&awinaffid=525947&clickref=&p=https%3A%2F%2Fwww.worldremit.com%2Fen", "_blank")}><FormattedMessage id="bank.Worldremit.features.low.3" defaultMessage="how we compare to Western Union " /></span></u> <FormattedMessage id="bank.Worldremit.features.low.4" defaultMessage="in what you'll pay and the exchange rate you'll get." /></p>
                                    <p><b style={{color: 'rgb(32, 37, 103)'}}><FormattedMessage id="bank.Worldremit.features.secure" defaultMessage="It’s secure: " /></b><FormattedMessage id="bank.Worldremit.features.secure.2" defaultMessage="That’s why people in over 50 countries trust Worldremit to send money to the people they care about in over 140 countries. Money is guaranteed to arrive safely every time, thanks to the ndustry-leading technology. Plus, Worldremit is licensed by government regulators globally. WorldRemit is a financial institution and  regulated (in the UK) by the Financial Conduct Authority (FCA). " /></p>
                                </div>
                            </Collapsible>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="bank.Worldremit.howItWork" defaultMessage="How does Worldremit work ?">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "4")} handleTriggerClick={this.handleClick} accordionPosition="4">
                                <ul className='text'>
                                    <li><b style={{color: 'rgb(32, 37, 103)'}}><FormattedMessage id="bank.Worldremit.howitwork2" defaultMessage="Choose the country you want to send to: " /></b><FormattedMessage id="bank.Worldremit.howitwork2.2" defaultMessage="you have a choice of over 125 different countries." /></li>
                                    <li><b style={{color: 'rgb(32, 37, 103)'}}><FormattedMessage id="bank.Worldremit.howitwork3" defaultMessage="Choose how you want send your money: " /></b><FormattedMessage id="bank.Worldremit.howitwork3.2" defaultMessage="cash pickup, bank deposit, Mobile Money or Airtime top-up." /></li>
                                    <li><b style={{color: 'rgb(32, 37, 103)'}}><FormattedMessage id="bank.Worldremit.howitwork4" defaultMessage="Choose how much you want to send: " /></b><FormattedMessage id="bank.Worldremit.howitwork4.2" defaultMessage="enter an amount and the low fees and exchange rates will be clearly shown." /></li>
                                    <li><b style={{color: 'rgb(32, 37, 103)'}}><FormattedMessage id="bank.Worldremit.howitwork5" defaultMessage="Create an account: " /></b><FormattedMessage id="bank.Worldremit.howitwork5.2" defaultMessage="if you don’t already have one, fill in some simple details." /></li>
                                    <li><b style={{color: 'rgb(32, 37, 103)'}}><FormattedMessage id="bank.Worldremit.howitwork6" defaultMessage="Enter your friend’s or family’s details: " /></b><FormattedMessage id="bank.Worldremit.howitwork6.2" defaultMessage="choose from a list of people you’ve already sent to, or add a new person by entering their details." /></li>
                                    <li><b style={{color: 'rgb(32, 37, 103)'}}><FormattedMessage id="bank.Worldremit.howitwork7" defaultMessage="Pay: " /></b><FormattedMessage id="bank.Worldremit.howitwork7.2" defaultMessage="Choose how you want to pay – bank account or debit/credit card - then confirm the amount. It’s as simple as that." /></li>
                                </ul>
                                <ReactPlayer
                                    width='100%'
                                    controls
                                    config={{
                                        youtube: {
                                            playerVars: { showinfo: 1 }
                                        }
                                    }}
                                    url="https://youtu.be/Rrv9sfXlahk"
                                />
                            </Collapsible>
                        )}
                    </FormattedMessage>
                    { this.props.language === 'fr-FR' ? null : <FormattedMessage id="bank.Worldremit.HowToSend" defaultMessage="How to send your money online">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "5")} handleTriggerClick={this.handleClick} accordionPosition="5">
                                <div className="text"><FormattedMessage id="bank.Worldremit.howtosend1" defaultMessage="Forget visiting an agent or bank, you can send your money online with Worldremit in just a few steps " /></div>
                                <div className='text'>
                                    <p><b style={{color: 'rgb(32, 37, 103)'}}><FormattedMessage id="bank.Worldremit.howtosend2" defaultMessage="1. Where do you need to send to?  " /></b><FormattedMessage id="bank.Worldremit.howtosend2.2" defaultMessage="150 countries are available to choose from" /></p>
                                    <p><b style={{color: 'rgb(32, 37, 103)'}}><FormattedMessage id="bank.Worldremit.howtosend3" defaultMessage="2. How do you want to send it? " /></b><FormattedMessage id="bank.Worldremit.howtosend3.2" defaultMessage="Depending on where you're sending, choose between " />
                                        <ul className="text">
                                            <li><u><span style={{cursor: 'pointer'}} onClick={() => window.open("https://www.awin1.com/cread.php?awinmid=5877&awinaffid=525947&clickref=&p=https%3A%2F%2Fwww.worldremit.com%2Fen%2Fhow-it-works-cash-pickup", "_blank")}><FormattedMessage id="bank.Worldremit.howtosend3.3" defaultMessage=" Cash pickup," /></span></u></li>
                                            <li><u><span style={{cursor: 'pointer'}} onClick={() => window.open("https://www.awin1.com/cread.php?awinmid=5877&awinaffid=525947&clickref=&p=https%3A%2F%2Fwww.worldremit.com%2Fen%2Fhow-it-works-bank-deposit", "_blank")}><FormattedMessage id="bank.Worldremit.howtosend3.4" defaultMessage=" Bank deposit," /></span></u></li>
                                            <li><u><span style={{cursor: 'pointer'}} onClick={() => window.open("https://www.awin1.com/cread.php?awinmid=5877&awinaffid=525947&clickref=&p=https%3A%2F%2Fwww.worldremit.com%2Fen%2Fhow-it-works-mobile-money", "_blank")}><FormattedMessage id="bank.Worldremit.howtosend3.5" defaultMessage=" Mobile money," /></span></u></li>
                                            <li><u><span style={{cursor: 'pointer'}} onClick={() => window.open("https://www.awin1.com/cread.php?awinmid=5877&awinaffid=525947&clickref=&p=https%3A%2F%2Fwww.awin1.com%2Fcread.php%3Fawinmid%3D5877%26awinaffid%3D525947%26clickref%3D%26p%3Dhttps%253A%252F%252Fwww.worldremit.com%252Fen%252Fhow-it-works-airtime-top-up", "_blank")}><FormattedMessage id="bank.Worldremit.howtosend3.6" defaultMessage=" Airtime top-up," /></span></u></li>
                                        </ul>
                                    </p>
                                    <p><b style={{color: 'rgb(32, 37, 103)'}}><FormattedMessage id="bank.Worldremit.howtosend4" defaultMessage="3. How much do you want to send?" /></b><FormattedMessage id="bank.Worldremit.howtosend4.2" defaultMessage="You'll always see the low fees and exchange rates up front" /></p>
                                    <p><b style={{color: 'rgb(32, 37, 103)'}}><FormattedMessage id="bank.Worldremit.howtosend5" defaultMessage="4. Who are you sending to?" /></b><FormattedMessage id="bank.Worldremit.howtosend5.2" defaultMessage="Add the details of the person you’re sending to or choose from a list of people you’ve sent to before" /></p>
                                    <p><b style={{color: 'rgb(32, 37, 103)'}}><FormattedMessage id="bank.Worldremit.howtosend6" defaultMessage="5. How would you like to pay?" /></b><FormattedMessage id="bank.Worldremit.howtosend6.2" defaultMessage="Choose how to pay (bank account or debit/credit card), double check your amount and you're done!" /></p>
                                </div>
                            </Collapsible>
                        )}
                    </FormattedMessage>
}
                    <FormattedMessage id="bank.Worldremit.video" defaultMessage="Video">
                    {msg => ( 
                    <Collapsible trigger={msg} open={(this.state.position === "6")} handleTriggerClick={this.handleClick} accordionPosition="6">
                        <ReactPlayer
                            width='100%'
                            controls
                            config={{
                                youtube: {
                                    playerVars: { showinfo: 1 }
                                }
                            }}
                            url={this.state.youtubeHW}
                        />
                    </Collapsible>
                    )}
                    </FormattedMessage>
                </div>
                <div className="footer" style={{ textAlign: 'center' }}>
                    <Button variant="contained" color="primary" className="button" onClick={this.handleClickAccount}>
                        <img src={Issoyo_logo} alt='' style={{ width: '11%', marginRight: '5%' }}></img>
                        <FormattedMessage id="bank.open.account"></FormattedMessage>
                    </Button>
                </div>
            </div>
        )
    }
}

export default (WorldremitModal);