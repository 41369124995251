const countryAvailable = [
    {
        'bank': [ "fr", "be", "bh", "sp", "at", "cy", "ee", "fi", "de", "gi", "is", "lv", "li", "lt", "lu", "mt", "mc", "nl", "kr", "pt", "qa", "sm", "sk", "si", "es", "au", "bd", "br", "bg", "ca", "cl", "cn", "cm", "hr", "cz", "cy", "dk", "eg", "ge", "gh", "gi", "gr", "hk", "om", "hu", "in", "id", "ie", "il", "it", "jp", "jo", "ke", "my", "mx", "ma", "np", "nz", "ng", "no", "pk", "pe", "pn", "pl", "ro", "ru", "sk", "si", "sg", "za", "lk", "se", "ch", "th", "tr", "ua", "gb", "us", "vn", "ae", "tw"],
        'telecom': ['fr', 'gb', 'us'],
        'insurances': ['fr', 'gb'],
        'house': ["fr", "be", "bh", "sp", "at", "cy", "ee", "fi", "de", "gi", "is", "lv", "li", "lt", "lu", "mt", "mc", "nl", "kr", "pt", "qa", "sm", "sk", "si", "es", "au", "bd", "br", "bg", "ca", "cl", "cn", "cm", "hr", "cz", "cy", "dk", "eg", "ge", "gh", "gi", "gr", "hk", "om", "hu", "in", "id", "ie", "il", "it", "jp", "jo", "ke", "my", "mx", "ma", "np", "nz", "ng", "no", "pk", "pe", "pn", "pl", "ro", "ru", "sk", "si", "sg", "za", "lk", "se", "ch", "th", "tr", "ua", "gb", "us", "vn", "ae", "tw",'gb', 'ca', 'ar', 'au', 'at', 'be', 'kh', 'ch', 'co', 'cr', 'cz', 'dk', 'ec', 'ee', 'de', 'gr', 'gt', 'hu', 'is', 'in', 'id', 'ir', 'it', 'jp', 'lv', 'lt', 'my', 'mt', 'mx', 'mc', 'no', 'om', 'pe', 'pl', 'pt', 'ro', 'ru', 'sg', 'si', 'kr', 'sp', 'se', 'ch', 'tw', 'nl', 'tr', 'ae', 'us', 'uy'],
        'transport': ['gb', 'fr', 'fi', 'at', 'cy', 'ee', 'de', 'gr', 'ie', 'it', 'lv', 'lt', 'lu', 'mt', 'nl', 'pt', 'sk', 'si', 'es', 'kr'],
        'internet': ['fr', 'gb'],
        'ticketing': ['fr', 'gb', 'ca', 'ar', 'au', 'at', 'be', 'kh', 'ch', 'co', 'cr', 'cz', 'dk', 'ec', 'ee', 'de', 'gr', 'gt', 'hu', 'is', 'in', 'id', 'ir', 'it', 'jp', 'lv', 'lt', 'my', 'mt', 'mx', 'mc', 'no', 'om', 'pe', 'pl', 'pt', 'ro', 'ru', 'sg', 'si', 'kr', 'sp', 'se', 'ch', 'tw', 'nl', 'tr', 'ae', 'us', 'uy'],
        'energy': ['fr'],
        'television': ['gb'],
        
    },
]
export {countryAvailable}