import React from 'react';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser, checkEmail, nextRegister, loginUserGoogle } from "../../../actions/authActions";
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { GoogleLogin } from 'react-google-login';


require("./RegisterPart2.scss");

const style = {
    root: {
        color: "rgba(68, 76, 165, 0.85)",
        fontFamily: "'Nunito', sans-serif !important",
    },
    input: {
        borderColor: '#3e45948a !important',
    },
};

class RegisterPart2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: this.props.localUser.email,
            password: '',
            repassword: '',
            showPassword: false,
            errorEmail: false,
            errorPassword: false,
            pwMatch: false,
            errorRequired: false,
            messageMail: <FormattedMessage id="registerPart2.error.email" defaultMessage="Please enter valid email" />,
            messagePassword: <FormattedMessage id="registerPart2.error.password" defaultMessage="Password must have 1 upper case and 8 characters" />,
            messageRepassword: <FormattedMessage id="registerPart2.error.repassword" defaultMessage="Password doesn't match" />
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.checkEmail = this.checkEmail.bind(this);
        this.checkPassword = this.checkPassword.bind(this);
        this.checkMatch = this.checkMatch.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.setErrorRed = this.setErrorRed.bind(this);
        this.responseGoogle = this.responseGoogle.bind(this);
    }

    responseGoogle(response) {
        if (response.email !== null) {
            var user = {};
            user.nickname = response.profileObj.givenName + '#' + Math.round(Math.random() * (9999 - 1) + 1);
            user.firstname = response.profileObj.givenName;
            user.name = response.profileObj.familyName;
            user.email = response.profileObj.email;
            user.token = response.profileObj.googleId;
            this.props.loginUserGoogle(user);
        }
    }

    setErrorRed() {
        if (this.state.email === '')
            this.setState({
                errorEmail: true,
                messageMail: <FormattedMessage id="registerPart2.error.email" defaultMessage="Please enter valid email" />,
            });
        if (this.state.password === '')
            this.setState({
                errorPassword: true,
                messagePassword: <FormattedMessage id="registerPart2.error.password" defaultMessage="Password must have 1 upper case and 8 characters" />,
            });
        if (this.state.repassword === '')
            this.setState({ pwMatch: true });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors.email) {
            this.setState({
                errorEmail: true,
                messageMail: <FormattedMessage id="registerPart2.error.email.used" defaultMessage="Email already used" />,
            })
        } else if (this.state.password !== ''){
            this.setState({ errorRequired: false });
            this.props.nextRegister({
                email: this.state.email,
                password: this.state.password,
                repassword: this.state.repassword,
                name: '',
                firstName: '',
                nickname: '',
                phone: '',
                title: 'none',
            });
            this.props.history.push('/signup1');
        }
    }

    componentDidMount() {
        // If logged in and user navigates to Register page, should redirect them to dashboard
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/");
        }
    }

    handleChange(event) {
        if (event.target.name === "email") {
            this.checkEmail(event.target.value);
            this.setState({ [event.target.name]: event.target.value });
        } else if (event.target.name === "password") {
            this.checkPassword(event.target.value);
            this.setState({ [event.target.name]: event.target.value });
        } else if (event.target.name === "repassword") {
            this.checkMatch(event.target.value);
            this.setState({ [event.target.name]: event.target.value });
        }
    }


    handleClick() {
			console.log("1...");
        if (this.state.email === '' || this.state.password === '' || this.state.repassword === '') {
					console.log("2...");
            this.setState({ errorRequired: true });
            this.setErrorRed();
        } else if ((this.state.errorEmail) || (this.state.errorPassword) || (this.state.pwMatch)) {
					console.log("3...");
            this.setState({ errorRequired: true });
            this.setErrorRed();
        } else {
					console.log("4...");
            const reqData = { email: this.state.email }
            this.props.checkEmail(reqData);
        }
    }

    checkMatch(repassword) {
        if (this.state.password !== repassword) {
            this.setState({
                pwMatch: true,
                messageRepassword: <FormattedMessage id="registerPart2.error.repassword" defaultMessage="Password doesn't match" />
            })
        }
        else
            this.setState({ pwMatch: false })
    }

    checkPassword(password) {
        var anUpperCase = /[A-Z]/;
        var numUpper = 0;
        for (var i = 0; i < password.length; i++) {
            if (anUpperCase.test(password[i]))
                numUpper++;
        }
        if (password.length < 8 || numUpper === 0) {
            this.setState({
                errorPassword: true,
                messagePassword: <FormattedMessage id="registerPart2.error.password" defaultMessage="Password must have 1 upper case and 8 characters" />,
            });
        } else {
            this.setState({ errorPassword: false });
        }
    }
    checkEmail(email) {
        var re = /\S+@\S+\.\S+/;
        if (re.test(email)) {
            this.setState({ errorEmail: false });
        }
        else {
            this.setState({
                errorEmail: true,
                messageMail: <FormattedMessage id="registerPart2.error.email" defaultMessage="Please enter valid email" />
            });
        }
    }

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                    <div className="Signup2 test">
                        <div className="signup_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.push('/')}></i></div>
                        <div className="col-12 title"><div>
                            <FormattedMessage id="registerPart2.title" defaultMessage="Create your account" />
                        </div></div>
                        <div style={{ marginBottom: '12px', marginTop: '12px' }}>
                        <FormattedMessage id="signin.google" defaultMessage="Sign in with Google">
                            {msg => (
                                <GoogleLogin
                                    //clientId="579721595351-htqc75h727bea53rc3lnpuugb79ue0jd.apps.googleusercontent.com"
                                  	clientId="922307892462-06cn4i30jh5js23vtvapd4e18fqo534b.apps.googleusercontent.com"//id issoyo.com et https://issoyoweb.nogaetechnologies.com/ et 	http://localhost:3000
                                   	buttonText={msg}
                                    onSuccess={this.responseGoogle}
                                    onFailure={this.responseGoogle}
                                    cookiePolicy={'single_host_origin'}
                                    className='login-google-btn'
                                />)}
                        </FormattedMessage>
                    </div>
                    <FormattedMessage id="signin.or" defaultMessage="Or">
                        {msg => (<hr className="telecom_operator-price" data-content={msg}></hr>
                        )}
                    </FormattedMessage>
                        <div className="error required" style={this.state.errorRequired ? {} : { display: 'none' }}>
                            <FormattedMessage id="registerPart2.error.required" defaultMessage="You must fill out all required fields" />
                        </div>
                        <div className="div-input-mail">
                            <TextField
                                required
                                error={this.state.errorEmail}
                                value={this.state.email}
                                id="outlined-email-input"
                                label="Email"
                                className="input-mail"
                                type="email"
                                name="email"
                                autoComplete="email"
                                margin="normal"
                                variant="outlined"
                                style={{ color: '#954ECC' }}
                                onChange={this.handleChange}
                                InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                            />
                            <div className="error" style={this.state.errorEmail ? {} : { display: 'none' }}>{this.state.messageMail}</div>
                        </div>
                        <div className="div-input-password">
                            <FormattedMessage id="registerPart2.password" defaultMessage="Password" >
                                {msg => (<TextField
                                    required
                                    value={this.state.password}
                                    error={this.state.errorPassword}
                                    name='password'
                                    id="outlined-adornment-password"
                                    className="input-password"
                                    variant="outlined"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    label={msg}
                                    onChange={this.handleChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="Toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                >
                                                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        classes: { root: classes.root, notchedOutline: classes.input }
                                    }}
                                />)}
                            </FormattedMessage>
                            <div className="error" style={this.state.errorPassword ? {} : { display: 'none' }}>{this.state.messagePassword}</div>
                        </div>
                        <div className="div-input-repassword">
                            <FormattedMessage id="registerPart2.repassword" defaultMessage="Password confirmation" >
                                {msg => (<TextField
                                    required
                                    value={this.state.repassword}
                                    error={this.state.pwMatch}
                                    id="outlined-adornment-repassword"
                                    className="input-repassword"
                                    variant="outlined"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    label={msg}
                                    name='repassword'
                                    onChange={this.handleChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="Toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                >
                                                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        classes: { root: classes.root, notchedOutline: classes.input }
                                    }}
                                />)}
                            </FormattedMessage>
                        </div>
                        <div className="error" style={this.state.pwMatch ? {} : { display: 'none' }}>{this.state.messageRepassword}</div>
                        <div className="row">
                            <div className="col send">
                                <Button variant="contained" color="primary" className="button" onClick={this.handleClick}>
                                    <FormattedMessage id="registerPart2.button.next" defaultMessage="Next" />
                                </Button>
                            </div>
                        </div>
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{ width: "25%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
            </React.Fragment>
        )
    }
}

RegisterPart2.propTypes = {
    checkEmail: PropTypes.func.isRequired,
    nextRegister: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    localUser: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.auth.error,
    localUser: state.localData.localUser
});

export default connect(
    mapStateToProps,
    { loginUser, checkEmail, nextRegister, loginUserGoogle }
)(withStyles(style)(RegisterPart2));
