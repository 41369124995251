import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { FormattedMessage } from 'react-intl';
import { withAlert } from 'react-alert'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";

require('./Recover.scss');

class Recover extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            repassword: '',
            errorPassword: false,
            pwMatch: false,
            errorRequired: false,
            messagePassword: <FormattedMessage id="recover.error.password" defaultMessage="Password must have 1 upper case and 8 characters" />,
            messageRepassword: <FormattedMessage id="recover.error.repassword" defaultMessage="Password doesn't match" />
        }
        this.handleChange = this.handleChange.bind(this);
        this.checkMatch = this.checkMatch.bind(this);
        this.checkPassword = this.checkPassword.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = ({ match }) => {
            if (this.state.password === '' || this.state.repassword === '') {
                this.setState({ errorRequired: true });
                if (this.state.password === '')
                    this.setState({ errorPassword: true });
                if (this.state.repassword === '')
                    this.setState({ pwMatch: true });
            } else if ((this.state.errorPassword) || (this.state.pwMatch)) {
                this.setState({ errorRequired: true });
                this.setErrorRed();
            } else {
                axios.put('/api/users/updatePassword', {token: this.props.match.params.token, password: this.state.password });
                this.props.alert.show('Password updated', { type: 'success' });
                this.props.history.push('/');
            }
    }

    handleChange(event) {
         if (event.target.name === "password") {
            this.checkPassword(event.target.value);
            this.setState({ [event.target.name]: event.target.value });
        } else if (event.target.name === "repassword") {
            this.checkMatch(event.target.value);
            this.setState({ [event.target.name]: event.target.value });
        }
    }

    checkMatch(repassword) {
        if (this.state.password !== repassword) {
            this.setState({
                pwMatch: true,
                messageRepassword: <FormattedMessage id="recover.error.repassword" defaultMessage="Password doesn't match" />
            })
        }
        else
            this.setState({ pwMatch: false })
    }

    checkPassword(password) {
        var anUpperCase = /[A-Z]/;
        var numUpper = 0;
        for (var i = 0; i < password.length; i++) {
            if (anUpperCase.test(password[i]))
                numUpper++;
        }
        if (password.length < 8 || numUpper === 0) {
            this.setState({
                errorPassword: true,
                messagePassword: <FormattedMessage id="recover.error.password" defaultMessage="Password must have 1 upper case and 8 characters" />,
            });
        } else {
            this.setState({ errorPassword: false });
        }
    }

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    render() {
        return (
            <div className="Recover">
                <div className="recover-title">Recover password</div>
                <div className="recover-subtitle">Enter a new password</div>
                <div className="error required" style={this.state.errorRequired ? {} : { display: 'none' }}>
                        <FormattedMessage id="recover.error.required" defaultMessage="You must fill out all required fields" />
                    </div>
                <div className="recover_form">
                    <div className="div-input-password">
                        <FormattedMessage id="recover.password" defaultMessage="Password" >
                            {msg => (<TextField
                                required
                                value={this.state.password}
                                error={this.state.errorPassword}
                                name='password'
                                id="outlined-adornment-password"
                                className="input-password"
                                variant="outlined"
                                type={this.state.showPassword ? 'text' : 'password'}
                                label={msg}
                                onChange={this.handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                            >
                                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />)}
                        </FormattedMessage>
                        <div className="error" style={this.state.errorPassword ? {} : { display: 'none' }}>{this.state.messagePassword}</div>
                    </div>
                    <div className="div-input-repassword">
                        <FormattedMessage id="recover.repassword" defaultMessage="Password confirmation" >
                            {msg => (<TextField
                                required
                                value={this.state.repassword}
                                error={this.state.pwMatch}
                                id="outlined-adornment-repassword"
                                className="input-repassword"
                                variant="outlined"
                                type={this.state.showPassword ? 'text' : 'password'}
                                label={msg}
                                name='repassword'
                                onChange={this.handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                            >
                                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />)}
                        </FormattedMessage>
                    </div>
                    <div className="error" style={this.state.pwMatch ? {} : { display: 'none' }}>{this.state.messageRepassword}</div></div>
                <div className="recover_send">
                    <Button variant="contained" color="primary" className="button" onClick={this.handleClick}>
                        <FormattedMessage id="recover.button.update" defaultMessage="Update" />
                    </Button>
                </div>
            </div>
        )
    }
}

Recover.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.auth.error
});
export default connect(
    mapStateToProps,
    { }
)(withAlert(Recover));