import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import ReactPlayer from 'react-player';
import Axa_logo from '../../../../../assets/img/providers/Axa_logo.jpg';

require('./Business.scss')

class Business extends Component {
    render() {
        return (
            <div className="business">
                <div className="business_logo">
                    <img style={{ width: '250px' }} src={Axa_logo} alt=''></img>
                </div>
                <div className="text axa_title">
                    <FormattedMessage id="axa.business.title" defaultMessage="Business insurance with AXA " />
                </div>
                <ul className="text">
                    <li><FormattedMessage id="axa.business.tailor" defaultMessage="Tailor your policy with a range of optional extras and levels of cover" /></li>
                    <li><FormattedMessage id="axa.business.protect" defaultMessage="AXA protect over 600,000 businesses in the UK" /></li>
                    <li><FormattedMessage id="axa.business.contact" defaultMessage="UK-based contact centre to guide you every step of the way" /></li>
                </ul>
                <div className="text axa_title">
                    <FormattedMessage id="axa.business.coverage.title" defaultMessage="Coverage for: " />
                </div>
                <ul className="text">
                    <li><u style={{ cursor: 'pointer' }} onClick={() => window.open("https://www.awin1.com/cread.php?awinmid=12435&awinaffid=525947&clickref=&p=https%3A%2F%2Fwww.axa.co.uk%2Fbusiness-insurance%2Femployers-liability%2F", "_blank")}><FormattedMessage id="axa.business.employer" defaultMessage="Employer’s liability" /></u></li>
                    <li><u style={{ cursor: 'pointer' }} onClick={() => window.open("https://www.awin1.com/cread.php?awinmid=12435&awinaffid=525947&clickref=&p=https%3A%2F%2Fwww.axa.co.uk%2Fbusiness-insurance%2Fprofessional-indemnity%2F", "_blank")} ><FormattedMessage id="axa.business.profesionnal" defaultMessage="Professional indemnity insurance" /></u></li>
                    <li><u style={{ cursor: 'pointer' }} onClick={() => window.open("https://www.awin1.com/cread.php?awinmid=12435&awinaffid=525947&clickref=&p=https%3A%2F%2Fwww.axa.co.uk%2Fbusiness-insurance%2Fpublic-liability%2F", "_blank")}><FormattedMessage id="axa.business.public" defaultMessage="Public liability insurance" /></u></li>
                    <li><u style={{ cursor: 'pointer' }} onClick={() => window.open("https://www.awin1.com/cread.php?awinmid=12435&awinaffid=525947&clickref=&p=https%3A%2F%2Fwww.axa.co.uk%2Fbusiness-insurance%2Fcontents-insurance%2F", "_blank")}><FormattedMessage id="axa.business.content" defaultMessage="Content insurance" /></u></li>
                </ul>
                <div className="axa_video">
                    <ReactPlayer
                        width='100%'
                        controls
                        config={{
                            youtube: {
                                playerVars: { showinfo: 1 }
                            }
                        }}
                        url="https://youtu.be/OLPJGmvV6vs"
                    />
                </div>
            </div>
        )
    }
}

export default Business;