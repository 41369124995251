import React, { Component } from 'react'
import luko_logo from '../../../../../assets/img/providers/luko.png';
import luko_brochure_fr from '../../../../../assets/document/insurance/luko_brochure_fr.pdf';
import luko_condition_fr from '../../../../../assets/document/insurance/luko_condition_fr.pdf';
import luko_brochure_en from '../../../../../assets/document/insurance/luko_brochure_en.pdf';
import luko_noo_brochure_fr from '../../../../../assets/document/insurance/ISSOYO_Luko_Noo_brochure_fr.pdf';
import luko_noo_condition_fr from '../../../../../assets/document/insurance/ISSOYO_Luko_Noo_condition_fr.pdf';
import luko_noo_ipid_fr from '../../../../../assets/document/insurance/ISSOYO_Luko_Noo_ipid_fr.pdf';
import luko_noo_brochure_en from '../../../../../assets/document/insurance/ISSOYO_Luko_Noo_brochure_en.pdf';
import forbes_brandvoice from '../../../../../assets/img/ISSOYO_forbes_brandvoice.jpg';
import les_echos from '../../../../../assets/img/ISSOYO_lesechos.jpg';
import challenges from '../../../../../assets/img/ISSOYO_challenges.png';
import latribune from '../../../../../assets/img/ISSOYO_latribune.png';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
import ReactPlayer from 'react-player';


require("./Habitation.scss")

function classicHabitation(language) {
    const handleClick = (document) => {
        if (document === "brochure") {
            if (language === 'fr-FR') {
                window.open(luko_brochure_fr, "_blank");
            } else {
                window.open(luko_brochure_en, "_blank");
            }
        } else if (document === "condition") {
            window.open(luko_condition_fr, "_blank");
        }
    }

    return (
        <div>
            <div className="title"><FormattedMessage id="luko.home.title" defaultMessage="Home Insurance with Luko" /></div>
            <div className="habitation_provider_logo">
                <img className="habitation_provider-img" src={luko_logo} alt='ICI1' />
            </div>
            <div className="text lulo_title">
                <FormattedMessage id="luko.advantage" defaultMessage="Luko benefits" />
            </div>
            <ul className="text">
                <li><FormattedMessage id="luko.covered" defaultMessage="Get covered in 2 minutes " /></li>
                <li><FormattedMessage id="luko.price" defaultMessage="At the best price" /></li>
                <li><FormattedMessage id="luko.commitment" defaultMessage="Without commitment" /></li>
                <li><FormattedMessage id="luko.refund" defaultMessage="Get paid in 2 hours" /></li>
                <li><FormattedMessage id="luko.repair" defaultMessage="Get repaired in 2 days" /></li>
                <li><FormattedMessage id="luko.policy" defaultMessage="Luko will cancel your old policy" /></li>
            </ul>
            <div className="habitation_document" style={{ marginTop: "24px", marginBottom: "48px" }}>
                <div className="">
                    <div className="health_document-container" onClick={() => handleClick("brochure")}>
                        <div>
                            <i className="fal fa-file-alt"></i> <FormattedMessage id="insurance.health.brochure" />
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className="health_document-container" onClick={() => handleClick("condition")}>
                        <div>
                            <i className="fal fa-file-alt"></i> <FormattedMessage id="insurance.health.condition" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
function nooHabitation(language) {
    const handleClick = (document) => {
        if (document === "brochure") {
            if (language === 'fr-FR') {
                window.open(luko_noo_brochure_fr, "_blank");
            } else {
                window.open(luko_noo_brochure_en, "_blank");
            }
        } else if (document === "condition") {
            window.open(luko_noo_condition_fr, "_blank");
        } else if (document === "ipid") {
            window.open(luko_noo_ipid_fr, "_blank");
        }
    }

    return (
        <div>
            <div className="title"><FormattedMessage id="luko.home.noo.title" defaultMessage="Insurance for non occupant owners with Luko" /></div>
            <div className="habitation_provider_logo">
                <img className="habitation_provider-img" src={luko_logo} alt='ICI' />
            </div>
            <div className="text luko_desc_noo">
                <div className="text"><FormattedMessage id="luko.essential" defaultMessage="All essential guarantees for your rental property in 1 click" /></div>
                <div className="text"><FormattedMessage id="luko.reminder" defaultMessage="As a reminder, if you are in co-ownership, you are obliged to insure your property." /></div>
                <div className="text"><FormattedMessage id="luko.complementary" defaultMessage="This insurance is complementary to building and renter's insurance and covers you in all situations!" /></div>
            </div>
            <div className="text lulo_title">
                <FormattedMessage id="luko.coverage" defaultMessage="Coverage" />
            </div>
            <ul className="text">
                <li><FormattedMessage id="luko.civil" defaultMessage="Civil liability" /></li>
                <li><FormattedMessage id="luko.criminal" defaultMessage="Criminal defence and appeals" /></li>
                <li><FormattedMessage id="luko.water" defaultMessage="Water damage" /></li>
                <li><FormattedMessage id="luko.fire" defaultMessage="Fires and associated risks" /></li>
                <li><FormattedMessage id="luko.theft" defaultMessage="Theft" /></li>
                <li><FormattedMessage id="luko.techno" defaultMessage="Technological disaster, terrorism and riots" /></li>
                <li><FormattedMessage id="luko.climat" defaultMessage="Climatic events and natural disasters" /></li>
                <li><FormattedMessage id="luko.elec" defaultMessage="Electrical damage" /></li>
                <li><FormattedMessage id="luko.glass" defaultMessage="Glass breakage" /></li>
            </ul>
            <div className="habitation_document" style={{ marginTop: "24px", marginBottom: "48px" }}>
                <div className="">
                    <div className="health_document-container" onClick={() => handleClick("brochure")}>
                        <div>
                            <i className="fal fa-file-alt"></i> <FormattedMessage id="insurance.health.brochure" />
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className="health_document-container" onClick={() => handleClick("condition")}>
                        <div>
                            <i className="fal fa-file-alt"></i> <FormattedMessage id="insurance.health.luko.condition" />
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className="health_document-container" onClick={() => handleClick("ipid")}>
                        <div>
                            <i className="fal fa-file-alt"></i> <FormattedMessage id="insurance.health.schengen.ipid" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
class House extends Component {
    constructor() {
        super();
        this.state = {
            habitationClass: "habitation_description",
            info: null,
            link: '',
            colorStudent: { color: '#7f3fce', backgroundColor: 'white' },
            colorSchengen: { color: '#7f3fce', backgroundColor: 'white' },
            youtubeHW: 'https://www.youtube.com/watch?v=YsThRyxUqgU',
        }
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(target) {
        if (target.value === "classic") {
            this.setState({
                habitationClass: 'habitation_description--appear',
                info: classicHabitation(this.props.language),
                colorClassic: { color: "white", backgroundColor: '#7f3fce' },
                colorNOO: { color: '#7f3fce', backgroundColor: 'white' },
            })
            if (this.props.language === 'fr-FR') {
                this.props.linkChange("https://www.awin1.com/cread.php?awinmid=16157&awinaffid=525947&clickref=&p=https%3A%2F%2Fwww.getluko.com%3Futm_source%3Dpartner%26utm_medium%3DAffiliation%26utm_campaign%3DAwin%26utm_content%3DHomeFR");
            } else {
                this.props.linkChange("https://www.awin1.com/cread.php?awinmid=16157&awinaffid=525947&clickref=&p=https%3A%2F%2Fwww.getluko.com%2Fen%3Futm_source%3Dpartner%26utm_medium%3DAffiliation%26utm_campaign%3DAwin%26utm_content%3DHomeFR");
            }
        } else if (target.value === 'noo') {
            this.setState({
                habitationClass: 'habitation_description--appear',
                info: nooHabitation(this.props.language),
                colorClassic: { color: "#7f3fce", backgroundColor: 'white' },
                colorNOO: { color: 'white', backgroundColor: '#7f3fce' },
            })
            if (this.props.language === 'fr-FR') {
                this.props.linkChange("https://www.awin1.com/cread.php?awinmid=16157&awinaffid=525947&clickref=&p=http%3A%2F%2Fwww.getluko.com%2Ffr%2Fassurance-proprietaire-non-occupant-luko%3Futm_source%3Dpartner%26utm_medium%3DAffiliation%26utm_campaign%3DAwin%26utm_content%3DHomeFR");
            } else {
                this.props.linkChange("https://www.awin1.com/cread.php?awinmid=16157&awinaffid=525947&clickref=&p=https%3A%2F%2Fwww.getluko.com%2Fen%2Fassurance-proprietaire-non-occupant-luko%3Futm_source%3Dpartner%26utm_medium%3DAffiliation%26utm_campaign%3DAwin%26utm_content%3DHomeFR");
            }
        }
    }


    render() {
        return (
            <div className="habitation_insurance">
                <div className="habitation_profile">
                    <div className="col-md-6 habitation_profile_question">
                        <FormattedMessage id="insurance.home.profile" defaultMessage="Contract type: " />
                    </div>
                    <div className="col-md-6 habitation_profile_sentence">
                        <div style={this.state.colorClassic} onClick={() => this.handleClick({ value: "classic" })} className="home_box">
                            <div>
                                <FormattedMessage id="insurance.home.classic" defaultMessage="Home Insurance" />
                            </div>
                        </div>
                        <div style={this.state.colorNOO} onClick={() => this.handleClick({ value: "noo" })} className="home_box">
                            <div>
                                <FormattedMessage id="insurance.home.nonoccupant" defaultMessage="Non occupant owners" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={this.state.habitationClass}>
                    {this.state.info}
                    <div>
                        <ReactPlayer
                            width='100%'
                            controls
                            config={{
                                youtube: {
                                    playerVars: { showinfo: 1 }
                                }
                            }}
                            url={this.state.youtubeHW}
                        />
                    </div>
                    <div className="insurance_description_presse">
                        <div className="title"><FormattedMessage id="insurance.presse" /></div>
                        <div className="presse_link">
                            <a className="presse_link-link" rel="noopener noreferrer" target="_blank" href="https://www.forbes.fr/brandvoice/luko-la-neoassurance-qui-a-seduit-des-milliers-de-francais-en-quelques-mois/">
                                <img className="image_presse" src={forbes_brandvoice} alt='forbes logo'></img>
                            </a>
                            <a className="presse_link-link" rel="noopener noreferrer" target="_blank" href="https://business.lesechos.fr/entrepreneurs/marketing-vente/0600041121153-luko-compte-conquerir-des-clients-sans-budget-marketing-325450.php">
                                <img className="image_presse" src={les_echos} alt='echos logo'></img>
                            </a>
                            <a className="presse_link-link" rel="noopener noreferrer" target="_blank" href="https://www.challenges.fr/finance-et-marche/assurances/images-satellites-datas-grace-a-big-brother-luko-assure-votre-maison_658880">
                                <img className="image_presse" src={challenges} alt='echos logo'></img>
                            </a>
                            <a className="presse_link-link" rel="noopener noreferrer" target="_blank" href="https://www.latribune.fr/entreprises-finance/banques-finance/luko-mise-sur-les-images-satellite-pour-depoussierer-l-assurance-habitation-maison-820723.html">
                                <img className="image_presse" src={latribune} alt='echos logo'></img>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

House.propTypes = {
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    service: state.services,
});

export default connect(
    mapStateToProps, {}
)(House);