import React, { Component } from 'react'
import axios from "axios";
import TableRow from './TableRow.jsx';
import PropTypes from "prop-types";
import { connect } from "react-redux";

class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = { blogs: [] };
        this.tabRow = this.tabRow.bind(this);
    }
    

    componentDidMount() {
        if (!this.props.auth.isAuthenticated || this.props.auth.user.userRole !== 'Admin') {
            this.props.history.push("/login");
        }
        axios.get('/api/service/getblog')
            .then(response => {
                this.setState({ blogs: response.data });
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    tabRow() {
        return this.state.blogs.map(function (object, i) {
            return <TableRow obj={object} key={i} />;
        });
    }

    render() {
        return (
            <div>
                <div style={{ marginTop: '100px' }} className="container">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <h2 href={'/'} className="navbar-brand">Blog ISSOYO</h2>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item">
                                    <a href={'/'} className="nav-link">Issoyo</a>
                                </li>
                                <li className="nav-item">
                                    <a href={'/admin-issoyo/blog/new'} className="nav-link">Create</a>
                                </li>
                                <li className="nav-item">
                                    <a href={'/Blog'} className="nav-link">Index</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                    <div>
                        <table className="table table-striped" style={{ marginTop: 20 }}>
                            <thead>
                                <tr>
                                    <th>Titre</th>
                                    <th>Resumé</th>
                                    <th>Date</th>
                                    <th>Pays</th>
                                    <th>Services</th>
                                    <th>Langue</th>
                                    <th colSpan="3">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.tabRow()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}


Blog.propTypes = {
    auth: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(
    mapStateToProps,
    { }
)(Blog);