import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { checkVerify } from "../../../actions/authActions.js";
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';


require("./RegisterPart3.scss");

const styles = {
    root: {
        color: 'rgb(62, 69, 148)',
        fontSize: '1.7rem',
        fontFamily: "'Nunito', sans-serif !important",
    },
    underline: {
        borderBottom: '3px solid #ae78d7',
    },
    formcontrol: {
        width: '40%'
    }
  };

class IssoyoNexmo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCodeSent: false,
            isCodeError: false,
            isPhoneError: false,
            isCodeValid: false,
            errorMsg: [],
            numone: '',
            numtwo: '',
            numthree: '',
            numfour: '',
        }
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors.status) {
            this.setState({
                isCodeValid: true,
            });
        }
    }
    handleChange(event) {
        const regex = /^[0-9]+$/;
        const form = event.target.form;
        const index = Array.prototype.indexOf.call(form, event.target);
        if ((regex.test(event.target.value) || event.target.value === '') && event.target.value.length <= 1) {
            this.setState({ [event.target.name]: event.target.value });
            if (event.target.value !== '' && index < 3)
                form.elements[index + 1].focus();
        }
    }

    onSubmit() {
        if ((this.state.numone === '') || (this.state.numtwo === '') || (this.state.numthree === '') ||
            (this.state.numfour === '')) {
            this.setState({ isCodeError: true, errorMsg: <FormattedMessage id="registerPart3.error.message" defaultMessage="You must fill out all required fields" /> });
        } else {
            const code = this.state.numone + this.state.numtwo + this.state.numthree + this.state.numfour;
            const userData = { request_id: this.props.auth.requestid, code: code };
            this.setState({ isCodeError: false })
            this.props.checkVerify(userData, this.props.history);
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <div className="error required" style={this.state.isCodeError ? {} : { display: 'none' }}>{this.state.errorMsg}</div>
                <form className="form">
                    <div className="row">
                        <div className="col div-input-code">
                            <TextField
                                error={this.state.isCodeError}
                                id="standard-number"
                                className="input-number"
                                margin="normal"
                                type='tel'
                                name='numone'
                                autoFocus
                                value={this.state.numone}
                                onChange={this.handleChange}
                                InputProps={{classes: {root: classes.root, underline: classes.underline, formControl: classes.formcontrol}}}
                            />
                        </div>
                        <div className="col div-input-code">
                            <TextField
                                error={this.state.isCodeError}
                                id="standard-number"
                                className="input-number"
                                margin="normal"
                                name='numtwo'
                                type='tel'
                                value={this.state.numtwo}
                                onChange={this.handleChange}
                            InputProps={{classes: {root: classes.root, underline: classes.underline, formControl: classes.formcontrol}}}
                            />
                        </div>
                        <div className="col div-input-code">
                            <TextField
                                error={this.state.isCodeError}
                                id="standard-number"
                                className="input-number"
                                margin="normal"
                                name='numthree'
                                type='tel'
                                value={this.state.numthree}
                                onChange={this.handleChange}
                            InputProps={{classes: {root: classes.root, underline: classes.underline, formControl: classes.formcontrol}}}

                            />
                        </div>
                        <div className="col div-input-code">
                            <TextField
                                error={this.state.isCodeError}
                                id="standard-number"
                                className="input-number"
                                margin="normal"
                                type='tel'
                                name='numfour'
                                value={this.state.numfour}
                                onChange={this.handleChange}
                            InputProps={{classes: {root: classes.root, underline: classes.underline, formControl: classes.formcontrol}}}

                            />
                        </div>
                    </div>
                </form>
                <div className="error-code" style={this.state.isCodeValid ? {} : { display: 'none' }}><FormattedMessage id="registerPart3.error.code" defaultMessage="Invalid code!" /></div>                
                <div className="row">
                    <div className="col send">
                        <Button variant="contained" color="primary" className="button" onClick={this.onSubmit}>
                            <FormattedMessage id="registerPart3.button.next" defaultMessage="Next" />
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
IssoyoNexmo.propTypes = {
    checkVerify: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.auth.error
});


export default connect(
    mapStateToProps,
    { checkVerify }
)(withRouter(withStyles(styles)(IssoyoNexmo)));