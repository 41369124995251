import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {
  GET_ERRORS,
  GET_LOCALUSER,
  RESET_LOCALUSER,
  SET_CURRENT_USER,
  SET_SUCCESS_VERIFY,
  SET_REQUEST_ID,
} from "./types";

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  }
};

export const setError = (payload) => {
  return {
    type: GET_ERRORS,
    payload: payload
  };
}
export const setSuccessVerify = (success) => {
  return {
    type: SET_SUCCESS_VERIFY,
    payload: success
  };
}

export const setRequestId = (request_id) => {
  return {
    type: SET_REQUEST_ID,
    payload: request_id
  };
}

export const nextRegister = (userData) => dispatch => {
  dispatch({
    type: GET_LOCALUSER,
    payload: userData,
  });
}

export const resetLocal = () => dispatch => {
  dispatch({
    type: RESET_LOCALUSER,
  });
}

export const setLocation = (userData) => dispatch => {
  let user = {};
  if (userData.user) {
    user = userData.user;
  }
  user.location = userData.location;
  user.country = userData.country;
  dispatch({
    type: SET_CURRENT_USER,
    payload: user
  });
}

// Verify Phone USer
export const sendVerify = (userData) => dispatch => {
	console.log("ici3_b2");
	console.log("userData = "+userData);
	console.log("userData = "+userData.number);
  axios.post('/api/users/sendverify', userData).then(res => {
		console.log("ici31_b2");
    const request_id = res.data.request_id;
    dispatch(setRequestId(request_id));
  }).catch((err) => {
		console.log("ici32_b2");
		console.log(err.response.data);
    dispatch(setError(err.response.data));
  });
};

export const reSendVerify = (userData) => dispatch => {
  axios.get('/api/users/cancelverify', userData).then(res => {
    return axios.post('/api/users/sendverify', userData).then(res => {
      const request_id = res.data.request_id;
      dispatch(setRequestId(request_id));
    }).catch((err) => {
      dispatch(setError(err.response.data));
    });
  })
};

// check nexmo code
export const checkVerify = (userData) => dispatch => {
  axios.post('/api/users/checkverify', userData).then(res => {
    dispatch(setSuccessVerify({ success: true }));
  }).catch(err => {
    dispatch(setError(err.response.data));
  });
};

// Register User
export const registerUser = (userData, history) => dispatch => {
  // re-direct to login on successful register
  axios.post("/api/users/register", userData).then(res => {
    dispatch(setCurrentUser(res.data));
    dispatch(resetLocal());
  }).catch(err => {
    dispatch(setError(err.response.data));
  });
};

// Login - get user token
export const loginUser = userData => dispatch => {
  axios.post("/api/users/login", userData).then(res => {
    // Save to localStorage
    // Set token to localStorage
    const { token } = res.data;
    localStorage.setItem("jwtToken", token);
    // Set token to Auth header
    setAuthToken(token);
    // Decode token to get user data
    const decoded = jwt_decode(token);
    // Set current user
    dispatch(setCurrentUser(decoded));
  }).catch(err => {
    dispatch(setError(err.response.data));
  })
};

// Login - get user token
export const loginUserGoogle = userData => dispatch => {
  axios.post("/api/users/logingoogle", userData).then(res => {
    // Save to localStorage
    // Set token to localStorage
    const { token } = res.data;
    localStorage.setItem("jwtToken", token);
    // Set token to Auth header
    setAuthToken(token);
    // Decode token to get user data
    const decoded = jwt_decode(token);
    // Set current user
    dispatch(setCurrentUser(decoded));
  }).catch(err => {
    dispatch(setError(err.response.data));
  })
};

export const sendConfirmation = reqData => dispatch => {
  axios.post('/api/users/register/sendconfirmation', reqData);
}

// Update user informations (name, nickname ...)
export const sendRecover = reqData => {
  axios.put('/api/users/recovermail', reqData);
}

// Update user email
export const updateEmail = reqData => dispatch => {
  axios.put('/api/users/updateemail', reqData).then(res => {
    dispatch(setCurrentUser(res.data.user));
  });
}

// Update user informations (name, nickname ...)
export const updateInfo = reqData => dispatch => {
  axios.put('/api/users/updateProfile', reqData).then(res => {
    dispatch(setCurrentUser(res.data.user));
  });
}

// Update user informations (name, nickname ...)
export const updateAccount = reqData => dispatch => {
  axios.put('/api/users/updateAccount', reqData).then(res => {
    dispatch(setCurrentUser(res.data.user));
  });
}

// Add new address
export const addAddress = reqData => dispatch => {
  axios.put('/api/users/addAddress', reqData).then(res => {
    dispatch(setCurrentUser(res.data.user));
  });
}

export const removeAddress = reqData => dispatch => {
  axios.put('api/users/deleteAddress', reqData).then(res => {
    dispatch(setCurrentUser(res.data.user));
  }).catch(err => { console.log(err) });
}

// Check if email doesn't exist
/*export const checkEmail = reqData => dispatch => {
  axios.post('/api/users/checkemail', reqData).then(res => {
		console.log("ici.");
    dispatch(setError({}));
  }).catch(err => dispatch(setError(err.response.data)));
}*/

export const checkEmail = reqData => dispatch => {
  axios.post('/api/users/checkemail', reqData).then(res => {
		console.log("ici.");
    dispatch(setError({}));
  }).catch(err => dispatch(setError(err.response.data)));
}

// Check if nickname doesn't exist
export const checkNickName = reqData => dispatch => {
  axios.post('/api/users/checknickname', reqData).then(res => {
    dispatch(setError({}));
  }).catch(err => dispatch(setError(err.response.data)));
}

// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

// Delete user and log user out
export const deleteUser = reqData => dispatch => {
  // Remove token from local storage
  console.log(reqData);
  axios.post('api/users/deleteAccount/sendconfirmation', reqData);
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};