import React from 'react';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
import Modal from 'react-responsive-modal';
import { withAlert } from 'react-alert';
import { updateAccount, checkEmail, deleteUser } from "../../../actions/authActions";

require("./Account.scss");

const stylesLogout = {
    modal: {
        'maxWidth': '500px',
        'width': '100%',
        'color': '#3f2751',
    }
};

class Account extends React.Component {
    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            repassword: '',
            showPassword: false,
            open: false,
            openDelete: false,
            messageMail: <FormattedMessage id="account.error.email" defaultMessage="Please enter valid email" />,
            errorEmail: false,
        };
        this.loginError = '';
        this.handleChange = this.handleChange.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickDelete = this.handleClickDelete.bind(this);
        this.checkEmail = this.checkEmail.bind(this);

    }

    componentDidMount() {
        this.setState({ email: this.props.auth.user.email })
    }
    handleChange(event) {
        if (event.target.name === "email") {
            this.checkEmail(event.target.value);
            this.setState({ [event.target.name]: event.target.value });
        } else {
            this.setState({ [event.target.name]: event.target.value });
        }
    }
    
    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.error.email) {
            this.setState({
                errorEmail: true,
                messageMail: <FormattedMessage id="account.error.email.used" defaultMessage="Email already used" />,
                open: false,
            })
        } else {
            const reqData = { password: this.state.password, email: this.props.auth.user.email, new_email: this.state.email }
            this.props.updateAccount(reqData);
            this.props.history.push('/profile');
        }
    }

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    handleClick() {
        const reqData = { email: this.state.email }
        this.props.checkEmail(reqData);

    }
    handleClickDelete() {
        const reqData = { user: { password: this.state.password, email: this.props.auth.user.email, new_email: this.state.email, _id: this.props.auth.user._id }};
        this.props.deleteUser(reqData);
        this.props.alert.show('An email to confirm has been send', { type: 'info' });
    }

    onOpenModal(opt) {
        if (opt === 'delete'){
        this.setState({ openDelete: true });
        } else {
        this.setState({ open: true });
        }
    };

    onCloseModal() {
        this.setState({ open: false, openDelete: false });
    };

    checkEmail(email) {
        var re = /\S+@\S+\.\S+/;
        if (re.test(email)) {
            this.setState({ errorEmail: false });
        }
        else {
            this.setState({
                errorEmail: true,
                messageMail: <FormattedMessage id="account.error.email" defaultMessage="Please enter valid email" />
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="Account">
                    <div className="account_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                    <div className="col-12 title"><div>
                        <FormattedMessage id="account.title" defaultMessage="My login and security" />
                    </div></div>
                    <div className="div-input-mail">
                        <TextField
                            id="outlined-email-input"
                            label="Email"
                            error={this.state.errorEmail}
                            className="input-mail"
                            type="email"
                            name="email"
                            autoComplete="email"
                            margin="normal"
                            value={this.state.email}
                            onChange={this.handleChange}
                            variant="outlined"
                        />
                        <div className="error" style={this.state.errorEmail ? {} : { display: 'none' }}>{this.state.messageMail}</div>
                    </div>
                    <div className="div-input-password">
                        <FormattedMessage id="account.newpassword" defaultMessage="New password" >
                            {msg => (<TextField
                                id="outlined-adornment-password"
                                className="input-password"
                                name="password"
                                variant="outlined"
                                onChange={this.handleChange}
                                type={this.state.showPassword ? 'text' : 'password'}
                                label={msg}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                            >
                                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />)}
                        </FormattedMessage>
                    </div>
                    <div className="div-input-repassword">
                        <FormattedMessage id="account.newrepassword" defaultMessage="New password confirmation" >
                            {msg => (<TextField
                                id="outlined-adornment-repassword"
                                className="input-repassword"
                                variant="outlined"
                                name="repassword"
                                type={this.state.showPassword ? 'text' : 'password'}
                                label={msg}
                                onChange={this.handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                            >
                                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />)}
                        </FormattedMessage>
                    </div>
                    <div className='row' style={{ justifyContent: 'space-evenly' }}>
                    <div className="delete">
                            <Button variant="contained" color="primary" className="button" onClick={() => this.onOpenModal('delete')}>
                                <FormattedMessage id="account.button.delete" defaultMessage="Delete account" />
                            </Button>
                        </div>
                        <div className="send">
                            <Button variant="contained" color="primary" className="button" onClick={this.onOpenModal}>
                                <FormattedMessage id="account.button.update" defaultMessage="Update" />
                            </Button>
                        </div>
                    </div>
                </div>
                <Modal open={this.state.open} onClose={this.onCloseModal} center styles={stylesLogout} blockScroll>
                    <p className='message-disconnect'><FormattedMessage id="account.modal.disconnect" defaultMessage="Are you sure you want to update your security information ?" /></p>
                    <Button color="primary" className="button" onClick={this.handleClick} style={{ fontFamily: 'Nunito', backgroundColor: 'transparent', color: 'rgba(68, 76, 165, 0.85)' }} >
                        <FormattedMessage id="account.modal.update.yes" defaultMessage="Yes" />
                    </Button>
                    <Button color="primary" className="button" onClick={this.onCloseModal} style={{ fontFamily: 'Nunito', backgroundColor: 'transparent', color: 'rgba(68, 76, 165, 0.85)' }} >
                        <FormattedMessage id="account.modal.update.no" defaultMessage="No" />
                    </Button>
                </Modal>
                <Modal open={this.state.openDelete} onClose={this.onCloseModal} center styles={stylesLogout} blockScroll>
                    <p className='message-disconnect'><FormattedMessage id="account.modal.delete" defaultMessage="Are you sure you want to delete your account ? This action cannot be undone" /></p>
                    <Button color="primary" className="button" onClick={this.handleClickDelete} style={{ fontFamily: 'Nunito', backgroundColor: 'transparent', color: 'rgba(68, 76, 165, 0.85)' }} >
                        <FormattedMessage id="account.modal.delete.yes" defaultMessage="Yes"/>
                    </Button>
                    <Button color="primary" className="button" onClick={this.onCloseModal} style={{ fontFamily: 'Nunito', backgroundColor: 'transparent', color: 'rgba(68, 76, 165, 0.85)' }} >
                        <FormattedMessage id="account.modal.delete.no" defaultMessage="No"/>
                    </Button>
                </Modal>
            </React.Fragment>
        )
    }
}

Account.propTypes = {
    checkEmail: PropTypes.func.isRequired,
    updateAccount: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(
    mapStateToProps, { updateAccount, checkEmail, deleteUser }
)(withAlert(Account));
