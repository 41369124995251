import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';

require("./Profile.scss");

class Profile extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="profile">
                <div className="signup_back"><i style={{ color: '#593e96' }} className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                    <div className="col-12 title"><div>
                        <FormattedMessage id="profile.title" defaultMessage="Settings" />
                    </div></div>
                    <div className="row">
                        <div className="col-sm-6 profile_card">
                            <div onClick={() => this.props.history.push('/account')} style={{ cursor: 'pointer' }} className="anchor-card">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            <FormattedMessage id="profile.account.title" defaultMessage="Login and security" />
                                        </h5>
                                        <p className="card-text">
                                            <FormattedMessage id="profile.account.message" defaultMessage="Edit your password, phone number..." />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 profile_card">
                            <div onClick={() => this.props.history.push('/address')} style={{ cursor: 'pointer' }} className="anchor-card">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            <FormattedMessage id="profile.address.title" defaultMessage="Address" />
                                        </h5>
                                        <p className="card-text">
                                            <FormattedMessage id="profile.address.message" defaultMessage="Edit or add new address." />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 profile_card">
                            <div onClick={() => this.props.history.push("/information")} style={{ cursor: 'pointer' }} className="anchor-card">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            <FormattedMessage id="profile.information.title" defaultMessage="Information" />
                                        </h5>
                                        <p className="card-text">
                                            <FormattedMessage id="profile.information.message" defaultMessage="Edit your first name, gender, ..." />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

Profile.propTypes = {
    profile: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    profile: state.auth.profile,
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    {  }
)(Profile);