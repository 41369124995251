const currencyCodeList = [
    {
    "symbol" : "$",
    "name" : "US Dollar",
    "symbol_native" : "$",
    "decimal_digits" : 2,
    "rounding" : 0,
    "code" : "USD",
    "country_code": "us",
    "name_plural" : "US dollars",
    "img": "../../../../../assets/img/flags/rounded/USA.png"
    },
    {
    "symbol" : "CA$",
    "name" : "Canadian Dollar",
    "symbol_native" : "$",
    "decimal_digits" : 2,
    "rounding" : 0,
    "code" : "CAD",
    "country_code": "ca",
    "name_plural" : "Canadian dollars",
    "img": "../../../../../assets/img/flags/rounded/flag_of_canada.png"
    },
    {
    "symbol" : "€",
    "name" : "Euro",
    "symbol_native" : "€",
    "decimal_digits" : 2,
    "rounding" : 0,
    "country_code": "eu",
    "code" : "EUR",
    "name_plural" : "euros",
    "img": "../../../../../assets/img/flags/rounded/european_union.png"
    },
    {
    "symbol" : "£",
    "name" : "British Pound Sterling",
    "symbol_native" : "£",
    "decimal_digits" : 2,
    "country_code": "gb",
    "rounding" : 0,
    "code" : "GBP",
    "name_plural" : "British pounds sterling",
    "img": "../../../../../assets/img/flags/rounded/flag_of_united_kingdom.png"
    },
    {
    "symbol" : "AED",
    "name" : "United Arab Emirates Dirham",
    "symbol_native" : "د.إ.‏",
    "decimal_digits" : 2,
    "rounding" : 0,
    "code" : "AED",
    "country_code": "ae",
    "name_plural" : "UAE dirhams",
    "img": "../../../../../assets/img/flags/rounded/United Arab Emirates.png"
    },
    {
    "symbol" : "AR$",
    "name" : "Argentine Peso",
    "symbol_native" : "$",
    "decimal_digits" : 2,
    "country_code": "ar",
    "rounding" : 0,
    "code" : "ARS",
    "name_plural" : "Argentine pesos",
    "img": "../../../../../assets/img/flags/rounded/Argentina.png"
    },
    {
    "symbol" : "AU$",
    "name" : "Australian Dollar",
    "symbol_native" : "$",
    "country_code": "au",
    "decimal_digits" : 2,
    "rounding" : 0,
    "code" : "AUD",
    "name_plural" : "Australian dollars",
    "img": "../../../../../assets/img/flags/rounded/australia.png"
    },
    {
    "symbol" : "Tk",
    "name" : "Bangladeshi Taka",
    "symbol_native" : "৳",
    "decimal_digits" : 2,
    "country_code": "bd",
    "rounding" : 0,
    "code" : "BDT",
    "name_plural" : "Bangladeshi takas",
    "img": "../../../../../assets/img/flags/rounded/Bangladesh.png"
    },
    {
    "symbol" : "BGN",
    "name" : "Bulgarian Lev",
    "symbol_native" : "лв.",
    "country_code": "bg",
    "decimal_digits" : 2,
    "rounding" : 0,
    "code" : "BGN",
    "name_plural" : "Bulgarian leva",
    "img": "../../../../../assets/img/flags/rounded/BULGARIA.png"
    },
    {
    "symbol" : "R$",
    "name" : "Brazilian Real",
    "symbol_native" : "R$",
    "decimal_digits" : 2,
    "rounding" : 0,
    "country_code": "br",
    "code" : "BRL",
    "name_plural" : "Brazilian reals",
    "img": "../../../../../assets/img/flags/rounded/Brazil.png"
    },
    {
    "symbol" : "CHF",
    "name" : "Swiss Franc",
    "symbol_native" : "CHF",
    "decimal_digits" : 2,
    "rounding" : 0.05,
    "country_code": "ch",
    "code" : "CHF",
    "name_plural" : "Swiss francs",
    "img": "../../../../../assets/img/flags/rounded/switzerland.png"
    },
    {
    "symbol" : "CL$",
    "name" : "Chilean Peso",
    "symbol_native" : "$",
    "decimal_digits" : 0,
    "country_code": "cl",
    "rounding" : 0,
    "code" : "CLP",
    "name_plural" : "Chilean pesos",
    "img": "../../../../../assets/img/flags/rounded/Chile.png"
    },
    {
    "symbol" : "CN¥",
    "name" : "Chinese Yuan",
    "symbol_native" : "CN¥",
    "decimal_digits" : 2,
    "rounding" : 0,
    "country_code": "cn",
    "code" : "CNY",
    "name_plural" : "Chinese yuan",
    "img": "../../../../../assets/img/flags/rounded/flag_of_china.png"
    },
    {
    "symbol" : "CO$",
    "name" : "Colombian Peso",
    "symbol_native" : "$",
    "decimal_digits" : 0,
    "rounding" : 0,
    "country_code": "co",
    "code" : "COP",
    "name_plural" : "Colombian pesos",
    "img": "../../../../../assets/img/flags/rounded/Colombia.png"
    },
    {
    "symbol" : "Kč",
    "name" : "Czech Republic Koruna",
    "symbol_native" : "Kč",
    "decimal_digits" : 2,
    "rounding" : 0,
    "country_code": "cz",
    "code" : "CZK",
    "name_plural" : "Czech Republic korunas",
    "img": "../../../../../assets/img/flags/rounded/Czech Republic.png"
    },
    {
    "symbol" : "Dkr",
    "name" : "Danish Krone",
    "symbol_native" : "kr",
    "decimal_digits" : 2,
    "rounding" : 0,
    "country_code": "dk",
    "code" : "DKK",
    "name_plural" : "Danish kroner",
    "img": "../../../../../assets/img/flags/rounded/Denmark.png"
    },
    {
    "symbol" : "EGP",
    "name" : "Egyptian Pound",
    "symbol_native" : "ج.م.‏",
    "decimal_digits" : 2,
    "rounding" : 0,
    "code" : "EGP",
    "country_code": "eg",
    "name_plural" : "Egyptian pounds",
    "img": "../../../../../assets/img/flags/rounded/Egypt.png"
    },
    {
    "symbol" : "GEL",
    "name" : "Georgian Lari",
    "symbol_native" : "GEL",
    "decimal_digits" : 2,
    "rounding" : 0,
    "code" : "GEL",
    "country_code": "ge",
    "name_plural" : "Georgian laris",
    "img": "../../../../../assets/img/flags/rounded/Georgia.png"
    },
    {
    "symbol" : "HK$",
    "name" : "Hong Kong Dollar",
    "symbol_native" : "$",
    "decimal_digits" : 2,
    "rounding" : 0,
    "code" : "HKD",
    "country_code": "hk",
    "name_plural" : "Hong Kong dollars",
    "img": "../../../../../assets/img/flags/rounded/flag_of_hong_kong.png"
    },
    {
    "symbol" : "kn",
    "name" : "Croatian Kuna",
    "symbol_native" : "kn",
    "decimal_digits" : 2,
    "rounding" : 0,
    "code" : "HRK",
    "name_plural" : "Croatian kunas",
    "country_code": "hr",
    "img": "../../../../../assets/img/flags/rounded/Croatia.png"
    },
    {
    "symbol" : "Ft",
    "name" : "Hungarian Forint",
    "symbol_native" : "Ft",
    "decimal_digits" : 0,
    "rounding" : 0,
    "code" : "HUF",
    "country_code": "hu",
    "name_plural" : "Hungarian forints",
    "img": "../../../../../assets/img/flags/rounded/Hungary.png"
    },
    {
    "symbol" : "Rp",
    "name" : "Indonesian Rupiah",
    "symbol_native" : "Rp",
    "decimal_digits" : 0,
    "rounding" : 0,
    "code" : "IDR",
    "country_code": "id",
    "name_plural" : "Indonesian rupiahs",
    "img": "../../../../../assets/img/flags/rounded/indonesia.png"
    },
    {
    "symbol" : "₪",
    "name" : "Israeli New Sheqel",
    "symbol_native" : "₪",
    "decimal_digits" : 2,
    "rounding" : 0,
    "code" : "ILS",
    "country_code": "il",
    "name_plural" : "Israeli new sheqels",
    "img": "../../../../../assets/img/flags/rounded/Israel.png"
    },
    {
    "symbol" : "Rs",
    "name" : "Indian Rupee",
    "symbol_native" : "টকা",
    "decimal_digits" : 2,
    "rounding" : 0,
    "code" : "INR",
    "country_code": "in",
    "name_plural" : "Indian rupees",
    "img": "../../../../../assets/img/flags/rounded/India.png"
    },
    {
    "symbol" : "¥",
    "name" : "Japanese Yen",
    "symbol_native" : "￥",
    "decimal_digits" : 0,
    "rounding" : 0,
    "code" : "JPY",
    "name_plural" : "Japanese yen",
    "img": "../../../../../assets/img/flags/rounded/flag_of_japan.png",
    "country_code": "jp"
    },
    {
    "symbol" : "₩",
    "name" : "South Korean Won",
    "symbol_native" : "₩",
    "decimal_digits" : 0,
    "rounding" : 0,
    "country_code": "kr",
    "code" : "KRW",
    "name_plural" : "South Korean won",
    "img": "../../../../../assets/img/flags/rounded/flag_of_south_korea.png"   
    },
    {
    "symbol" : "SLRs",
    "name" : "Sri Lankan Rupee",
    "symbol_native" : "SL Re",
    "decimal_digits" : 2,
    "rounding" : 0,
    "code" : "LKR",
    "country_code": "lk",
    "name_plural" : "Sri Lankan rupees",
    "img": "../../../../../assets/img/flags/rounded/Sri Lanka.png"
    },
    {
    "symbol" : "MAD",
    "name" : "Moroccan Dirham",
    "symbol_native" : "د.م.‏",
    "decimal_digits" : 2,
    "rounding" : 0,
    "country_code": "us",
    "code" : "ma",
    "name_plural" : "Moroccan dirhams",
    "img": "../../../../../assets/img/flags/rounded/Morocco.png"
    },
    {
    "symbol" : "MX$",
    "name" : "Mexican Peso",
    "symbol_native" : "$",
    "decimal_digits" : 2,
    "rounding" : 0,
    "code" : "MXN",
    "country_code": "mx",
    "name_plural" : "Mexican pesos",
    "img": "../../../../../assets/img/flags/rounded/Mexico.png"
    },
    {
    "symbol" : "RM",
    "name" : "Malaysian Ringgit",
    "symbol_native" : "RM",
    "decimal_digits" : 2,
    "rounding" : 0,
    "country_code": "my",
    "code" : "MYR",
    "name_plural" : "Malaysian ringgits",
    "img": "../../../../../assets/img/flags/rounded/Malaysia.png"
    },
    {
    "symbol" : "₦",
    "name" : "Nigerian Naira",
    "symbol_native" : "₦",
    "decimal_digits" : 2,
    "rounding" : 0,
    "code" : "NGN",
    "country_code": "ng",
    "name_plural" : "Nigerian nairas",
    "img": "../../../../../assets/img/flags/rounded/Nigeria.png"
    },
    {
    "symbol" : "Nkr",
    "name" : "Norwegian Krone",
    "symbol_native" : "kr",
    "decimal_digits" : 2,
    "rounding" : 0,
    "country_code": "no",
    "code" : "NOK",
    "name_plural" : "Norwegian kroner",
    "img": "../../../../../assets/img/flags/rounded/Norway.png"
    },
    {
    "symbol" : "NPRs",
    "name" : "Nepalese Rupee",
    "symbol_native" : "नेरू",
    "decimal_digits" : 2,
    "rounding" : 0,
    "country_code": "np",
    "code" : "NPR",
    "name_plural" : "Nepalese rupees",
    "img": "../../../../../assets/img/flags/rounded/nepal.png"
    },
    {
    "symbol" : "NZ$",
    "name" : "New Zealand Dollar",
    "symbol_native" : "$",
    "decimal_digits" : 2,
    "rounding" : 0,
    "country_code": "nz",
    "code" : "NZD",
    "name_plural" : "New Zealand dollars",
    "img": "../../../../../assets/img/flags/rounded/New Zealand.png"
    },
    {
    "symbol" : "OMR",
    "name" : "Omani Rial",
    "symbol_native" : "ر.ع.‏",
    "decimal_digits" : 3,
    "rounding" : 0,
    "country_code": "om",
    "code" : "OMR",
    "name_plural" : "Omani rials",
    "img": "../../../../../assets/img/flags/rounded/Oman.png"
    },
    {
    "symbol" : "S/.",
    "name" : "Peruvian Nuevo Sol",
    "symbol_native" : "S/.",
    "decimal_digits" : 2,
    "rounding" : 0,
    "code" : "PEN",
    "country_code": "pe",
    "name_plural" : "Peruvian nuevos soles",
    "img": "../../../../../assets/img/flags/rounded/Peru.png"
    },
    {
    "symbol" : "₱",
    "name" : "Philippine Peso",
    "symbol_native" : "₱",
    "decimal_digits" : 2,
    "rounding" : 0,
    "code" : "PHP",
    "country_code": "ph",
    "name_plural" : "Philippine pesos",
    "img": "../../../../../assets/img/flags/rounded/Philippines.png"
    },
    {
    "symbol" : "PKRs",
    "name" : "Pakistani Rupee",
    "symbol_native" : "₨",
    "decimal_digits" : 0,
    "rounding" : 0,
    "country_code": "pk",
    "code" : "PKR",
    "name_plural" : "Pakistani rupees",
    "img": "../../../../../assets/img/flags/rounded/PAKISTAN.png"
    },
    {
    "symbol" : "zł",
    "name" : "Polish Zloty",
    "symbol_native" : "zł",
    "decimal_digits" : 2,
    "rounding" : 0,
    "code" : "PLN",
    "country_code": "pl",
    "name_plural" : "Polish zlotys",
    "img": "../../../../../assets/img/flags/rounded/POLA0001.png"
    },
    {
    "symbol" : "RON",
    "name" : "Romanian Leu",
    "symbol_native" : "RON",
    "decimal_digits" : 2,
    "rounding" : 0,
    "code" : "RON",
    "country_code": "ro",
    "name_plural" : "Romanian lei",
    "img": "../../../../../assets/img/flags/rounded/Romania.png"
    },
    {
    "symbol" : "RUB",
    "name" : "Russian Ruble",
    "symbol_native" : "руб.",
    "decimal_digits" : 2,
    "rounding" : 0,
    "code" : "RUB",
    "country_code": "ru",
    "name_plural" : "Russian rubles",
    "img": "../../../../../assets/img/flags/rounded/Russian.png"
    },
    {
    "symbol" : "Skr",
    "name" : "Swedish Krona",
    "symbol_native" : "kr",
    "decimal_digits" : 2,
    "country_code": "se",
    "rounding" : 0,
    "code" : "SEK",
    "name_plural" : "Swedish kronor",
    "img": "../../../../../assets/img/flags/rounded/Sweden.png"
    },
    {
    "symbol" : "S$",
    "name" : "Singapore Dollar",
    "symbol_native" : "$",
    "decimal_digits" : 2,
    "rounding" : 0,
    "country_code": "sg",
    "code" : "SGD",
    "name_plural" : "Singapore dollars",
    "img": "../../../../../assets/img/flags/rounded/Singapore.png"
    },
    {
    "symbol" : "฿",
    "name" : "Thai Baht",
    "symbol_native" : "฿",
    "country_code": "th",
    "decimal_digits" : 2,
    "rounding" : 0,
    "code" : "THB",
    "name_plural" : "Thai baht",
    "img": "../../../../../assets/img/flags/rounded/Thailand.png"
    },
    {
    "symbol" : "TL",
    "name" : "Turkish Lira",
    "country_code": "tr",
    "symbol_native" : "TL",
    "decimal_digits" : 2,
    "rounding" : 0,
    "code" : "TRY",
    "name_plural" : "Turkish Lira",
    "img": "../../../../../assets/img/flags/rounded/Turkey.png"
    },
    {
    "symbol" : "₴",
    "name" : "Ukrainian Hryvnia",
    "symbol_native" : "₴",
    "country_code": "ua",
    "decimal_digits" : 2,
    "rounding" : 0,
    "code" : "UAH",
    "name_plural" : "Ukrainian hryvnias",
    "img": "../../../../../assets/img/flags/rounded/Ukraine.png"
    },
    {
    "symbol" : "R",
    "name" : "South African Rand",
    "symbol_native" : "R",
    "country_code": "za",
    "decimal_digits" : 2,
    "rounding" : 0,
    "code" : "ZAR",
    "name_plural" : "South African rand",
    "img": "../../../../../assets/img/flags/rounded/South Africa.png"
    },
    {
    "symbol" : "ZK",
    "name" : "Zambian Kwacha",
    "symbol_native" : "ZK",
    "decimal_digits" : 0,
    "country_code": "zm",
    "rounding" : 0,
    "code" : "ZMK",
    "name_plural" : "Zambian kwachas",
    "img": "../../../../../assets/img/flags/rounded/Zambia.png"
    } 
];

export {currencyCodeList};