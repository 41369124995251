import React from 'react';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import flag_fr from "../../../assets/img/flags/fr.svg";
import flag_us from "../../../assets/img/flags/us.svg";

require('./Menu.scss');



class Menuapp extends React.Component {

  changePage = (path) => {
    this.props.history.push(path);
  }

  render() {
    let menu;
    if (this.props.auth.isAuthenticated) {
      menu = <div className="menu">
        <div className="my-menu"><FormattedMessage id="menu.menu" defaultMessage="Menu" /></div>
        <div className="message"><FormattedMessage id="menu.hello" defaultMessage="Hello" /> {this.props.auth.user.firstname}</div>
        <div className="menu_button">
          <div className="menu_button-home">
            <Button color="primary" className="button" onClick={() => this.changePage('/')} style={{ fontFamily: 'Nunito', backgroundColor: 'transparent' }} >
              <i className="fas fa-home" style={{ marginRight: '12px', width: '16px' }}></i><FormattedMessage id="menu.home" defaultMessage="Home" />
            </Button>
          </div>
          <div className="menu_button-services">
            <Button color="primary" className="button" onClick={() => this.changePage("/services")} style={{ fontFamily: 'Nunito', backgroundColor: 'transparent' }} >
              <i className="fas fa-concierge-bell" style={{ marginRight: '12px', width: '16px' }}></i><FormattedMessage id="menu.service" defaultMessage="Services" />
            </Button>
          </div>
          <div className="menu_button-services">
            <Button color="primary" className="button" onClick={() => this.changePage("/blog")} style={{ fontFamily: 'Nunito', backgroundColor: 'transparent' }} >
              <i className="fas fa-newspaper"style={{ marginRight: '12px', width: '16px' }}></i><FormattedMessage id="menu.blog" defaultMessage="Blog" />
            </Button>
          </div>
          <div className="menu_button-profile">
            <Button color="primary" className="button" onClick={() => this.changePage("/profile")} style={{ fontFamily: 'Nunito', backgroundColor: 'transparent' }} >
              <i className="fas fa-user" style={{ marginRight: '12px', width: '16px' }}></i><FormattedMessage id="menu.account" defaultMessage="Account" />
            </Button>
          </div>
          <div className="menu_button-wishlist">
          <Button color="primary" className="button" onClick={() => this.changePage("/wishlist")} style={{ fontFamily: 'Nunito', backgroundColor: 'transparent' }} >
              <i className="fas fa-vote-yea" style={{ marginRight: '12px', width: '16px' }}></i><FormattedMessage id="menu.wishlist" defaultMessage="Wishlist" />
            </Button>
          </div>
          <div className="menu_button-logout">
            <Button color="primary" className="button" onClick={this.props.onOpenModalLogout()} style={{ fontFamily: 'Nunito', backgroundColor: 'transparent' }} >
              <i className="fas fa-sign-out-alt" style={{ marginRight: '12px', width: '16px' }}></i><FormattedMessage id="menu.logout" defaultMessage="Logout" />
            </Button>
          </div>
        </div>
      </div>;
    } else {
      menu = <div className="menu">
        <div className="my-menu"><FormattedMessage id="menu.menu" defaultMessage="Menu" /></div>
        <div className="menu_button">
          <div className="menu_button-home">
            <Button color="primary" className="button" onClick={() => this.changePage('/')} style={{ fontFamily: 'Nunito', backgroundColor: 'transparent' }} >
              <i className="fas fa-home" style={{ marginRight: '12px', width: '16px' }}></i><FormattedMessage id="menu.home" defaultMessage="Home" />
            </Button>
          </div>
          <div className="menu_button-signup">
            <Button color="primary" className="button" onClick={() => this.changePage("/signup")} style={{ fontFamily: 'Nunito', backgroundColor: 'transparent' }} >
              <i className="fab fa-wpforms" style={{ marginRight: '12px', width: '16px' }}></i><FormattedMessage id="menu.signup" defaultMessage="Sign up" />
            </Button>
          </div>
          <div className="menu_button-login">
            <Button color="primary" className="button" onClick={() => this.changePage("/login")} style={{ fontFamily: 'Nunito', backgroundColor: 'transparent' }} >
              <i className="fas fa-sign-in-alt" style={{ marginRight: '12px', width: '16px' }}></i><FormattedMessage id="menu.login" defaultMessage="Login" />
            </Button>
          </div>
          <div className="menu_button-services">
            <Button color="primary" className="button" onClick={() => this.changePage("/services")} style={{ fontFamily: 'Nunito', backgroundColor: 'transparent' }} >
              <i className="fas fa-concierge-bell" style={{ marginRight: '12px', width: '16px' }}></i><FormattedMessage id="menu.service" defaultMessage="Services" />
            </Button>
          </div>
          <div className="menu_button-services">
            <Button color="primary" className="button" onClick={() => this.changePage("/blog")} style={{ fontFamily: 'Nunito', backgroundColor: 'transparent' }} >
              <i className="fas fa-newspaper"style={{ marginRight: '12px', width: '16px' }}></i><FormattedMessage id="menu.blog" defaultMessage="Blog" />
            </Button>
          </div>
        </div>
      </div>;
    }
    return (
      <div>
        {menu}
        <div className="language">
          <div className="language-title">Language</div>
            <div className="language_flag">
              <img className="language_flag-img" src={flag_fr} onClick={() => this.props.langChange('fr-FR')} alt='' style={{ marginRight:'10%'  }} ></img>
              <img className="language_flag-img" src={flag_us} onClick={() => this.props.langChange('en-US')} alt='' style={{ cursor: 'pointer' }} ></img>
            </div>
        </div>
      </div>
    );
  }
}

Menuapp.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {})(Menuapp);
