import React, { Component } from 'react'

require('./Wishlist.scss');

class Wishlist extends Component {
    render() {
        return (
            <div className="Wishlist">
                <iframe className="wish_iframe"
                id="inlineFrameExample"
                    title="Inline Frame Example"
                    src="https://issoyo.nolt.io/">
                </iframe>
            </div>
        )
    }
}

export default Wishlist;