import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import Issoyo_logo from '../../../../assets/img/fav_icon32@2x.png';
import nowtv_logo from '../../../../assets/img/providers/nowtv_logo.png';
require('./Television.scss')

class Television extends Component {
    render() {
        return (
            <div className="Television">
                <div className="bank_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                <div className="title">
                    <FormattedMessage id="service.tv.title" defaultMessage="Need to watch the last TV show?" />
                </div>
                <div>
                    <img style={{ maxWidth: "250px" }} src={nowtv_logo} alt='' />
                </div>
                {window.matchMedia("(max-width: 500px)").matches ?
                    <div className="television_info">
                        <div className="row television_info-wrapper">
                            <div className="col-6 television_info-wrapper-img">
                                <img src="https://web.static.nowtv.com/images/NOWTV_2019/Brand/P1/No_Contract.svg" alt=''></img>
                                <div className="text"><FormattedMessage id="service.tv.nocontract" defaultMessage="The best of pay TV without the strings." /></div>
                            </div>
                            <div className="col-6 television_info-wrapper-img">
                                <img src="https://web.static.nowtv.com/images/NOWTV_2019/Brand/P1/SuperSimple.svg" alt=''></img>
                                <div className="text"> <FormattedMessage id="service.tv.easy" defaultMessage="Sign up and start streaming instantly." /></div>
                            </div>
                        </div>
                        <div className="row television_info-wrapper">
                            <div className="col-6 television_info-wrapper-img">
                                <img src="https://web.static.nowtv.com/images/NOWTV_2019/Brand/P1/Devices.svg" alt=''></img>
                                <div className="text"><FormattedMessage id="service.tv.devices" defaultMessage="Compatible on over 60 devices, stream on 2 at once." /></div>
                            </div>
                            <div className="col-6 television_info-wrapper-img">
                                <img src="https://web.static.nowtv.com/images/NOWTV_2019/Brand/P1/Greatest_content.svg" alt=''></img>
                                <div className="text"><FormattedMessage id="service.tv.last" defaultMessage="Mix and match the TV Passes that suit you." /></div>
                            </div>
                        </div>
                    </div> :
                    <div className="television_info">
                        <div className="row television_info-wrapper">
                            <div className="col-4 television_info-wrapper-img">
                                <img src="https://web.static.nowtv.com/images/NOWTV_2019/Brand/P1/No_Contract.svg" alt=''></img>
                                <div className="text"><FormattedMessage id="service.tv.nocontract" defaultMessage="The best of pay TV without the strings." /></div>
                            </div>
                            <div className="col-4 television_info-wrapper-img">
                                <img src="https://web.static.nowtv.com/images/NOWTV_2019/Brand/P1/SuperSimple.svg" alt=''></img>
                                <div className="text"> <FormattedMessage id="service.tv.easy" defaultMessage="Sign up and start streaming instantly." /></div>
                            </div>
                        <div className="col-4 television_info-wrapper-img">
                            <img src="https://web.static.nowtv.com/images/NOWTV_2019/Brand/P1/Devices.svg" alt=''></img>
                            <div className="text"><FormattedMessage id="service.tv.devices" defaultMessage="Compatible on over 60 devices, stream on 2 at once." /></div>
                        </div>
                        <div className="col-4 television_info-wrapper-img">
                            <img src="https://web.static.nowtv.com/images/NOWTV_2019/Brand/P1/Greatest_content.svg" alt=''></img>
                            <div className="text"><FormattedMessage id="service.tv.last" defaultMessage="Mix and match the TV Passes that suit you." /></div>
                        </div>
                        </div>
                    </div>
                }
                <div className="television_offer">
                    <div className="television_offer-wrapper">
                        <img style={{ width: "275px" }} src="https://web.static.nowtv.com/images/NOWTV_2019/Brand/P1/Cinema.svg" alt=''></img>
                        <div className="text"><FormattedMessage id="service.tv.cinema" defaultMessage="Over 1000 movies on demand." /></div>
                        <div className="text little"><FormattedMessage id="service.tv.ciema.prix" defaultMessage="7 day free trial, then £11.99 a month unless cancelled." /></div>
                    </div>
                    <div className="television_offer-wrapper">
                        <img style={{ width: "275px" }} src="https://web.static.nowtv.com/images/NOWTV_2019/Brand/P1/Ents-Ticket.svg" alt=''></img>
                        <div className="text"><FormattedMessage id="service.tv.entertainement" defaultMessage="Over 300 binge-worthy Box Sets" /></div>
                        <div className="text little"><FormattedMessage id="service.tv.entertainement.prix" defaultMessage="7 day free trial, then £11.99 a month unless cancelled." /></div>
                    </div>
                    <div className="television_offer-wrapper">
                        <img style={{ width: "275px" }} src="https://web.static.nowtv.com/images/NOWTV_2019/Brand/P1/Kids2.svg" alt=''></img>
                        <div className="text"><FormattedMessage id="service.tv.kids" defaultMessage="1000's of awesome Kids TV shows" /></div>
                        <div className="text little"><FormattedMessage id="service.tv.kids.prix" defaultMessage="7 day free trial then £3.99 a month, unless cancelled." /></div>

                    </div>
                    <div className="television_offer-wrapper">
                        <img style={{ width: "275px" }} src="https://web.static.nowtv.com/images/NOWTV_2017/02_TV_Passes/06_hayu/home/hayu_pass2.svg" alt=''></img>
                        <div className="text"><FormattedMessage id="service.tv.hayu" defaultMessage="Thousands of reality shows fresh from the US" /></div>
                        <div className="text little"><FormattedMessage id="service.tv.hayu.prix" defaultMessage="7 day free trial then £3.99 a month, unless cancelled." /></div>
                    </div>
                    <div className="television_offer-wrapper">
                        <img style={{ width: "275px" }} src="https://web.static.nowtv.com/images/NOWTV_2019/Brand/P1/Sports.svg" alt=''></img>
                        <div className="text"><FormattedMessage id="service.tv.sports" defaultMessage="Watch the biggest sporting moments on Sky Sports from just £5.99." /></div>
                    </div>
                </div>
                <div className="insurances_buy" style={{ textAlign: 'center' }}>
                    <Button variant="contained" color="primary" className="insurances_buy-button" onClick={() => window.open("https://www.awin1.com/cread.php?awinmid=11006&awinaffid=525947&clickref=&p=%5B%5Bhttps%3A%2F%2Fwww.nowtv.com%2Ftv-passes%5D%5D", "_blank")}>
                        <img src={Issoyo_logo} alt='' style={{ width: '11%', marginRight: '5%' }}></img>
                        <FormattedMessage id="service.television.get" defaultMessage="get" />
                    </Button>
                </div>
            </div>
        )
    }
}

export default Television