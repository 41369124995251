import React, { Component } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import axios from 'axios';
import PropTypes from "prop-types";
import { connect } from "react-redux";

class EditBlog extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            text: "",
            title: "",
            country: '',
            service: '',
            langue: 'FR',
        }
    }

    componentDidMount() {
        if (!this.props.auth.isAuthenticated || this.props.auth.user.userRole !== 'Admin') {
            this.props.history.push("/login");
        }
        axios.get('/api/service/editblog/' + this.props.match.params.id)
            .then(response => {
                console.log(response)
                this.setState({
                    text: response.data.description,
                    title: response.data.title,
                    country: response.data.country,
                    service: response.data.typeService,
                    langue: response.data.langueService,
                });
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    onSubmit(e) {
        e.preventDefault();
        const obj = {
            country: this.state.country,
            title: this.state.title,
            text: this.state.text,
            service: this.state.service,
            langue: this.state.langue,
        };
        axios.post('/api/service/updateblog/' + this.props.match.params.id, obj).then(res => console.log(res.data));
        console.log(`The values are ${this.state.title}, ${this.state.text}`)
        window.open('/admin-issoyo/blog', "_self");
    }

    render() {
        return (
            <div style={{ margin: 'auto', marginTop: '100px', maxWidth: '800px' }}>
                <h2>Edit blog</h2>
                <div>
                    <form onSubmit={this.onSubmit}>
                        <div className="form-group">
                            <label>Titre:  </label>
                            <input
                                type="text"
                                name="title"
                                className="form-control"
                                value={this.state.title}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div style={{border: '1px solid lightgray'}}>
                        <CKEditor
                        editor={ DecoupledEditor }
                        data={this.state.text}
                        onInit={ editor => {
                            // Insert the toolbar before the editable area.
                            editor.ui.getEditableElement().parentElement.insertBefore(
                                editor.ui.view.toolbar.element,
                                editor.ui.getEditableElement()
                            );
                        } }
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            this.setState({ text: data })
                        }}
                    ></CKEditor>
                    </div>
                        <div className="form-group">
                            <label>Country ISO (Ex: FR, GB, DE, KR, ... ) </label>
                            <input
                                type="text"
                                name="country"
                                className="form-control"
                                value={this.state.country}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Services (all, issoyo, bank, telecom, utilities, housing, insurances, internet, transport, utilities)  </label>
                            <input
                                type="text"
                                name="service"
                                className="form-control"
                                value={this.state.service}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Langues ISO (ex: fr, en, kr, ...)  </label>
                            <input
                                type="text"
                                name="langue"
                                className="form-control"
                                value={this.state.langue}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-group" style={{ marginTop: '20px' }}>
                            <input type="submit" value="Publier" className="btn btn-primary" />
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

EditBlog.propTypes = {
    auth: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(
    mapStateToProps,
    { }
)(EditBlog);