import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AppartcityModal from './Appartcity/AppartcityModal.jsx';
import ExpatimmoModal from './Expatimmo/ExpatimmoModal.jsx';
import Issoyoservicemodal from '../Issoyoservice/Issoyoservicemodal.jsx';



class HouseModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: [],
        }
        this.handleClickAccount = this.handleClickAccount.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    onClose(){
        this.props.onCloseModal();
    }

    handleClickAccount(link){
        this.props.handleClickAccount(link);
    }

    componentWillMount(){
        if (this.props.name === "APPARTCITY") {
            this.setState({modal: <AppartcityModal onClose={this.onClose} index={this.props.index} language={this.props.language}/>});
        } else if (this.props.name === "Expat.immo") {
            this.setState({modal: <ExpatimmoModal onClose={this.onClose} index={this.props.index} language={this.props.language}/>});
        }
        else if (this.props.name === "Issoyoimmo") {
            this.setState({modal: <Issoyoservicemodal serviceName='house' onClose={this.onClose} index={this.props.index} language={this.props.language}/>});
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.modal}
            </React.Fragment>
        )
    }
}

HouseModal.propTypes = {
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    service: state.services,
});

export default connect(
    mapStateToProps, {}
)(HouseModal);