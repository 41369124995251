import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
import Modal from 'react-responsive-modal';
import { withAlert } from 'react-alert'
import { removeAddress } from '../../../actions/authActions';

require("./Adresses.scss");
var noScroll = require('no-scroll');

const modalstyles = {
    modal: {
        'max-width': '500px',
        'width': '100%',
        'color': '#3f2751',
        error: {
            'color': '#cc0033',
            'display': 'flex',
            'font-size': '12px'
        }
    },
};

class Adresses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            selectedAdr: '',
            modalName: '',
        };
        this.handleDelete = this.handleDelete.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    onOpenModal(addresse) {
        noScroll.on();
        this.setState({ open: true, selectedAdr: addresse });
    };

    onCloseModal() {
        noScroll.off();
        this.setState({ open: false });
    };

    handleDelete() {
        const request = {
            _id: this.props.auth.user._id,
            adresses: this.props.auth.user.addresses,
            selectedAdr: this.state.selectedAdr,
        }
        this.props.removeAddress(request);
        this.props.alert.show('Adresse supprimée', { type: 'success' });
        this.onCloseModal();
    }

    render() {

        const { open } = this.state;
        const address = this.props.auth.user.addresses.map((d, key) =>
                <div className="card" key={key}>
                <div onClick={() => this.onOpenModal(d.name)} style={{cursor: 'pointer'}}><i className="fas fa-times"></i></div>
                    <div className="card-body">
                        <h5 className="card-title">{d.name}</h5>
                        <div className="row">
                            <div className="col">
                                <p className="card-text"><i className="fas fa-map-marker-alt" style={{width: '20px'}}></i> {d.street}</p>
                                <p className="card-text"><i className="fas fa-city"></i> {d.zip}, {d.city}</p>
                                <p className="card-text"><i className="far fa-flag" style={{width: '20px'}}></i> {d.country.label}</p>
                            </div>
                        </div>
                    </div>
            </div>)
        return (
            <React.Fragment>
                <div className="Adresses">
                <div className="adresses_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                    <div className="col-12 title">
                        <div>
                            <FormattedMessage id="address.title" defaultMessage="My address" />
                        </div>
                    </div>
                    <div className="adresses_card">
                    {address}
                    </div>
                    <div className='row' style={{ justifyContent: 'space-between' }}>
                        <div className="col send">
                            <Button variant="contained" color="primary" className="button" onClick={() => this.props.history.push('/addadress')} style={{ fontFamily: 'Nunito' }} >
                                <FormattedMessage id="address.button.add" defaultMessage="+ Add" />
                            </Button>
                        </div>
                    </div>
                </div >
                <Modal open={open} onClose={this.onCloseModal} center styles={modalstyles}>
                    <p className='message-delete'><FormattedMessage id="address.modal.delete" defaultMessage='Are you sure you want to delete this address ?' /></p>
                    <Button color="primary" className="button" onClick={this.handleDelete} style={{ fontFamily: 'Nunito', backgroundColor: 'transparent', color: 'rgba(68, 76, 165, 0.85)' }} >
                        <FormattedMessage id="address.modal.delete.yes" defaultMessage="Yes" />
                    </Button>
                    <Button color="primary" className="button" onClick={this.onCloseModal} style={{ fontFamily: 'Nunito', backgroundColor: 'transparent', color: 'rgba(68, 76, 165, 0.85)' }} >
                        <FormattedMessage id="address.modal.delete.no" defaultMessage="No" />
                    </Button>
                </Modal>
            </React.Fragment >
        )
    }
}

Adresses.propTypes = {
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(
    mapStateToProps, { removeAddress }
)(withAlert(Adresses));
