import React, { Component } from 'react'
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';
import { connect } from "react-redux";
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { regionList } from './region.js';

require('./Ticketing.scss');

const styles = {
    root: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    selectMenu: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    input: {
        borderColor: '#3e45948a !important',
    },
};

class TicketingFrance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ticketList: [],
            ticketListCity: [],
            genderList: [],
            searchCity: 'search_city',
            searchGender: 'search_gender',
            searchDate: 'search_date',
            cardName: 'card_issoyo',
            city: '',
            gender: '',
        }
        this.handleChangeGender = this.handleChangeGender.bind(this);
        this.handleChangeCity = this.handleChangeCity.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(link) {
        if (!this.props.auth.isAuthenticated) {
            this.props.history.push("/login");
        } else {
            window.open(link, "_blank");
        }
    }

    handleChangeCity(e) {
        const region = regionList;
        const regionSelected = e.target.value;
        var listCity = this.props.service.ticketList.filter(function (data) {
            if (regionSelected === 'nord_ouest') {
                if (region[0].nord_ouest.indexOf(data.ville_spectacle.toLowerCase()) > -1) {
                    return true;
                }
            } else if (regionSelected === 'nord_est') {
                if (region[0].nord_est.indexOf(data.ville_spectacle.toLowerCase()) > -1) {
                    return true;
                }
            } else if (regionSelected === 'haut_de_france') {
                if (region[0].haut_de_france.indexOf(data.ville_spectacle.toLowerCase()) > -1) {
                    return true;
                }
            } else if (regionSelected === 'ile_de_france') {
                if (region[0].ile_de_france.indexOf(data.ville_spectacle.toLowerCase()) > -1) {
                    return true;
                }
            } else if (regionSelected === 'sud_ouest') {
                if (region[0].sud_ouest.indexOf(data.ville_spectacle.toLowerCase()) > -1) {
                    return true;
                }
            } else if (regionSelected === 'sud_est') {
                if (region[0].sud_est.indexOf(data.ville_spectacle.toLowerCase()) > -1) {
                    return true;
                }
            }
            return false;
        });
        var listGender = listCity.map(data => {
            if (data.type_spectacle === "One-Man-Show et Café-théâtre" || data.type_spectacle === "2TH" || data.type_spectacle === "Théâtre") {
                return { name: "Théâtre - Humour", value: ["One-Man-Show et Café-théâtre", "2TH", "Théâtre"] };
            } else if (data.type_spectacle === "Grands spectacles - Shows - Cabaret" || data.type_spectacle === "Spectacles Enfants - Famille" || data.type_spectacle === "5FA") {
                return { name: "Spectacle", value: ["Grands spectacles - Shows - Cabaret", "Spectacles Enfants - Famille", "5FA"] };
            } else if (data.type_spectacle === "Concerts" || data.type_spectacle === "Comédie Musicale" || data.type_spectacle === "Festivals" || data.type_spectacle === "1MC") {
                return { name: "Musique - Concerts", value: ["Concerts", "Comédie Musicale", "1MC"] };
            } else if (data.type_spectacle === "Musées - Expos" || data.type_spectacle === "6AR") {
                return { name: "Musées - Expos", value: ["Musées - Expos", "6AR"] };
            } else if (data.type_spectacle === "Loisirs - Sport" || data.type_spectacle === "4SP" || data.type_spectacle === "7TL") {
                return { name: "Loisirs - Sport", value: ["Loisirs - Sport", "4SP", "7TL"] };
            } else if (data.type_spectacle === "Opéra - Classique" || data.type_spectacle === "Danse - Ballets" || data.type_spectacle === "3DA") {
                return { name: "Classique - Danse - Ballets", value: ["Opéra - Classique", "Danse - Ballets", "3DA"] };
            } else if (data.type_spectacle === "Cirques") {
                return { name: "Cirques", value: ["Cirques"] };
            } else if (data.type_spectacle === "Parcs d'attraction" || data.type_spectacle === "71P") {
                return { name: "Parcs d'attraction", value: ["Parcs d'attraction", "71P"] };
            } else if (data.type_spectacle === "Tourisme" || data.type_spectacle === "8CI") {
                return { name: "Tourisme", value: ["Tourisme", "8CI"] };
            }
            return { name: data.type_spectacle }
        });
        var uniqGender = listGender.map(e => e['name']).map((e, i, final) => final.indexOf(e) === i && i).filter(e => listGender[e]).map(e => listGender[e]);
        uniqGender.sort(function (a, b) {
            var textA = a.name.toUpperCase();
            var textB = b.name.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.setState({
            [e.target.name]: e.target.value,
            ticketListCity: listCity,
            ticketList: [],
            genderList: uniqGender,
            searchCity: 'search_city_appear',
            searchGender: 'search_gender_appear',
            gender: 'none',
        });
    }

    handleChangeGender(e) {
        const genderSelected = e.target.value;
        if (e.target.value === 'all') {
            const listCity = this.state.ticketListCity;
            this.setState({ [e.target.name]: e.target.value, ticketList: listCity, cardName: 'card_issoyo_appear' });

        } else {
            var listFinal = this.state.ticketListCity.filter(function (data) {
                if (genderSelected.value.indexOf(data.type_spectacle) > -1) {
                    return true;
                }
                return false;
            });
            this.setState({ [e.target.name]: e.target.value, ticketList: listFinal, cardName: 'card_issoyo_appear' })
        }
    }

    render() {
        const card = this.state.ticketList.map((data, key) =>
            <div className='wrapper'>
                <div className="card_bg" key={key}>
                    {
                        data.title.length <= 40 ? <div className="card_title">{data.title}</div> : <div className="card_title">{data.title.substring(0, 40)} ...</div>
                    }
                    <div className="card_place">{data.lieu_spectacle} - {data.ville_spectacle}</div>
                    {
                        data.store === "ticketbar" || data.merchant_name === "Fnac Spectacles FR" ? <div></div> : <div className="card_date"><span><FormattedMessage id="ticketing.date.from" defaultMessage="From " /> <span style={{ color: '#8e53cb', fontWeight: '600' }}>{data.date_debut_spectacle.substring(0, data.date_debut_spectacle.indexOf(' '))}</span> <FormattedMessage id="ticketing.date.to" defaultMessage=" to " /> <span style={{ color: '#8e53cb', fontWeight: '600' }}>{data.date_fin_spectacle.substring(0, data.date_fin_spectacle.indexOf(' '))}</span></span></div>
                    }
                    <div className="card_info">
                        <div className="card_img"><img className="card-img" src={data.product_images} alt=''></img></div>
                        <div className="card_desc">
                            <div className="card_desc-text">{data.desc.substring(0, 100)} ... </div>
                        </div>
                    </div>
                    <div className="card_desc-button">
                        <Button variant="contained" color="primary" className="button" onClick={() => this.handleClick(data.url)}>
                            <FormattedMessage id="ticketing.book" defaultMessage="Booking" />
                        </Button></div>
                </div>
            </div>
        );
        const { classes } = this.props;
        if (this.props.auth.user.location !== 'FR') {
            return (<div className="Insurances">
                <div className="bank_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                <div className="text" style={{ textAlign: "center" }}> No Ticketing avaible for this country</div></div>)
        } else {
            return (
                <div>
                    <div className="search_field">
                        <div className="search">
                            <div className={`select ${this.state.searchCity}`}>
                                <FormattedMessage id="ticketing.city" defaultMessage="City">
                                    {msg => (<TextField
                                        id="outlined-select-currency"
                                        select
                                        label={msg}
                                        className="list search_city_list"
                                        value={this.state.city}
                                        name="city"
                                        onChange={this.handleChangeCity}
                                        SelectProps={{
                                            MenuProps: {
                                                className: classes.menu,
                                            },
                                        }}
                                        InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                        margin="normal"
                                        variant="outlined"
                                    >
                                        <MenuItem value='none' disabled classes={{ root: classes.root }}><FormattedMessage id="ticketing.select" defaultMessage="Select area" /></MenuItem>
                                        <MenuItem value='haut_de_france' classes={{ root: classes.root }}>Région Hauts-de-France</MenuItem>
                                        <MenuItem value='nord_ouest' classes={{ root: classes.root }}>Région Nord-ouest</MenuItem>
                                        <MenuItem value='nord_est' classes={{ root: classes.root }}>Région Nord-est</MenuItem>
                                        <MenuItem value='ile_de_france' classes={{ root: classes.root }}>Région Ile-de-France</MenuItem>
                                        <MenuItem value='sud_ouest' classes={{ root: classes.root }}>Région Sud-ouest</MenuItem>
                                        <MenuItem value='sud_est' classes={{ root: classes.root }}>Région Sud-est</MenuItem>
                                    </TextField>
                                    )}
                                </FormattedMessage>
                            </div>
                            <div className={`select ${this.state.searchGender}`}>
                                <FormattedMessage id="ticketing.type" defaultMessage="Type">
                                    {msg => (
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            label={msg}
                                            className="list search_gender_list"
                                            value={this.state.gender}
                                            name="gender"
                                            onChange={this.handleChangeGender}
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                            InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                            margin="normal"
                                            variant="outlined"
                                        >
                                            <MenuItem value='none' disabled classes={{ root: classes.root }}>Select type</MenuItem>
                                            {this.state.genderList.map((data, key) => (
                                                <MenuItem classes={{ root: classes.root }} key={key} value={data}>
                                                    {data.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                </FormattedMessage>
                            </div>
                        </div>
                    </div>
                    <div className={this.state.cardName}>
                        {card}
                    </div>
                </div>
            )
        }
    }
}
TicketingFrance.propTypes = {
    auth: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    service: state.services,
});

export default connect(
    mapStateToProps,
    {}
)(withStyles(styles)(TicketingFrance));