import React from 'react'
import { FormattedMessage } from 'react-intl';

const promoList = [
    {
        "operator": "SOSH",
        "name": "Forfait 20Go",
        "price": "9,99",
        "internet": "20 Go",
        "internet_value": 20000,
        "call": 11,
        "detail_1": <FormattedMessage id="telecom.forfait.unlimited.call.france"/>,
        "detail_2": <FormattedMessage id="telecom.forfait.unlimited.call.europe.10.go"/>,
        "detail_3": "",
        "country": "FR",
        "pics": '/static/media/sosh.png',
        date_debut: "2019-07-20",
        date_fin: "2019-07-29",
        "type": 'line',
        "trad_eng": <FormattedMessage id="telecom.line.no.commitment"/>,
        "eng": "Sans engagement",
        "sim": "5",
        "trackingLink": "https://www.awin1.com/cread.php?s=2174265&v=7302&q=338569&r=525947"
    },
    {
        "operator": "SOSH",
        "name": "Forfait 50Go",
        "price": "14,99",
        "internet": "50 Go",
        "internet_value": 50000,
        "call": 11,
        "detail_1": <FormattedMessage id="telecom.forfait.unlimited.call.france"/>,
        "detail_2": <FormattedMessage id="telecom.forfait.unlimited.call.europe.10.go"/>,
        "detail_3": <FormattedMessage id="telecom.forfait.unlimited.call.usa"/>,
        "country": "FR",
        "pics": '/static/media/sosh.png',
        "type": 'line',
        date_debut: "2019-07-20",
        date_fin: "2019-07-29",
        "trad_eng": <FormattedMessage id="telecom.line.no.commitment"/>,
        "eng": "Sans engagement",
        "sim": "5",
        "trackingLink": "https://www.awin1.com/cread.php?s=2174265&v=7302&q=338569&r=525947"
    },
    {
        "operator": "B&YOU",
        "name": "Forfait 30Go",
        "price": "9,99",
        "internet": "30 Go",
        "internet_value": 30000,
        "call": 11,
        "detail_1": <FormattedMessage id="telecom.forfait.unlimited.call.france"/>,
        "detail_2": <FormattedMessage id="telecom.forfait.unlimited.call.europe.4.go"/>,
        "detail_3": "",
        "country": "FR",
        "pics": '/static/media/bouygues.png',
        "type": 'line',
        date_debut: "2019-07-20",
        date_fin: "2019-08-05",
        "trad_eng": <FormattedMessage id="telecom.line.no.commitment"/>,
        "eng": "Sans engagement",
        "sim": "5",
        "trackingLink": "http://tracking.publicidees.com/clic.php?progid=5334&partid=57757&dpl=https%3A%2F%2Fwww.bouyguestelecom.fr%2Fforfaits-mobiles%2Fsans-engagement"
    },
    {
        "operator": "B&YOU",
        "name": "Forfait 80Go",
        "price": "16,99",
        "internet": "80 Go",
        "internet_value": 50000,
        "call": 11,
        "detail_1": <FormattedMessage id="telecom.forfait.unlimited.call.france"/>,
        "detail_2": <FormattedMessage id="telecom.forfait.unlimited.call.europe.8.go"/>,
        "detail_3": "",
        "country": "FR",
        "pics": '/static/media/bouygues.png',
        "type": 'line',
        date_debut: "2019-07-20",
        date_fin: "2019-08-05",
        "trad_eng": <FormattedMessage id="telecom.line.no.commitment"/>,
        "eng": "Sans engagement",
        "sim": "10",
        "trackingLink": "http://tracking.publicidees.com/clic.php?progid=5334&partid=57757&dpl=https%3A%2F%2Fwww.bouyguestelecom.fr%2Fbons-plans%2Fserie-speciale-b-you"
    },
    {
        "operator": "CORIOLIS TELECOM",
        "name": "Forfait 50Mo",
        "price": "1,99",
        "internet": "50 Mo",
        "internet_value": 50,
        "call": 2,
        "detail_1": <FormattedMessage id="telecom.forfait.call.2"/>,
        "detail_2": <FormattedMessage id="telecom.forfait.coriolis.sms.europe"/>,
        "detail_3": "",
        "country": "FR",
        "pics": '/static/media/coriolis.png',
        "type": 'line',
        "trad_eng": <FormattedMessage id="telecom.line.no.commitment"/>,
        "eng": "Sans engagement",
        date_debut: "2019-07-20",
        date_fin: "2019-08-20",
        "sim": "1",
        "trackingLink": "http://tracking.publicidees.com/clic.php?progid=2284&partid=57757&dpl=http%3A%2F%2Fwww.coriolis.com%2Fforfaits%2Fforfaits-sans-engagement%2F"
    },
    {
        "operator": "CORIOLIS TELECOM",
        "name": "Forfait 50Go",
        "price": "8,99",
        "internet": "50 Go",
        "internet_value": 50000,
        "call": 11,
        "detail_1": <FormattedMessage id="telecom.forfait.unlimited.call.france"/>,
        "detail_2": <FormattedMessage id="telecom.forfait.unlimited.call.europe"/>,
        "detail_3": "",
        "country": "FR",
        "pics": '/static/media/coriolis.png',
        "type": 'line',
        "trad_eng": <FormattedMessage id="telecom.line.no.commitment"/>,
        "eng": "Sans engagement",
        date_debut: "2019-07-20",
        date_fin: "2019-08-20",
        "sim": "1",
        "trackingLink": "http://tracking.publicidees.com/clic.php?progid=2284&partid=57757&dpl=http%3A%2F%2Fwww.coriolis.com%2Fforfaits%2Fforfaits-sans-engagement%2F"
    },
    {
        "operator": "RED by SFR",
        "name": "RED illimité 40Go",
        "price": "10",
        "internet": "40 Go",
        "internet_value": 40000,
        "call": 11,
        "detail_1": <FormattedMessage id="telecom.forfait.unlimited.call.france"/>,
        "detail_2": <FormattedMessage id="telecom.forfait.unlimited.call.europe.10.go"/>,
        "detail_3": "",
        "country": "FR",
        date_debut: "2019-07-20",
        date_fin: "2019-08-05",
        "pics": '/static/media/red.png',
        "type": 'line',
        "trad_eng": <FormattedMessage id="telecom.line.no.commitment"/>,
        "eng": "Sans engagement",
        "sim": "10",
        "trackingLink": "https://www.awin1.com/pclick.php?p=3726009137&a=525947&m=7310"
    },
    {
        "operator": "RED by SFR",
        "name": "RED illimité 5Go",
        "price": "5",
        "internet": "5 Go",
        "internet_value": 5000,
        "call": 11,
        "detail_1": <FormattedMessage id="telecom.forfait.unlimited.call.france"/>,
        "detail_2": <FormattedMessage id="telecom.forfait.unlimited.call.europe.4.go"/>,
        "detail_3": "",
        "country": "FR",
        date_debut: "2019-07-20",
        date_fin: "2019-08-05",
        "pics": '/static/media/red.png',
        "type": 'line',
        "trad_eng": <FormattedMessage id="telecom.line.no.commitment"/>,
        "eng": "Sans engagement",
        "sim": "10",
        "trackingLink": "https://www.awin1.com/pclick.php?p=3726009137&a=525947&m=7310"
    },
    {
        "operator": "RED by SFR",
        "name": "RED illimité 60Go",
        "price": "15",
        "internet": "60 Go",
        "internet_value": 60000,
        "call": 11,
        "detail_1": <FormattedMessage id="telecom.forfait.unlimited.call.france"/>,
        "detail_2": <FormattedMessage id="telecom.forfait.unlimited.call.europe.america.15.go"/>,
        "detail_3": ' ',
        "country": "FR",
        date_debut: "2019-07-20",
        date_fin: "2019-08-12",
        "pics": '/static/media/red.png',
        "type": 'line',
        "trad_eng": <FormattedMessage id="telecom.line.no.commitment"/>,
        "eng": "Sans engagement",
        "sim": "10",
        "trackingLink": "https://www.awin1.com/pclick.php?p=3726009137&a=525947&m=7310"
    },
]


export {promoList};