import React, { Component } from 'react';

export default class TiqetAvailability extends Component {
    constructor(props){
        super(props);
        this.state ={
            test: null
        }
    }
    componentWillReceiveProps(props){
        console.log(props);
        this.setState({
            test: null,
        }, () => {this.setState({test: <div data-tiqets-widget="availability" data-product-id={props.idEvent} data-product-ids="" data-language={this.props.language} data-currency={this.props.devise.name} data-partner="issoyo" data-cards-layout="horizontal"></div>,})})
        var addScript = document.createElement('script');
        addScript.setAttribute('src', 'https://widgets.tiqets.com/loader.js');
        document.body.appendChild(addScript);
    }
    render() {
        return (
            <div>
                {this.state.test}
            </div>
        )
    }
}
