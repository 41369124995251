const countryList = [
  {
    "value": "AR",
    "label": "Argentina"
  },
  {
    "value": "AU",
    "label": "Australia"
  },
  {
    "value": "AT",
    "label": "Austria"
  },
  {
    "value": "BH",
    "label": "Bahrain"
  },
  {
    "value": "BD",
    "label": "Bangladesh"
  },
  {
    "value": "BE",
    "label": "Belgium"
  },
  {
    "value": "BR",
    "label": "Brazil"
  },
  {
    "value": "BG",
    "label": "Bulgaria"
  },
  {
    "value": "KH",
    "label": "Cambodia"
  },
  {
    "value": "CM",
    "label": "Cameroon"
  },
  {
    "value": "CA",
    "label": "Canada"
  },
  {
    "value": "CL",
    "label": "Chile"
  },
  {
    "value": "CN",
    "label": "China"
  },
  {
    "value": "CO",
    "label": "Colombia"
  },
  {
    "value": "CR",
    "label": "Costa Rica"
  },
  {
    "value": "HR",
    "label": "Croatia"
  },
  {
    "value": "CY",
    "label": "Cyprus"
  },
  {
    "value": "CZ",
    "label": "Czech Republic"
  },
  {
    "value": "DK",
    "label": "Denmark"
  },
  {
    "value": "EC",
    "label": "Ecuador"
  },
  {
    "value": "EG",
    "label": "Egypt"
  },
  {
    "value": "EE",
    "label": "Estonia"
  },
  {
    "value": "ET",
    "label": "Ethiopia"
  },
  {
    "value": "FI",
    "label": "Finland"
  },
  {
    "value": "FR",
    "label": "France"
  },
  {
    "value": "GE",
    "label": "Georgia"
  },
  {
    "value": "DE",
    "label": "Germany"
  },
  {
    "value": "GH",
    "label": "Ghana"
  },
  {
    "value": "GI",
    "label": "Gibraltar"
  },
  {
    "value": "GR",
    "label": "Greece"
  },
  {
    "value": "GT",
    "label": "Guatemala"
  },
  {
    "value": "HK",
    "label": "Hong Kong"
  },
  {
    "value": "HU",
    "label": "Hungary"
  },
  {
    "value": "IS",
    "label": "Iceland"
  },
  {
    "value": "IN",
    "label": "India"
  },
  {
    "value": "ID",
    "label": "Indonesia"
  },
  {
    "value": "IE",
    "label": "Ireland"
  },
  {
    "value": "IL",
    "label": "Israel"
  },
  {
    "value": "IT",
    "label": "Italy"
  },
  {
    "value": "JP",
    "label": "Japan"
  },
  {
    "value": "JO",
    "label": "Jordan"
  },
  {
    "value": "KE",
    "label": "Kenya"
  },
  {
    "value": "LA",
    "label": "Latvia"
  },
  {
    "value": "LI",
    "label": "Liechtenstein"
  },
  {
    "value": "LT",
    "label": "Lithuania"
  },
  {
    "value": "LU",
    "label": "Luxembourg"
  },
  {
    "value": "MY",
    "label": "Malaysia"
  },
  {
    "value": "MT",
    "label": "Malta"
  },
  {
    "value": "MX",
    "label": "Mexico"
  },
  {
    "value": "MC",
    "label": "Monaco"
  },
  {
    "value": "MA",
    "label": "Morocco"
  },
  {
    "value": "NP",
    "label": "Nepal"
  },
  {
    "value": "NL",
    "label": "Netherlands"
  },
  {
    "value": "NZ",
    "label": "New Zealand"
  },
  {
    "value": "NG",
    "label": "Nigeria"
  },
  {
    "value": "NO",
    "label": "Norway"
  },
  {
    "value": "OM",
    "label": "Oman"
  },
  {
    "value": "PK",
    "label": "Pakistan"
  },
  {
    "value": "PE",
    "label": "Peru"
  },
  {
    "value": "PN",
    "label": "Pitcairn"
  },
  {
    "value": "PL",
    "label": "Poland"
  },
  {
    "value": "PT",
    "label": "Portugal"
  },
  {
    "value": "QA",
    "label": "Qatar"
  },
  {
    "value": "RO",
    "label": "Romania"
  },
  {
    "value": "RU",
    "label": "Russian Federation"
  },
  {
    "value": "SM",
    "label": "San Marino"
  },
  {
    "value": "SG",
    "label": "Singapore"
  },
  {
    "value": "SK",
    "label": "Slovakia"
  },
  {
    "value": "SI",
    "label": "Slovenia"
  },
  {
    "value": "KR",
    "label": "South Korea"
  },
  {
    "value": "ZA",
    "label": "South Africa"
  },
  {
    "value": "ES",
    "label": "Spain"
  },
  {
    "value": "SR",
    "label": "Sri Lanka"
  },
  {
    "value": "SE",
    "label": "Sweden"
  },
  {
    "value": "CH",
    "label": "Switzerland"
  },
  {
    "value": "TW",
    "label": "Taiwan"
  },
  {
    "value": "TH",
    "label": "Thailand"
  },
  {
    "value": "TR",
    "label": "Turkey"
  },
  {
    "value": "UA",
    "label": "Ukraine"
  },
  {
    "value": "AE",
    "label": "United Arab Emirates"
  },
  {
    "value": "GB",
    "label": "United Kingdom"
  },
  {
    "value": "US",
    "label": "United States"
  },
  {
    "value": "VN",
    "label": "Vietnam"
  }
]

export { countryList }
