import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
import logo_issoyo from "../../../assets/img/issoyo_logo_trans.png";


require("./SuccesRegister.scss");


class SuccesRegister extends Component {

    componentDidMount() {
        // If logged in and user navigates to Register page, should redirect them to dashboard
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/home");
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className='welcome'>
                    <div className='activate_account'>
                        <div className='col-md-4 logo'>
                            <img className="logo-img" src={logo_issoyo} alt=''></img>
                        </div>
                        <div className="col-md-8 content-check">
                            <div className="title">
                                <div style={{textAlign: "left"}}>
                                    <FormattedMessage id="succesRegister.welcome" defaultMessage="Welcome to ISSOYO!"  />
                                </div>
                            </div>
                            <div className="text">
                                <FormattedMessage id="succesRegister.text" defaultMessage="Your account has been validated, you can now use ISSOYO!"/>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}

SuccesRegister.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    localUser: state.localData.localUser,
    errors: state.auth.error
});


export default connect(
    mapStateToProps,
    { }
)(SuccesRegister);
