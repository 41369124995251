import React, { Component } from 'react'
import taxi_en from '../../../../../assets/img/taxi_gb_en.png';
import taxi_fr from '../../../../../assets/img/taxi_gb_fr.png';
import { FormattedMessage } from 'react-intl';


require('./Taxi.scss');


class TaxiGB extends Component {
    render() {
        var img_taxi = taxi_en;
        if (this.props.language === 'FR'){
            img_taxi = taxi_fr;
        }
        return (
            <div className="taxi">
                <div className="text taxi_describe">
                <div className="taxi_provider_logo">
                        <img className="taxi_provider-img" src="https://www.asiamiles.com/content/dam/am-content/brand-v2/transport-pillar/logo-image/rentalcars.com-logo.png/jcr:content/renditions/cq5dam.resize.390.234.png" alt='' />
                </div>
                    <div style={{marginBottom: "22px"}}><FormattedMessage id="transport.taxi.gb.describe" defaultMessage="Arranging car hire in 163 countries and 40 languages, Rentalcars.com is the world biggest online car rental service. Working with leading suppliers, we offer great prices on all car groups, including luxury cars, people carriers, minivans and automatic cars. With a multilingual call centre open 7 days a week, we can find you the best rental prices whenever you want – wherever you go." /></div>
                    <div>
                        <img className="taxi_provider-img" style={{width: "100%"}} src={img_taxi}alt='' />
                </div>
                </div>
                <br></br>
                <div className="text taxi_title">
                    <FormattedMessage id="transport.taxi.kr.advantage" />
                </div>
                <div>
                    <ul className="text">
                        <li><FormattedMessage id="transport.taxi.price" defaultMessage="Price promised" /></li>
                        <li><FormattedMessage id="transport.taxi.availabilty" defaultMessage="Permanent availability via telephone or e-mail through our call center" /></li>
                        <li><FormattedMessage id="transport.taxi.nocharges" defaultMessage="No service or credit card charges" /></li>
                        <li><FormattedMessage id="transport.taxi.unbeatable" defaultMessage="Unbeatable prices on all vehicle classes" /></li>
                        <li><FormattedMessage id="transport.taxi.leading" defaultMessage="Leading suppliers worldwide" /></li>
                        <li><FormattedMessage id="transport.taxi.providers" defaultMessage="Comparison from over 250+ providers" /></li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default TaxiGB;