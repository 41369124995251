import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import ReactPlayer from 'react-player';
import Axa_logo from '../../../../../assets/img/providers/Axa_logo.jpg';
import axa_policy from '../../../../../assets/document/insurance/ISSOYO_axa_car_policy_summary.pdf';
import axa_wording from '../../../../../assets/document/insurance/ISSOYO_axa_car_policy_wording.pdf';

require('./CarUnitedKingdom.scss')

class CarUnitedKingdom extends Component {
    render() {
        return (
            <div className="business">
                <div className="business_logo">
                    <img style={{ width: '250px' }} src={Axa_logo} alt=''></img>
                </div>
                <div className="text axa_title">
                    <FormattedMessage id="axa.car.gb.title" defaultMessage="Car Insurance with AXA" />
                </div>
                <ul className="text">
                    <li><FormattedMessage id="axa.car.gb.defaqto" defaultMessage="5 star Defaqto rated" /></li>
                    <li><FormattedMessage id="axa.car.gb.insurance" defaultMessage="99% of car insurance claims paid" /><span className="small_text">*</span></li>
                    <li><FormattedMessage id="axa.car.gb.emergency" defaultMessage="24/7 emergency claims lines" /></li>
                    <li><FormattedMessage id="axa.car.gb.uninsured" defaultMessage="Uninsured driver promise" /><span className="small_text">**</span></li>
                    <li><FormattedMessage id="axa.car.gb.courtesy" defaultMessage="Courtesy car for as long as your car is being repaired" /></li>
                    <li><FormattedMessage id="axa.car.gb.lifetime" defaultMessage="Lifetime guarantee on repairs" /><span className="small_text">***</span></li>
                    <li><FormattedMessage id="axa.car.gb.wrong" defaultMessage="Wrong fuel cover" /></li>
                </ul>
                <p className="small_text"><span>*</span><FormattedMessage id="axa.car.gb.insurance.1" defaultMessage="Data relates to personal Motor insurance claims for policies underwritten by AXA Insurance UK plc from May 2018 to April 2019 inclusive."/></p>
                <p className="small_text"><span>**</span><FormattedMessage id="axa.car.gb.uninsured.1" defaultMessage="Included in AXA comprehensive car insurance quotes only"/></p>
                <p className="small_text"><span>***</span><FormattedMessage id="axa.car.gb.lifetime.1" defaultMessage="All repairs carried out by our approved repairers are guaranteed throughout the time that you own your car"/></p>
                <div className="habitation_document" style={{ marginTop: "24px", marginBottom: "48px" }}>
                    <div className="">
                        <div className="health_document-container" onClick={() => window.open(axa_policy, "_blank")}>
                            <div>
                                <i className="fal fa-file-alt"></i> <FormattedMessage id="insurance.car.gb.summary" />
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="health_document-container" onClick={() => window.open(axa_wording, "_blank")}>
                            <div>
                                <i className="fal fa-file-alt"></i> <FormattedMessage id="insurance.car.gb.wording" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="axa_video">
                    <ReactPlayer
                        width='100%'
                        controls
                        config={{
                            youtube: {
                                playerVars: { showinfo: 1 }
                            }
                        }}
                        url="https://youtu.be/NYVpqeJjF6w"
                    />
                </div>
            </div>
        )
    }
}

export default CarUnitedKingdom;