import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPhoneList } from "../../../../actions/serviceActions.js";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Issoyo_logo from '../../../../assets/img/fav_icon32@2x.png';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { forfaitList } from './forfait.js';
import { phoneList } from './phone.js';
import { sfr_card } from '../../../../assets/img/providers/sfr_card.png';
import { bouygues } from '../../../../assets/img/providers/bouygues.png';
import { sky } from '../../../../assets/img/providers/sky_logo.jpg';
import { opmobile } from '../../../../assets/img/providers/1pmobile_logo.png';

const devises = [
    {
        name: 'EUR',
        symbole: '€',
        country: ['fr', 'be', 'de', 'es', 'gr', 'pt', 'at', 'cy', 'fi', 'bu', 'ee', 'lt', 'lu', 'nl', 'it', 'pl', 'li', 'ir', 'ho', 'cz']
    },
    {
        name: 'AUD',
        symbole: 'A$',
        country: ['au']
    },
    {
        name: 'CAD',
        symbole: 'C$',
        country: ['ca']
    },
    {
        name: 'CHF',
        symbole: 'Fr',
        country: ['ch']
    },
    {
        name: 'GBP',
        symbole: '£',
        country: ['gb']
    },
    {
        name: 'HKD',
        symbole: 'HK$',
        country: ['hk']
    },
    {
        name: 'HUF',
        symbole: 'Ft',
        country: ['hu']
    },
    {
        name: 'JPY',
        symbole: '¥',
        country: ['jp']
    },
    {
        name: 'NOK',
        symbole: 'kr',
        country: ['no']
    },
    {
        name: 'SEK',
        symbole: 'kr',
        country: ['se']
    },
    {
        name: 'SGD',
        symbole: 'S$',
        country: ['si']
    },
    {
        name: 'USD',
        symbole: '$',
        country: ['us']
    }
]

const styles = {
    root: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    selectMenu: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    input: {
        borderColor: '#3e45948a !important',
    },
};

class LineMob extends React.Component {
    constructor() {
        super();
        this.state = {
            forfait: 'line_phone',
            index: 0,
            name: '',
            operator: [],
            operator_selected: 'none',
            operator_info: [],
            internet_list: [],
            internet_list_size: [],
            price_forfait: '?',
            phone_list: [],
            res_list: [],
            phone_selected: 'none',
            price_phone: '?',
            buttonDisable: true,
            trackingLink: '',
            internet_value: 'none',
            name_operator: '',
            phone_value: 0,
            phone_brand: 'none',
            phone_brandList: phoneList.map(data => data.name),
            phone_colorList: [],
            phone_color: 'none',
            phone_sizeList: [],
            phone_size: 'none',
            isDisablePhone: true,
            isDisableOperator: true,
            telecomDisplay: 'telecom',
            displayInternet: 'internet',
            displayOperator: 'operator',
            countryTelecom: [],
            deviseSelected: [],
            disableSize: true,
            disableColor: true,

        }
        this.handleChangeOperator = this.handleChangeOperator.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeLine = this.handleChangeLine.bind(this);
        this.handleChangePhone = this.handleChangePhone.bind(this);
        this.getOperator = this.getOperator.bind(this);
        this.getPhone = this.getPhone.bind(this);
    }

    componentWillMount() {
        var country = this.props.auth.user.location;
        var tmp = forfaitList.filter(function (data) {
            if (data.country.toLowerCase() === country.toLowerCase()) {
                return true;
            }
            return false;
        })
        var location = this.props.auth.user.location.toLowerCase();
        var devise = devises.filter(function (data) {
            if (data.country.indexOf(location) > -1) {
                return data;
            }
        })
        if (devise.length > 0) {
            this.setState({ deviseSelected: devise[0], countryTelecom: tmp })
        } else {
            this.setState({
                deviseSelect: 'EUR', deviseSelected: {
                    name: 'EUR',
                    symbole: '€',
                    country: ['fr', 'be', 'de', 'es', 'gr', 'pt', 'at', 'cy', 'fi', 'bu', 'ee', 'lt', 'lu', 'nl', 'it', 'pl', 'li', 'ir', 'ho', 'cz']
                },
                countryTelecom: tmp
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.service.errors.status) {
            console.log('error');
        }
        else if (nextProps.service.phoneList) {
            this.setState({ phone_list: nextProps.service.phoneList });
            console.log(nextProps.service.phoneList)
        }
    };

    handleClick() {
        if (!this.props.auth.isAuthenticated) {
            this.props.handleClickAccount();
        } else {
            window.open(this.state.trackingLink, "_blank");
        }
    }

    handleChangeOperator = event => {
        if (event.target.value === 'none') {
            this.setState({ [event.target.name]: event.target.value })
        } else {
            const operator = forfaitList.filter(({ name }) => name === event.target.value);
            var call = null;
            if (operator[0].call === 11) {
                call = "unlimited"
            } else {
                call = operator[0].call.toString() + "h";
            }
            const info =
                <div className="card-telecom">
                    <div className="row card-display">
                        <div className="telecom_operator-logo"><img className="telecom_operator-logo--img" src={operator[0].pics} alt=''></img> <span className="card_name-m">{operator[0].operator}</span></div>
                        <div className="telecom_operator-details-wrap">
                            <div className="telecom_operator-details" >{operator[0].detail_1}</div>
                            <div className="telecom_operator-details" >{operator[0].detail_2}</div>
                            <div className="telecom_operator-details" >{operator[0].detail_3}</div>
                        </div>
                        <hr className="mobile-hr"></hr>
                        <div className="card_price-wrap">
                            <div className="card-price">{operator[0].price}<span className="card-unit">{this.state.deviseSelected.symbole} /<FormattedMessage id="telecom.linemob.month" /></span><p className="card-eng-m">{operator[0].eng}</p></div>
                            <div className="card-eng-d">{operator[0].eng}</div>
                        </div>
                    </div>
                    <div className="card-sim"><div className="col telecom_icon"><i className="fas fa-sim-card" style={{ color: 'rgba(68, 76, 165, 0.85)' }}></i> <FormattedMessage id="telecom.sim" /> {operator[0].sim} {this.state.deviseSelected.symbole} </div><div className="col telecom_icon"><i className="fas fa-phone" style={{ color: 'rgba(68, 76, 165, 0.85)' }}></i> <FormattedMessage id="telecom.call" /> {call}</div><div className="col telecom_icon"><i className="fas fa-signal" style={{ color: 'rgba(68, 76, 165, 0.85)' }}></i> Internet {operator[0].internet}</div></div>
                </div>
            this.setState({
                [event.target.name]: event.target.value,
                operator_info: info,
                name_operator: operator[0].operator,
                price_forfait: operator[0].price,
                phone_selected: 'none',
                phone_brand: 'none',
                phone_color: 'none',
                phone_size: 'none',
                price_phone: '?',
                isDisablePhone: true,
                telecomDisplay: 'telecom--appear',
            });
            this.props.getPhoneList({ forfait: operator[0].name, name: operator[0].operator });
        }
    };

    handleChange = event => {

        if (event.target.value === 'none') {
            this.setState({ [event.target.name]: event.target.value })
        } else {
            this.setState({
                [event.target.name]: event.target.value,
                price_phone: event.target.value.search_price,
                trackingLink: event.target.value.aw_deep_link,
                buttonDisable: false,
            });
        }
    };

    getOperator() {
        const internet = this.state.internet_value;
        const op = this.state.internet_list.filter(function (data) {
            if (data.type === 'linemob') {
                if (data.internet === internet) {
                    return true;
                } else {
                    return false;
                }
            }
            return false;
        })
        if (op.length < 1) {
            this.setState({ operator: op, isDisableOperator: true })
        } else {
            this.setState({ operator: op, isDisableOperator: false })
        }
    }

    getPhone() {
        var count = forfaitList.map(function (e) { return e.name; }).indexOf(this.state.operator_selected);
        var op = forfaitList[count].operator;
        const brand = this.state.phone_brand.toLowerCase();
        var color = '';
        var size = '';
        if (this.state.phone_size !== 'none') {
            size = this.state.phone_size.toLowerCase();
        }
        if (this.state.phone_color !== 'none') {
            color = this.state.phone_color.toLowerCase();
        }
        const res = this.state.phone_list.filter(function (data) {
            if (data.product_name.toLowerCase().includes(brand)) {
                if (data.product_name.toLowerCase().includes(color)) {
                    if (data.product_name.toLowerCase().includes(size)) {
                        if (data.product_name.toLowerCase().includes('REC'.toLowerCase())) {
                            return false
                        }
                        if (op === 'SFR' && data.merchant_product_third_category.toLowerCase().includes('MOB'.toLowerCase())) {
                            return true
                        }
                        if (op === 'BOUYGUES') {
                            return true
                        }
                        return false;
                    }
                }
            }
            return false;
        })
        if (op === 'BOUYGUES') {
            res.map((data) => {
                if (data.product_name.indexOf('avec') > -1) {
                    data.product_name = data.product_name.substr(0, data.product_name.indexOf('avec'));
                    data.search_price = data.search_price.substr(0, data.search_price.indexOf('EUR'));
                } else {
                    return null;
                }
            });
        }
        if (size === '') {
            const size_list = this.state.phone_sizeList.filter(function(data) {
                var size_available = data.toLowerCase();
                var size_result = res.filter(function (data) {
                    if (data.product_name.toLowerCase().includes(brand)){
                        if (data.product_name.toLowerCase().includes(size_available)){
                            return true;
                        }
                    }
                    return false;
                })
                if (size_result.length > 0) {
                    return true;
                }
                return false;
            })
            this.setState({ phone_sizeList: size_list });
        }
        if (size !== '' && color === '') {
            const color_list = this.state.phone_colorList.filter(function (data) {
                var color_available = data.toLowerCase();
                var test = res.filter(function (data) {
                    if (data.product_name.toLowerCase().includes(size)) {
                        if (data.product_name.toLowerCase().includes(color_available)) {
                            return true
                        }
                    }
                    return false;
                })
                if (test.length > 0) {
                    return true;
                }
                return false;
            })
            this.setState({ phone_colorList: color_list })
        }
        this.setState({ res_list: res });
    }

    handleChangeLine(event) {
        if (event.target.name === 'phone_value') {
            const call = event.target.value;
            var tmparray = [];
            var country = this.props.auth.user.location;
            var internet = forfaitList.filter(function (data) {
                if (data.country.includes(country)) {
                    if (data.type === 'linemob') {
                        if (data.call === call) {
                            tmparray.push({ name: data.internet, value: data.internet_value });
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
                return false
            });
            tmparray.sort((a, b) => parseInt(a.value, 10) - parseInt(b.value, 10));
            tmparray = tmparray.map(function (item) {
                return item.name;
            });
            var tmpset = Array.from(new Set(tmparray));
            this.setState({
                [event.target.name]: event.target.value,
                operator_selected: 'none',
                internet_list: internet,
                internet_list_size: tmpset,
                internet_value: 'none',
                phone_selected: 'none',
                phone_brand: 'none',
                phone_color: 'none',
                phone_size: 'none',
                displayInternet: 'internet--appear',
                telecomDisplay: 'telecom',
            })
        } else {
            if (event.target.value === 'none') {
                this.setState({
                    [event.target.name]: event.target.value,
                    operator_selected: 'none',
                    phone_selected: 'none',
                    phone_brand: 'none',
                    phone_color: 'none',
                    phone_size: 'none',
                    displayInternet: 'internet--appear',
                    telecomDisplay: 'telecom',
                })
            } else {
                this.setState({
                    [event.target.name]: event.target.value,
                    operator_selected: 'none',
                    phone_selected: 'none',
                    phone_brand: 'none',
                    phone_color: 'none',
                    phone_size: 'none',
                    displayOperator: 'operator--appear',
                    telecomDisplay: 'telecom',
                }, () => this.getOperator())
            }
        }
    }

    handleChangePhone(event) {
        var brand = phoneList.filter(({ name }) => name === event.target.value)
        if (this.state.phone_brand !== 'none' ){
            brand = phoneList.filter(({ name }) => name === this.state.phone_brand)
        }
        var size = brand[0].size;
        if (this.state.name_operator === 'BOUYGUES'){
            size = brand[0].size_bouygue;
        }
        if (event.target.name === 'phone_brand') {
            if (event.target.value === 'none') {
                this.setState({
                    [event.target.name]: event.target.value,
                    phone_selected: 'none',
                    phone_color: 'none',
                    phone_size: 'none',
                    disableColor: true,
                    disableSize: true,
                })
            } else {
                this.setState({
                    [event.target.name]: event.target.value,
                    isDisablePhone: false,
                    phone_colorList: brand[0].color,
                    phone_sizeList: size,
                    phone_selected: 'none',
                    phone_size: 'none',
                    phone_color: 'none',
                    disableColor: true,
                    disableSize: false,
                }, () => this.getPhone());
            }
        } else if (event.target.name === 'phone_size') {
            this.setState({
                [event.target.name]: event.target.value,
                isDisablePhone: false,
                phone_colorList: brand[0].color,
                phone_selected: 'none',
                phone_color: 'none',
                disableColor: false,
            }, () => this.getPhone())
        } else {
            this.setState({
                [event.target.name]: event.target.value,
                isDisablePhone: false,
                phone_selected: 'none',
            }, () => this.getPhone())
        }
    };

    render() {
        const { classes } = this.props;
        console.log(this.state.telecomDisplay)
        return (
            <React.Fragment>
                <div className='row linemob'>
                    <div className="select phone">
                        <TextField
                            id="outlined-select-currency"
                            select
                            label="Communication"
                            className="telecom_operator-list"
                            value={this.state.phone_value}
                            name="phone_value"
                            onChange={this.handleChangeLine}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                            margin="normal"
                            variant="outlined"
                        >
                            <MenuItem disabled value={0}><FormattedMessage id="telecom.linmob.select.timeCall" defaultMessage="Select time call" /></MenuItem>
                            <MenuItem value={2} classes={{ root: classes.root }}>2h</MenuItem>
                            <MenuItem value={11} classes={{ root: classes.root }}><FormattedMessage id="telecom.call.unlimited" /></MenuItem>
                        </TextField>
                    </div>
                    <div className={this.state.displayInternet}>
                        <div className="select">
                            <TextField
                                id="outlined-select-currency"
                                select
                                label="Internet"
                                className="telecom_operator-list"
                                value={this.state.internet_value}
                                name="internet_value"
                                onChange={this.handleChangeLine}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                margin="normal"
                                variant="outlined"
                            >
                                <MenuItem disabled value='none'><FormattedMessage id="telecom.linemob.select.data" defaultMessage="Select data" /></MenuItem>
                                {this.state.internet_list_size.map((data, key) => (
                                    <MenuItem classes={{ root: classes.root }} key={key} value={data}>
                                        {data}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                    <div className={this.state.displayOperator}>
                        <div className="select">
                            <TextField
                                id="outlined-select-currency"
                                select
                                disabled={this.state.isDisableOperator}
                                label="Telecom"
                                className="telecom_operator-list"
                                value={this.state.operator_selected}
                                name="operator_selected"
                                onChange={this.handleChangeOperator}
                                SelectProps={{
                                    MenuProps: {
                                        className: classes.menu,
                                    },
                                }}
                                InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                margin="normal"
                                variant="outlined"
                            >
                                <MenuItem value='none' classes={{ root: classes.root }}><FormattedMessage id="telecom.linemob.select.telecom" defaultMessage="Select telecom" /></MenuItem>
                                {this.state.operator.map((data, key) => (
                                    <MenuItem classes={{ root: classes.root }} key={key} value={data.name}>
                                        {data.operator} - {data.name} - {data.price} {this.state.deviseSelected.symbole}/<FormattedMessage id="telecom.linemob.month" />
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </div>
                </div>
                <div className={this.state.telecomDisplay}>
                    <div className="telecom_operator-info">
                        {this.state.operator_info}
                    </div>
                    <FormattedMessage id="telecom.linemob.month">
                        {msg => (<hr className="telecom_operator-price" data-content={`${this.state.price_forfait} ${this.state.deviseSelected.symbole}/${msg}`}></hr>
                        )}
                    </FormattedMessage>
                    <div className='row linemob'>
                        <div className="col-sm-6 telecom_phone_brand">
                            <FormattedMessage id="telecom.linemob.brand" defaultMessage="brand">
                                {msg => (<TextField
                                    id="outlined-select-currency"
                                    select
                                    label={msg}
                                    className="telecom_phone_brand-list"
                                    value={this.state.phone_brand}
                                    name="phone_brand"
                                    onChange={this.handleChangePhone}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    <MenuItem value='none' disabled classes={{ root: classes.root }}><FormattedMessage id="telecom.linemob.select.brand" defaultMessage="Select brand" /></MenuItem>
                                    {this.state.phone_brandList.map((data, key) => (
                                        <MenuItem classes={{ root: classes.root }} key={key} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                )}
                            </FormattedMessage>
                        </div>
                        <div className="col-sm-6 telecom_phone_size">
                            <FormattedMessage id="telecom.linemob.storage" defaultMessage="Storage">
                                {msg => (<TextField
                                    id="outlined-select-currency"
                                    select
                                    label={msg}
                                    className="telecom_phone_size-list"
                                    value={this.state.phone_size}
                                    name="phone_size"
                                    onChange={this.handleChangePhone}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    disabled={this.state.disableSize}
                                    InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    <MenuItem value='none' classes={{ root: classes.root }}><FormattedMessage id="telecom.select.all" /></MenuItem>
                                    {this.state.phone_sizeList.map((data, key) => (
                                        <MenuItem classes={{ root: classes.root }} key={key} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                )}
                            </FormattedMessage>
                        </div>
                        <div className="col-sm-6 telecom_phone_color">
                            <FormattedMessage id="telecom.linemob.color" defaultMessage="Color">
                                {msg => (<TextField
                                    id="outlined-select-currency"
                                    select
                                    label={msg}
                                    className="telecom_phone_color-list"
                                    value={this.state.phone_color}
                                    name="phone_color"
                                    onChange={this.handleChangePhone}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    disabled={this.state.disableColor}
                                    InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    <MenuItem value='none' classes={{ root: classes.root }}><FormattedMessage id="telecom.select.all" /></MenuItem>
                                    {this.state.phone_colorList.map((data, key) => (
                                        <MenuItem classes={{ root: classes.root }} key={key} value={data}>
                                            {data}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                )}
                            </FormattedMessage>
                        </div>
                        <div className='col-sm-6 telecom_phone' >
                            <FormattedMessage id="telecom.linemob.phone" defaultMessage="Phone">
                                {msg => (
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        disabled={this.state.isDisablePhone}
                                        label={msg}
                                        className="telecom_phone-list"
                                        value={this.state.phone_selected}
                                        name="phone_selected"
                                        onChange={this.handleChange}
                                        SelectProps={{
                                            MenuProps: {
                                                className: classes.menu,
                                            },
                                        }}
                                        margin="normal"
                                        InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                        variant="outlined"
                                    >
                                        <MenuItem value={'none'} classes={{ root: classes.root }}><FormattedMessage id="telecom.linemob.select.phone" defaultMessage="Choose your phone" /></MenuItem>
                                        {this.state.res_list.map((data, key) => (
                                            <MenuItem classes={{ root: classes.root }} key={key} value={data}>
                                                {data.product_name} - {data.search_price} €
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}</FormattedMessage>
                        </div>
                    </div>
                    <div className={this.state.telecomDisplay}>
                        <hr className="telecom_phone-price" data-content={`${this.state.price_phone} ${this.state.deviseSelected.symbole}`}></hr>
                    </div>
                    <div className="footer-telecom" style={{ textAlign: 'center' }}>
                        <Button variant="contained" color="primary" className="button" onClick={this.handleClick} disabled={this.state.buttonDisable}>
                            <img src={Issoyo_logo} alt='' style={{ width: '11%', marginRight: '5%' }}></img>
                            <FormattedMessage id="telecom.get" />
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

LineMob.propTypes = {
    auth: PropTypes.object.isRequired,
    getPhoneList: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    service: state.services,
});

export default connect(
    mapStateToProps, { getPhoneList }
)(withStyles(styles)(LineMob));