import React, { Component } from 'react'
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ImgurUploaderInit from 'ckeditor5-imgur-uploader'

class BlogNew extends Component {
    constructor() {
        super()
        this.state = {
            text: "",
            title: "",
            country: '',
            service: 'all',
            langue: 'FR',
            file: null,
            data: new FormData(),
        }
        this.ImgurUploader= ImgurUploaderInit({clientID: '6ad8ce27b56203a'})
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
        if (!this.props.auth.isAuthenticated || this.props.auth.user.userRole !== 'Admin') {
            this.props.history.push("/login");
        }
}

    handleChange(e){
        if (e.target.name === 'file') {
            var tmp = this.state.data;
            for (var i = 0; i <= e.target.files.length; i++){
                tmp.append('productImage', e.target.files[i]);
            }
            this.setState({ [e.target.name]: tmp })
        } else {
            this.setState({ [e.target.name]: e.target.value})
        }
    }
    onSubmit(e) {
        e.preventDefault();
        const obj = {
            country: this.state.country,
            title: this.state.title,
            text: this.state.text,
            service: this.state.service,
            langue: this.state.langue
        };
        axios.post('/api/service/createblog', obj).then(res => console.log(res.data));
        window.open('/admin-issoyo/blog', "_self");
    }

    render() {
        return (
            <div style={{margin: 'auto',  marginTop: '100px', maxWidth: '800px' }}>
                <h2>New blog</h2>
                <div>
                    <form onSubmit={this.onSubmit}>
                        <div className="form-group">
                            <label>Titre:  </label>
                            <input
                                type="text"
                                name="title"
                                className="form-control"
                                value={this.state.title}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div style={{border: '1px solid lightgray'}}>
                    <CKEditor
                        config={{
                            extraPlugins: [this.ImgurUploader],
                        }}
                        editor={ DecoupledEditor }
                        onInit={ editor => {
                            // Insert the toolbar before the editable area.
                            editor.ui.getEditableElement().parentElement.insertBefore(
                                editor.ui.view.toolbar.element,
                                editor.ui.getEditableElement(),
                            );
                        } }
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            this.setState({ text: data })
                        }}
                    ></CKEditor></div>
                    <div className="form-group">
                            <label>Country ISO (Ex: FR, GB, DE, KR, ... )</label>
                            <input
                                type="text"
                                name="country"
                                className="form-control"
                                value={this.state.country}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Service (all, issoyo, bank, telecom, utilities, housing, insurances, internet, transport, utilities) </label>
                            <input
                                type="text"
                                name="service"
                                className="form-control"
                                value={this.state.service}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Langue ISO (ex: fr, en, kr, ...)</label>
                            <input
                                type="text"
                                name="langue"
                                className="form-control"
                                value={this.state.langue}
                                onChange={this.handleChange}
                            />
                        </div>
                    <div className="form-group" style={{marginTop: '20px'}}>
                        <input type="submit" value="Publier" className="btn btn-primary" />
                    </div>
                </form>
                </div>
            </div>
        )
    }
}

BlogNew.propTypes = {
    auth: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(
    mapStateToProps,
    { }
)(BlogNew);

/*                        <div className="form-group">
                            <label>Header: </label><br/>
                            <input type='file' name='file' onChange={this.handleChange} />
                        </div>*/