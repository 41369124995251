import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { withAlert } from 'react-alert';
import Button from '@material-ui/core/Button';
import axios from 'axios';

require('./ContactModal.scss')

const styles = {
    root: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    selectMenu: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    input: {
        borderColor: '#3e45948a !important',
    },
    rootCheck: {
        color: '#836bc1bf !important',
        '&$checked': {
            color: '#836bc1bf !important',
        },
    },
};

class ContactModal extends Component {
    constructor() {
        super();
        this.state = {
            message: '',
            contact: '',
            errorEmail: false,
            errorRequired: false,
            messageMail: <FormattedMessage id="service.annonce.modal.contact.error" defaultMessage="Please enter valid email" />
        }
        this.handleChange = this.handleChange.bind(this);
        this.contact = this.contact.bind(this);
        this.checkEmail = this.checkEmail.bind(this);
    }

    checkEmail(email) {
        var re = /\S+@\S+\.\S+/;
        if (re.test(email)) {
            this.setState({ errorEmail: false });
        }
        else {
            this.setState({ errorEmail: true });
        }
    }

    contact() {
        var request = {
            emailContact: this.props.emailContact,
            authorContact: this.props.authorContact,
            firstname: this.props.auth.user.firstname,
            contact: this.state.contact,
            message: this.state.message,
            title: this.props.adsTitle,
        }
        if (this.state.title === '' || this.state.contact === '' || this.state.description === '' ||
            this.state.price === '' || this.errorEmail === true) {
            this.setState({ errorRequired: true });
        } else {
            axios.post('api/mail/sendContactEmail', request).catch(err => { console.log(err); })
            this.props.onCloseModal();
            this.props.alert.show('Message envoyé', { type: 'success' });
        }
    }

    componentDidMount() {
        this.setState({ contact: this.props.auth.user.email });
    }

    handleChange(e) {
        if (e.target.name === "contact") {
            this.checkEmail(e.target.value);
        }
        this.setState({ [e.target.name]: e.target.value })
    }
    
    render() {
        const { classes } = this.props;
        return (
            <div className="ContactModal">
                <div className="contactmodal_title">
                    <FormattedMessage id="service.annonce.modal.contact.title" defaultMessage="Send your message" />
                </div>
                <div className="annonce_form">
                <div className="error required" style={this.state.errorRequired ? {} : { display: 'none' }}>
                        <FormattedMessage id="service.annonce.error.required" defaultMessage="You must fill correctly out all required fields" />
                    </div>
                    <div className="input annonce_message">
                        <FormattedMessage id="service.annonce.message" defaultMessage="Message" >
                            {msg => (<TextField
                                required
                                id="outlined-with-placeholder"
                                label={msg}
                                multiline
                                rows="4"
                                rowsMax="4"
                                className="annonce_message-input"
                                name="message"
                                margin="normal"
                                variant="outlined"
                                value={this.state.message}
                                InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                onChange={this.handleChange}
                            />)}
                        </FormattedMessage>
                    </div>
                    <div className="annonce_disclaimer">
                        <FormattedMessage id="service.annonce.disclaimer" defaultMessage="Enter your email to let the author to contact you, ISSOYO is not responsable for any issue between you and the author" />
                    </div>
                    <div className="row info">
                        <div className="input annonce_contact">
                            <FormattedMessage id="service.annonce.modal.contact" defaultMessage="Contact" >
                                {msg => (<TextField
                                    required
                                    error={this.state.errorEmail}
                                    id="outlined-with-placeholder"
                                    label={msg}
                                    className="annonce_contact-input"
                                    name="contact"
                                    margin="normal"
                                    variant="outlined"
                                    value={this.state.contact}
                                    InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                    onChange={this.handleChange}
                                />)}
                            </FormattedMessage>
                            <div className="error" style={this.state.errorEmail ? {} : { display: 'none' }}>{this.state.messageMail}</div>
                        </div>
                        <div className="annonce_message_send">
                            <Button variant="contained" color="primary" className='annonce_message-button' onClick={this.contact}>
                                <FormattedMessage id="service.annonce.send" defaultMessage="Send" />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ContactModal.propTypes = {
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    service: state.services,
});

export default connect(
    mapStateToProps, {}
)(withStyles(styles)(withAlert(ContactModal)));