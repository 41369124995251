import React, { Component } from 'react'
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';
import { connect } from "react-redux";
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';
import { SliderRail, Handle, Track } from './SliderComponent'
import ScrollUpButton from "react-scroll-up-button";
import Issoyoservice from '../Issoyoservice/Issoyoservice.jsx';
import { promoList } from './promoList.js';

require('./Internet.scss');

const devises = [
    {
        name: 'EUR',
        symbole: '€',
        country: ['fr', 'be', 'de', 'es', 'gr', 'pt', 'at', 'cy', 'fi', 'bu', 'ee', 'lt', 'lu', 'nl', 'it', 'pl', 'li', 'ir', 'ho', 'cz']
    },
    {
        name: 'AUD',
        symbole: 'A$',
        country: ['au']
    },
    {
        name: 'CAD',
        symbole: 'C$',
        country: ['ca']
    },
    {
        name: 'CHF',
        symbole: 'Fr',
        country: ['ch']
    },
    {
        name: 'GBP',
        symbole: '£',
        country: ['gb']
    },
    {
        name: 'HKD',
        symbole: 'HK$',
        country: ['hk']
    },
    {
        name: 'HUF',
        symbole: 'Ft',
        country: ['hu']
    },
    {
        name: 'JPY',
        symbole: '¥',
        country: ['jp']
    },
    {
        name: 'NOK',
        symbole: 'kr',
        country: ['no']
    },
    {
        name: 'SEK',
        symbole: 'kr',
        country: ['se']
    },
    {
        name: 'SGD',
        symbole: 'S$',
        country: ['si']
    },
    {
        name: 'USD',
        symbole: '$',
        country: ['us']
    }
]

const styles = {
    root: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    selectMenu: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    input: {
        borderColor: '#3e45948a !important',
    },
    rootLabel: {
        fontFamily: "'Nunito', sans-serif !important",
    },
    rootControlLabel: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    rootCheck: {
        color: '#836bc1bf !important',
        '&$checked': {
            color: '#836bc1bf !important',
        }
    }
};

const sliderStyle = {  // Give the slider some width
    position: 'relative',
    width: 200,
    height: 60,
    marginTop: 35,
}


class Internet extends Component {
    constructor() {
        super();
        this.state = {
            displayEng: 'internet_eng',
            radioEng: true,
            radioSaneng: true,
            internetList: [],
            checkList: [],
            minUp: 10,
            maxUp: 40,
            min: 10,
            max: 40,
            lowprice: false,
            deviseSelected: [],
            user_location: '',
            today_promo: [],
        }
        this.handleChangeCheck = this.handleChangeCheck.bind(this);
        this.getOperator = this.getOperator.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.onClickSort = this.onClickSort.bind(this);
    }

    handleClick(url) {
        if (!this.props.auth.isAuthenticated) {
            this.props.history.push("/login");
        } else {
            window.open(url, "_blank");
        }
    }

    onClickSort() {
        var tmp = this.state.internetList;
        if (this.state.lowprice === false) {
            tmp.sort((a, b) => parseInt(a.prix, 10) - parseInt(b.prix, 10));
            this.setState({ internetList: tmp, lowprice: true });
        } else {
            tmp.sort((a, b) => parseInt(b.prix, 10) - parseInt(a.prix, 10));
            this.setState({ internetList: tmp, lowprice: false });
        }

    }

    getOperator() {
        const eng = this.state.radioEng;
        const saneng = this.state.radioSaneng;
        var list = this.state.checkList.filter(function (data) {
            if ((((!data.engagement.includes("sans") && !data.engagement.includes("Without")) && eng) === true)
                || ((data.engagement.includes("sans") || data.engagement.includes("Without")) && saneng) === true) {
                return true;
            }
            return false;
        })
        this.setState({ internetList: list })
    }

    componentDidMount() {
        if (!this.props.auth.user.location){
            this.props.history.push('/services');
        } else {
            this.setState({ user_location: this.props.auth.user.location})
        }
        this.setState({ internetList: this.props.service.internetList, checkList: this.props.service.internetList })
        window.scrollTo(0, 0);
        var location = this.props.auth.user.location.toLowerCase();
        var devise = devises.filter(function (data) {
            if (data.country.indexOf(location) > -1) {
                return data;
            }
            return false;
        })
        if (devise.length > 0) {
            this.setState({ deviseSelected: devise[0] })
        } else {
            this.setState({
                deviseSelect: 'EUR', deviseSelected: {
                    name: 'EUR',
                    symbole: '€',
                    country: ['fr', 'be', 'de', 'es', 'gr', 'pt', 'at', 'cy', 'fi', 'bu', 'ee', 'lt', 'lu', 'nl', 'it', 'pl', 'li', 'ir', 'ho', 'cz']
                },
            })
        }
        const country = this.props.auth.user.location;
        var promo_list = promoList.map((data, key) => {
            var date = new Date(Date.parse(data.date_fin));
            const now_date = new Date();
            if (data.country === country) {
                if (now_date >= new Date(Date.parse(data.date_debut)) && now_date <= date) {
                    return (
                        <div key={key}>
                            <div className="telecom_promo-container">
                                <div className="card-telecom">
                                    <div style={{ position: 'absolute' }}>
                                        <div className="ribbon">
                                            <div><FormattedMessage id="telecom.promo.until"/> {("0" + date.getDate()).slice(-2)} / {("0" + (date.getMonth() + 1)).slice(-2)}</div>
                                        </div>
                                    </div>
                                    <div className="card_name-com">{data.name}</div>
                                    <div className="card-price comp" style={{width: '250px', margin: 'auto'}}><div className="card-old-price"><s>{data.old_price}</s></div>{data.price}<span className="card-unit"></span></div>
                                    <div className="row card-display-promo">
                                        <div className="card-display-details-promo">
                                            <div className="telecom_operator-logo promo" style={{marginRight: '20px'}}><img className="telecom_operator-logo--img img-promo" src={data.pics} alt=''></img> <span className="card_name-com-m">{data.name}</span></div>
                                            <div className="telecom_operator-details-wrap">
                                                <div className="telecom_operator-details" >{data.detail_1}</div>
                                                <div className="telecom_operator-details" >{data.detail_2}</div>
                                                <div className="telecom_operator-details" >{data.detail_3}</div>
                                            </div>
                                        </div>
                                        <hr className="mobile-hr"></hr>
                                        <div className="card_price-wrap">
                                        <div className="card-price mob"><div className="card-old-price"><s>{data.old_price}</s></div>{data.price}<span className="card-unit"></span></div>
                                            <div className="card-button">
                                                <Button variant="contained" color="primary" className="button" onClick={() => window.open(data.trackingLinkk, "_blank")}>
                                                    <FormattedMessage id="telecom.get" />
                                                </Button>
                                            </div>
                                            <div className="card-eng-d">{data.eng}</div>
                                        </div>
                                    </div>
                                    <div className="text card-sim" style={{display: "flex", justifyContent: 'space-between', color: 'rgba(68, 76, 165, 0.85)',fontSize: "12px", textAling:'left'}}><span><FormattedMessage id="bank.promo.french.only" /></span>{data.more_info === true ? <div onClick={this.openModalInformation}><u><FormattedMessage id="bank.promo.more" /></u></div> : null}</div>
                                </div>
                            </div>
                        </div>
                    )
                }
            } else {
                return null;
            }
        })
        promo_list = promo_list.filter(function (data) {
            if (data === null) {
                return false;
            } else {
                return data;
            }
        })
        this.setState({ today_promo: promo_list })
    }

    handleChangeCheck(e) {
        if (e.target.value === "radioEng") {
            if (this.state.radioSaneng === false && this.state.radioEng === true) {
                this.setState({ radioEng: false, radioSaneng: true }, () => this.getOperator());
            } else {
                this.setState({ [e.target.value]: !this.state.radioEng }, () => this.getOperator());
            }
        } else {
            if (this.state.radioEng === false && this.state.radioSaneng === true) {
                this.setState({ radioEng: true, radioSaneng: false }, () => this.getOperator());
            } else {
                this.setState({ [e.target.value]: !this.state.radioSaneng }, () => this.getOperator());
            }
        }
    }

    onUpdate = update => {
        this.setState({ minUp: update[0], maxUp: update[1] })
    }

    onChange = values => {
        const min = values[0]
        const max = values[1]
        var list = this.props.service.internetList.filter(function (data) {
            if (min <= parseInt(data.prix) && max >= parseInt(data.prix)) {
                return true;
            }
            return false;
        })
        this.setState({ start: values[0], end: values[1], internetList: list, checkList: list })
    };

    render() {
        const { classes } = this.props;
        const card = this.state.internetList.map((data, key) => {
            var display = 'unset'
            if (data.chaines === '0' || data.chaines === 'option') {
                display = 'none'
            }
            return (
                <div key={key}>
                    <div className="card-internet">
                        <div className="row card-display">
                            <div className="card_name-d">{data.operateur} - {data.offre}</div>
                            <div className="internet_operator-logo"><img className="internet_operator-logo--img" src={data.Img} alt=''></img> <span className="card_name-m">{data.operateur} - {data.offre}</span></div>
                            <div className="internet_operator-details-wrap">
                                <div className="internet_operator-details" >
                                    <div className="internet_operator-logo"><i className="fas fa-wifi" style={{ color: 'rgba(68, 76, 165, 0.85)' }} /></div>
                                    <div className="internet_operator-speed">{data.reseau}</div>
                                </div>
                                <div className="internet_operator-details" >
                                    <div className="internet_operator-logo"><i className="fas fa-phone" style={{ color: 'rgba(68, 76, 165, 0.85)' }} /></div>
                                    <div className="internet_operator-speed"><FormattedMessage id="telecom.call.unlimited"/>*</div>
                                </div>
                                <div className="internet_operator-details" style={{ display: display }} >
                                    <div className="internet_operator-logo"><i className="fas fa-tv" style={{ color: 'rgba(68, 76, 165, 0.85)' }} /></div>
                                    <div className="internet_operator-speed">{data.chaines}</div>
                                </div>
                            </div>
                            <div className="card_price-wrap">
                                <div className="card-price">{data.prix}<span className="card-unit"> {this.state.deviseSelected.symbole}</span><p className="card-eng-m">{data.engagement}</p></div>
                                <div className="card-button">
                                    <Button variant="contained" color="primary" className="button" onClick={() => this.handleClick(data.Url)}>
                                        <FormattedMessage id="telecom.get"/>
                                </Button>
                                </div>
                                <div className="card-eng-d">{data.engagement}</div>
                            </div>
                        </div>
                        <div className="card-sim"><div className="col internet_icon">*{data.appels}</div><div className="col internet_fee"><i class="fas fa-file-alt"></i> {data.Frais}{this.state.deviseSelected.symbole} <FormattedMessage id='internet.fee.contract'/></div></div>
                    </div>
                </div>
            )
        })
        return (
            <div className="Internet">
                <div className="internet_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                <div className="title">
                    <div>
                        <FormattedMessage id="service.internet.title" defaultMessage="You need internet provider ?" />
                    </div>
                </div>
                <div className="internet_search">
                    <div className="internet_price">
                        <FormLabel component="legend" classes={{ root: classes.rootLabel }}><FormattedMessage id="internet.choose.price" defaultMessage="Choose Price:" /> {this.state.minUp}{this.state.deviseSelected.symbole} - {this.state.maxUp}{this.state.deviseSelected.symbole} </FormLabel>
                        <Slider
                            mode={2}
                            step={1}
                            domain={[10, 40]}
                            rootStyle={sliderStyle}
                            onChange={this.onChange}
                            onUpdate={this.onUpdate}
                            values={[10, 40]}
                            className="internet_slider"
                        >
                            <Rail>
                                {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
                            </Rail>
                            <Handles>
                                {({ handles, getHandleProps }) => (
                                    <div className="slider-handles">
                                        {handles.map(handle => (
                                            <Handle
                                                key={handle.id}
                                                handle={handle}
                                                domain={[10, 40]}
                                                getHandleProps={getHandleProps}
                                            />
                                        ))}
                                    </div>
                                )}
                            </Handles>
                            <Tracks left={false} right={false}>
                                {({ tracks, getTrackProps }) => (
                                    <div className="slider-tracks">
                                        {tracks.map(({ id, source, target }) => (
                                            <Track
                                                key={id}
                                                source={source}
                                                target={target}
                                                getTrackProps={getTrackProps}
                                            />
                                        ))}
                                    </div>
                                )}
                            </Tracks>
                        </Slider>
                    </div>
                    { this.props.auth.user.location === 'GB' ? null :
                    <div className={this.state.displayEng}>
                        <FormLabel component="legend" classes={{ root: classes.rootLabel }}><FormattedMessage id="internet.choose.period" defaultMessage="Choose period commitment" /></FormLabel>
                        <FormattedMessage id="telecom.line.with">
                            {msg => (
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={this.state.radioEng} onChange={this.handleChangeCheck} value="radioEng" name="radioEng" classes={{ root: classes.rootCheck }} />
                                    }
                                    label={msg}
                                    classes={{ label: classes.rootControlLabel }}
                                />
                            )}
                        </FormattedMessage>
                        <FormattedMessage id="telecom.line.without">
                            {msg => (
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={this.state.radioSaneng} onChange={this.handleChangeCheck} value="radioSaneng" name="radioSaneng" classes={{ root: classes.rootCheck }} />
                                    }
                                    label={msg}
                                    classes={{ label: classes.rootControlLabel }}
                                />
                            )}
                        </FormattedMessage>
                    </div>
                    }
                </div>
                <div className="telecom_sort_price" onClick={this.onClickSort}>
                    <FormattedMessage id="telecom.sort" /> <i className="fas fa-sort" ></i>
                </div>
                <div>
                    {card.length > 0 ? card : <div>  <Issoyoservice serviceName='internet' language={this.state.language} /></div>}
                </div>
                <div>
                {this.state.today_promo.length > 0 ?
                        <div>
                            <hr />
                            <div className='title'><FormattedMessage id='telecom.promo'/></div>
                            <div className="telecom_promo">
                                <div className="telecom_promo-container">
                                    {this.state.today_promo}
                                </div>
                            </div>
                        </div> : null}
                        </div>
                <ScrollUpButton ShowAtPosition={70} />
            </div>
        )
    }
}
Internet.propTypes = {
    auth: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    service: state.services,
});

export default connect(
    mapStateToProps,
    {}
)(withStyles(styles)(Internet));