import React, { Component } from 'react';
import { Footer } from './Footer.jsx';

export class Condition extends Component {
    render() {
        return (
            <React.Fragment>
                <div style={{ marginTop: "80px", paddingRight: "42px", paddingLeft: "42px", maxWidth: "1040px" }}>
                    <div className="signup_back"><i style={{color: '#593e96'}} className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                    <div>
                        <h1>Conditions générales d'utilisation</h1>
                        <p>Les présentes conditions d'utilisation ont pour objet de définir les modalités de mise à disposition et
                            d'utilisation du site www.issoyo.com (ci-après le « Site ») et les prestations fournies par
                l’intermédiaire du Site.</p>
                        <p>La poursuite de la navigation sur le Site vaut acceptation sans réserve de ces conditions d'utilisation.
            </p>
                        <p>Le Site se réserve le droit de modifier ces conditions d'utilisation à tout moment.</p>
                    </div>
                    <div>
                        <h2>Article 1 - Informations légales</h2>
                        <p>1.1 Éditeur (ci-après l'« Éditeur ») : ISSOYO, société par actions simplifiée (SAS) au capital de 25.000
                            euros, dont le siège social est situé au 18 Allée Julien Manceau 95580 Margency - France, RCS (Pontoise
                            838 876 787), Adresse de courrier électronique : mikael@issoyo.com, Directeur de la publication : Mikael
                Yitzhakov.</p>
                        <p>1.2 Hébergeur (ci-après l'« Hébergeur ») : Microsoft Ireland Operations Ltd (siège social : One Microsoft
                            Place, South County Business Park, Leopardstown, Dublin 18, D18 PS21, Irlande. Numéro d’enregistrement
                TVA FR00419423728</p>
                        <p>1.3 En qualité d'intermédiaire d'assurance au sens de l'article L. 512-5 du code des assurances, ISSOYO
                            est immatriculée auprès de l'Organisme pour le registre des Intermédiaires d'assurance (ORIAS) sous le
                numéro 18006105.</p>
                        <p>Pour toute information complémentaire, vous pouvez contacter :</p>
                        <p>L'ORIAS, 1 rue Jules Lefebvre – 75311 Paris Cedex 09, contact@orias.fr ;</p>
                    </div>
                    <div>
                        <h2>Article 2 - Accès au Site</h2>
                        <p>L'accès au Site et son utilisation sont réservés à un usage strictement personnel. Vous vous engagez à ne
                            pas utiliser le Site et les informations ou données qui y figurent à des fins commerciales, politiques,
                            publicitaires et pour toute forme de sollicitation commerciale et notamment l'envoi de courriers
                            électroniques non sollicités.
                            Il est précisé que tous matériels ou logiciels nécessaires à l'utilisation du Site restent exclusivement
                à votre charge.</p>
                    </div>
                    <div>
                        <h2>Article 3 - Contenu du Site</h2>
                        <p>Le nom de domaine ISSOYO.com est la propriété exclusive de l'Éditeur.</p>
                        <p>Par ailleurs, toutes autres marques, les photographies, textes, commentaires, illustrations, images
                            animées ou non, séquences vidéo, sons, ainsi que toutes les applications informatiques qui pourraient
                            être utilisées pour faire fonctionner le Site et plus généralement tous les éléments reproduits ou
                utilisés sur le Site sont protégés par les lois en vigueur au titre de la propriété intellectuelle.</p>
                        <p>Ils sont la propriété pleine et entière de l'Éditeur ou de ses partenaires. Toute reproduction,
                            représentation, utilisation ou adaptation, sous quelque forme que ce soit, de tout ou partie de ces
                            éléments, y compris les applications informatiques, sans l'accord préalable et écrit de l'Éditeur, sont
                            strictement interdites. Le fait pour l'Éditeur de ne pas engager de procédure dès la prise de
                            connaissance de ces utilisations non autorisées ne vaut pas acceptation desdites utilisations et
                renonciation aux poursuites.</p>
                    </div>
                    <div>
                        <h2>Article 4 - Gestion du Site</h2>
                        <p>Pour la bonne gestion du Site, l'Éditeur pourra à tout moment :</p>
                        <p>suspendre, interrompre ou limiter l'accès à tout ou partie du Site, réserver l'accès au Site, ou à
                certaines parties du Site, à une catégorie déterminée d'internaute ;</p>
                        <p>supprimer toute information pouvant en perturber le fonctionnement ou entrant en contravention avec les
                lois nationales ou internationales ;</p>
                        <p>suspendre le Site afin de procéder à des mises à jour.</p>
                    </div>
                    <div>
                        <h2>Article 5 - Conditions d'inscription et compte utilisateur</h2>
                        <p>Pour pouvoir utiliser le Site et profiter des offres qui y sont proposées, vous devrez vous inscrire et
                créer un compte utilisateur (ci-après le « Compte Utilisateur »).</p>
                        <p>Lors de l’ouverture du Compte Utilisateur, vous devrez communiquer des informations personnelles
                            relatives à votre identité telles que nom, prénom, adresse email et numéro de téléphone. A défaut,
                            l’ouverture de votre Compte Utilisateur ne sera pas possible. L’utilisation de vos données à caractère
                personnel est régie par l’article 13 des conditions générales d'utilisation.</p>
                        <p>Le Site et tout Compte Utilisateur sont exclusivement réservés aux utilisateurs qui doivent
                            impérativement être des personnes physiques majeures de plus de 18 ans ou des personnes physiques ayant
                            pouvoir pour représenter toute personne morale dûment immatriculée auprès du Registre du Commerce et des
                Sociétés compétent.</p>
                        <p>Dans le cas où vous ne remplirez pas les conditions prévues dans les conditions générales d'utilisation,
                            vous vous verrez interdire l’accès et l’utilisation du Site et du Compte Utilisateur. L'Editeur se
                réserve le droit de suspendre temporairement ou définitivement tout Compte Utilisateur.</p>
                        <p>Vous reconnaissez et acceptez de garder confidentiel l’identifiant et le mot de passe permettant un accès
                            à votre Compte Utilisateur. L’identifiant et le mot de passe sont personnels et ne doivent pas être
                            partagés ni transférés à une quelconque personne physique ou morale, sous quelque forme que ce soit,
                sans le consentement écrit et préalable de l'Editeur.</p>
                        <p>Vous êtes seul responsable de votre Compte Utilisateur et de l’utilisation qui en est faite.</p>
                    </div>
                    <div>
                        <h2>Article 6 - Les offres</h2>
                        <p>Les offres des fournisseurs partenaires de l'Editeur sont consultables sur le Site (ci-après les «
                            Services »). Les Offres sont régulièrement mises à jour et l'Editeur se réserve la possibilité de
                modifier la liste et le contenu des Offres à tout moment.</p>
                        <p>Chaque Offre contient une description des caractéristiques essentielles des services fournis et le prix
                HT ou TTC des services fournis.</p>
                    </div>
                    <div>
                        <h2>Article 7 - Relations entre les parties</h2>
                        <p>L'Editeur, les fournisseurs de l'Editeur, et vous-même sont des parties indépendantes, chacune agissant
                en son nom et pour son propre compte.</p>
                        <p>En utilisant le Site et en souscrivant aux Offres proposées, vous reconnaissez que l'Editeur est un
                            intermédiaire mettant en relation des clients et des fournisseurs et qu’il n'intervient qu'en qualité
                            d’intermédiaire lors de la souscription des Offres demandées. Ainsi il n’est pas partie auxdites Offres
                et ne sera pas partie aux contrats en découlant.</p>
                        <p>L'Editeur est partenaire de plusieurs plateformes d'affiliation le rémunérant (Awin, TimeOne et
                            Tradedoubler) dont l'ensemble des fournisseurs peut être trouvé aux adresses suivantes
                            awin.com/fr/editeurs/repertoire-annonceurs ;
                performance.timeonegroup.com/fr/editeurs/programmes-en-cours ; tradedoubler.com/en/about-us/</p>
                        <p>Les conditions générales d'utilisation ne créent aucun lien de subordination, société en participation,
                entreprise commune, de relations employeur/employé ou franchiseur/franchisé entre vous et l'Editeur.</p>
                        <p>Vous acceptez les Offres en toute indépendance.</p>
                    </div>
                    <div>
                        <h2>Article 8 - Responsabilités</h2>
                        <p>La responsabilité de l'Éditeur ne peut être engagée en cas de défaillance, panne, difficulté ou
                interruption de fonctionnement, empêchant l'accès au Site ou à une de ses fonctionnalités.</p>
                        <p>Le matériel de connexion au Site que vous utilisez est sous votre entière responsabilité. Vous devez
                            prendre toutes les mesures appropriées pour protéger votre matériel et vos propres données notamment
                            d'attaques virales par Internet. Vous êtes par ailleurs le seul responsable des sites et données que
                vous consultez.</p>
                        <p>L'Éditeur ne pourra être tenu responsable en cas de poursuites judiciaires à votre encontre : </p>
                        <ul>
                            <li>du fait de l'usage du Site ou de tout service accessible via Internet ;</li>
                            <li>du fait du non-respect par vous des présentes conditions d'utilisation.</li>
                        </ul>
                        <p>L'Éditeur n'est pas responsable des dommages causés à vous-même, à des tiers et/ou à votre équipement du fait de votre connexion ou de votre utilisation du Site et vous renoncez à toute action contre lui de ce fait.</p>
                        <p>Si l'Éditeur venait à faire l'objet d'une procédure amiable ou judiciaire à raison de votre utilisation du Site, il pourra se retourner contre vous pour obtenir indemnisation de tous les préjudices, sommes, condamnations et frais qui pourraient découler de cette procédure.</p>
                        <p>Enfin l'Éditeur n'intervient qu'en qualité de mandataire ou intermédiaire, et il ne peut être tenu responsable et ne fournit aucune garantie, même partiellement ou indirectement, des Offres proposées sur le Site, ou de toute inexécution ou mauvaise exécution de tout contrat de quelque nature qu'il soit qui aurait été conclu entre vous et des partenaires, par l'intermédiaire du Site ainsi que celle de l'Éditeur.</p>
                        <p>L’Éditeur n’exerce notamment pas de contrôle de manière systématique et n’assume aucune responsabilité́ en rapport avec :</p>
                        <ul>
                            <li>la qualité́, la conformité́ des Offres à la loi ou l’adéquation des Offres proposées sur le Site ;</li>
                            <li>la fiabilité́ et l’exactitude de toute information relative aux Offres.</li>
                        </ul>
                    </div>
                    <div>
                        <h2>Article 9 - Intermédiation d'assurance</h2>
                        <p>La validité des Offres proposées sur le Site et par les partenaires est subordonnée à l'exactitude des réponses fournies auxquelles vous vous engagez à répondre de façon loyale et honnête.</p>
                        <p>ISSOYO n’est pas soumise à une obligation contractuelle de travailler exclusivement avec une ou plusieurs entreprises d'assurance. Après avoir reçu une Offre par le Site et avant toute souscription par l'intermédiaire de l'Editeur, il vous appartient de lire l'ensemble des conditions générales et particulières du contrat proposé afin, notamment, d'en avoir une vision exhaustive et d'en vérifier la conformité avec votre situation sans qu'aucune responsabilité ne puisse être retenue à l'encontre du Site et de l'Éditeur à cet égard.</p>
                        <p>Dans le cas où le Site ne serait pas en mesure de présenter une Offre correspondante à votre profil ou en cas d'inexactitude portant sur le prix ou le service décrit par le Site ou encore en cas de mauvaise exécution ou d'inexécution du contrat conclu par vous avec les entreprises d'assurance partenaires du Site, aucune responsabilité ne pourrait être retenue à l'encontre du Site et de l'Éditeur.</p>
                    </div>
                    <div>
                        <h2>Article 10 - Réclamations et médiation (intermédiation d’assurance)</h2>
                        <p>Dans tous les cas de désaccord, ISSOYO se tient à votre disposition pour vous écouter et rechercher une solution.</p>
                        <p>Si le litige persiste, vous pouvez, à tout moment, après avoir eu recours à la démarche exposée ci-dessus, présenter une réclamation par lettre adressée à : ISSOYO, 18 Allée Julien Manceau, 95580 Margency - France, ou par message électronique à : mikael@issoyo.com.</p>
                        <p>Si après examen de votre réclamation, le désaccord n’a toujours pas été résolu, vous pouvez déposer une réclamation sur le site de La Médiation de l’assurance : www.mediation-assurance.org ou envoyer un courrier simple à La Médiation de l’Assurance, TSA 50110, 75441 PARIS Cedex 09. Cette médiation interviendra selon les modalités et dans les limites prévues par la Charte de la médiation de l’assurance (cette charte peut vous être adressée sur simple demande).</p>
                        <p>L’avis du médiateur de l’assurance ne lie pas les parties ; si l’assuré demeure insatisfait, il conserve la possibilité de saisir le tribunal compétent.</p>
                    </div>
                    <div>
                        <h2>Article 11 - Liens hypertextes</h2>
                        <p>L’Éditeur se réserve le droit de refuser et de retirer tous liens hypertextes mis en place par vous vers tout ou partie du Site.</p>
                        <p>Dans le cas où l'Éditeur ne demanderait pas le retrait de liens hypertextes mis en place par vous, cette liberté qui vous est accordée par l'Éditeur n'est dans tous les cas que spéciale, temporaire, et l'Éditeur pourra vous demander de les retirer à tout moment sur simple demande, sans obligation de justification à la charge de l'Éditeur.</p>
                        <p>Le Site peut faire l'objet de publicité. Toute information accessible via un lien vers d'autres sites n'est pas sous le contrôle de l'Éditeur qui décline toute responsabilité quant à leur contenu.</p>
                    </div>
                    <div>
                        <h2>Article 12 - Collecte de données</h2>
                        <p>L’Éditeur recueille aussi bien certaines informations nominatives vous concernant sur le Site que par l'intermédiaire du call center. La collecte et le traitement de ces informations personnelles sont conformes au règlement général sur la protection des données en vigueur.</p>
                        <p>Les informations personnelles sont recueillies aux fins de donner suite à vos demandes de devis ou de souscription et aux fins statistiques et publicitaires.</p>
                        <p>Conformément aux dispositions de la loi n° 78-17 du 6 janvier 1978 modifiée, relative à l'informatique, aux fichiers et aux libertés, vous disposez d'un droit d'accès, d'interrogation, de modification et de suppression des informations qui vous concernent, à exercer à tout moment auprès de l'Éditeur soit par courrier postal à l'adresse suivante : 18 allée Julien Manceau 95580 Margency - France, soit par courriel à l'adresse suivante : mikael@issoyo.com.</p>
                        <p>Pour des raisons de sécurité et pour éviter toute demande frauduleuse, cette demande devra être accompagnée d'un justificatif d'identité. Après traitement de la demande ce justificatif sera détruit.</p>
                        <p>Les informations recueillies peuvent être communiquées à des tiers liés à l'Éditeur par contrat. Si vous ne le souhaitez pas, vous pouvez envoyer un par courriel à l'adresse suivante : mikael@issoyo.com. Ces informations pourront être divulguées en application d'une loi, d'un règlement ou en vertu d'une décision d'une autorité réglementaire ou judiciaire compétente ou encore, si cela s'avère nécessaire, aux fins pour ISSOYO, de préserver ses droits et intérêts.</p>
                        <p>Lorsque certaines informations sont obligatoires pour accéder à des fonctionnalités spécifiques du Site, l'Éditeur indiquera ce caractère obligatoire au moment de la saisie des données.</p>
                        <p>Vous êtes susceptible de recevoir des offres commerciales de l'Éditeur ou de ses partenaires. Si vous ne le souhaitez pas, vous pouvez envoyer un courriel à l'adresse suivante : mikael@issoyo.com</p>
                        <p>Si, lors de la consultation du Site, vous accédez à des données à caractère personnel, vous devez vous abstenir de toute collecte, de toute utilisation non autorisée et de tout acte pouvant constituer une atteinte à la vie privée ou à la réputation des personnes. L'Éditeur décline toute responsabilité à cet égard.</p>
                        <p>Les utilisateurs reconnaissent que le service de la société ISSOYO inclut des formulaires web adossés à certaines pages webs au sein desquelles ces derniers peuvent être amenés à délivrer des données personnelles nominatives. Les utilisateurs du service reconnaissent que ces différentes données personnelles peuvent amener ISSOYO à les contacter par le biais de télé-conseillers afin de leur faciliter la souscription des offres de services proposées. Au-delà, ISSOYO rappelle à l’ensemble de ses utilisateurs que le gouvernement a mis en place depuis le 1er juin 2016 une liste d’opposition au démarchage téléphonique, gratuite pour les consommateurs et accessible via l’adresse www.bloctel.gouv.fr.</p>
                        <p>Les utilisateurs reconnaissent que les services de l’Editeur peuvent requérir de demander auprès de l’utilisateurs ses données personnelles nominative ainsi que notamment son numéro de téléphone et ce dans le but de les contacter. Au-delà, l’Editeur rappelle à tous les utilisateurs que le gouvernement a mis en place une liste d’opposition au démarchage téléphonique, gratuite pour les consommateurs et accessible via l’adresse www.bloctel.gouv.fr depuis le 1er juin 2016.</p>
                        <p>Le responsable du traitement des données utilisateurs collectées via le site www.issoyo.com est ISSOYO, société par actions simplifiée (SAS) au capital de 25.000 euros, dont le siège social est situé au 18 allée Julien Manceau 95580 – Margency, RCS (Pontoise 838 876 787).</p>
                    </div>
                    <div>
                        <h2>Article 13 - Cookies</h2>
                        <p>Le Site peut collecter automatiquement des informations standards. Toutes les informations collectées indirectement ne seront utilisées que pour suivre le volume, le type et la configuration du trafic utilisant le Site, pour en développer la conception et l'agencement et à d'autres fins administratives et de planification et plus généralement pour améliorer le service que nous vous offrons.</p>
                        <p>Néanmoins, vous pouvez paramétrer votre logiciel de navigation afin d’être informé préalablement de l’envoi d’un cookie par le Site et être ainsi en mesure de l’accepter ou de le refuser. Toutefois, le refus d’accepter un cookie est susceptible d’empêcher l’accès à certaines parties du Site.</p>
                        <p>Des sociétés tierces peuvent également présenter, après en avoir obtenu l'autorisation, des publicités détaillant les services proposés par ISSOYO. Ces sociétés sont susceptibles de placer des cookies et des actions tags (ou « simple pixel gif ») sur votre ordinateur afin de pouvoir mesurer l'efficacité de la publicité. Vous pouvez paramétrer votre logiciel de navigation selon les mêmes modalités ci-dessus définies.</p>
                    </div>
                    <div>
                        <h2>Article 14 - Photographies et représentation des produits</h2>
                        <p>Les photographies de produits, accompagnant leur description, ne sont pas contractuelles et n'engagent pas l'Éditeur.</p>
                    </div>
                    <div>
                        <h2>Article 15 - Loi applicable</h2>
                        <p>Les présentes conditions d'utilisation du Site sont régies par la loi française et soumises à la juridiction du ressort de la Cour d'appel de Paris, sous réserve d'une attribution de compétence spécifique découlant d'un texte de loi ou réglementaire particulier.</p>
                    </div>
                    <div>
                        <h2>Article 16 - Contactez-nous</h2>
                        <p>Pour toute question, information sur les produits présentés sur le Site, ou concernant le Site lui-même, vous pouvez laisser un message à l'adresse suivante : mikael@issoyo.com</p>
                    </div>
                    <div>
                        <h2>Mise à jour</h2>
                        <p>
                            Les présentes Conditions Générales de Vente ont été mises à jour le 23 novembre 2018.
            </p>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}

export default (Condition);