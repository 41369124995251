import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from "prop-types";
import LineMob from "./LineMob";
import LineMobUk from "./LineMobUk";
import Line from "./Line";
import Prepaid from "./Prepaid";
import Mobile from "./Mobile";
import { connect } from "react-redux";
import { getPhoneList } from "../../../../actions/serviceActions.js";
import { withStyles } from '@material-ui/core/styles';
import { promoList } from './promoList.js';
import ScrollUpButton from "react-scroll-up-button";
import { bouygues } from '../../../../assets/img/providers/bouygues.png';
import Button from '@material-ui/core/Button';
import Issoyo_logo from '../../../../assets/img/fav_icon32@2x.png';
import Issoyo_logo_blanc from '../../../../assets/img/issoyo_blanc.png';
import Issoyoservice from '../Issoyoservice/Issoyoservice.jsx';
import { countryAvailable } from '../listCountry.js';


require("./Telecom.scss");

const styles = {
    root: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    selectMenu: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    input: {
        borderColor: '#3e45948a !important',
    },
};

class Telecom extends React.Component {
    constructor() {
        super();
        this.state = {
            forfait: '',
            forf_comp: null,
            colorPrepaid: { color: '#7f3fce', backgroundColor: 'white' },
            colorLine: { color: '#7f3fce', backgroundColor: 'white' },
            colorLinemob: { color: '#7f3fce', backgroundColor: 'white' },
            colorMobile: { color: '#7f3fce', backgroundColor: 'white' },
            disableCard: "",
            disableMobile: "",
            today_promo: [],
            colorIssoyoenergy: { color: '#7f3fce', backgroundColor: 'white' },
            logo: Issoyo_logo,
            user_location: '',
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleClickAccount = this.handleClickAccount.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.language !== this.props.language) {
            this.setState({
                forfait: '',
                forf_comp: null,
                colorPrepaid: { color: '#7f3fce', backgroundColor: 'white' },
                colorLine: { color: '#7f3fce', backgroundColor: 'white' },
                colorLinemob: { color: '#7f3fce', backgroundColor: 'white' },
                colorMobile: { color: '#7f3fce', backgroundColor: 'white' },
                colorIssoyoenergy: { color: '#7f3fce', backgroundColor: 'white' },
            })
        }
    }

    componentDidMount() {
        if (!this.props.auth.user.location) {
            this.props.history.push('/services');
        } else {
            this.setState({ user_location: this.props.auth.user.location})
        }
        window.scrollTo(0, 0)
        if (this.props.auth.user.location === 'US') {
            this.setState({ disableCard: "service_disabled", disableMobile: "service_disabled" });
        } else if (this.props.auth.user.location === 'GB') {
            this.setState({ disableMobile: "service_disabled" });
        }
        const country = this.props.auth.user.location;
        var promo_list = promoList.map((data, key) => {
            var call = data.call.toString() + "h";
            if (data.call === 11) {
                call = "unlimited"
            }
            var date = new Date(Date.parse(data.date_fin));
            const now_date = new Date();
            console.log(data.country)
            console.log(country)
            if (data.country === country) {
                if (now_date >= new Date(Date.parse(data.date_debut)) && now_date <= date) {
                    return (
                        <div key={key}>
                            <div className="telecom_promo-container">
                                <div className="card-telecom">
                                    <div style={{ position: 'absolute' }}>
                                        <div className="ribbon">
                                            <div><FormattedMessage id="telecom.promo.until" defaultMessage="Until " /> {("0" + date.getDate()).slice(-2)} / {("0" + (date.getMonth() + 1)).slice(-2)}</div>
                                        </div>
                                    </div>
                                    <div className="card_name-com">{data.name}</div>
                                    <div className="row card-display-promo">
                                        <div className="card-display-details-promo">
                                            <div className="telecom_operator-logo promo"><img className="telecom_operator-logo--img img-promo" src={data.pics} alt=''></img> <span className="card_name-com-m">{data.name}</span></div>
                                            <div className="telecom_operator-details-wrap">
                                                <div className="telecom_operator-details" >{data.detail_1}</div>
                                                <div className="telecom_operator-details" >{data.detail_2}</div>
                                                <div className="telecom_operator-details" >{data.detail_3}</div>
                                            </div>
                                        </div>
                                        <hr className="mobile-hr"></hr>
                                        <div className="card_price-wrap">
                                            <div className="card-price"><div className="card-old-price"><s>{data.old_price}</s></div>{data.price}<span className="card-unit">€ /<FormattedMessage id="telecom.linemob.month" /></span><p className="card-eng-m">{data.eng}</p></div>
                                            <div className="card-button">
                                                <Button variant="contained" color="primary" className="button" onClick={() => window.open(data.trackingLinkk, "_blank")}>
                                                    <FormattedMessage id="telecom.get" />
                                                </Button>
                                            </div>
                                            <div className="card-eng-d">{data.eng}</div>
                                        </div>
                                    </div>
                                    <div className="card-sim"><div className="col telecom_icon"><i className="fas fa-sim-card" style={{ color: 'rgba(68, 76, 165, 0.85)' }}></i> <FormattedMessage id="telecom.sim" /> {data.sim} € </div><div className="col telecom_icon"><i className="fas fa-phone" style={{ color: 'rgba(68, 76, 165, 0.85)' }}></i> <FormattedMessage id="telecom.call" /> {call}</div><div className="col telecom_icon"><i className="fas fa-signal" style={{ color: 'rgba(68, 76, 165, 0.85)' }}></i> Internet {data.internet}</div></div>
                                </div>
                            </div>
                        </div>
                    )
                }
            } else {
                return null;
            }
        })
        promo_list = promo_list.filter(function (data) {
            if (data === null) {
                return false;
            } else {
                return data;
            }
        })
        this.setState({ today_promo: promo_list })
    }

    handleClickAccount() {
        this.props.history.push('/login');
    }

    handleClick(value) {
        if (value === 'linemob') {
            if (this.props.auth.user.location === 'GB') {
                this.setState({
                    forfait: value,
                    forf_comp: <LineMobUk handleClickAccount={() => this.handleClickAccount()} />,
                    colorPrepaid: { color: '#7f3fce', backgroundColor: 'white' },
                    colorLine: { color: '#7f3fce', backgroundColor: 'white' },
                    colorLinemob: { color: "white", backgroundColor: '#7f3fce' },
                    colorMobile: { color: '#7f3fce', backgroundColor: 'white' },
                    colorIssoyoenergy: { color: '#7f3fce', backgroundColor: 'white' },
                    logo: Issoyo_logo
                })
            } else {
                this.setState({
                    forfait: value,
                    forf_comp: <LineMob handleClickAccount={() => this.handleClickAccount()} />,
                    colorPrepaid: { color: '#7f3fce', backgroundColor: 'white' },
                    colorLine: { color: '#7f3fce', backgroundColor: 'white' },
                    colorLinemob: { color: "white", backgroundColor: '#7f3fce' },
                    colorMobile: { color: '#7f3fce', backgroundColor: 'white' },
                    colorIssoyoenergy: { color: '#7f3fce', backgroundColor: 'white' },
                    logo: Issoyo_logo
                })
            }
        } else if (value === 'linephone') {
            this.setState({
                forfait: value,
                forf_comp: <Line handleClickAccount={() => this.handleClickAccount()} />,
                colorPrepaid: { color: '#7f3fce', backgroundColor: 'white' },
                colorLine: { color: "white", backgroundColor: '#7f3fce' },
                colorLinemob: { color: '#7f3fce', backgroundColor: 'white' },
                colorMobile: { color: '#7f3fce', backgroundColor: 'white' },
                colorIssoyoenergy: { color: '#7f3fce', backgroundColor: 'white' },
                logo: Issoyo_logo
            })
        } else if (value === 'prepaid') {
            this.setState({
                forfait: value,
                forf_comp: <Prepaid handleClickAccount={() => this.handleClickAccount()} />,
                colorPrepaid: { color: "white", backgroundColor: '#7f3fce' },
                colorLine: { color: '#7f3fce', backgroundColor: 'white' },
                colorLinemob: { color: '#7f3fce', backgroundColor: 'white' },
                colorMobile: { color: '#7f3fce', backgroundColor: 'white' },
                colorIssoyoenergy: { color: '#7f3fce', backgroundColor: 'white' },
                logo: Issoyo_logo
            })
        } else if (value === 'mobile') {
            this.setState({
                forfait: value,
                forf_comp: <Mobile handleClickAccount={() => this.handleClickAccount()} />,
                colorPrepaid: { color: "#7f3fce", backgroundColor: 'white' },
                colorLine: { color: '#7f3fce', backgroundColor: 'white' },
                colorLinemob: { color: '#7f3fce', backgroundColor: 'white' },
                colorMobile: { color: 'white', backgroundColor: '#7f3fce' },
                colorIssoyoenergy: { color: '#7f3fce', backgroundColor: 'white' },
                logo: Issoyo_logo
            })
        } else if (value === 'issoyotelecom') {
            this.setState({
                forf_comp: <Issoyoservice  serviceName='telecom' handleClickAccount={() => this.handleClickAccount()} language={this.state.language} />,
                colorPrepaid: { color: "#7f3fce", backgroundColor: 'white' },
                colorLine: { color: '#7f3fce', backgroundColor: 'white' },
                colorLinemob: { color: '#7f3fce', backgroundColor: 'white' },
                colorMobile: { color: '#7f3fce', backgroundColor: 'white' },
                colorIssoyoenergy: { color: 'white', backgroundColor: '#7f3fce' },
                logo: Issoyo_logo_blanc
            });
        }
    }

    render() {
        if (countryAvailable[0].telecom.indexOf(this.state.user_location.toLowerCase()) < 0) {
            return (
                <div className="Telecom">
                    <div className="telecom_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                    <div className="text" style={{ textAlign: "center" }}><Issoyoservice serviceName='telecom'handleClickAccount={() => this.handleClickAccount()} language={this.state.language} /></div></div>
            
            )
        } else {
        console.log(this.state.today_promo);

        return (
            <React.Fragment>
                <div className="Telecom">
                    <div className="telecom_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                    <div className="col-12 title">
                        <FormattedMessage id="service.telecom.title" defaultMessage="A mobile line ?" />
                    </div>
                    <div className="flex_chevron"><i className="fal fa-chevron-left"></i></div>
                    <div className="flex_chevron"><i className="fal fa-chevron-right"></i></div>
                    <div className="container_type">
                        <div className="container_flex_type">
                            <div className="telecom_forfait">
                                <div className="flex_item_type">
                                    <div className={`box ${this.state.disableCard}`} onClick={() => this.handleClick('prepaid')} style={this.state.colorPrepaid}>
                                        <div className="col-12 no-border-padding">
                                            <div className="logo-box"><i className="fal fa-sim-card"></i></div>
                                        </div>
                                        <div className="col-12 no-border-padding"><div className="text-box" style={{ color: this.state.colorPrepaid.color }}>
                                            <FormattedMessage id="service.telecom.prepaid" defaultMessage="Prepaid" />
                                        </div></div>
                                    </div>
                                </div>
                                <div className="flex_item_type">
                                    <div className="box" onClick={() => this.handleClick('linephone')} style={this.state.colorLine}>
                                        <div className="col-12 no-border-padding">
                                            <div className="logo-box"><i className="fal fa-file-alt"></i></div>
                                        </div>
                                        <div className="col-12 no-border-padding"><div className="text-box" style={{ color: this.state.colorLine.color }}>
                                            <FormattedMessage id="service.telecom.line" defaultMessage="Line phone" />
                                        </div></div>
                                    </div>
                                </div>
                                <div className="flex_item_type">
                                    <div className={`box ${this.state.disableCard}`} onClick={() => this.handleClick('linemob')} style={this.state.colorLinemob}>
                                        <div className="col-12 no-border-padding">
                                            <div className="logo-box"><i className="fal fa-file-alt"></i> + <i className="fal fa-mobile-android-alt"></i></div>
                                        </div>
                                        <div className="col-12 no-border-padding"><div className="text-box linemob" style={{ color: this.state.colorLinemob }}>
                                            <FormattedMessage id="service.telecom.linemob" defaultMessage="Line phone + Mobile" />
                                        </div></div>
                                    </div>
                                </div>
                                <div className="flex_item_type">
                                    <div className={`box ${this.state.disableMobile}`} onClick={() => this.handleClick('mobile')} style={this.state.colorMobile}>
                                        <div className="col-12 no-border-padding">
                                            <div className="logo-box"><i className="fal fa-mobile-android-alt"></i></div>
                                        </div>
                                        <div className="col-12 no-border-padding"><div className="text-box" style={{ color: this.state.colorMobile }}>
                                            <FormattedMessage id="service.telecom.mobile" defaultMessage="Mobile" />
                                        </div></div>
                                    </div>
                                </div>
                                <div className="flex_item_type">
                                    <div className="box" onClick={() => this.handleClick('issoyotelecom')} style={this.state.colorIssoyoenergy}>
                                        <div className="col-12 no-border-padding">
                                            <div className="logo-box"><img src={this.state.logo} style={{ width: '40px' }}></img></div>
                                        </div>
                                        <div className="col-12 no-border-padding"><div className="text text-box" style={{ color: this.state.colorIssoyoenergy.color, textAlign: 'center' }}>
                                            Issoyo
                                    </div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.forf_comp}
                    {this.state.today_promo.length > 0 ?
                        <div>
                            <hr />
                            <div className='title'><FormattedMessage id='telecom.promo' defaultMessage="Today promotion" /></div>
                            <div className="telecom_promo">
                                <div className="telecom_promo-container">
                                    {this.state.today_promo}
                                </div>
                            </div>
                        </div> : null}

                </div>
                <ScrollUpButton ShowAtPosition={70} />
            </React.Fragment>
        );
                    }
    }
}

Telecom.propTypes = {
    auth: PropTypes.object.isRequired,
    getPhoneList: PropTypes.func.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    service: state.services,
});

export default connect(
    mapStateToProps, { getPhoneList }
)(withStyles(styles)(Telecom));