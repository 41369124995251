 const phoneList = [
    {
        'name': 'APPLE',
        'color': ['Noir', 'Gris', 'Or Rose', 'Argent', 'Or', 'Bleu', 'Jaune'],
        'color_english': ['Black', 'Grey', 'Pink Gold', 'Silver', 'Gold '],
        'size': ['32Go', '64Go', '128Go', '256Go', '512Go'],
        'size_bouygue': ['32 Go', '64 Go', '128 Go', '256 Go', '512 Go'],
        'size_english': ['32GB', '64GB', '128GB', '256GB', '512GB'],
        'type': ['iPhone 6s', 'iPhone 7', 'iPhone 8', 'iPhone X', 'iPhone XR'] 
    },
    {
        'name': 'SAMSUNG',
        'color': ['Noir ', 'Gris ', 'Bleu ', 'Rouge ', 'Or Rose ', 'Argent ', 'Or '],
        'color_english': ['Black', 'Grey', 'Pink Gold', 'Silver', 'Gold ', 'Blue', 'Red'],
        'size': ['16Go', '32Go', '64Go', '128Go', '256Go', '512Go'],
        'size_bouygue': ['16 Go', '32 Go', '64 Go', '128 Go', '256 Go', '512 Go'],
        'size_english': ['32GB', '64GB', '128GB', '256GB', '512GB'],
    },
    {
        'name': 'HUAWEI',
        'color': ['Noir ', 'Bleu ', 'Rose ', 'Or '],
        'color_english': ['Black', 'Blue', 'Rose', 'Silver', 'Gold '],
        'size': ['16Go', '32Go', '64Go', '128Go'],
        'size_bouygue': ['16 Go', '32 Go', '64 Go', '128 Go'],
        'size_english': ['32GB', '64GB', '128GB', '16GB'],
    },
]
export {phoneList}