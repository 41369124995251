import { GET_LOCALUSER, RESET_LOCALUSER } from "../actions/types";

const initialState = {
    localUser: {
        email: '',
        password: '',
        repassword: '',
        firstName: '',
        name: '',
        nickname: '',
        phoneNumber: '',
        title: 'none',
    },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_LOCALUSER: 
      return {
        ...state,
        localUser: action.payload,
      }
      case RESET_LOCALUSER:
      return initialState;
    default:
      return state;
  }
}