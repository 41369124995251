import React, { Component } from 'react'
import PropTypes from "prop-types";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
import { checkEmail, updateEmail, sendConfirmation } from "../../../actions/authActions";
import logo_issoyo from "../../../assets/img/issoyo_logo_trans.png";
import { withAlert } from 'react-alert'


require("./RegisterPart4.scss");


class RegisterPart4 extends Component {
    constructor() {
        super();
        this.state = {
            email: '',
            errorEmail: false,
            messageMail: [],
            resendEmail: 'resend_email',
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleClickUpdate = this.handleClickUpdate.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.checkEmail = this.checkEmail.bind(this);
        this.clickDisplay = this.clickDisplay.bind(this);
    }

    componentDidMount() {
        // If logged in and user navigates to Register page, should redirect them to dashboard
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/home");
        }
        this.setState({ email: this.props.auth.user.email })
    }

    checkEmail(email) {
        var re = /\S+@\S+\.\S+/;
        if (re.test(email)) {
            this.setState({ errorEmail: false });
        }
        else {
            this.setState({
                errorEmail: true,
                messageMail: <FormattedMessage id="registerPart4.error.email" defaultMessage="Please enter valid email" />
            });
        }
    }

    clickDisplay(event) {
        if (this.state.resendEmail === 'resend_email') {
            this.setState({ resendEmail: 'resend_email--appear' });
        } else {
            this.setState({ resendEmail: 'resend_email' })
        }
    }

    handleClickUpdate(event) {
        if (this.state.email === '' || this.state.errorEmail === true) {
            this.setState({
                errorEmail: true,
                messageMail: <FormattedMessage id="registerPart4.error.email" defaultMessage="Please enter valid email" />
            });
        } else {
            this.setState({ errorEmail: false });
            this.props.alert.show('Confirmation email sent', { type: 'info' });
            this.props.updateEmail({ user: this.props.auth.user, change: { email: this.state.email } });
        }
    }

    handleClick(event) {
        this.props.alert.show('Confirmation email sent', { type: 'info' });
        this.props.sendConfirmation(this.props.auth);
    }

    handleChange(event) {
        this.checkEmail(event.target.value);
        this.setState({ [event.target.name]: event.target.value });
    }

    render() {
        return (
            <React.Fragment>
                <div className='signup4'>
                    <div className='activate_account'>
                        <div className='col-md-4 logo'>
                            <img className="logo-img" src={logo_issoyo} alt=''></img>
                        </div>
                        <div className="col-md-8 content-check">
                            <div className="title">
                                <div style={{textAlign: "left"}}>
                                    <FormattedMessage id="registerPart4.title" defaultMessage="Activate your account" />
                                </div>
                            </div>
                            <div className="text">
                                <FormattedMessage id="registerPart4.text" defaultMessage="An activation link was sent to " />
                                <div><b>{this.props.auth.user.email}</b></div>
                            </div>
                            <div className='recover'>
                                <div className='recover-anch' style={{ cursor: 'pointer' }} onClick={this.clickDisplay}>
                                    <i class="fas fa-chevron-right"></i><FormattedMessage id="registerPart4.recover" defaultMessage=" Didn't get email ?" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={this.state.resendEmail}>
                        <div className="changemail">
                            <FormattedMessage id="registerPart4.resend" defaultMessage="Send the link again to" />
                        </div>
                        <div className="input-mail">
                            <TextField
                                required
                                value={this.state.email}
                                id="outlined-email-input"
                                label="Email"
                                error={this.state.errorEmail}
                                className="input-mail"
                                type="email"
                                name="email"
                                autoComplete="email"
                                margin="normal"
                                variant="outlined"
                                style={{ color: '#954ECC' }}
                                onChange={this.handleChange}
                            />
                            <div className="error" style={this.state.errorEmail ? {} : { display: 'none' }}>{this.state.messageMail}</div>
                        </div>
                        <div className="send-newmail">
                            <Button variant="contained" color="primary" className="button" onClick={this.handleClickUpdate}>
                                <FormattedMessage id="registerPart4.button.send" defaultMessage="Send" />
                            </Button>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}

RegisterPart4.propTypes = {
    checkEmail: PropTypes.func.isRequired,
    updateEmail: PropTypes.func.isRequired,
    sendConfirmation: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    localUser: state.localData.localUser,
    errors: state.auth.error
});


export default connect(
    mapStateToProps,
    { checkEmail, updateEmail, sendConfirmation }
)(withAlert(RegisterPart4));
