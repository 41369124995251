import {
  SET_CURRENT_USER,
  SET_REQUEST_ID,
  SET_SUCCESS_VERIFY,
  GET_ERRORS,
} from "../actions/types";

const initialState = {
  isAuthenticated: false,
  requestid: '',
  user: {},
  profile: {},
  loading: false,
  successverify: false,
  error: {},
};

export default function (state = initialState, action) {

  switch (action.type) {
    case SET_SUCCESS_VERIFY:
      return {
        ...state,
        error: {},
        successverify: action.payload
      }
    case SET_REQUEST_ID:
      return {
        ...state,
        error: {},
        requestid: action.payload
      }
    case SET_CURRENT_USER:
      return {
        ...state,
        error: {},
        isAuthenticated: action.payload.confirmed,
        user: action.payload
      };
    case GET_ERRORS:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}