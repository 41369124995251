import React, { Component } from 'react'
import ButtonToService from '../../ButtonToService/ButtonToService';
import issoyoimmo from '../../../../assets/img/providers/issoyo_logo.png';
import { FormattedMessage } from 'react-intl';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from 'react-responsive-modal';
import Issoyoservicemodal from './Issoyoservicemodal.jsx';

require("./Issoyoservice.scss")

class Issoyoservice extends Component {
    constructor() {
        super();
        this.state = {
            style: {
                modal: {
                    'maxWidth': '800px',
                    'width': '100%',
                    'color': '#3f2751',
                    'zIndex': '10000 !important',

                },
            },
            open: false,
        }
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentWillMount() {
        if (window.matchMedia("(min-width: 436px)").matches) {
            /* The viewport is at least 400 pixels wide */
            this.setState({
                style: {
                    modal: {
                        'maxWidth': '800px',
                        'width': '100%',
                        'color': '#3f2751',
                        'zIndex': '10000 !important',
                    },
                }
            });
        } else {
            /* The viewport is less than 400 pixels wide */
            this.setState({
                style: {
                    modal: {
                        'height': '100%',
                        'left': '0',
                        'position': 'fixed',
                        'top': '0',
                        'width': '100%',
                        'color': '#3f2751',
                        'zIndex': '10000 !important',

                    },
                }
            });
        }
    }

    onOpenModal() {
        this.setState({ open: true});
    };

    onClose() {
        this.setState({ open: false });
    };

    render() {
        return (
            <div>
                <div className="carLocation_description">
                    <div className="carLocation_provider_logo">
                        <img className="carLocation_provider-img" src={issoyoimmo} alt='' />
                    </div>
                    <div className="title carLocation_title">
                        <FormattedMessage id="transport.issoyo.dontfind" />
                    </div>
                    <div className="text">
                        <FormattedMessage id={`${this.props.serviceName}.issoyo.service`} />
                        <p><FormattedMessage id="transport.issoyo.service.matter"/></p>
                    </div>
                </div>
                <ButtonToService issoyoService="true" onOpenModal={() => this.onOpenModal()}/>
                <Modal open={this.state.open} onClose={this.onClose} center styles={this.state.style} >
                    <Issoyoservicemodal serviceName={this.props.serviceName} onClose={this.onClose} language={this.props.language}></Issoyoservicemodal>
                </Modal>
            </div>
        )
    }
}

Issoyoservice.propTypes = {
    auth: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    service: state.services,
});

export default connect(
    mapStateToProps,
    {}
)(Issoyoservice);