import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser, reSendVerify } from "../../../actions/authActions.js";
import IssoyoNexmo from './IssoyoNexmo.jsx';
import { FormattedMessage } from 'react-intl';
import { withAlert } from 'react-alert'
import Divider from '@material-ui/core/Divider';

require("./RegisterPart3.scss");

class RegisterPart3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isButtonDisabled: true,
            verify: false,
            cheatcode: false,
        };
        this.userRequest = '';
        this.onSubmitRegister = this.onSubmitRegister.bind(this);
        this.onSubmitMail = this.onSubmitMail.bind(this);
        this.hidephone = "****** " + this.props.localUser.phoneNumber.slice(this.props.localUser.phoneNumber.length - 2);
        this.sendCode = this.sendCode.bind(this);

    }

    componentDidMount() {
        // If logged in and user navigates to Register page, should redirect them to dashboard
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/home");
        }
        setTimeout(() => this.setState({ isButtonDisabled: false }), 30000)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors.status && this.state.cheatcode === false) {
        } else if (nextProps.auth.user.confirmed === false) {
            this.props.alert.show('Confirmation email sent', { type: 'info' });
            this.props.history.push('/signup3');
        } else if (nextProps.auth.user.confirmed === true) {
            this.props.alert.show('Account verified', { type: 'success' });
            this.props.history.push('/signup3');
        } else if (nextProps.auth.successverify || (this.state.cheatcode === true)) {
            this.onSubmitRegister();
        } else if (nextProps.auth.requestid) {
        }
    }

    sendCode() {
        const userData = { number: this.state.phoneNumber, request_id: this.props.auth.requestid };
        this.props.reSendVerify(userData);
        setTimeout(() => this.setState({ isButtonDisabled: true }), 30000)
    }

    buildUserRequest() {
        this.userRequest = {
            name: this.props.localUser.name,
            firstname: this.props.localUser.firstName,
            nickname: this.props.localUser.nickname,
            email: this.props.localUser.email,
            password: this.props.localUser.password,
            password2: this.props.localUser.repassword,
            phone: this.props.localUser.phoneNumber,
            title: this.props.localUser.title,
            confirmed: false,
        }
        return this.userRequest;
    }

    onSubmitRegister() {
        this.userRequest = this.buildUserRequest();
        this.props.registerUser(this.userRequest, this.props.history);
    }

    onSubmitMail() {
        this.userRequest = this.buildUserRequest();
        this.props.registerUser(this.userRequest, this.props.history);
    }

    render() {
        return (
            <React.Fragment>
                    <div className="Signup3">
                        <div className="signup_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                        <div className="col-12 title"><div>
                            <FormattedMessage id="registerPart3.title" defaultMessage="Enter your code validation" />
                        </div></div>
                        <div className="col-8 subtitle"><div className="text">
                            <FormattedMessage id="registerPart3.message" defaultMessage="We have sent you a confirmation code at" />
                            {this.hidephone}</div></div>
                        <IssoyoNexmo />
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{ width: "75%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <div className="divider-reverify">
                            <Divider variant="middle" />
                        </div>
                        <div className="col-8 subtitle"><div className="text"></div>
                            <div style={{ textAlign: "center", cursor: 'pointer', color: 'rgba(68, 76, 165, 0.85)9c'}} onClick={this.onSubmitMail}><u><FormattedMessage id="registerPart3.codeNotReceived" defaultMessage="You didn't received code ?" /></u></div>
                        </div>
                    </div>
            </React.Fragment>
        )
    }
}
RegisterPart3.propTypes = {
    registerUser: PropTypes.func.isRequired,
    reSendVerify: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    localUser: state.localData.localUser,
    errors: state.auth.error
});


export default connect(
    mapStateToProps,
    { registerUser, reSendVerify }
)(withRouter(withAlert(RegisterPart3)));
