import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPhoneList } from "../../../../actions/serviceActions.js";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { forfaitList } from './forfait.js';
import Modal from 'react-responsive-modal';


const styles = {
    root: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },
    selectMenu: {
        color: 'rgba(68, 76, 165, 0.85)',
        fontFamily: "'Nunito', sans-serif !important",
    },

    input: {
        borderColor: '#3e45948a !important',
    },
};

class Prepaid extends Component {
    constructor() {
        super();
        this.state = {
            operator: [],
            operator_selected: 'none',
            operator_info: [],
            price_value: 0,
            displayOperator: 'operator',
            displayInfo: 'telecom',
            displayEng: 'telecom_operator-checkbox',
            zone: 0,
            offer : '4',
            open: false,
            price_1p: '0',
            style: {
                modal: {
                    'maxWidth': '700px',
                    'width': '100%',
                    'color': '#3f2751',
                    'textAlign': 'justify'
                },
            },
            countryTelecom: [],
            test_card: [],
        }
        this.handleClick = this.handleClick.bind(this);
        this.handleChangePrice = this.handleChangePrice.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.buildCard = this.buildCard.bind(this);

    }

    componentWillMount() {
        var country = this.props.auth.user.location;
        var tmp = forfaitList.filter(function (data) {
            if (data.country.toLowerCase() === country.toLowerCase()) {
                return true;
            }
            return false;
        })
        const op = forfaitList.filter(function (data) {
            if (data.type === 'prepaid') {
                return true
            }
            return false;
        })
        if (op.length <= 1) {
            this.setState({ operator: op })
        } else {
            this.setState({ operator: op })
        }
        this.setState({ countryTelecom: tmp })
    }
    
    onOpenModal() {
        this.setState({ open: true });
    };

    onCloseModal() {
        this.setState({ open: false });
    };

    handleChangePrice(e) {
        if (e.target.name === 'zone') {
            this.setState({ [e.target.name]: e.target.value });
        } else if (e.target.name === 'offer') {
            if (e.target.value === '10tp') {
            this.setState({ [e.target.name]: e.target.value, price_1p: '10'});                
            } else {
            this.setState({ [e.target.name]: e.target.value, price_1p: e.target.value });            
            }
        } else {
            this.setState({ [e.target.name]: e.target.value, displayOperator: "operator--appear" }, () => this.getOperator());
        }
    }

    handleClick(link) {
        if (!this.props.auth.isAuthenticated){
            this.props.handleClickAccount();
        } else {
            window.open(link, "_blank");
        }
    }

    buildCard(){
        const zone = this.state.zone;
        const { classes } = this.props;
        const country = this.props.auth.user.location
        const filter_country = this.state.operator.filter(function(data) {
            if (data.country === country){
                return true;
            }
            return false;
        })
        return filter_country.map((data, key) => {
            if (data.operator === '1Pmobile'){
                return (
                    <div key={key}>
                                    <div className="card-telecom">
                                        <div className="row card-display">
                                            <div className="telecom_operator-logo"><img className="telecom_operator-logo--img" src={data.pics} alt=''></img> <span className="card_name-m">{data.operator}</span></div>
                                            <div className="telecom_operator-details-wrap-prep">
                                                <div className="telecom_operator-details" ><i className="fas fa-phone-volume" style={{marginLeft: '6px', fontWeight: '800', color: 'rgba(68, 76, 165, 0.85)'}}></i><span> <FormattedMessage id="telecom.prepaid.incall" defaultMessage="Incall:"/> {data.tarif_incall[0]} p/min</span></div>
                                                <div className="telecom_operator-details" ><i className="fas fa-sms" style={{marginLeft: '3px', fontWeight: '800', color: 'rgba(68, 76, 165, 0.85)'}}></i><span> <FormattedMessage id="telecom.prepaid.sms" defaultMessage="SMS:"/> {data.tarif_text[zone]} p/SMS</span></div>
                                                <div className="telecom_operator-details" ><i className="fas fa-signal" style={{color: 'rgba(68, 76, 165, 0.85)', fontWeight: '800'}}></i><span> Internet: {data.tarif_internet[0]} p/Mb</span></div>
                                            </div>
                                            <div className="card_zone">
                                            <FormattedMessage id="telecom.prepaid.offers" defaultMessage="Offer">
                                            {msg => (
                                                <TextField
                                                    id="outlined-select-currency"
                                                    select
                                                    label={msg}
                                                    className="card_zone-select"
                                                    value={this.state.offer}
                                                    name="offer"
                                                    onChange={this.handleChangePrice}
                                                    SelectProps={{
                                                        MenuProps: {
                                                            className: classes.menu,
                                                        },
                                                    }}
                                                    InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                                    margin="normal"
                                                    variant="outlined"
                                                >
                                                    <MenuItem disabled value={'4'}><FormattedMessage id="telecom.prepaid.select.offer" defaultMessage="Select offer"/></MenuItem>
                                                    <MenuItem value={'10tp'} classes={{ root: classes.root }}>SIM + £10 top-up</MenuItem>
                                                    <MenuItem value={'6'} classes={{ root: classes.root }}>1GB data</MenuItem>
                                                    <MenuItem value={'10'} classes={{ root: classes.root }}>2GB data</MenuItem>
                                                    <MenuItem value={'15'} classes={{ root: classes.root }}>4GB data</MenuItem>
                                                    <MenuItem value={'25'} classes={{ root: classes.root }}>10GB data</MenuItem>
                                                </TextField>
                                                )}
                                                </FormattedMessage>
                                            </div>
                                            <div className="card_price-wrap">
                                                <div className="card-price prepaid_price">{this.state.price_1p}<span className="card-unit"> £</span><p className="card-eng-m">{data.trad_eng}</p></div>
                                                <div className="card-button">
                                                    <Button variant="contained" color="primary" className="button" onClick={() => this.handleClick(data.trackingLink)}>
                                                        <FormattedMessage id="telecom.get"/>
                                                </Button>
                                                </div>
                                                <div className="card-eng-d">{data.trad_eng}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                )
            } else if (data.detail.length === 0) {
                return (
                        <div key={key}>
                                    <div className="card-telecom">
                                        <div className="row card-display">
                                            <div className="telecom_operator-logo"><img className="telecom_operator-logo--img" src={data.pics} alt=''></img> <span className="card_name-m">{data.operator}</span></div>
                                            <div className="telecom_operator-details-wrap-prep">
                                                <div className="telecom_operator-details" ><i className="fas fa-phone" style={{color: 'rgba(68, 76, 165, 0.85)', fontWeight: '800'}}/><span> <FormattedMessage id="telecom.prepaid.outcall" defaultMessage="Outcall:"/> {data.tarif_outcall[zone]}€ /min</span></div>
                                                <div className="telecom_operator-details" ><i className="fas fa-phone-volume" style={{marginLeft: '6px', fontWeight: '800', color: 'rgba(68, 76, 165, 0.85)'}}></i><span> <FormattedMessage id="telecom.prepaid.incall" defaultMessage="Incall:"/> {data.tarif_incall[0]}€ /min</span></div>
                                                <div className="telecom_operator-details" ><i className="fas fa-sms" style={{marginLeft: '3px', fontWeight: '800', color: 'rgba(68, 76, 165, 0.85)'}}></i><span> <FormattedMessage id="telecom.prepaid.sms" defaultMessage="SMS:"/> {data.tarif_text[zone]}€ /SMS</span></div>
                                                <div className="telecom_operator-details" ><i className="fas fa-signal" style={{color: 'rgba(68, 76, 165, 0.85)', fontWeight: '800'}}></i><span> Internet: {data.tarif_internet[0]}€ /Mo</span></div>
                                            </div>
                                            <div className="card_zone">
                                            <FormattedMessage id="telecom.prepaid.zone" defaultMessage="Zone">
                                            {msg => (
                                                <TextField
                                                    id="outlined-select-currency"
                                                    select
                                                    label={msg}
                                                    className="card_zone-select"
                                                    value={this.state.zone}
                                                    name="zone"
                                                    onChange={this.handleChangePrice}
                                                    SelectProps={{
                                                        MenuProps: {
                                                            className: classes.menu,
                                                        },
                                                    }}
                                                    InputProps={{ classes: { root: classes.root, notchedOutline: classes.input } }}
                                                    margin="normal"
                                                    variant="outlined"
                                                >
                                                    <MenuItem disabled value={4}><FormattedMessage id="telecom.prepaid.select.zone" defaultMessage="Select zone"/></MenuItem>
                                                    <MenuItem value={0} classes={{ root: classes.root }}><FormattedMessage id="telecom.prepaid.to" defaultMessage="To ">{ msg => (`${msg} zone 1`)}</FormattedMessage></MenuItem>
                                                    <MenuItem value={1} classes={{ root: classes.root }}><FormattedMessage id="telecom.prepaid.to">{ msg => (`${msg} zone 2`)}</FormattedMessage></MenuItem>
                                                    <MenuItem value={2} classes={{ root: classes.root }}><FormattedMessage id="telecom.prepaid.to">{ msg => (`${msg} zone 3`)}</FormattedMessage></MenuItem>
                                                    <MenuItem value={3} classes={{ root: classes.root }}><FormattedMessage id="telecom.prepaid.to">{ msg => (`${msg} zone 4`)}</FormattedMessage></MenuItem>
                                                </TextField>
                                                )}
                                                </FormattedMessage>
                                                <button className="help-button" onClick={this.onOpenModal}><i className="fas fa-question-circle" style={{ color: '#836bc1bf' }} /></button>
                                            </div>
                                            <div className="card_price-wrap">
                                                <div className="card-price prepaid_price">{data.price}<span className="card-unit"> €</span><p className="card-eng-m">{data.trad_eng}</p></div>
                                                <div className="card-button">
                                                    <Button variant="contained" color="primary" className="button" onClick={() => this.handleClick(data.trackingLink)}>
                                                        <FormattedMessage id="telecom.get"/>
                                                </Button>
                                                </div>
                                                <div className="card-eng-d">{data.trad_eng}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                )
            } else {
                return (
                    <div key={key}>
                                    <div className="card-telecom">
                                        <div className="row card-display">
                                            <div className="telecom_operator-logo"><img className="telecom_operator-logo--img" src={data.pics} alt=''></img> <span className="card_name-m">{data.operator}</span></div>
                                            <div className="telecom_operator-details-wrap">
                                                {data.detail.map((data, key) => {return (
                                                    <div className="telecom_operator-details" key={key}>{data}</div>
                                                )})}
                                            </div>
                                            <div className="card_price-wrap">
                                                <div className="card-price prepaid_price">{data.price}<span className="card-unit"> €</span><p className="card-eng-m">{data.trad_eng}</p></div>
                                                <div className="card-button">
                                                    <Button variant="contained" color="primary" className="button" onClick={() => this.handleClick(data.trackingLink)}>
                                                        <FormattedMessage id="telecom.get"/>
                                                </Button>
                                                </div>
                                                <div className="card-eng-d">{data.trad_eng}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                )
            }
        })
    }

    render() {
        const { classes } = this.props;
        if (this.state.countryTelecom.length === 0) {
            return (
                <div className="text" style={{ textAlign: "center" }}>
                    <FormattedMessage id="service.telecom.avaible"/>
                </div>
            )
        } else {
        return (
            <React.Fragment>
                <div className="operator--appear">
                    <div className="telecom_operator-info">
                        {this.buildCard()}
                    </div>
                </div>
                <Modal open={this.state.open} styles={this.state.style} onClose={this.onCloseModal} center blockScroll>
                    <div className="title" style={{ fontSize: '1.3rem', fontWeight: '900' }}><FormattedMessage id="telecom.prepaid.list" defaultMessage="List country avaible"/> </div>
                    <div className="subtitle" style={{ fontWeight: '700' }}>Zone 1</div>
                    <div className="text" style={{marginLeft: "12px"}}>
                        <FormattedMessage id="telecom.prepaid.zone1.country" defaultMessage="Autriche, Belgique, Bulgarie, Chypre Croatie, Danemark, Estonie, Finlande, France, Allemagne,
                        Grèce, Gibraltar, Hongrie, Iles Féroé, Iles Åland, Islande, Irlande, Italie, Lettonie, Lichtenstein, Lituanie,
                        Luxembourg, Malte, Norvège, Pays-Bas, Pologne, Portugal, République tchèque, Roumanie, Saint Marin, Slovaquie, Slovénie, Espagne, Suède,
                        Royaume-Uni, D.R.O.M. et C.O.M (Martinique, Guadeloupe, Guyane française, Réunion, Mayotte, Saint-Martin, Saint-Barthélemy, Saint-Pierre et Micquelon), Açores, Madère et Iles Canaries."/>
                    </div>
                    <div className="subtitle" style={{ fontWeight: '700', marginTop: "12px" }}>Zone 2</div>
                    <div className="text" style={{marginLeft: "12px"}}>
                        <FormattedMessage id="telecom.prepaid.zone2.country" defaultMessage="Algérie, Maroc, Suisse, Tunisie"/>
                    </div>
                    <div className="subtitle" style={{ fontWeight: '700', marginTop: "12px" }}>Zone 3</div>
                    <div className="text" style={{marginLeft: "12px"}}>
                    <FormattedMessage id="telecom.prepaid.zone3.country" defaultMessage="Albanie, Bosnie-Herzegovine, Turquie, Macédoine, Serbie, Monténégro, Kosovo"/>
                    </div>
                    <div className="subtitle" style={{ fontWeight: '700', marginTop: "12px" }}>Zone 4</div>
                    <div className="text" style={{marginLeft: "12px"}} >
                        <FormattedMessage id="telecom.prepaid.otherCountry" defaultMessage="Tous les autres pays ne figurant pas dans la liste"/>
                    </div>
                </Modal>
            </React.Fragment >
        )}
    }
}

Prepaid.propTypes = {
    auth: PropTypes.object.isRequired,
    getPhoneList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    service: state.services,
});

export default connect(
    mapStateToProps, { getPhoneList }
)(withStyles(styles)(Prepaid));