import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import ButtonToService from '../../ButtonToService/ButtonToService';
import corporate_logo from '../../../../assets/img/providers/corporate_logo.png';
import secretary_illustration from '../../../../assets/img/illustration_secretary.svg';
import finance_illustration from '../../../../assets/img/illustration_finance.svg';
import interview_illustration from '../../../../assets/img/illustration_interview.svg';
import console_illustration from '../../../../assets/img/illustration_console.svg';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CorporateModal from './CorporateModal.jsx';
import Modal from 'react-responsive-modal';

require('./Corporate.scss')

class Corporate extends Component {
    constructor(){
        super();
        this.state = {
            open: false,
        }
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentWillMount() {
        if (window.matchMedia("(min-width: 436px)").matches) {
            /* The viewport is at least 400 pixels wide */
            this.setState({
                style: {
                    modal: {
                        'maxWidth': '800px',
                        'width': '100%',
                        'color': '#3f2751',
                        'zIndex': '10000 !important',
                    },
                }
            });
        } else {
            /* The viewport is less than 400 pixels wide */
            this.setState({
                style: {
                    modal: {
                        'height': '100%',
                        'left': '0',
                        'position': 'fixed',
                        'top': '0',
                        'width': '100%',
                        'color': '#3f2751',
                        'zIndex': '10000 !important',

                    },
                }
            });
        }
    }

    componentDidMount() {
        if (!this.props.auth.user.location){
            this.props.history.push('/services');
        }
        window.scrollTo(0, 0)
    }

    onOpenModal() {
        this.setState({open: true });
    };

    onClose() {
        console.log('touch')
        this.setState({ open: false });
    };


    render() {
        var offer = <div className="corporate_offer">
        <div className="corporate_offer_secretarial">
            <div className="corporate_title title" style={{textAlign: 'left', marginTop: "40px"}}>
                <FormattedMessage id="service.corporate.secretarial.title" defaultMessage="Corporate Secretarial Services"/>
            </div>
            <div className="corporate_title title" style={{textAlign: 'left'}}>
                <FormattedMessage id="service.corporate.accounting.title" defaultMessage="Accounting and Bookkeeping Services"/>
            </div>
            <div className="corporate_title title" style={{textAlign: 'left'}}>
                <FormattedMessage id="service.corporate.payroll.title" defaultMessage="HR and Payroll Services"/>
            </div>
            <div className="corporate_title title" style={{textAlign: 'left'}}>
                <FormattedMessage id="service.corporate.saas.title" defaultMessage="BlueMeg SaaS: the Console"/>
            </div>
        </div>
        <div className="habitation_document" style={{ marginTop: "24px", marginBottom: "48px" }}>
        <div className="">
            <div className="health_document-container" onClick={() => window.open("https://bluemeg.com/files/bluemeg-services.pdf", '_blank')}>
                <div>
                    <i className="fal fa-file-alt"></i> Brochure
                </div>
            </div>
        </div>
    </div>
    </div>
    if (window.matchMedia("(max-width: 437px)").matches){
        offer = <div className="corporate_offer">
        <div className="corporate_offer_secretarial">
            <div className="title corporate_title">
                <FormattedMessage id="service.corporate.secretarial.title" defaultMessage="Corporate Secretarial Services"/>
            </div>
        </div>
        <div className="corporate_offer_accounting">
            <div className="title corporate_title ">
                <FormattedMessage id="service.corporate.accounting.title" defaultMessage="Accounting and Bookkeeping Services"/>
            </div>
        </div>
        <div className="corporate_offer_payroll">
            <div className="title corporate_title ">
                <FormattedMessage id="service.corporate.payroll.title" defaultMessage="HR and Payroll Services"/>
            </div>
        </div>
        <div className="corporate_offer_saas">
            <div className="title corporate_title ">
                <FormattedMessage id="service.corporate.saas.title" defaultMessage="BlueMeg SaaS: the Console"/>
            </div>
        </div>
        <div className="habitation_document" style={{ marginTop: "24px", marginBottom: "48px" }}>
        <div className="">
            <div className="health_document-container" onClick={() => window.open("https://bluemeg.com/files/bluemeg-services.pdf", '_blank')}>
                <div>
                    <i className="fal fa-file-alt"></i> Brochure
                </div>
            </div>
        </div>
    </div>
    </div>
    }
        return (
            <div className="Corporate">
                <div className="bank_back"><i className="fas fa-arrow-left" onClick={() => this.props.history.goBack()}></i></div>
                <div className="title">
                    <FormattedMessage id="service.corporation.title" defaultMessage="Corporate Services" />
                </div>
                <div style={{width: "100%"}}>
                    <img style={{width: "100%"}} src={corporate_logo} alt='' />
                </div>
                <div className="corporate_info" style={{marginTop: "20px"}}>
                    <div className="text">
                        <FormattedMessage id="service.corporate.description" 
                        defaultMessage="BlueMeg is an independent corporate services provider, bridging the gap between tailored quality services and the new digital era. Corporate services supported by cutting edge technology to enhance clients’ experience at competitive and transparent pricing.
                        BlueMeg’s team of industry professionals, are fully committed to providing custom-made solutions to a diverse client base from entrepreneurs, start-ups and SME’s to multinational corporations and institutional investors."/>
                    </div>
                </div>
                {offer}
                <ButtonToService issoyoService="true" onOpenModal={() => this.onOpenModal()}/>
                <Modal open={this.state.open} onClose={this.onClose} center styles={this.state.style}>
                    <CorporateModal onClose={this.onClose} index={this.state.index} language={this.props.language} name={this.state.name} handleClickAccount={link => this.handleClickAccount(link)} ></CorporateModal>
                </Modal>
            </div>
        )
    }
}

Corporate.propTypes = {
    auth: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    service: state.services,
});

export default connect(
    mapStateToProps, {}
)(Corporate);