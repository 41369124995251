import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Collapsible from 'react-collapsible';
import ReactPlayer from 'react-player'
import TransferwiseLogo from '../../../../../assets/img/providers/transfertWise_card_new.png';
import TransferWiseSimulator from './TransferWiseSimulator.jsx';
import Modal from 'react-responsive-modal';
import Button from '@material-ui/core/Button';
import Issoyo_logo from '../../../../../assets/img/fav_icon32@2x.png';
import TransferWiseCurrency from './TransferWiseCurrency';
import TransferWisePricing from './TransferWisePricing';

require('./TransferWiseModal.scss');


class TransferWiseModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            position: '0',
            littlepos: '',
            open: false,
            style: {
                modal: {
                    'maxWidth': '700px',
                    'width': '100%',
                    'color': '#3f2751',
                    'textAlign': 'justify'
                },
            },
            youtubeHW: 'https://www.youtube.com/watch?v=MLKKzRvOsLQ',
        }
        this.handleClick = this.handleClick.bind(this);
        this._onReady = this._onReady.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.handleClickAccount = this.handleClickAccount.bind(this);
    }

    componentDidMount(){
        if (this.props.language === "fr-FR") {
            this.setState({youtubeHW: 'https://youtu.be/YkFK4K0vW04'})
        } else {
            this.setState({youtubeHW: 'https://www.youtube.com/watch?v=MLKKzRvOsLQ'})
        }
    }

    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }

    onOpenModal() {
        this.setState({ open: true });
    };

    onCloseModal() {
        this.setState({ open: false });
    };

    handleClick(position) {
        if (this.state.position === position) {
            this.setState({ position: "0" })
        } else {
            this.setState({ position: position });
        }
    };

    handleClickAccount() {
        this.props.handleClickAccount("https://prf.hn/click/camref:1011l7tRd/pubref:TF/destination:https%3A%2F%2Ftransferwise.com%2Fregister%2F");
    };

    render() {
        const { open } = this.state;
        return (
            <div className="service-bank-transfertWise">
                <div className="header">
                    <div className="header_logo"><img className="header-pic" src={TransferwiseLogo} alt=''></img></div>
                    <div className="header-title"><b><FormattedMessage id="bank.TransfertWise.title" defaultMessage="The TransferWise bordeless account" /></b></div>
                </div>
                <div className="cont_vh">
                <div className="cont-mod">
                    <FormattedMessage id="bank.TransfertWise.benefits" defaultMessage="Benefits / Features">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "1")} handleTriggerClick={this.handleClick} accordionPosition="1">
                                <ul className='text'>
                                    <li><FormattedMessage id="bank.TransfertWise.reason1" defaultMessage="Cheaper way to send money internationally." />
                                        <div onClick={this.onOpenModal} style={{ cursor: 'pointer', textDecoration: 'underline' }} className="anchor-card" ><FormattedMessage id="bank.TransfertWise.reason1.1" defaultMessage="Here the reasons." /></div></li>
                                    <li><FormattedMessage id="bank.TransfertWise.reason2" defaultMessage="One account to receive and spend money around the world." /></li>
                                    <li><FormattedMessage id="bank.TransfertWise.reason3" defaultMessage="Hold and convert +40 currencies." /></li>
                                    <li><FormattedMessage id="bank.TransfertWise.reason4" defaultMessage="Get the real mid-market FX rate. Total transparency, no hidden fees." /></li>
                                    <li><FormattedMessage id="bank.TransfertWise.reason5" defaultMessage="Easy online setup." /></li>
                                    <li><FormattedMessage id="bank.TransfertWise.reason6" defaultMessage="Over 3m customers." /></li>
                                    <li><FormattedMessage id="bank.TransfertWise.reason7" defaultMessage="FCA regulated." /></li>
                                    <li><FormattedMessage id="bank.TransfertWise.reason8" defaultMessage="Free debit card including." /></li>
                                    <li><FormattedMessage id="bank.TransfertWise.reason9" defaultMessage="Awesome customer support. TransferWise’s customer support can be accessed via phone, email or TransferWise webchat." /></li>
                                    <li><FormattedMessage id="bank.TransfertWise.reason10" defaultMessage="App available in 13 languages." /></li>
                                </ul>
                            </Collapsible>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="bank.TransfertWise.currencies" defaultMessage="Currencies">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "2")} handleTriggerClick={this.handleClick} accordionPosition="2">
                                <TransferWiseCurrency></TransferWiseCurrency>
                            </Collapsible>
                        )}
                    </FormattedMessage>
                    <FormattedMessage id="bank.TransfertWise.howItWork" defaultMessage="How does TransfertWise work ?">
                        {msg => (
                            <Collapsible trigger={msg} open={(this.state.position === "5")} handleTriggerClick={this.handleClick} accordionPosition="5">
                                <p className='text' >The system is based on free or extremely low-cost local bank account transfers. Money never crosses borders so TransferWise can cut out extortionate bank charges.</p>
                                <ReactPlayer
                                    width='100%'
                                    controls
                                    config={{
                                        youtube: {
                                            playerVars: { showinfo: 1 }
                                        }
                                    }}
                                    url={this.state.youtubeHW}
                                />
                            </Collapsible>)}
                    </FormattedMessage>
                <FormattedMessage id="bank.TransfertWise.testimonials" defaultMessage="Testimonials">
                {msg => (
                    <Collapsible trigger={msg} open={(this.state.position === "7")} handleTriggerClick={this.handleClick} accordionPosition="7">
                        <ReactPlayer
                            width='100%'
                            controls
                            config={{
                                youtube: {
                                    playerVars: { showinfo: 1 }
                                }
                            }}
                            url="https://youtu.be/ONLJpT_F62I"
                        />
                    </Collapsible> 
                )}
                </FormattedMessage>
                <FormattedMessage id="bank.TransfertWise.pricing" defaultMessage="Pricing">
                {msg => (
                    <Collapsible trigger={msg} open={(this.state.position === "3")} handleTriggerClick={this.handleClick} accordionPosition="3">
                        <TransferWisePricing />
                    </Collapsible>
                )}
                    </FormattedMessage>
                <FormattedMessage id="bank.TransfertWise.simulator" defaultMessage="Simulator">
                {msg => (
                    <Collapsible trigger={msg} open={(this.state.position === "8")} handleTriggerClick={this.handleClick} accordionPosition="8">
                        <TransferWiseSimulator />
                    </Collapsible>
                )}
                </FormattedMessage>
                </div>
                </div>
                <div className="footer" style={{ textAlign: 'center' }}>
                    <Button variant="contained" color="primary" className="button" onClick={this.handleClickAccount}>
                        <img src={Issoyo_logo} alt='' style={{ width: '11%', marginRight: '5%' }}></img>
                        <FormattedMessage id="bank.open.account"></FormattedMessage>
                </Button>
                </div>
                <Modal open={open} styles={this.state.style} onClose={this.onCloseModal} center blockScroll>
                    <div className='title' style={{ fontSize: '1.25rem', color: '#3E4594' }}>Reasons</div>
                    <ul className='text'>
                        <li><FormattedMessage id="bank.TransfertWise.reason1.2" defaultMessage="Money never crosses borders. When TransferWise is exchanging euros into pounds, euros are sent into the TransferWise euro account. And when TransferWise receive that, TransferWise send the equivalent amount in pounds from our pounds account."/></li>
                    </ul>
                </Modal>
            </div>
        )
    }
}

TransferWiseModal.propTypes = {
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    service: state.services,
});

export default connect(
    mapStateToProps, {}
)(TransferWiseModal);
